<template>
  <div>
    <div id="map_ref" ref="map_ref" style="height: 635px; width: 860px"></div>
    <el-dialog
      title="省份地图"
      class="pro_title pro_map"
      top="110px"
      :visible.sync="pro_visible"
      :modal-append-to-body="false"
      :append-to-body="false"
      :close-on-click-modal="false"
    >
      <promap
        :pro_info="pro_info"
        :map_data="map_data"
        :city_data="city_data"
      />
    </el-dialog>
  </div>
</template>

<script>
import ret from "../../public/static/map/china.json";
import { getProvinceMapInfo } from "@/utils/map_utils";
import addressList from "../assets/20201201.js";
import promap from "./provmap.vue";
const pieces1 = [
  {
    lte: 1,
    color: "#87E2FF",
  },
  {
    gt: 0,
    lte: 9,
    color: "#4FA0FF",
  },
  {
    gt: 9,
    lte: 29,
    color: "#2C5AFF",
  },
  {
    gt: 29,
    lte: 49,
    color: "#2405E3",
  },
  {
    gt: 49,
    lte: 100,
    color: "#1407A3",
  },
];
let airDate = [
  { name: "北京", value: 0 },
  { name: "安徽", value: 0 },
  { name: "陕西", value: 0 },
  { name: "山西", value: 0 },
  { name: "内蒙古", value: 0 },
  { name: "青海", value: 0 },
  { name: "云南", value: 0 },
  { name: "甘肃", value: 0 },
  { name: "宁夏", value: 0 },
  { name: "吉林", value: 0 },
  { name: "黑龙江", value: 0 },
  { name: "西藏", value: 0 },
  { name: "四川", value: 0 },
  { name: "重庆", value: 0 },
  { name: "贵州", value: 0 },
  { name: "广西", value: 0 },
  { name: "广东", value: 0 },
  { name: "湖南", value: 0 },
  { name: "海南", value: 0 },
  { name: "福建", value: 0 },
  { name: "台湾", value: 0 },
  { name: "新疆", value: 0 },
  { name: "辽宁", value: 0 },
  { name: "河北", value: 0 },
  { name: "山东", value: 0 },
  { name: "天津", value: 0 },
  { name: "河南", value: 0 },
  { name: "江苏", value: 0 },
  { name: "湖北", value: 0 },
  { name: "江西", value: 0 },
  { name: "上海", value: 0 },
  { name: "浙江", value: 0 },
];
const geoCode = [
  { name: "上海", spell: "shanghai", coord: [121.46, 31.28] },
  { name: "河北", spell: "hebei", coord: [116.21, 39.44] },
  { name: "山西", spell: "shanxi", coord: [111.95, 37.65] },
  { name: "内蒙古", spell: "neimenggu", coord: [112.17, 42.81] },
  { name: "辽宁", spell: "liaoning", coord: [122.42, 41.29] },
  { name: "吉林", spell: "jilin", coord: [126.32, 43.68] },
  { name: "黑龙江", spell: "heilongjiang", coord: [128.34, 48.85] },
  { name: "江苏", spell: "jiangsu", coord: [119.26, 33.04] },
  { name: "浙江", spell: "zhejiang", coord: [120.15, 29.28] },
  { name: "安徽", spell: "anhui", coord: [117.28, 31.86] },
  { name: "福建", spell: "fujian", coord: [118.31, 26.07] },
  { name: "江西", spell: "jiangxi", coord: [115.89, 27.97] },
  { name: "山东", spell: "shandong", coord: [118.01, 36.37] },
  { name: "河南", spell: "henan", coord: [113.46, 34.25] },
  { name: "湖北", spell: "hubei", coord: [112.29, 30.98] },
  { name: "湖南", spell: "hunan", coord: [112.08, 27.79] },
  { name: "广东", spell: "guangdong", coord: [113.98, 22.82] },
  { name: "广西", spell: "guangxi", coord: [108.67, 23.68] },
  { name: "海南", spell: "hainan", coord: [110.03, 19.33] },
  { name: "四川", spell: "sichuan", coord: [103.36, 30.65] },
  { name: "贵州", spell: "guizhou", coord: [106.91, 26.67] },
  { name: "云南", spell: "yunnan", coord: [101.71, 24.84] },
  { name: "西藏", spell: "xizang", coord: [89.13, 31.76] },
  { name: "陕西", spell: "shanxi1", coord: [108.94, 35.86] },
  { name: "甘肃", spell: "gansu", coord: [100.82, 38.05] },
  { name: "青海", spell: "qinghai", coord: [96.07, 35.62] },
  { name: "宁夏", spell: "ningxia", coord: [106.27, 37.56] },
  { name: "新疆", spell: "xinjiang", coord: [84.61, 42.89] },
  { name: "北京", spell: "beijing", coord: [116.4, 40.4] },
  { name: "天津", spell: "tianjin", coord: [117.44, 39.52] },
  { name: "重庆", spell: "chongqing", coord: [107.51, 30.33] },
  { name: "香港", spell: "xianggang", coord: [114.17, 22.38] },
  { name: "澳门", spell: "aomen", coord: [113.55, 22.17] },
  { name: "台湾", spell: "taiwan", coord: [121.01, 23.54] },
];
let effectScatter = {};
geoCode.forEach((element) => {
  effectScatter[element.name] = element.coord;
});
export default {
  props: { map_data: {} },
  watch: {
    map_data() {
      this.city_data = {};
      let color_list = ["#87E2FF", "#4FA0FF", "#2C5AFF", "#2405E3", "#1407A3"];
      this.pieces = (this.map_data?.province_color || []).map((ele, index) => {
        // if(ele.max===0){
        //     return {lte: 0,color: ele.color}
        // }else{
        //     return {gt: ele.min-1,lte: ele.max,color: ele.color}
        // }
        if (ele.max === 0) {
          return { lte: 0, color: color_list[0] };
        } else {
          return { gt: ele.min - 1, lte: ele.max, color: color_list[index] };
        }
      });
      let re_data = {};
      (this.map_data?.platforms || []).forEach((ele) => {
        let pro_name = addressList.province_list[ele.province_code] || "";
        let city_name = "";
        if (!addressList.city_list[ele.city_code]) {
          city_name = addressList.county_list[ele.city_code];
        } else {
          city_name = addressList.city_list[ele.city_code];
        }
        // this.city_data = {}
        for (let idx in airDate) {
          let p_name = airDate[idx].name;
          if (pro_name.indexOf(p_name) != -1) {
            if (!re_data[p_name]) {
              re_data[p_name] = 1;
            } else {
              re_data[p_name] = re_data[p_name] + 1;
            }
            if (!this.city_data[p_name]) {
              this.city_data[p_name] = [];
            }
            this.city_data[p_name].push({
              ...ele,
              city_code: ele.city_code,
              city_name,
              desc: ele.desc,
              pro_name: ele.name,
              show_detail: ele.show_detail,
              uni_code: ele.uni_code,
            });
            break;
          }
        }
      });
      this.rel_data = re_data;
      this.air_date = airDate.map((ele) => {
        if (re_data[ele.name]) {
          return { name: ele.name, value: re_data[ele.name] };
        } else {
          return ele;
        }
      });
      this.initChart();
    },
  },
  components: { promap },
  data() {
    return {
      xhartInstance: "",
      pro_visible: false,
      pro_info: { name: "" },
      pieces: pieces1,
      air_date: [],
      rel_data: {},
      city_data: {},
    };
  },
  methods: {
    initChart() {
      this.xhartInstance = this.$echarts.init(this.$refs.map_ref);
      // 获取中国矢量数据
      this.$echarts.registerMap("china", ret);

      // let scatterData = []
      // for(let i in this.rel_data){
      //     scatterData.push({value:effectScatter[i]})
      // }

      const initOption = {
        tooltip: {
          trigger: "item",
          borderColor: "transparent",
          borderWidth: 0,
          padding: 0,
          backgroundColor: "rgba(5,125,133,0.6)",
          formatter: (params, data) => {
            //  +'<div style="color:#fff;font-size:12px;margin:0">'+this.value_name+'</div>'
            let tipHtml =
              '<div style="background: rgba(5,125,133,0.45);padding:5px 12px;backdrop-filter: blur(5px);font-size:12px;">' +
              '<div style="color:#fff;font-size:12px;margin:0">' +
              params.name +
              "</div>";
            if (params.value > 0) {
              tipHtml +=
                '<div style="color:#fff;font-size: 32px;font-weight: 700;color: transparent;height:37px;text-align:center;line-height:37px;-webkit-text-fill-color:#FFCF5F;-webkit-text-stroke:1px #FFF;margin:0">' +
                params.value +
                "</div>";
            }
            tipHtml += "</div>";
            return tipHtml;
          },
        },
        geo: {
          type: "map",
          map: "china",

          grid: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: "100%",
            height: "100%",
          },
          zoom: 1.25,
          label: {
            normal: {
              show: false,
              label: {
                show: false,
              },
            },
            emphasis: {
              show: false,
            },
          },
          itemStyle: {
            label: {
              show: false,
            },
            normal: {
              borderColor: "#31FBFB",
              borderWidth: 1.5,
              // areaColor: '#74C3E1',
              areaColor: "#87E2FF",
              shadowColor: "#31FBFB",
            },
            emphasis: {
              shadowOffsetY: 2,
              shadowOffsetX: 2,
              shadowBlur: 12,
              // areaColor: '#87E2FF',
              areaColor: "#74C3E1",
              label: {
                show: false,
              },
              itemStyle: {
                shadowBlur: 6,
                shadowColor: "yellow",
                color: "yellow",
              },
            },
          },
        },
        series: [
          {
            data: this.air_date,
            geoIndex: 0,
            type: "map",
          },
          {
            // data:scatterData,
            type: "effectScatter",
            coordinateSystem: "geo",
            rippleEffect: {
              scale: 7,
              brushType: "stroke",
              color: "yellow",
              itemStyle: {
                shadowBlur: 6,
                shadowColor: "yellow",
                color: "yellow",
              },
            },
            label: {
              normal: {
                show: true,
                textStyle: {
                  color: "#000",
                },
                label: {
                  show: false,
                },
              },
              emphasis: {
                show: false,
                label: {
                  show: false,
                },
              },
            },
            showEffectOn: "render",
            normal: {
              color: "yellow", //颜色
            },
            itemStyle: {
              shadowBlur: 6,
              shadowColor: "yellow",
              color: "yellow",
            },
          },
        ],
        visualMap: {
          pieces: this.pieces,
          show: false,
          // min:0,
          // max:28,
          // show:false,
          // inRange:{
          //     color:['#74C3E1','#2C5AFF',]
          // },
        },
      };
      this.xhartInstance.setOption(initOption);
      this.xhartInstance.on("click", (arg) => {
        this.pro_info = getProvinceMapInfo(arg.name);
        if (!this.rel_data[arg.name]) {
          return;
        }
        this.pro_info.name = arg.name;
        if (this.pro_info.key) {
          this.pro_visible = true;
        }
      });
      // this.xhartInstance.on('mouseover',arg=>{
      //     this.xhartInstance.dispatchAction({
      //         type:'downplay'
      //     })
      // })
    },
  },
  mounted() {},
};
</script>

<style>
</style>