<template>
  <div class="edit-homer2">
      <div>
        <div style="font-weight: 500;line-height: 24px;height:24px;font-size:14px">文本信息</div>
        <div class="cut-line"></div>
        <div class="flex-bte" style="margin-bottom:24px;position: relative;">
            <div class="flex-bte"><span style="line-height:32px">区块标题：</span>
                <div>
                    <el-input v-model="dialog_info.title" @change="endEmpty(dialog_info,'title')" :class="{errorbd:check_type&&!dialog_info.title}" maxlength="10" placeholder="请输入，10字内"></el-input>
                    <div class="error-txt" style="left:80px" v-if="check_type&&!dialog_info.title">区块标题不得为空</div>
                </div>
            </div>
            <!-- <div class="flex-bte">
                <span style="line-height:32px">横轴单位：</span>
                <el-input v-model="dialog_info.x_unit" @change="endEmpty(dialog_info,'x_unit')" maxlength="10" placeholder="请输入，10字内"></el-input>
            </div> -->
        </div>
        <div class="flex-bte" style="margin-bottom:24px;position: relative;">
            <div class="flex-bte"><span style="line-height:32px">数值名称：</span>
                <div>
                    <el-input v-model="dialog_info.value_name" @change="endEmpty(dialog_info,'value_name')" maxlength="10" placeholder="请输入，10字内"></el-input>
                </div>
            </div>
            <div class="flex-bte">
                <span style="line-height:32px">数值单位：</span>
                <el-input v-model="dialog_info.value_unit" @change="endEmpty(dialog_info,'value_unit')" maxlength="10" placeholder="请输入，10字内"></el-input>
            </div>
        </div>
        <div class="img-set" style="display:flex;">
            <span style="width:180px">横轴项设置:（1～15项）</span>
            <div class="set-list" style="width:588px">
                <span v-for="(item,index) in legend" :key="index" style="position:relative">
                    <el-input :id="'legend_'+index" style="margin-bottom:8px" :class="{errorbd:check_type&&!legend[index]}" maxlength="10" @change="endEmpty(legend,index)" v-model="legend[index]">
                        <i slot="suffix" class="el-icon-error" v-if="legend.length>1" @click="deleItem(index)"></i>
                    </el-input>
                    <div class="error-txt" style="left:5px;top:24px" v-if="check_type&&!legend[index]">此项不得为空</div>
                </span>
                <span @click="addLegend" v-if="legend.length<15" class="add-btn" style="display: inline-block;background: rgba(255,255,255,0.40);border: 1px solid #d9d9d9;padding-right:11px">+添加</span>
            </div>
        </div>
        <div class="img-set" style="display:flex;margin-top:24px">
            <span style="width:180px">纵轴项设置:（1～12项）</span>
            <div class="set-list" style="width:588px">
                <span v-for="(item,index) in legendy" :key="index" style="position:relative">
                    <el-input :id="'legendy_'+index" style="margin-bottom:8px" :class="{errorbd:check_type&&!legendy[index]}" maxlength="10" @change="endEmpty(legendy,index)" v-model="legendy[index]"><i slot="suffix" v-if="legendy.length>1" class="el-icon-error" @click="deleItemy(index)"></i></el-input>
                    <div class="error-txt" style="left:5px;top:24px" v-if="check_type&&!legendy[index]">此项不得为空</div>
                </span>
                <span class="add-btn" v-if="legendy.length<12" @click="addLegendy" style="display: inline-block;background: rgba(255,255,255,0.40);border: 1px solid #d9d9d9;padding-right:11px">+添加</span>
            </div>
        </div>

        <div style="font-weight: 500;line-height: 24px;height:24px;font-size:14px;margin-top:24px">图表数据</div>
        <div class="cut-line"></div>
        <div class="flex-bte" style="position:relative">
            <div class="con-l" style="height:240px;padding-bottom:0;padding-top:0;" @scroll="orderScroll">
                <div :class="{'box-shadow':box_shadow}" style="top:0"></div>
                <div v-if="legendy.length==0" class="no-data-l">
                    <div class="no-data-l-img"></div>
                    <div>还没有添加数据</div>
                    <div></div>
                </div>
                <div v-for="(item,index) in legendy" :class="{active:active_index===index,itemlist:true,lidiv:true}" :key="index" @click="changeActive(index)">{{item}}</div>
            </div>
            <div class="con-r" style="height:240px;flex-wrap: wrap;">
                <div class="flex-bte" style="flex-wrap: wrap;">
                    <div v-if="now_data.length===0" class="no-data-r">
                        <div class="no-data-r-img"></div>
                        <div>暂无数据</div>
                    </div>
                    <div v-else v-for="(item,index) in legend" :key="index">
                        <div>{{item}}</div>
                        <el-input class="width188" v-model="now_data[index]" maxlength="9" @change="formatN(now_data,index)" @input="formatVal(now_data,index)" :disabled="!active_index&&active_index!=0" placeholder=""></el-input>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div></div>
  </div>
</template>

<script>
import {heatmapinter} from "../api/getdata"
export default {
    props:{
        map_info:{title:'',x_unit:'',value_name:'',value_unit:'',x:[],y:[],data:[]}
    },
    data(){
        return{
            edit_type:false,
            edit_dialog_visible:false,
            dialog_info:this.map_info||{},
            legend:this.map_info.x||[],
            legendy:this.map_info.y||[],
            now_data:[],
            active_index:'',
            check_type:false
        }
    },
    methods:{
        addLegend(){
            let index = this.legend?.length ||0
            if(index>15){
                return
            }
            this.legend.push('')
            this.$nextTick(()=>{
                document.querySelector('#legend_'+index).focus()

            })
            if(!this.dialog_info.data){
                this.$set(this.dialog_info,'data',[])
            }
            this.dialog_info.data.forEach(ele=>{
                if(!ele){
                    ele=[]
                }
                ele.push(null)
            })
        },
        addLegendy(){
            let index = this.legendy?.length ||0
            if(index>12){
                return
            }
            this.legendy.push('')
            this.$nextTick(()=>{
                document.querySelector('#legendy_'+index).focus()

            })
            if(!this.dialog_info.data){
                this.$set(this.dialog_info,'data',[])  
            }
            let values = this.legend.map(ele=>{
                return ''
            })
            this.$set(this.dialog_info,'data',[...this.dialog_info.data,values])
            if(this.legendy.length==1){
                this.changeActive(0)
            }
        },
        deleItem(index){
            this.legend.splice(index,1)
            this.dialog_info.data.forEach(ele => {
                ele.splice(index,1)
            });
        },
        deleItemy(index){
            this.legendy.splice(index,1)
            this.dialog_info.data.splice(index,1)
            if(this.dialog_info.data.length===0){
                this.now_data=[]
            }
            if(this.active_index===0){
                this.changeActive(0)
            }else{
                this.changeActive(this.active_index-1)
            }
        },
        getDate(){
            heapmapinter('detail').then(ele=>{
                this.dialog_info = ele.data ||{}
                this.r2_name = this.dialog_info.title || ''

            })
        },
        changeActive(index){
            if(this.dialog_info?.data?.length==0){
                return
            }
            this.active_index = index;
            this.now_data = this.dialog_info?.data[index] || []
        },
        formatVal(now_data,index){
            now_data[index] = now_data[index].replace(/[^-\d.]/g, '').replace(".", "$#$").replace(/\./g, "").replace("$#$", ".") ||''
            if(['.','-','0'].includes(now_data[index].slice(-1))){
                return
            }
            if(!now_data[index]||isNaN(now_data[index])){
                now_data[index] = null
                return
            }
            now_data[index] = parseFloat(now_data[index])
        },
        formatN(now_data,index){
            if(now_data[index]===null){
                return
            }else{
                now_data[index] = Number(now_data[index])
            }
        },
        saveEdit(){
            this.check_type = true
            if(!this.dialog_info.title){
                this.$message.error({
                    showClose: true,
                    message: "失败：区块标题不得为空"
                });
                return
            }
            if(this.legend.length<1){
                this.$message.error({
                    showClose: true,
                    message: "失败：*横轴项设置*至少需添加一项"
                });
                return
            }
            for(let i of this.legend){
                if(!i){
                    this.$message.error({
                        showClose: true,
                        message: "失败：横轴项设置名称不得为空"
                    });
                    return
                }
            }
            if(this.legendy.length<1){
                this.$message.error({
                    showClose: true,
                    message: "失败：*纵轴项设置*至少需添加一项"
                });
                return
            }
            
            for(let i of this.legendy){
                if(!i){
                    this.$message.error({
                        showClose: true,
                        message: "失败：纵轴项设置名称不得为空"
                    });
                    return
                }
            }
            this.dialog_info.data.forEach((ele,index)=>{
                this.dialog_info.data[index] = ele.map(ele1=>{
                    if(ele1===""||ele1==null||isNaN(ele1)){
                        return null
                    }else{
                        return Number(ele1)
                    }
                })
            })
            heatmapinter('update',{...this.dialog_info,x:this.legend,y:this.legendy}).then(ele=>{
                this.$message.success({
                    showClose: true,
                    message: "成功：操作成功"
                });
                setTimeout(() => {
                    this.$emit('updateDate')
                }, 800);
                this.$emit('update:visible',false)
            })
            
        }
    },
    mounted(){
        this.changeActive(0)
    }
}
</script>

<style lang="less">
.edit-homer2{
    .con-l div.itemlist{
        cursor: pointer;
        &.active,&:hover{
            background: rgba(18,155,255,0.40);
            border: 1px solid #129bff;
        }
    }
}
</style>