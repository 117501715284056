<template>
  <div class="project-pg">
    <!-- 标题 -->
    <div class="top-line">
      <div class="title-name">
        {{ name }}
        <!-- <img v-if="!showedit" src="../assets/cancel.png" alt="" /> -->
        <div v-if="!showedit" class="img" @click="closepage">
          <i class="el-icon-close"> </i>
        </div>
      </div>
      <div
        v-show="!showedit && Manage"
        @dblclick="showEdit"
        class="edit"
      ></div>
      <div v-show="showedit" class="edit2 no-select" @click="outedit">
        退出编辑
      </div>
      <div v-show="showedit" class="edit2 edit3 no-select" @click="set_model">
        设置页面模版
      </div>
    </div>
    <!-- 主要内容区 -->
    <div class="content-bd">
      <!-- 左边内容 -->
      <div class="left-p">
        <!-- 左上组件 -->
        <r1
          v-if="data0.type == 1"
          @fatheredit="goedit(0)"
          :data3="data0"
          :showedit="showedit"
        ></r1>

        <l2
          v-if="data0.type == 2"
          @fatheredit="goedit(0)"
          :data1="data0"
          :showedit="showedit"
        ></l2>
        <l1
          v-if="data0.type == 3"
          @fatheredit="goedit(0)"
          :data0="data0"
          :showedit="showedit"
        ></l1>
        <r2
          v-if="data0.type == 4"
          @fatheredit="goedit(0)"
          :data3="data0"
          :showedit="showedit"
        ></r2>
        <!-- 左下组件 -->
        <r1
          v-if="data1.type == 1"
          @fatheredit="goedit(1)"
          :data3="data1"
          :showedit="showedit"
        ></r1>
        <l2
          v-if="data1.type == 2"
          @fatheredit="goedit(1)"
          :data1="data1"
          :showedit="showedit"
        ></l2>
        <l1
          v-if="data1.type == 3"
          @fatheredit="goedit(1)"
          :data0="data1"
          :showedit="showedit"
        ></l1>
        <r2
          v-if="data1.type == 4"
          @fatheredit="goedit(1)"
          :data3="data1"
          :showedit="showedit"
        ></r2>
      </div>
      <!-- 中间内容 -->
      <div class="content-p">
        <div :class="{ 'c1-bd': true, none: data2length < 6 }">
          <div class="p-title">
            {{ data2.title
            }}<span v-if="data2.value_unit">（{{ data2.value_unit }}）</span>
            <img
              @click="goedit(2)"
              v-if="showedit"
              src="../assets/editicon.png"
              alt=""
            />
          </div>

          <el-carousel
            class="c1-bottom"
            trigger="click"
            height="95px"
            arrow="never"
            :loop="true"
            :interval="5000"
            v-if="data2.data.length != 0"
          >
            <el-carousel-item
              class="c1-bottom_item"
              v-for="(item1, index) in data2.data"
              :key="index"
            >
              <div v-for="(item, index) in item1" :key="index">
                <div>
                  <div class="item_left">
                    <div class="text">
                      <div class="p1">{{ item.min }}</div>
                      <div class="p2">~</div>
                      <div class="p3">{{ item.max }}</div>
                    </div>
                  </div>
                  <div class="item_right">
                    <div class="p1">{{ item.name }}</div>
                    <div class="p2">
                      {{ item.value
                      }}<span class="span">{{ data2.value_unit }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
          <div v-else class="c1-bd-nodata">
            <img src="../assets/empty.png" alt="" />
            <div>暂无数据</div>
          </div>
        </div>

        <div class="c2-bd">
          <img
            class="edit"
            v-if="showedit"
            src="../assets/editicon.png"
            alt=""
            @click="showimagedetail"
          />
          <!-- <img v-if="imagedetail" :src="imagedetail" alt="图片详情" /> -->
          <!-- <img v-else src="../assets/bg.png" alt="图片详情" /> -->
          <el-image
            v-if="imagedetail"
            :src="imagedetail"
            :preview-src-list="[imagedetail]"
            class="el-img"
          >
          </el-image>

          <div class="no" v-if="!imagedetail || !viewall">
            <img src="../assets/empty.png" alt="" />
            <div>暂无数据</div>
          </div>
        </div>
      </div>
      <!-- 右边内容 -->
      <div class="right-p">
        <!-- <r1  @fatheredit="goedit(3)" :data3="data3" :showedit="showedit"></r1> -->
        <r1
          v-if="data3.type == 1"
          @fatheredit="goedit(3)"
          :data3="data3"
          :showedit="showedit"
        ></r1>
        <l2
          v-if="data3.type == 2"
          @fatheredit="goedit(3)"
          :data1="data3"
          :showedit="showedit"
        ></l2>
        <l1
          v-if="data3.type == 3"
          @fatheredit="goedit(3)"
          :data0="data3"
          :showedit="showedit"
        ></l1>
        <r2
          v-if="data3.type == 4"
          @fatheredit="goedit(3)"
          :data3="data3"
          :showedit="showedit"
        ></r2>

        <!-- <r2  @fatheredit="goedit(4)" :data3="data4" :showedit="showedit"></r2> -->
        <r1
          v-if="data4.type == 1"
          @fatheredit="goedit(4)"
          :data3="data4"
          :showedit="showedit"
        ></r1>
        <l2
          v-if="data4.type == 2"
          @fatheredit="goedit(4)"
          :data1="data4"
          :showedit="showedit"
        ></l2>
        <l1
          v-if="data4.type == 3"
          @fatheredit="goedit(4)"
          :data0="data4"
          :showedit="showedit"
        ></l1>
        <r2
          v-if="data4.type == 4"
          @fatheredit="goedit(4)"
          :data3="data4"
          :showedit="showedit"
        ></r2>
      </div>
    </div>
    <!-- 图片弹出框 -->
    <el-dialog
      title="项目图片"
      :visible.sync="dialogVisible1"
      width="472"
      class="title-edit-dialog dialog2"
      :close-on-click-modal="false"
      :modal-append-to-body="true"
      :append-to-body="true"
    >
      <div class="box">
        <div class="upload">
          <div v-if="uploadStatus == 'upload'" class="status1">
            <el-upload
              action=""
              list-type="picture"
              :show-file-list="false"
              :before-upload="beforeUpload"
              :http-request="upLoad"
              :on-change="imgSaveToUrl"
              class="box"
            >
              <img src="../assets/upadd.png" alt="" />
              <div>上传</div>
            </el-upload>
          </div>
          <div v-if="uploadStatus == 'uploadding'" class="status2 status22">
            <img src="../assets/cycle.png" alt="" />
            <div>上传中</div>
          </div>
          <div v-if="uploadStatus == 'afterupload'" class="status2">
            <div>上传中</div>
            <el-progress
              :show-text="false"
              :stroke-width="4"
              :percentage="showtime"
            ></el-progress>
          </div>
          <div v-if="uploadStatus == 'uploadError'" class="status3">
            <div class="img">
              <img src="../assets/uperror.png" alt="" />
              <div>image.png</div>
            </div>
            <div class="pop">
              <div>
                <el-upload
                  action=""
                  list-type="picture"
                  :show-file-list="false"
                  :before-upload="beforeUpload"
                  :http-request="upLoad"
                  :on-change="imgSaveToUrl"
                >
                  <img src="../assets/xiugai.png" alt="" />
                </el-upload>
              </div>
            </div>
          </div>
          <div v-if="uploadStatus == 'uploaddone'" class="status4">
            <img :src="active_url" alt="" />
            <div class="pop">
              <div>
                <el-upload
                  action=""
                  list-type="picture"
                  :show-file-list="false"
                  :before-upload="beforeUpload"
                  :http-request="upLoad"
                  :on-change="imgSaveToUrl"
                >
                  <img src="../assets/xiugai.png" alt="" />
                </el-upload>
                <!-- <img src="../assets/xiugai.png" alt="" /> -->
                <el-image
                  v-if="active_url"
                  style="width: 40px; height: 40px"
                  :src="require('../assets/eyes.png')"
                  :preview-src-list="[active_url]"
                >
                </el-image>
              </div>
            </div>
          </div>
        </div>
        <div class="text">
          请上传10M以内的png/jpg/jpeg/gif格式文件，<br />
          建议图片比例为16:10
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancel" @click="cancelImage">取 消</el-button>
        <el-button class="submit-btn" type="primary" @click="saveImage"
          >保存</el-button
        >
      </span>
    </el-dialog>
    <!-- 修改数据弹出框 -->
    <el-dialog
      title=" 项目数据"
      :visible.sync="dialogVisible"
      width="830"
      class="title-edit-dialog dialog1"
      :close-on-click-modal="false"
      :modal-append-to-body="true"
      :append-to-body="true"
      @close="closedialog"
    >
      <div class="dialog-content">
        <div class="title">基础信息</div>
        <div class="input">
          <div class="input-top">
            <div class="text-top">区块标题：</div>
            <el-input
              @blur="loseblur1"
              @focus="titlefocus"
              maxlength="10"
              v-model="datainfo.title"
              placeholder="请输入，10字内"
              class="resetclass"
            >
            </el-input>
            <div v-if="showerror1" class="error">此项不得为空</div>
          </div>

          <div class="input-bottom">
            <div class="text-bottom">数值单位：</div>
            <el-input
              v-model="datainfo.value_unit"
              maxlength="5"
              @blur="loseblur2"
              @focus="titlefocus"
              placeholder="请输入，5字内"
            >
            </el-input>
            <!-- <div v-if="showerror2" class="error">错误提示</div> -->
          </div>
        </div>
        <div class="style">
          <div class="style-l">列表样式：</div>
          <div class="style-rr">
            <div v-if="area != 2" class="tab">
              <div
                @click="active(1)"
                class="tab1"
                :class="datainfo.type == 1 ? 'activeclass' : ''"
              >
                样式1
              </div>
              <div
                @click="active(2)"
                class="tab2"
                :class="datainfo.type == 2 ? 'activeclass' : ''"
              >
                样式2
              </div>
              <div
                @click="active(3)"
                class="tab3"
                :class="datainfo.type == 3 ? 'activeclass' : ''"
              >
                样式3
              </div>
            </div>
            <div v-if="area != 2" class="style-bottom">
              <img
                v-if="datainfo.type == 1"
                src="../assets/liststyle1.png"
                alt=""
              />
              <img
                v-if="datainfo.type == 2"
                src="../assets/liststyle2.png"
                alt=""
              />
              <img
                v-if="datainfo.type == 3"
                src="../assets/liststyle3.png"
                alt=""
              />
            </div>
            <div v-else class="style-bottom">
              <img src="../assets/liststyle.png" alt="" />
            </div>
          </div>
          <div v-if="false" class="style-r">
            <img src="../assets/liststyle.png" alt="" />
          </div>
        </div>
        <div class="title parameter">参数列表</div>
        <div id="param-list" class="param-list">
          <div class="th">
            <div class="th1">序号</div>
            <div class="th2">参数名称</div>
            <div class="th3">当前值</div>
            <div class="th4">阈值下限</div>
            <div class="th5">阈值上限</div>
            <div class="th6">操作</div>
          </div>
          <div class="nolist" v-if="datainfo.data.length == 0">
            <img src="../assets/tianjia.png" alt="" /><span>暂无参数，</span
            ><span class="tianjia" @click="addlist">添加</span>
          </div>
          <div v-else class="td">
            <div
              class="td1"
              v-for="(item, index) in datainfo.data"
              :key="index"
              :id="index + 'Id'"
            >
              <div class="td1-1">{{ index + 1 }}</div>
              <div class="td1-2 retd1-2">
                <input
                  @focus="getfocus"
                  @blur="losefocus1"
                  maxlength="15"
                  v-model="item.name"
                  type="text"
                  class="input1"
                />
              </div>
              <div class="td1-3">
                <input
                  @focus="getfocus"
                  @blur="losefocus"
                  v-model.number="item.value"
                  maxlength="5"
                  type="text"
                  onkeyup="this.value=this.value.replace(/[^\-?\d.]/g,'')"
                />
              </div>
              <div class="td1-4">
                <input
                  @focus="getfocus"
                  @blur="losefocus"
                  v-model.number="item.min"
                  maxlength="5"
                  type="text"
                  onkeyup="this.value=this.value.replace(/[^\-?\d.]/g,'')"
                />
              </div>
              <div class="td1-5">
                <input
                  @focus="getfocus"
                  @blur="losefocus"
                  v-model.number="item.max"
                  maxlength="5"
                  type="text"
                  onkeyup="this.value=this.value.replace(/[^\-?\d.]/g,'')"
                />
              </div>
              <div class="td1-6">
                <img
                  @click="dellist(index, item)"
                  class="del"
                  src="../assets/del.png"
                  alt=""
                />
                <img
                  v-if="index == datainfo.data.length - 1"
                  @click="addlist"
                  src="../assets/add.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancel" @click="dialogVisible = false"
          >取 消</el-button
        >
        <el-button class="submit-btn" type="primary" @click="saveData"
          >保存</el-button
        >
      </span>
    </el-dialog>
    <!-- 设置页面模板弹出框 -->
    <el-dialog
      title="设置页面模板"
      :visible.sync="show_setingModel"
      width="418px"
      class="title-edit-dialog seting_model1"
      :close-on-click-modal="false"
      :modal-append-to-body="true"
      :append-to-body="true"
      @closed="model_cancel"
    >
      <div class="dialog-body">
        <div class="model">
          <div class="left" @click="select_model1">
            <div class="left_picture">
              <img src="../components/projectinfo/img/model1.png" alt="" />
            </div>
            <div class="left_name">模板 1</div>
            <img
              v-if="show_select == 1"
              class="left_select_img"
              src="../components/projectinfo/img/select_model.png"
              alt=""
            />
          </div>
          <div class="right" @click="select_model2">
            <div class="right_picture">
              <img src="../components/projectinfo/img/model2.png" alt="" />
            </div>
            <div class="right_name">模板 2</div>
            <img
              v-if="show_select == 2"
              class="right_select_img"
              src="../components/projectinfo/img/select_model.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancel" @click="model_cancel">取 消</el-button>
        <el-button class="submit-btn" type="primary" @click="model_save"
          >保存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  teamdetail,
  uploadoss,
  uploadfile,
  userinfo,
  template1,
} from "../api/getdata"; //引入接口

//四个组件 （左上，左下，右上， 右下）
import l1 from "../components/left1-bd.vue";
import l2 from "../components/left2-bd.vue";
import r1 from "../components/right1-bd.vue";
import r2 from "../components/right1-bd.vue";
import md5 from "js-md5";
export default {
  components: {
    l1,
    l2,
    r1,
    r2,
  },
  data() {
    return {
      // editall: false,
      // viewall: false,
      name: "", //项目标题
      showedit: false,
      dialogVisible: false,
      dialogVisible1: false,
      showerror1: false,
      showerror2: false,
      show_setingModel: false, //设置页面模板
      show_select: 2, //控制显示地模板
      data2length: 0,
      upload_imginfo: {}, //获取key授权码
      uploaddingimage: "",
      active_url: "",
      // uploadStatus: "uploadError",
      uploadStatus: "upload", //记录上传进度
      showtime: 0, //上传进度 表示百分比
      file_key: "",
      imagedetail: "", //图片详情数据
      area: 0,
      data0: {}, //左上数据
      data1: {}, //左下数据
      data2: { data: [] }, //中上数据 data是为了用length判断有无数据 是否显示隐藏
      data3: {}, ///右上数据
      data4: {}, //右下数据
      datalist: [{ name: "3" }, { name: "4" }],
      datainfo: {
        title: "",
        type: 1,
        data: [
          { name: "3", value: 2, min: 0, max: 3 },
          { name: "3", value: 2, min: 0, max: 3 },
        ],
      }, //各块区域弹出框回显数据
    };
  },

  mounted() {
    userinfo("info").then((ele) => {
      let user_auths = ele.data?.auths || {};
      this.uni_code = this.$route.query.uni_code;
      this.name = this.$route.query.name;
      this.getDetail(0);
      this.getDetail(1);
      this.getDetail(2);
      this.getDetail(3);
      this.getDetail(4);
      this.getImageDetail();
    });
  },
  methods: {
    //获取详情数据
    getDetail(area) {
      // let arg = { name: this.keyWords, ...this.pageinfo };
      teamdetail("detail", {
        uni_code: this.uni_code,
        area,
      }).then((ele) => {
        // console.log(ele);
        // this.datainfo = ele.data || {};
        // this.datainfo={...this.datainfo}
        ele.data = ele?.data || { type: 2, data: [] };
        this.datainfo = JSON.parse(JSON.stringify(ele.data || { data: [] }));

        if (area == 0) {
          this.data0 = ele.data || {};
          // this.datainfo = ele.data;
        }
        if (area == 1) {
          this.data1 = ele.data || {};
        }
        if (area == 2) {
          this.data2 = ele.data || { data: [] };
          let datalength = this.data2?.data?.length || 0;
          this.data2length = datalength;
          let length = Math.ceil(datalength / 5); //要拆分小数组的长度

          let newarr = [];

          for (let i = 0; i < length; i++) {
            newarr.push(this.data2.data.slice(i * 5, (i + 1) * 5));
          }
          this.data2.data = newarr;
        }
        if (area == 3) {
          this.data3 = ele.data;
        }
        if (area == 4) {
          this.data4 = ele.data;
        }
      });
    },
    //获取图片详情
    getImageDetail() {
      teamdetail("image_detail", {
        uni_code: this.uni_code,
      }).then((ele) => {
        this.imagedetail = ele.data?.url || "";
      });
    },
    //关闭弹窗 重置样式
    closedialog(event) {
      document.getElementsByClassName(
        "resetclass"
      )[0].style = `border: 1px solid rgba(255, 255, 255, 0.65)`;
      this.showerror1 = false;
      document.getElementsByClassName("retd1-2").forEach((item) => {
        item.style = `border: 1px solid rgba(255, 255, 255, 0.19);`;
      });
    },

    //保存图标数据
    saveData() {
      if (this.showerror1 || !this.datainfo.title) {
        this.showerror1 = true;
        this.$message.error("区块标题不能为空");
        return;
      }
      this.datainfo.data.forEach((item) => {
        if (item.value === "") {
          item.value = null;
        }
        if (item.min === "") {
          item.min = null;
        }
        if (item.max === "") {
          item.max = null;
        }
      });

      let elements = document.getElementsByClassName("input1");
      for (let i = 0; i < elements.length; i++) {
        if (elements[i].value === "") {
          elements[i].parentElement.style = `border: 1px solid rgb(255, 0, 0);`;
          this.$message.error("参数名称不能为空");
          return;
        }
      }

      for (let i = 0; i < this.datainfo.data.length; i++) {
        if (!this.datainfo.data[i].name) {
          this.$message.error("参数名称不能为空");

          this.$nextTick(() => {
            let lidv = document.getElementById(i + "Id"); // 要滚动的元素
            lidv.scrollIntoView();
          });
          return;
        }
      }
      teamdetail("save", {
        uni_code: this.uni_code,
        area: this.area,
        data: this.datainfo,
      }).then((ele) => {
        if (ele.code != 2000) {
          this.$message.error(ele.msg);
          return;
        }
        this.getDetail(this.area);
        this.dialogVisible = false;
        this.$message.success("操作成功");
      });
    },
    //双击编辑
    showEdit() {
      this.showedit = true;
      // let user_auths = JSON.parse(localStorage.getItem("user_auths"))[
      //   "edit-all"
      // ];

      // if (user_auths) {
      //   this.showedit = true;
      // } else {
      //   this.$message.error("没有编辑权限");
      // }
    },
    //编辑点击
    showimagedetail() {
      this.dialogVisible1 = true;
      if (this.imagedetail) {
        this.active_url = this.imagedetail;
        this.uploadStatus = "uploaddone";
      }
    },
    //点击关闭按钮
    closepage() {
      window.close();
    },
    //退出编辑
    outedit() {
      this.showedit = false;
    },
    //点击进行编辑
    goedit(area) {
      this.dialogVisible = true;
      this.area = area;
      this.getDetail(area);
    },
    //编辑点击进行删除
    dellist(index, item) {
      this.datainfo.data.splice(index, 1);
    },
    //点击添加
    addlist() {
      this.datainfo.data.push({});

      this.$nextTick(() => {
        // this.$nextTick 更改数据后当你想立即使用js操作新的视图的时候需要使用它
        let lidv = document.getElementsByClassName("td")[0]; // 要滚动的元素
        lidv.scrollTop = lidv.scrollHeight;
        // console.log( lidv.scrollHeight);元素的总高度
        // console.log( lidv.scrollTop);超出元素本身的高度
      });
    },
    // 标题输入框获焦点
    titlefocus($event) {
      $event.target.parentElement.style = `border: 1px solid #0a6ed1;
        `;
      this.showerror1 = false;
    },
    // 输入框获焦点
    getfocus($event) {
      $event.target.parentElement.style = `border: 1px solid rgba(255, 255, 255, 0.65);
         background: #1c1f25;
        `;
      $event.target.style = `
         background: #1c1f25;
        `;
      console.log($event.target);
    },
    // 输入框失去焦点 border-right: 2px solid rgba(255, 255, 255, 0.19);
    losefocus($event) {
      $event.target.parentElement.style = `border-right: 1px solid rgba(255, 255, 255, 0.19);  border-bottom: 1px solid rgba(255, 255, 255, 0.19);`;
      $event.target.style = `
        rgba(255, 255, 255, 0.19);
        `;
      console.log($event.target.parentElement);
      console.log($event);
    },
    losefocus1($event) {
      if (!$event.target.value) {
        console.log($event.target.parentElement);
        $event.target.parentElement.style = `border: 1px solid rgba(255, 0, 0); `;
        $event.target.style = `
          rgba(255, 255, 255, 0.19);
          `;
        // this.$message.error("参数名称不能为空1");
      } else {
        $event.target.parentElement.style = `border-right: 1px solid rgba(255, 255, 255, 0.19);  border-bottom: 1px solid rgba(255, 255, 255, 0.19);`;
        $event.target.style = `
        rgba(255, 255, 255, 0.19);
        `;
      }

      console.log($event.target.parentElement);
      console.log($event);
    },

    //选项卡切换
    active(index) {
      this.datainfo.type = index;
    },
    format(percentage) {
      return percentage === 100 ? "1" : `${percentage}%`;
    },
    //标题失去焦点
    loseblur1(event) {
      if (event.target.value.length == 0) {
        this.showerror1 = true;
        event.target.parentElement.style = `border: 1px solid #f85f64;`;
      } else {
        this.showerror1 = false;
        event.target.parentElement.style = `border: 1px solid rgba(255, 255, 255, 0.65);`;
      }
    },
    //标题失去焦点
    loseblur2(event) {
      if (event.target.value.length == 0) {
        this.showerror2 = true;
      } else {
        this.showerror2 = false;
        event.target.parentElement.style = `border: 1px solid rgba(255, 255, 255, 0.65);`;
      }
    },

    //获取授权

    beforeUpload(ele) {
      console.log(ele);
      let file_type = ele.name.split(".").pop();
      let arr = ["jpg", "JPG", "jpeg", "JPEG", "png", "PNG", "gif", "GIF"];
      if (arr.indexOf(file_type) == -1) {
        this.$message.error("文件大小或格式不匹配");
        return false;
      }
      const isLt2M = ele.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error("上传文件大小不能超过 10M");
        return false;
      }
      let type = "detail_image";
      return new Promise((resolve, reject) => {
        //从后台获取第一步所需的数据
        //getOssToken 获取OSS秘钥的接口地址
        this.uploadStatus = "uploadding";
        uploadfile({ type })
          .then((response) => {
            this.upload_imginfo = response.data;
            console.log(this.upload_imginfo);
            resolve(true);
          })
          .catch(() => {
            this.$message.error("获取授权失败，请检查网络或联系系统管理员");
            reject(false);
          });
      });
    },
    // 上传文件
    upLoad(file) {
      console.log("upLoad");

      const _self = this;
      let formdate = new FormData();
      let file_name = md5(new Date().getTime + file.file.name);
      let file_type = file.file.name.split(".").pop();
      let file_key = _self.upload_imginfo.dir + file_name + "." + file_type;
      console.log(file_key);
      formdate.append("key", file_key);
      formdate.append("OSSAccessKeyId", _self.upload_imginfo.access_key_id); //accessKeyId
      formdate.append("policy", _self.upload_imginfo.policy); //policy
      formdate.append("Signature", _self.upload_imginfo.signature); //签名
      formdate.append("success_action_status", 200);
      formdate.append("file", file.file);
      file.file.file_key = file_key;
      this.file_key = file_key;
      this.upload_mode = true;
      // this.loading = Loading.service({ background: "rgba(0, 0, 0, 0.4)" });
      uploadoss(_self.upload_imginfo.host, formdate)
        .then(() => {
          let t = setInterval(() => {
            this.showtime += 10;
            if (this.showtime >= 100) {
              this.showtime == 100;
            }
          }, 100);
          this.uploadStatus = "afterupload";
          setTimeout(() => {
            this.uploadStatus = "uploaddone";
            // this.uploadStatus = "uploadError";
            clearInterval(t);
            this.showtime = 0;
          }, 1200);

          // file.url = this.active_url;
          this.active_url = this.uploaddingimage;
        })
        .catch(() => {
          this.uploadStatus = "afterupload";
          setTimeout(() => {
            this.uploadStatus = "uploadError";

            this.$message.error("上传失败");
          }, 2000);
        });
    },
    //选择上传的文件
    imgSaveToUrl(file) {
      let url = URL.createObjectURL(file.raw);

      this.uploaddingimage = url;
      // this.active_url = url;
    },
    //点击保存 图片
    saveImage() {
      console.log(this.active_url == "");
      console.log(this.active_url);
      console.log(this.file_key);
      teamdetail("save_image", {
        uni_code: this.uni_code,
        image: this.file_key,
      }).then((ele) => {
        console.log(ele);
        if (ele.code != 2000) {
          this.$message.error("上传失败");
          return;
        }
        this.$message.success("上传成功");
        this.getImageDetail();
        this.dialogVisible1 = false;
      });
    },
    //取消保存
    cancelImage() {
      this.dialogVisible1 = false;

      this.active_url = this.imagedetail;
    },
    // 显示模板
    set_model() {
      this.show_setingModel = true;
    },
    //设置页面模板取消
    model_cancel() {
      this.show_select = 2;
      this.show_setingModel = false;
    },
    //设置页面模板保存
    model_save() {
      let arg = {
        uni_code: this.uni_code,
        template: this.show_select,
      };
      template1("templatesave", arg).then((ele) => {
        if (ele.code === 2000) {
          if (this.show_select == 1) {
            this.$router.push({
              path: "/project1",
              query: { uni_code: this.uni_code, name: this.name },
            });
          }
          this.show_setingModel=false;
        }else {
          this.$message.error("操作失败，请稍后重试");
        }
      });

      this.show_setingModel = false;
    },
    // 选择模板1
    select_model1() {
      this.show_select = 1;
    },
    // 选择模板2
    select_model2() {
      this.show_select = 2;
    },
  },
};
</script>

<style lang="less">
//图片弹出框样式
.dialog2 {
  .el-dialog {
    width: 472px;
    // height: 430px;
    height: auto;
    background: #2c323c;
    color: #fff;

    .box {
      position: relative;

      .upload {
        width: 352px;
        height: 220px;
        opacity: 1;
        margin: 0 auto;

        position: relative;
        > div {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
        .status1 {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;

          width: 100%;
          height: 100%;
          border: 1px dashed rgba(255, 255, 255, 0.65);

          .box {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
          img {
            width: 14px;
            height: 14px;
          }
          > div {
            width: 28px;
            height: 22px;
            opacity: 1;
            margin-top: 8px;
            font-size: 14px;
            font-family: PingFang HK, PingFang HK-Regular;
            font-weight: 400;
            text-align: LEFT;
            color: rgba(255, 255, 255, 0.85);
            line-height: 22px;
          }
        }
        .status2 {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border: 1px dashed rgba(255, 255, 255, 0.65);
          width: 100%;
          height: 100%;
          // background-color: #f00;
          .el-progress {
            margin-top: 18px;
            width: 110px;
          }
        }
        .status22 {
          @keyframes turn {
            0% {
              transform: rotate(0deg);
            }

            100% {
              transform: rotate(360deg);
            }
          }
          img {
            animation: turn 1s linear infinite;
          }
        }
        .status3 {
          // background-color: pink;
          display: flex;

          align-items: center;
          justify-content: center;
          position: relative;
          width: 100%;
          height: 100%;
          border: 1px solid #f85f64;
          border-radius: 2px;
          .pop {
            display: none;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            position: absolute;
            top: 0;
            left: 0;
            > div {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              img {
                width: 40px;
                height: 40px;
              }
            }
          }
          .img {
            display: flex;
            flex-direction: column;

            align-items: center;
            justify-content: center;

            img {
              width: 30px;
              height: 30px;
            }
            > div {
              width: 88px;
              height: 22px;
              opacity: 1;
              margin-top: 16px;
              font-size: 14px;
              font-family: PingFang HK, PingFang HK-Regular;
              font-weight: 400;
              text-align: CENTER;
              color: #f85f64;
              line-height: 22px;
            }
          }
        }
        .status3:hover .pop {
          display: block;
        }
        .status4 {
          position: relative;
          width: 100%;
          height: 100%;

          padding: 9px;
          box-sizing: border-box;
          border: 1px solid #ecbbbe;
          border-radius: 2px;
          text-align: center;
          .pop {
            display: none;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            position: absolute;
            top: 0;
            left: 0;
            > div {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              display: flex;
              .el-image {
                img {
                  width: 40px;
                  height: 40px;
                  color: #fff;
                }
              }
              img {
                width: 40px;
                height: 40px;
              }
            }
          }
          img {
            // max-height: 100%;
            // max-width: 100%;
            width: 100%;
            height: 100%;

            object-fit: contain;
          }
        }
        .status4:hover .pop {
          display: block;
        }
      }
      .text {
        // width: 252px;
        height: 34px;
        margin: 16px auto;
        opacity: 0.85;

        font-size: 12px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: CENTER;
        color: rgba(255, 255, 255, 0.65);
      }
    }
    .el-dialog__footer {
      text-align: right;
      .el-button {
        border-radius: 0;
      }
      .cancel {
        color: #fff;
        margin-right: 8px;
        background-color: transparent;
        border: none;
        &:hover {
          background-color: #41474f;
          opacity: 0.85;
        }
      }
      .submit-btn {
        color: #fff;
        border: none;
        background-color: #0a6ed1;
        &:hover {
          background-color: #2f8cde;
        }
        &:active {
          background-color: #0050ab;
        }
      }
    }
  }
}
.project-pg {
  width: 1920px;
  height: 1080px;
  position: absolute;
  background-image: url("../assets/bgblock.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-sizing: border-box;
  .none {
    .el-carousel__indicators {
      display: none;
    }
  }
  // 公共标题样式
  .p-title {
    position: relative;
    width: 299px;
    height: 31px;
    white-space: nowrap;
    color: #75bdff;
    font-size: 24px;
    height: 31px;
    // line-height: 31px;
    font-family: YouSheBiaoTiHei, YouSheBiaoTiHei-Regular;
    vertical-align: middle;
    display: flex;
    align-items: flex-end;

    .span {
      font-size: 24px;
    }
    span {
      font-size: 12px;
    }

    img {
      width: 31px;
      height: 31px;
      // margin-left: 4px;
      cursor: pointer;
    }
  }
  .top-line {
    height: 128px;
    padding-top: 24px;
    position: relative;
    .title-name {
      position: relative;
      font-size: 46px;
      color: #fff;
      height: 60px;
      line-height: 60px;
      text-align: center;
      .img:hover i {
        color: #129bff;
      }
      .img:active i {
        color: #0479d9;
      }
      .img {
        width: 41px;
        height: 41px;
        position: absolute;
        bottom: 0px;
        right: 41px;
        // background-color: #f00;
        cursor: pointer;
        background-image: url(../assets/bchb.png);
        background-size: cover;
        i {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          font-size: 18px;
        }
      }
    }
    .edit {
      position: absolute;
      right: 0;
      top: 0;
      width: 100px;
      height: 40px;
      opacity: 0.25;
      cursor: pointer;
    }
    .edit2 {
      position: absolute;
      right: 48px;
      top: 48px;
      // width: 56px;
      height: 20px;
      opacity: 0.65;
      font-size: 14px;
      font-weight: 600;
      text-align: LEFT;
      color: #ffffff;
      cursor: pointer;
      &:hover {
        opacity: 0.9;
      }
      &:active {
        opacity: 1;
      }
    }
    .edit3 {
      position: absolute;
      right: 122px;
      top: 48px;
    }
  }
  //主要内容区
  .content-bd {
    // margin: 0 64px;
    box-sizing: border-box;
    display: flex;
    // 左边样式
    .left-p {
      width: 430px;
      box-sizing: border-box;
      z-index: 20000000;
      .l1-bd,
      .l2-bd {
        height: 400px;
        width: 289px;

        margin-left: 64px;
        &::-webkit-scrollbar {
          width: 0 !important;
        }
      }
      //左上样式 对应数据data0
      .l1-bd {
        margin-bottom: 88px;
        .dataList::-webkit-scrollbar {
          display: none;
        }

        .dataList {
          padding-top: 22px;
          height: 370px;
          overflow-y: scroll;
          .dataList_item {
            margin-top: 16px;
          }
          .text {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Semibold;
            font-weight: 600;
            text-align: LEFT;
            color: #ffffff;
            letter-spacing: 0px;
          }
          img {
            width: 92px;
            height: 18px;
            opacity: 1;
            margin-top: 8px;
            margin-bottom: 4px;
          }
          .num {
            font-size: 34px;
            font-family: DIN Alternate, DIN Alternate-Bold;
            font-weight: 700;
            text-align: LEFT;
            color: #31fbfb;
            letter-spacing: 0px;
          }
        }
      }
      //左下样式 对应数据data1
      .l2-bd {
        .dataList::-webkit-scrollbar {
          display: none;
        }
        .dataList {
          width: 100%;
          height: 370px;
          overflow-y: scroll;

          margin-top: 6px;
          .dataList_item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 18px;
            .item_left {
              .p1 {
                font-size: 14px;
                font-family: PingFang SC, PingFang SC-Semibold;
                font-weight: 600;
                text-align: LEFT;
                color: #ffffff;
                letter-spacing: 0px;
              }
              .p2 {
                font-size: 16px;
                font-family: DIN Alternate, DIN Alternate-Bold;
                font-weight: 700;
                text-align: LEFT;
                color: #31fbfb;
                letter-spacing: 0px;
              }
            }
            .item_right {
              font-size: 34px;
              font-family: DIN Alternate, DIN Alternate-Bold;
              font-weight: 700;
              text-align: RIGHT;
              color: #31fbfb;
              letter-spacing: 0px;
              // .p2 {
              //   .span {
              //     font-size: 18px;
              //     font-family: PingFang HK, PingFang HK-Regular;
              //     font-weight: 400;
              //     text-align: LEFT;
              //     color: #f00;
              //     letter-spacing: 0px;
              //   }
              // }
            }
          }
        }
      }
    }
    //中间样式
    .content-p {
      width: 1062px;
      box-sizing: border-box;
      //  中间上边样式 对应数据data2
      .c1-bd {
        width: 100%;
        height: 143px;
        // background-color: #a02569;
        margin-bottom: 23px;

        .c1-bottom {
          // display: flex;
          // overflow: auto;
          // align-items: center;
          margin-top: 32px;
          // justify-content: space-between;
          white-space: nowrap;
          .el-carousel__indicators--horizontal {
            .el-carousel__indicator--horizontal {
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background-color: #095286;

              // is-active
              overflow: hidden;
              padding: 0;
              margin-right: 4px;

              .el-carousel__button {
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: #129bff;
              }
            }
          }

          .c1-bottom_item {
            flex: none;
            width: 100%;
            // display: flex;
            // justify-content: space-between;
            // align-items: center;

            > div {
              display: inline-block !important;
              width: 212px;
              > div {
                display: flex;
                // flex-direction: column;
                align-items: center;
                justify-content: center;
              }
            }
            .item_left {
              position: relative;
              width: 72px;
              height: 72px;
              background: url(../assets/yali.png);
              background-size: cover;
              text-align: center;
              font-size: 12px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              text-align: CENTER;
              color: #ffffff;
              letter-spacing: 0px;
              .text {
                // margin: 0 auto;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -40%);
              }
            }
            .item_right {
              margin-left: 2px;

              .p1 {
                width: 120px;
                height: 20px;
                opacity: 0.8;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 14px;
                font-family: PingFang SC, PingFang SC-Semibold;
                font-weight: 600;
                text-align: LEFT;
                color: #ffffff;
                letter-spacing: 0px;
                margin-bottom: 9px;
              }
              .p2 {
                width: 100%;
                height: 24px;
                font-size: 17px;
                font-family: Digital Numbers, Digital Numbers-Regular;
                font-weight: 400;
                text-align: LEFT;
                color: #31fbfb;
                letter-spacing: 0px;

                .span {
                  font-size: 17px;
                  font-family: PingFang HK, PingFang HK-Regular;
                  font-weight: 400;
                  text-align: LEFT;
                  color: #31fbfb;
                  letter-spacing: 0px;
                }
              }
            }
          }
        }
        .c1-bd-nodata {
          width: 100%;
          height: 92px;

          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          img {
            width: 97px;
            height: 60px;
          }
          > div {
            width: 56px;
            height: 20px;
            opacity: 1;

            font-size: 14px;
            font-family: Roboto, Roboto-Regular;
            font-weight: 400;
            text-align: LEFT;
            color: #ffffff;
            line-height: 22px;
          }
        }
      }
      // 中间下边样式  对应数据图片详情
      .c2-bd {
        width: 100%;
        height: 663px;
        position: relative;
        vertical-align: middle;
        text-align: center;
        .edit {
          width: 31px;
          height: 31px;
          position: absolute;
          top: -34px;
          right: 0;
          z-index: 9999;
          cursor: pointer;
        }
        img {
          max-width: 100%;
          max-height: 100%;
        }
        .el-img {
          width: 100% !important;
          height: 100% !important;
          img {
            object-fit: contain;
          }
        }
        .no {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          img {
            width: 97px;
            height: 60px;
          }
          > div {
            width: 56px;
            height: 20px;
            opacity: 1;

            font-size: 14px;
            font-family: Roboto, Roboto-Regular;
            font-weight: 400;
            text-align: LEFT;
            color: #ffffff;
            line-height: 22px;
          }
        }
      }
    }
    //右边样式
    .right-p {
      width: 430px;
      box-sizing: border-box;

      .r1-bd,
      .r2-bd {
        height: 400px;
        width: 289px;
        // overflow: scroll;
        margin: 0 auto;
        &::-webkit-scrollbar {
          width: 0 !important;
        }
      }
      //右边样式
      .r1-bd {
        margin-bottom: 88px;

        .explain {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 289px;
          height: 37px;
          box-sizing: border-box;
          padding: 0 8px;
          opacity: 1;
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #ffffff;
          border-radius: 4px;
          margin-top: 14px;
        }
        .r1-bd_list::-webkit-scrollbar {
          display: none;
        }
        .r1-bd_list {
          height: 321px;
          overflow-y: scroll;
          > div {
            margin-top: 4px;
          }
          .r1-bd_list_item {
            display: flex;
            align-items: center;
            width: 289px;
            height: 36px;
            opacity: 1;
            // background: rgba(18, 155, 255, 0.1);
            border-radius: 4px;
            align-items: center;

            .item1 {
              width: 113px;
              margin-left: 5px;
              font-size: 14px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              text-align: LEFT;
              color: #ffffff;
              line-height: 14px;
            }
            .item2 {
              width: 50px;
              margin-left: 6px;
              font-size: 16px;
              font-family: DIN Alternate, DIN Alternate-Bold;
              font-weight: 700;
              text-align: LEFT;
              color: #31fbfb;
              letter-spacing: 0px;
            }
            .item3 {
              width: 102px;
              margin-left: 8px;
              font-size: 14px;
              font-family: DIN Alternate, DIN Alternate-Bold;
              font-weight: 700;
              text-align: RIGHT;
              color: #31fbfb;
              letter-spacing: 0px;
            }
          }
          .r1-bd_list_item:nth-child(odd) {
            background: rgba(18, 155, 255, 0.1);
          }
          .r1-bd_list_item:nth-child(even) {
            background: rgba(255, 255, 255, 0.1);
          }
        }
      }
      .r2-bd {
        margin-bottom: 88px;
      }
    }
  }
}
//各个区域弹出框样式
.dialog1 {
  .el-dialog {
    width: 830px;
    // height: 70vh;
    .el-dialog__footer {
      text-align: center;
      .el-button {
        border-radius: 0;
      }
      .cancel {
        color: #fff;
        margin-right: 8px;
        background-color: transparent;
        border: none;
        &:hover {
          background-color: #41474f;
          opacity: 0.85;
        }
      }
      .submit-btn {
        color: #fff;
        border: none;
        background-color: #0a6ed1;
        &:hover {
          background-color: #2f8cde;
        }
        &:active {
          background-color: #0050ab;
        }
      }
    }
    .dialog-content {
      width: 710px;
      margin: 0 auto;
      .parameter {
        margin-top: 13px;
      }
      .title {
        height: 40px;
        line-height: 40px;
        font-size: 16px;
        font-family: PingFang HK, PingFang HK-Medium;
        font-weight: 500;
        text-align: LEFT;
        color: #ffffff;
        border-bottom: 1px solid rgba(255, 255, 255, 0.25);
      }
      .input {
        width: 100%;
        display: flex;
        margin-top: 23px;
        justify-content: space-between;
        position: relative;
        .input-top {
          display: flex;
          align-items: center;
          .text-top {
            width: 70px;
            opacity: 1;
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.85);
            line-height: 14px;
          }
          .el-input {
            width: 234px;
            height: 32px;
            box-sizing: border-box;
            overflow: hidden;
            opacity: 1;
            background: rgba(0, 0, 0, 0.4);
            border: 1px solid rgba(255, 255, 255, 0.65);
            // border: 1px solid #f85f64;
            border-radius: 2px;

            .el-input__inner {
              border: none;
            }
          }
        }
        .input-bottom {
          display: flex;
          align-items: center;
          position: relative;
          .text-bottom {
            width: 70px;
            opacity: 1;
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.85);
            line-height: 14px;
          }
          .el-input {
            width: 234px;
            height: 32px;
            opacity: 1;
            box-sizing: border-box;
            overflow: hidden;
            background: rgba(0, 0, 0, 0.4);
            border: 1px solid rgba(255, 255, 255, 0.65);
            // border: 1px solid #f85f64;
            border-radius: 2px;
            .el-input__inner {
              border: none;
            }
            border-radius: 2px;
          }
        }
        .error {
          // width: 20px;
          // height: 20px;
          // background-color: pink;
          position: absolute;
          top: 34px;
          left: 76px;
          color: #f85f64;
        }
      }
      .style {
        display: flex;
        margin-top: 24px;
        .style-l {
          width: 70px;
          height: 14px;
          opacity: 1;
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: RIGHT;
          color: rgba(255, 255, 255, 0.85);
          line-height: 14px;
        }
        .style-rr {
          .tab {
            display: flex;
            align-items: flex-end;
            > div {
              width: 75px;
              height: 24px;
              line-height: 24px;
              text-align: center;
              margin-right: 4px;
              opacity: 1;
              background: #1a1f25;
              border-radius: 3px 3px 0px 0px;
              font-size: 12px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              cursor: pointer;
              color: rgba(255, 255, 255, 0.85);
              overflow: hidden;
            }
            .activeclass {
              width: 77px;
              height: 28px;
              line-height: 28px;
              opacity: 1;
              background: #464b54;
              border-radius: 3px 3px 0px 0px;
              // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
              font-size: 14px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              overflow: hidden;
              color: rgba(255, 255, 255, 0.85);
            }
          }
          .style-bottom {
            width: 314px;
            height: 136px;
            border: 12px solid #464b54;
            box-sizing: border-box;
            margin-top: -0.2px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }

        .style-r {
          position: relative;
          width: 314px;
          height: 136px;
          border: 12px solid #464b54;
          box-sizing: border-box;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .param-list {
        margin-top: 23px;
        height: 216px;

        .th {
          display: flex;

          > div {
            height: 36px;
            width: 94px;
            box-sizing: border-box;
            line-height: 36px;
            background: rgba(0, 0, 0, 0.3);
            font-size: 14px;
            font-family: PingFang HK, PingFang HK-Medium;
            font-weight: 500;
            border: 1px solid #474b54;
            padding-left: 8px;
            //  text-align: center;
          }
          > div:last-child {
            width: 92px;
            border-right: 1px solid transparent;
          }
          .th1 {
            // text-align: center;
            border-left: 1px solid transparent;
            padding-left: 8px;
          }
          .th2 {
            width: 262px;
            box-sizing: border-box;
          }
        }
        .nolist {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 708px;
          height: 64px;
          opacity: 1;
          background: rgba(255, 255, 255, 0.12);
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: rgba(255, 255, 255, 0.85);
          line-height: 22px;
          img {
            width: 24px;
            height: 15px;
          }
          .tianjia {
            text-decoration: underline;
          }
          .tianjia:hover {
            color: #129bff;
            cursor: pointer;
            text-decoration: underline;
          }
        }
        .td {
          height: 180px;
          overflow: auto;
          &::-webkit-scrollbar {
            width: 0 !important;
          }
          .td1 {
            display: flex;

            > div {
              height: 36px;
              width: 94px;
              line-height: 36px;
              box-sizing: border-box;
              background: rgba(255, 255, 255, 0.12);
              font-size: 14px;
              font-family: PingFang HK, PingFang HK-Medium;
              font-weight: 500;
              border: 1px solid rgba(255, 255, 255, 0.19);

              overflow: hidden;

              // border: 1px solid rgba(255, 255, 255, 0.65);
              // border-radius: 2px;

              input {
                width: 100%;
                height: 100%;
                padding: 0;
                margin: 0;
                border: none;
                outline: none;
                background: #464b54;
                color: rgba(255, 255, 255, 0.85);
                font-size: 14px;
                font-family: PingFang HK, PingFang HK-Medium;
                font-weight: 500;
                padding-left: 8px;
              }
              img {
                width: 24px;
                height: 24px;
                margin-top: 6px;
              }
              .del {
                margin-left: 4px;
                margin-right: 6px;
              }
            }
            > div:last-child {
              width: 92px;
              border-right: 1px solid transparent;
            }
            .td1-1 {
              // text-align: center;
              border-left: 1px solid transparent;
              padding-left: 8px;
            }
            .td1-2 {
              width: 262px;
              box-sizing: border-box;
            }
            .td1-3 {
              input::-webkit-outer-spin-button,
              input::-webkit-inner-spin-button {
                -webkit-appearance: none;
              }
              input[type="number"] {
                -moz-appearance: textfield;
              }
            }
            .td1-4 {
              input::-webkit-outer-spin-button,
              input::-webkit-inner-spin-button {
                -webkit-appearance: none;
              }
              input[type="number"] {
                -moz-appearance: textfield;
              }
            }
            .td1-5 {
              input::-webkit-outer-spin-button,
              input::-webkit-inner-spin-button {
                -webkit-appearance: none;
              }
              input[type="number"] {
                -moz-appearance: textfield;
              }
            }
          }
        }
      }
    }
  }
}
// 设置页面模板弹出框样式
.seting_model1 {
  .el-dialog {
    .el-dialog__body {
      padding: 0;
      .dialog-body {
        padding: 0 24px;
        .model {
          width: 100%;
          display: flex;
          justify-content: space-between;
          > div {
            width: 177px;
            height: 160px;
            opacity: 1;
            background: #ffffff;
            border-radius: 6px;
            overflow: hidden;
            box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
          }
          .left {
            box-sizing: border-box;
            position: relative;
            &:hover {
              border: 2px solid #129bff;
            }
            .left_picture {
              width: 100%;
              height: 104px;
              box-sizing: border-box;
              padding: 10px 8px 4px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .left_name {
              height: 56px;
              line-height: 56px;
              background: #f8f9fa;
              box-sizing: border-box;
              padding-left: 10px;
              font-size: 16px;
              font-family: PingFang SC, PingFang SC-Medium;
              font-weight: 600;
              text-align: LEFT;
              color: rgba(0, 0, 0, 0.85);
            }
            .left_select_img {
              width: 30px;
              height: 30px;
              position: absolute;
              bottom: -2px;
              right: -2px;
            }
          }
          .right {
            position: relative;
            box-sizing: border-box;
            &:hover {
              border: 2px solid #129bff;
            }
            .right_picture {
              width: 100%;
              height: 104px;
              box-sizing: border-box;
              padding: 10px 8px 4px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .right_name {
              height: 56px;
              line-height: 56px;
              background: #f8f9fa;
              box-sizing: border-box;
              padding-left: 10px;
              font-size: 16px;
              font-family: PingFang SC, PingFang SC-Medium;
              font-weight: 600;
              text-align: LEFT;
              color: rgba(0, 0, 0, 0.85);
            }
            .right_select_img {
              width: 30px;
              height: 30px;
              position: absolute;
              bottom: -2px;
              right: -2px;
            }
          }
        }
      }
    }
    .el-dialog__footer {
      padding: 16px  0 16px;
      text-align: center;
      .el-button {
        border-radius: 0;
      }
      .cancel {
        color: #fff;
        margin-right: 8px;
        background-color: transparent;
        border: none;
        &:hover {
          background-color: #41474f;
          opacity: 0.85;
        }
      }
      .submit-btn {
        color: #fff;
        border: none;
        background-color: #0a6ed1;
        &:hover {
          background-color: #2f8cde;
        }
        &:active {
          background-color: #0050ab;
        }
      }
    }
  }
}
</style>
