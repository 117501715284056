<template>
  <div>
    <el-upload
      action=""
      list-type="picture"
      :show-file-list="false"
      :before-upload="beforeUpload"
      :http-request="upLoad"
    >
      <div>批量导入</div>
    </el-upload>
  </div>
</template>

<script>
import { uploadfile, uploadoss } from "../api/getdata"; //引入接口
import XLSX from "xlsx";
import md5 from "js-md5";
export default {
  props: ["text", "title", "issuccess"],
  data() {
    return {
      upload_info: {},
      file_key: "",
      showtime: 0,
      uploadStatus: "beforeup",
      filename: "",
      filesize: 0,
      analysis: [],
      resultArray: [],
      error: false,
    };
  },
  watch: {},
  mounted() {},
  methods: {
    //获取授权
    beforeUpload(ele) {
      console.log(ele);
      let file_type = ele.name.split(".").pop();
      let arr = ["XLS", "xls", "XLSX", "xlsx", "CSV", "csv"];
      if (arr.indexOf(file_type) == -1) {
        this.$message.error("文件大小或格式不匹配");
        return false;
      }
      const isLt2M = ele.size / 1024 / 1024 < 2;
      this.filesize = (ele.size / 1024 / 1024).toFixed(2);
      if (!isLt2M) {
        this.$message.error("上传文件大小不能超过 2M");
        return false;
      }
    },

    upLoad(file) {
      console.log(file);
      console.log(file.file);

      this._toJson(file.file);
    },

    _toJson(fiel) {
      let self = this;
      let file = fiel;
      console.log(file);
      let reader = new FileReader();
      reader.onload = (event) => {
        console.log(event);
        let a = event.target.result;
        const workbook = XLSX.read(a, {
          type: "binary",
          codepage: 936
        });

        let resultArray = [];
        let tableArray = [];
     
       
          // console.log(sheetName);
          // console.log("workbook.Sheets[sheetName]", workbook.Sheets[sheetName]);
          // XLSX.utils.sheet_to_json(worksheet , {defval:""})
        
          let sheetName=workbook.SheetNames[0];
          let data = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
            header: 1,
            defval: "",
          });
          console.log("data", data);
          resultArray = [...data];
          resultArray.splice(0, 2);
          resultArray.forEach((item, index) => {
            item = item.slice(0, 4);
            console.log(item);
            item[0] = String(item[0]).substring(0, 20);

            let prefix = String(item[1]).substring(0, 2);
            if (prefix == "黑龙") {
              item[1] = String(item[1]).substring(0, 3);
            } else {
              item[1] = prefix;
            }
            if (item[1] === "内蒙") {
              item[1] = "内蒙古";
            }
            item[2] = String(item[2]).substring(0, 10);

            if (typeof item[3] == "number") {
              console.log(typeof item[3]);
              if (item[3] < 0) {
                item[3] = "";
              }else {
                item[3] = Number(String(item[3]).substring(0, 10));
              }
              
            } else {
              item[3] = "";
            }
            tableArray.push(
              {
              name: item[0],
              province: item[1],
              category: item[2],
              revenue: item[3],
              show_detail: 2,
              image: { name: "", url: "" },
            }
            )
      
          });

       
      

           console.log(resultArray);
          console.log(tableArray);
          self.$emit("accept", tableArray);
      };

      reader.readAsBinaryString(file);
    },
  },
};
</script>

<style lang='less'>
</style>