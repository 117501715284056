<template>
  <div class="homel1-bd">
      <div class="hl1-line-1">
          <div class="video-view">
              <video :src="first_video.url" height="100%" width="100%" muted></video>
              <div class="vv-mask" @click="openVideo"></div>
          </div>
          <div class="com-slogn">
              <div>
                  <div class="go-div dlh-red-btn un-select" @click="goAbout">Go></div>
                  <div style="color:#FFF;font-size: 16px;opacity: 0.65;font-weight: 600;text-align: left;font-family: PingFang HK, PingFang HK-Semibold;margin-left:47px;line-height:30px">THE INTRODUCE</div>
              </div>
              <div v-if="tab_info.slogan&&tab_info.slogan.length<10" style="display: flex;justify-content: center;align-items: center;color:#F85F64;height: 50px;line-height: 50px;font-size: 22px;font-family: PingFang HK, PingFang HK-Semibold;font-weight: 600;margin-top:12px;text-align: CENTER;">
                  {{tab_info.slogan}}
              </div>
              <div v-else style="display: flex;justify-content: center;line-height: 17px;align-items: center;color:#F85F64;height: 50px;font-size: 18px;line-height: 27px;font-family: PingFang HK, PingFang HK-Semibold;font-weight: 600;margin-top:12px;text-align: CENTER;">
                  {{tab_info.slogan}}
                  <!-- ||'极具匠心 苛求臻美' -->
              </div>
          </div>
          
      </div>
      <div class="hl1-line-2">
          <div class="framse-img">
              <div id='hl1' ref="hl1" style="height:250px;width:250px;margin: 0 auto;top:66px;left:1px"></div>
          </div>
          <div class="hl1-line-2-title">{{tab_info.chart_name||''}}</div>
      </div>

    <el-dialog
    title=""
    class="video_title video-body"
    :visible.sync="full_video"
    :modal-append-to-body="false"
    :append-to-body="false"
    :fullscreen="false"
    :close-on-click-modal="false"
    width="1100px"
    @close="closeVideo"
    >   
        <i class="el-icon-arrow-left" v-if="tab_info.videos&&tab_info.videos.length>1" @click="preVideo" style="position:absolute;top:50%;font-size:40px;left:-60px;color:#FFF;cursor:pointer;margin-top: -17px;"></i>
        <i class="el-icon-arrow-right" v-if="tab_info.videos&&tab_info.videos.length>1" @click="nextVideo" style="position:absolute;top:50%;font-size:40px;right:-60px;color:#FFF;cursor:pointer;margin-top: -17px;"></i>
        <video v-if="full_video" id="videoplay" ref="videoplay" :src="active_vide.url" height="620" width="1100" controls="controls"></video>
        <div class="video-info" v-show="active_vide.desc">{{active_vide.desc}}</div>
    </el-dialog>
  </div>
</template>

<script>
export default {
    props:{
        edit_type:{type:Boolean,default:false},
        map_data:{videos:[]}
    },
    watch:{
        map_data(){
            this.tab_info = this.map_data ||{videos:[]}
            let videos = this.tab_info.videos ||[]
            this.active_vide = videos[0]||{}
            this.data = this.tab_info.chart_data||[]
            this.initChart()
        }
    },
    data(){
        return {
            full_video:false,
            tab_info:{videos:[]},
            now_video:0,
            active_vide:{},
            data:[],
            hl1Instance:'',
            data_range : {
                data_min:null,
                data_max:null}
            
        }
    },
    computed:{
        first_video(){
            if(this.tab_info.videos&&this.tab_info?.videos[0]){
                return this.tab_info?.videos[0] || {}
            }else{
                return {}
            }
            
        }
        
    },
    methods:{
        initChart(){
            this.hl1Instance = this.$echarts.init(this.$refs.hl1)
            this.findMax(this.data)
            let data_range = {}
            if(this.data_range.data_min!==null&&this.data_range.data_max!==null){
                data_range = {min:this.data_range.data_min,max:this.data_range.data_max}
            }
            let option = {
                    visualMap: {
                        type: 'continuous',
                        ...data_range,
                        // min: 0,
                        // max: 10,
                        show:false,
                        inRange: {
                            color: ['#2F93C8', '#AEC48F', '#FFDB5C', '#F98862']
                        }
                    },
                    tooltip: {
                        borderColor: 'transparent',
                        borderWidth: 0,
                        backgroundColor:"rgba(5,125,133,0.6)",
                        formatter: (params,data)=>{
                            //  +'<div style="color:#fff;font-size:12px;padding:0 5px;margin:0">'+this.value_name+'</div>'
                           let tipHtml = '<div style="backdrop-filter: blur(5px);font-size:12px;min-width:99px">'
                            +'<div style="color:#fff;font-size:12px;padding:0 5px;margin:0">'+ params.name +'</div>'
                            +'<div style="display:flex;justify-content:space-between;padding:0 5px"><span>'+this.tab_info?.value_name +'</span><span style="color:#FFCF5F;margin-left:10px">'+params.value+' '+this.tab_info?.value_unit+'</span></div>'
                            +'</div>';
                            return tipHtml
                        }
                    },
                    series: {
                        type: 'sunburst',
                        data: this.data,
                        radius: [0, '90%'],
                        label: {
                            rotate: 'radial',
                            show:false,
                        },
                    }
                };
            this.hl1Instance.setOption(option);
        },
        goAbout(){
            let query_id = this.$route.query.id
            this.$router.push({path: '/about',query: {id:query_id}})
            // if(this.edit_type){
            //     this.$router.push({path: '/about', query: {id:query_id,edit: "1"}})
            // }else{
            //     this.$router.push({path: '/about',query: {id:query_id}})
            // }
            
        },
        closeVideo(){
            this.$refs.videoplay.pause()
        },
        openVideo(){
            if(!this.tab_info.videos||this.tab_info.videos.length===0){
                this.$message.error({
                    showClose: true,
                    message: "失败：暂无可播放的视频"
                });
                return
            }
            this.now_video=0
            this.active_vide = this.tab_info.videos[this.now_video]
            this.full_video=true
        },
        preVideo(){
            let len = this.tab_info.videos.length
            if(this.now_video==0){
                this.now_video=len-1
            }else{
                this.now_video-=1
            }
            this.active_vide = this.tab_info.videos[this.now_video]
        },
        nextVideo(){
            let len = this.tab_info.videos.length
            this.now_video+=1
            if(this.now_video<len){
                console.log(0)
            }else{
                this.now_video=0
            }
            this.active_vide = this.tab_info.videos[this.now_video]
        },
        findMax(array){
            array.forEach(ele=>{
                if(this.data_range.data_min===null){
                    this.data_range.data_max = this.data_range.data_min=ele.value
                }
                if(ele.value!=null){
                    if(ele.value<this.data_range.data_min){
                        this.data_range.data_min = ele.value
                    }
                    if(ele.value>this.data_range.data_max){
                        this.data_range.data_max = ele.value
                    }
                }
                if(!ele.children){
                    return
                }
                this.findMax(ele.children)
            })
        },
    },
    mounted(){
        this.initChart()
    }
}
</script>

<style lang="less">
.homel1-bd{
    .hl1-line-1{
        display: flex;
        flex: 1;
        .video-view{
            height: 120px;
            width: 215px;
            background: linear-gradient(179deg,#1d3150 100%, rgba(177,199,223,0.00) 100%, #1d3150 100%);
            // background-image: url('../assets/godetail.png');
            // background-image: url('../assets/govideo.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            margin-left: 16px;
            position: relative;
            border-radius: 4px;
            .vv-mask{
                height: 100%;
                width: 100%;
                position: absolute;
                background: linear-gradient(179deg,#1d3150 100%, rgba(177,199,223,0.00) 100%, #1d3150 100%);
                top: 0;
                left: 0;
                background-image: url('../assets/playbl.png');
                background-size: 100% 100%;
                background-repeat: no-repeat;
                cursor: pointer;
                &:hover{
                    opacity: 0.85;
                }
            }
        }
        .com-slogn{
            width: 215px;
            height: 120px;
            margin-left: 10px;
            position: relative;
            text-align: RIGHT;
            
            background-image: url('../assets/godetail.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            overflow: hidden;
            .cs-line1{
                width: 215px;
                height: 67px;
            }
            .go-div{
                height: 20px;
                width: 35px;
                line-height: 20px;
                font-size: 14px;
                color: #FFF;
                font-weight: 200;
                text-align: center;
                position:absolute;
                font-size: 12px;
                top: 5px;

            }
            .com-text{
                color: #f85f64;
                font-size: 32px;
                font-weight: 600;
                line-height: 33px;
            }
            .com-slogn-text{
                width: 215px;
                height: 48px;
                font-size: 14px;
                line-height: 22px;
                color: #FFF;
                display: inline-flex;
                justify-content: flex-end;
                align-items: center;
            }
        }
    }
    .hl1-line-2{
            background-image: url('../assets/fram2.png');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        .framse-img{
            height: 346px;
            width: 472px;
            // margin: 0 auto;
            // background-image: url('../assets/fram2.png');
            // background-size: 120% 120%;
            // background-size: 106% 145%;
            // background-repeat: no-repeat;
            // background-position: center;
        }
        .hl1-line-2-title{
            font-size: 16px;
            font-weight: 400;
            margin-top:16px;
            text-align: CENTER;
            color: #ffffff;
        }
    }
}
.video_title{
    .el-dialog{
        background-color: transparent;
    }
    .el-dialog__header{
        padding: 0;
    }
    .el-dialog__body{
        padding: 0;
        // max-height: fit-content;
        max-height: 1000px;
        video{
                max-height: 900px;
        }
    }
    .el-dialog.is-fullscreen{
        overflow: hidden;
    }
    .el-dialog__headerbtn .el-dialog__close{
        height: 42px;
        width: 42px;
        line-height: 42px;
        font-size: 24px;
        border-radius: 50%;
        color: #000000;
        font-weight: 900;
        // background-color:rgba(0,0,0,0.25);
        background-color:rgba(255,255,255,0.85);
    }
    .el-dialog__headerbtn{
        z-index: 20000;
        top: -42px;
        right: -42px;
        &:hover{
            height: 42px;
            width: 42px;
            line-height: 30px;
            border-radius: 50%;
            // color: #FFF;
            font-weight: 900;
            opacity: 0.8;
            // background-color:rgba(0,0,0,0.25);
        }
    }
    .video-info{
        width: 1100px;
        min-height: 74px;
        margin-top: -4px;
        background: rgba(255,255,255,0.20);
        backdrop-filter: blur(40px);
        font-size: 16px;
        color: #FFF;
        display: flex;
        padding: 24px 45px;
        box-sizing: border-box;
    }
}
</style>