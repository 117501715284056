<template>
  <div class="color-line">
      <div class="cl-1"></div>
      <div class="cl-2"></div>
      <div class="cl-3"></div>
      <div class="cl-4"></div>
      <div class="cl-5"></div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less">
.color-line{
    width: 157px;
    height: 15px;
    opacity: 0.48;
    border: 1px solid #3686ff;
    display: flex;
    flex: 1;
    position: absolute;
    right:0;
    bottom: -2px;
    .cl-1{
        width: 50px;
        height: 15px;
        opacity: 0.8;
        margin-left: 37px;
        background: linear-gradient(180deg,#36fff3 0%, rgba(54,135,255,0.00) 100%);
    }
    .cl-2{
        width: 16px;
        height: 15px;
        opacity: 0.8;
        background: linear-gradient(180deg,#36fff3 0%, rgba(54,135,255,0.00) 100%);
    }
    .cl-3{
        width: 41px;
        height: 15px;
        opacity: 0.8;
        background: linear-gradient(180deg,#3687ff 0%, rgba(54,135,255,0.00) 100%);
    }
    .cl-4{
        width: 9px;
        height: 15px;
        opacity: 0.8;
        background: linear-gradient(180deg,#ff83cd 0%, rgba(250,148,208,0.00) 100%);
    }
    .cl-5{
        width: 20px;
        height: 15px;
        opacity: 0.8;
        background: linear-gradient(180deg,#ab46fb 0%, rgba(250,148,208,0.00) 100%);
    }
}

</style>