<template>
  <div class="prov-map">
    <div class="pm-map-bd">
      <div>
        <div class="pm-pro-title">
          <div>{{ pro_name }}-项目分布图</div>
          <div class="pm-pro-bl"></div>
        </div>
      </div>
      <div style="margin-left: 110px; position: relative; top: -7px">
        <div
          id="pro-map"
          ref="promap"
          style="width: 520px; height: 545px"
        ></div>
      </div>
      <div class="map-cr"></div>
      <div class="map-tt">
        <div class="map-tt-s">{{ now_count || 0 }}</div>
        <div class="map-tt-t">{{ totl_count || 0 }}</div>
        <div
          style="
            position: absolute;
            width: max-content;
            color: #fff;
            bottom: -92px;
          "
        >
          总项目数/<span style="font-size: 12px; opacity: 0.8">当前省市</span>
        </div>
      </div>
      <div class="show-nu">
        <div v-for="(item, index) in map_data.city_color" :key="index">
          <span :style="{ background: item.color }"> </span>&nbsp;
          <span v-if="index === 0" style="margin-right: -10px"> 0</span>
          <span
            v-else-if="index === map_data.city_color.length - 1"
            style="margin-right: -10px"
          >
            >{{ item.min }}及以上</span
          >
          <span v-else> {{ item.min }}~{{ item.max }}</span>
        </div>
      </div>
    </div>
    <div class="pm-right-bd">
      <div v-show="app_list.length > 0">
        <div class="pm-pro-name">{{ active_pro_info.name || "" }}</div>
        <!-- two-line -->
        <div class="pm-pro-name-1">
          <i class="el-icon-location-outline" style="margin-right: 10px"></i
          >{{ active_pro_info.pro_adr || "" }}
        </div>
        <div class="pm-pro-name-bd" id="pm-pro-name-bd" style="text-align: left; opacity: 0.85">
          <span v-if="active_pro_info.pro_info" v-html="pro_info_n">{{
            active_pro_info.pro_info
          }}</span>
          <img
            v-else
            src="../assets/nodata.png"
            alt=""
            style="margin: 0 auto; display: block; margin-top: 82px"
          />
        </div>
        <div
          class="pm-pro-more"
          v-if="active_pro_info.show_detail == 1 && active_pro_info.pro_info"
          @click="goPage"
        >
          查看详情
        </div>
        <div
          class="pm-pro-more"
          style="margin: 0 auto"
          v-if="active_pro_info.show_detail == 1 && !active_pro_info.pro_info"
          @click="goPage"
        >
          查看详情
        </div>
        <div
          class="icon-arrow-left"
          @click="prePage"
          v-show="app_list.length > 1"
        >
          上一个
        </div>
        <div
          class="icon-arrow-right"
          @click="nexPage"
          v-show="app_list.length > 1"
        >
          下一个
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { template1 } from "../api/getdata"; //引入接口
export default {
  props: {
    pro_info: {},
    map_data: {},
    city_data: {},
  },

  // watch:{
  //     map_data(){
  //         console.log(this.map_data)
  //     },
  //     pro_info(){
  //         console.log(this.pro_info)
  //     },
  //     city_data(){
  //         console.log(this.city_data)
  //     },
  // },
  data() {
    return {
      airDate: [],
      active_pro_index: 0,
      active_pro_info: {},
      app_list: [],
      cityre_data: {},
    };
  },
  computed: {
    pro_name() {
      return this.pro_info.name || "";
    },
    totl_count() {
      return this.map_data?.platforms?.length || "";
    },
    now_count() {
      return this.city_data[this.pro_name]?.length || "";
    },
    pro_info_n() {
      let text = "";
      if (this.active_pro_info?.pro_info) {
        text = this.active_pro_info.pro_info.replace(/\n|\r\n/g, "<br>");
      }
      return text;
    },
  },
  watch: {
    pro_name() {
      this.initData();
    },
    map_data() {
      this.initData();
    },
  },
  methods: {
    initChart() {
      if (!this.pro_name) {
        return;
      }
      let ret = require("../../public/static/map/province/" +
        this.pro_info.key +
        ".json");
      let color_list = ['#87E2FF','#4FA0FF','#2C5AFF','#2405E3','#1407A3']
      let splitList = this.map_data.city_color.map((ele,index) => {
        if (ele.max === 0) {
          return { start: 0, end: 0, label: "", color: "#87E2FF" };
        } else {
          return { start: ele.min, end: ele.max, label: "", color: color_list[index] };
        }
      });
      function foundCityIp(citycode) {
        for (let item of ret.features) {
          if (item.id === citycode) {
            return item.properties.cp;
          }
        }
      }
      let city_data = (this.city_data[this.pro_name] || []).map((ele) => {
        return {
          name: ele.city_name || "",
          coord: foundCityIp(ele.city_code) || "",
          num: this.cityre_data[ele.city_name],
        };
      });
      this.xhartInstance = this.$echarts.init(this.$refs.promap);
      // 获取中国矢量数据
      this.$echarts.registerMap(this.pro_info.key, ret);
      const initOption = {
        tooltip : {
          trigger: 'item',
          borderColor: 'transparent',
          borderWidth: 0,
          padding:0,
          backgroundColor:"rgba(5,125,133,0.6)",
          formatter:  (params,data)=>{
                  //  +'<div style="color:#fff;font-size:12px;margin:0">'+this.value_name+'</div>'
                  let tipHtml = '<div style="background: rgba(5,125,133,0.45);padding:5px 12px;backdrop-filter: blur(5px);font-size:12px;">'
                  +'<div style="color:#fff;font-size:12px;margin:0">'+ params.name +'</div>'
                  if(params.value>0){
                      tipHtml+='<div style="color:#fff;font-weight: 700;font-size: 32px;color: transparent;height:37px;text-align:center;line-height:37px;font-weight: 700;-webkit-text-fill-color:#FFCF5F;-webkit-text-stroke:1px #FFF;margin:0">'+ params.value +'</div>'
                  }
                  tipHtml+='</div>'
                  return tipHtml
              }
        },
        geo: {
          type: "map",
          map: this.pro_info.key,
          grid: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: "100%",
            height: "100%",
          },
          label:{
            normal: {
                show: false,
                label: {
                    show: false,
                }
            },
            emphasis: {
                show: false,
                }
          },
          zoom: 1.25,
          itemStyle: {
            label:{
                show:false
            },
            normal: {
                borderColor: '#31FBFB',
                borderWidth: 1.5,
                // areaColor: '#74C3E1',
                areaColor: '#87E2FF',
                shadowColor: '#31FBFB',
            },
            emphasis: {
                shadowOffsetY: 2,
                shadowOffsetX: 2,
                shadowBlur: 12,
                areaColor: '#74C3E1',
                label:{
                    show:false
                },
                itemStyle: {
                    shadowBlur: 6,
                    shadowColor: 'yellow',
                    color: 'yellow'
                },
                },
            },
        },
        series: [
          {
            data: this.airDate,
            geoIndex: 0,
            type: "map",
          },
        ],
        dataRange: {
          x: "-1000 px", //图例横轴位置
          y: "-1000 px", //图例纵轴位置
          splitList: splitList,
        }, //各省地图颜色；start：值域开始值；end：值域结束值；label：图例名称；color：自定义颜色值；
      };
      this.xhartInstance.setOption(initOption);
    },
    goPage() {
      // this.$router.push({
      //     path:'/project',
      //     query:{
      //         uni_code:this.active_pro_info.uni_code||""
      //     }
      // })
      if(this.active_pro_info.way==1){
        template1('templatedetail',{uni_code:this.active_pro_info.uni_code}).then(ele=>{
          console.log(ele);
          let path = "/project"
          if(ele.data.template==1){
            path="/project1"
          }
          let routeData = this.$router.resolve({
            path,
            query: {
              uni_code: this.active_pro_info.uni_code || "",
              name:this.active_pro_info.name|| ""
            },
          });
          window.open(routeData.href, "_blank");
          })
        
      }else if(this.active_pro_info.way==2){
         let active_url = location.origin.replace('colorc','upkeep')
         let token = localStorage.getItem('token')
         let guidMini = location.pathname?.split('/')[1] || ''
         if(guidMini=='home.html'){guidMini=''}
         if(guidMini){
            window.open(active_url+'/'+guidMini+'?pid='+token+'&platform='+this.active_pro_info.platform_id)
         }else{
            window.open(active_url+'?pid='+token+'&platform='+this.active_pro_info.platform_id)
         }
         
      }else if(this.active_pro_info.way==3){
        window.open(this.active_pro_info.link, "_blank"); 
      }
      
      
    },
    nexPage() {
      if (this.active_pro_index < this.app_list.length - 1) {
        this.active_pro_index += 1;
      } else {
        this.active_pro_index = 0;
      }
      this.active_pro_info = this.app_list[this.active_pro_index];
      let lidv = document.getElementById('pm-pro-name-bd')
      lidv.scrollTop = 0
    },
    prePage() {
      if (this.active_pro_index > 0) {
        this.active_pro_index -= 1;
      } else {
        this.active_pro_index = this.app_list.length - 1;
      }
      this.active_pro_info = this.app_list[this.active_pro_index];
      let lidv = document.getElementById('pm-pro-name-bd')
      lidv.scrollTop = 0
    },
    initData() {
      let re_data = this.city_data[this.pro_name] || [];

      this.app_list = re_data.map((ele) => {
        let city_name = ele?.city_name ||''
        if(city_name==='undefined'){
          city_name = ''
        }
        return {
          ...ele,
          uni_code: ele.uni_code,
          name: ele.pro_name,
          pro_adr: this.pro_name + " " + city_name,
          pro_info: ele.desc,
          show_detail: ele.show_detail || "",
          
        };
      });
      this.cityre_data = {};
      re_data.forEach((ele) => {
        if (!this.cityre_data[ele.city_name]) {
          this.cityre_data[ele.city_name] = 1;
        } else {
          this.cityre_data[ele.city_name] += 1;
        }
      });
      this.airDate = [];
      for (let i in this.cityre_data) {
        this.airDate.push({ name: i, value: this.cityre_data[i] });
      }
      this.active_pro_info = this.app_list[0] || {};
      this.initChart();
    },
  },
  mounted() {
    this.initData();
  },
};
</script>

<style lang="less">
.pro_title {
  color: #fff;
  .el-dialog {
    width: 1200px;
    background-color: #434f5f;
    background-image: url("../assets/shanxipng.png");
    background-size: contain;
    background-repeat: no-repeat;
  }
  .el-dialog__header {
    //   background-color: transparent;
  }
}
.prov-map {
  // background-color: #434F5F;

  position: relative;
  > div {
    display: inline-block;
  }
  height: 758px;
  .pm-pro-title {
    font-size: 46px;
    font-weight: 400;
    color: #fff;
    margin: 24px;

    .pm-pro-bl {
      margin-top: 18px;
      width: 289px;
      height: 4px;
      opacity: 1;
      background: linear-gradient(270deg, #129bff 0%, #31fbfb);
    }
  }
  .pm-right-bd {
    position: relative;
    width: 400px;
    height: 611px;
    background-color: rgba(255, 255, 255, 0.1);
    float: right;
    top: 74px;
    left: -32px;
    backdrop-filter: blur(10px);
    .pm-pro-name {
      // margin: 29px 24px 16px 24px;
      // width: 352px;
      // line-height: 36px;
      // font-weight: 600;
      // text-align: center;
      // font-size: 32px;
      // color: #F85F64;
      margin: 24px;
      width: 343px;
      height: 50px;
      font-size: 24px;
      font-weight: 400;
      text-align: LEFT;
      color: #129bff;
      line-height: 26px;
      display: flex;
      align-items: center;
      padding-left: 5px;
      border-left: 4px solid #129bff;
      overflow: hidden;
      // width: 4px;
      // height: 43px;
      // opacity: 1;
      // background: #129bff;
    }
    .pm-pro-name-1 {
      width: 352px;
      // height: 43px;
      margin: 0 auto;
      text-align: left;
      color: #fff;
      font-size: 14px;
    }
    .pm-pro-name-bd {
      // height: 422px;
      max-height: 337px;
      // min-height: 126px;
      // width: 267px;
      width: 351px;
      overflow-x: hidden;
      overflow-y: auto;
      margin: 0 auto;
      // margin-top: 32px;
      margin-top: 20px;
      margin-bottom: 16px;
      font-size: 16px;
      color: #fff;
      line-height: 26px;
      text-align: center;
      &::-webkit-scrollbar {
        width: 0 !important;
      }
    }
    .pm-pro-more {
      // margin: 0 auto;
      // height: 32px;
      // width: 189px;
      // line-height: 32px;
      // text-align: center;
      width: 80px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      border-radius: 4px;
      background: rgba(255, 255, 255, 0.1);
      color: #fff;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      margin-left: 24px;
      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }
      &:active {
        background: rgba(255, 255, 255, 0.1);
      }
    }
    .icon-arrow-left,
    .icon-arrow-right {
      position: absolute;
      left: 24px;
      bottom: 30px;
      font-size: 14px;
      color: #fff;
      width: 170px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      background: #0a6ed1;
      cursor: pointer;
      &:hover {
        opacity: 0.85;
      }
      &:active {
        opacity: 0.95;
      }
    }
    .icon-arrow-right {
      left: auto;
      right: 24px;
    }
  }
  #prop-map {
    position: relative;
  }
  .map-cr {
    height: 91px;
    width: 89px;
    position: absolute;
    // left: 675px;
    left: 641px;
    bottom: 24px;
    background-image: url("../assets/mapcr.png");
    background-size: contain;
    background-repeat: no-repeat;
  }
  .map-tt {
    position: absolute;
    left: 618px;
    top: 11px;
    .map-tt-t {
      height: 68px;
      line-height: 68px;
      width: 68px;
      background-image: url("../assets/groupttblue.png");
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      font-size: 31px;
      overflow: hidden;
      text-align: center;
      color: #fff;
    }
    .map-tt-s {
      height: 35px;
      line-height: 35px;
      width: 34px;
      font-size: 15px;
      overflow: hidden;
      text-align: center;
      color: #fff;
      background-image: url("../assets/groupttyel1.png");
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      top: 31px;
      right: -93px;
    }
  }
  .pro-nu-icon {
    // position:absolute;
    cursor: pointer;
    img {
      height: 32px;
      width: 32px;
      border-radius: 50%;
      position: relative;
    }
    div {
      position: absolute;
      height: 32px;
      width: 32px;
      text-align: center;
      left: 0;
      line-height: 32px;
      overflow: hidden;
      color: #fff;
      top: 0;
    }
    .prov-name-icon {
      height: 30px;
      background-image: url("../assets/probgc.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      line-height: 30px;
      // position: absolute;
      width: 0;
      // opacity: 0;
      width: max-content;
      padding: 5px 10px;
      left: 16px;
      top: 2px;
      overflow: hidden;
      font-size: 16px;
      color: #fff;
      // display: none;
      // transition:all 0.5s ease;
    }
    &:hover {
      .prov-name-icon {
        // display: inline-block;
        width: max-content;
        padding: 0 5px 0 20px;
        opacity: 1;
        // transition:all 1s ease-out;
      }
    }
  }
  .show-nu {
    min-width: 400px;
    color: #fff;
    display: flex;
    // margin-left:189px;
    margin-left: 136px;
    position: absolute;
    bottom: 24px;
    div {
      margin-right: 16px;
    }
    span {
      display: inline-block;
      height: 12px;
      min-width: 36px;
      // margin-right: 2px;
    }
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-120px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
</style>
