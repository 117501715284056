<template>
  <div class="upimg">
    <!-- 上传前 -->
    <div v-if="uploadStatus == 'upload'" class="before_upload">
      <el-upload
        action=""
        list-type="picture"
        :show-file-list="false"
        :before-upload="beforeUpload"
        :http-request="upLoad"
        :on-change="imgSaveToUrl"
        class="box"
      >
        <img src="./img/tianjia.png" alt="" />
        <div>上传</div>
      </el-upload>
    </div>
    <!-- 上传中 -->
    <div v-if="uploadStatus == 'uploadding'" class="uploading">
      <img src="../../assets/cycle.png" alt="" />
      <div>上传中</div>
    </div>
    <!-- 上传完成后 -->
    <div v-if="uploadStatus == 'afterupload'" class="uploaddone">
      <div>上传中</div>
      <el-progress
        :show-text="false"
        :stroke-width="4"
        :percentage="showtime"
      ></el-progress>
    </div>
    <!-- 上传失败 -->
    <div v-if="uploadStatus == 'uploadError'" class="uploaderror">
      <div class="img">
        <img src="../../assets/uperror.png" alt="" />
        <div>image.png</div>
      </div>
      <div class="pop">
        <div>
          <el-upload
            action=""
            list-type="picture"
            :show-file-list="false"
            :before-upload="beforeUpload"
            :http-request="upLoad"
            :on-change="imgSaveToUrl"
          >
            <img src="../../assets/xiugai.png" alt="" />
          </el-upload>
        </div>
      </div>
    </div>
    <!-- 显示上传完成的图片 -->
    <div v-if="uploadStatus == 'uploaddone'" class="showupload">
      <!-- <img src="../../assets/bg.png" alt="" /> -->
      <img :src="active_url" alt="" />
      <div class="pop">
        <div>
          <el-upload
            action=""
            list-type="picture"
            :show-file-list="false"
            :before-upload="beforeUpload"
            :http-request="upLoad"
            :on-change="imgSaveToUrl"
          >
            <img src="../../assets/xiugai.png" alt="" />
          </el-upload>

          <el-image
            v-if="active_url"
            style="width: 40px; height: 40px"
            :src="require('../../assets/eyes.png')"
            :preview-src-list="[active_url]"
          >
          </el-image>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { uploadfile, uploadoss } from "../../api/getdata"; //引入接口
import md5 from "js-md5";
export default {
  data() {
    return {
      upload_imginfo: "", //上传图片的信息
      showtime: 0, //上传进度 表示百分比
      uploadStatus: "upload", //上传图片的状态
      file_key: "", //传给后台的图片的信息
      uploaddingimage: "",
      active_url:''
    };
  },
  mounted() {
    this.monitoring(); // 注册监听事件
  },
 
  methods: {
    //获取授权

    beforeUpload(ele) {
      console.log(ele);
      let file_type = ele.name.split(".").pop();
      let arr = ["jpg", "JPG", "jpeg", "JPEG", "png", "PNG", "gif", "GIF"];
      if (arr.indexOf(file_type) == -1) {
        this.$message.error("文件大小或格式不匹配");
        return false;
      }
      const isLt2M = ele.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error("上传文件大小不能超过 10M");
        return false;
      }
      let type = "detail_image";
      return new Promise((resolve, reject) => {
        this.uploadStatus = "uploadding";
        uploadfile({ type })
          .then((response) => {
            console.log(response);
            this.upload_imginfo = response.data;
            console.log(this.upload_imginfo);
            resolve(true);
          })
          .catch(() => {
            this.$message.error("获取授权失败，请检查网络或联系系统管理员");
            reject(false);
          });
      });
    },
    // 上传文件
    upLoad(file) {
      const _self = this;
      let formdate = new FormData();
      let file_name = md5(new Date().getTime() + file.file.name);
      let file_type = file.file.name.split(".").pop();
      let file_key = _self.upload_imginfo.dir + file_name + "." + file_type;
      console.log(file_key);
      formdate.append("key", file_key);
      formdate.append("OSSAccessKeyId", _self.upload_imginfo.access_key_id); //accessKeyId
      formdate.append("policy", _self.upload_imginfo.policy); //policy
      formdate.append("Signature", _self.upload_imginfo.signature); //签名
      formdate.append("success_action_status", 200);
      formdate.append("file", file.file);
      file.file.file_key = file_key;
      this.file_key = file_key;
      uploadoss(_self.upload_imginfo.host, formdate)
        .then(() => {
          let t = setInterval(() => {
            if (this.showtime >= 100) {
              this.showtime == 100;
              return;
            }
            this.showtime += 1;
          }, 10);
          this.uploadStatus = "afterupload";
          setTimeout(() => {
            this.uploadStatus = "uploaddone";
            // this.uploadStatus = "uploadError";
            clearInterval(t);
            this.showtime = 0;
          }, 1200);

          // file.url = this.active_url;
          this.active_url = this.uploaddingimage;
          this.sendMsg();
        })
        .catch(() => {
          this.uploadStatus = "afterupload";
          setTimeout(() => {
            this.uploadStatus = "uploadError";

            this.$message.error("上传失败");
          }, 2000);
        });
    },
    //选择上传的文件
    imgSaveToUrl(file) {
      let url = URL.createObjectURL(file.raw);
      this.uploaddingimage = url;
    },
    // 给父组件传值
    sendMsg() {
      this.$emit("getMsg", this.file_key); // 调用父组件传递过来的方法，同时把数据传递出去
    },
    // 监听事件父组件
    monitoring() {
      this.$on("childMethod", (res) => {
        console.log(res);
        if(res){
       
          this.active_url = res
          this.uploadStatus="uploaddone"
        }else{
          this.uploadStatus="upload"
        }
        
      });
    },
  },
};
</script>

<style lang="less">
.upimg {
  width: 370px;
  height: 218px;
  margin: 0 auto;
  .before_upload {
    width: 100%;
    height: 100%;
    border: 1px dashed rgba(106, 109, 112, 0.45);
    .box {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 14px;
        height: 14px;
      }
    }
  }
  .uploading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px dashed rgba(255, 255, 255, 0.65);
    width: 100%;
    height: 100%;
    > div {
      margin-top: 18px;
    }
    @keyframes turn {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
    img {
      animation: turn 1s linear infinite;
    }
  }
  .uploaddone {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border: 1px dashed rgba(106, 109, 112, 0.45);
    .el-progress {
      margin-top: 18px;
      width: 110px;
    }
  }
  .uploaderror {
    display: flex;

    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px solid #f85f64;
    border-radius: 2px;
    .pop {
      display: none;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      top: 0;
      left: 0;
      > div {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        img {
          width: 40px;
          height: 40px;
        }
      }
    }
    .img {
      display: flex;
      flex-direction: column;

      align-items: center;
      justify-content: center;

      img {
        width: 30px;
        height: 30px;
      }
      > div {
        width: 88px;
        height: 22px;
        opacity: 1;
        margin-top: 16px;
        font-size: 14px;
        font-family: PingFang HK, PingFang HK-Regular;
        font-weight: 400;
        text-align: CENTER;
        color: #f85f64;
        line-height: 22px;
      }
    }
  }
  .uploaderror:hover .pop {
    display: block;
  }
  .showupload {
    position: relative;
    width: 100%;
    height: 100%;

    padding: 9px;
    box-sizing: border-box;
    border: 1px solid #ecbbbe;
    border-radius: 2px;
    text-align: center;
    .pop {
      display: none;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      top: 0;
      left: 0;
      > div {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        .el-image {
          img {
            width: 40px;
            height: 40px;
            color: #fff;
          }
        }
        img {
          width: 40px;
          height: 40px;
        }
      }
    }
    img {
      // max-height: 100%;
      // max-width: 100%;
      width: 100%;
      height: 100%;

      object-fit: contain;
    }
  }
  .showupload:hover .pop {
    display: block;
  }
}
</style>