<template>
  <div class="edit-homel1">
    <div>
      <div
        style="
          font-weight: 500;
          line-height: 24px;
          height: 24px;
          font-size: 16px;
        "
      >
        文本信息
      </div>
      <div class="cut-line"></div>
      <div
        class="flex-bte"
        style="margin-bottom: 24px; position: relative; margin-right: 95px"
      >
        <div class="flex-bte">
          <span
            style="
              line-height: 32px;
              width: 120px;
              padding-right: 10px;
              text-align: right;
            "
            >区块标题：</span
          >
          <div>
            <el-input
              v-model="dialog_info.title"
              @change="endEmpty(dialog_info, 'title')"
              :class="{ errorbd: check_type && !dialog_info.title }"
              maxlength="10"
              placeholder="请输入，10字内"
            ></el-input>
            <div
              class="error-txt"
              style="left: 140px"
              v-if="check_type && !dialog_info.title"
            >
              区块标题不得为空
            </div>
          </div>
        </div>
        <div class="flex-bte" style="position: relative">
          <span style="line-height: 32px">企业标语：</span>
          <div>
            <el-input
              v-model="dialog_info.slogan"
              @change="endEmpty(dialog_info, 'slogan')"
              :class="{ errorbd: check_type && !dialog_info.slogan }"
              maxlength="20"
              placeholder="请输入，20字内"
            ></el-input>
            <div
              class="error-txt"
              style="left: 80px"
              v-if="check_type && !dialog_info.slogan"
            >
              企业标语不得为空
            </div>
          </div>
        </div>
      </div>
      <div class="flex-bte" style="margin-bottom: 24px; position: relative">
        <div class="flex-bte">
          <span
            style="
              line-height: 32px;
              width: 120px;
              padding-right: 10px;
              text-align: right;
            "
            >旭日图_图名：</span
          >
          <div>
            <el-input
              v-model="dialog_info.chart_name"
              @change="endEmpty(dialog_info, 'chart_name')"
              :class="{ errorbd: check_type && !dialog_info.chart_name }"
              maxlength="20"
              placeholder="请输入，20字内"
            ></el-input>
            <div
              class="error-txt"
              style="left: 140px"
              v-if="check_type && !dialog_info.chart_name"
            >
              旭日图_图名不得为空
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex-bte"
        style="margin-bottom: 24px; position: relative; margin-right: 95px"
      >
        <div class="flex-bte">
          <span
            style="
              line-height: 32px;
              width: 120px;
              padding-right: 10px;
              text-align: right;
            "
            >旭日图_数值名称：</span
          >
          <div>
            <el-input
              v-model="dialog_info.value_name"
              @change="endEmpty(dialog_info, 'value_name')"
              maxlength="10"
              placeholder="请输入，10字内"
            ></el-input>
          </div>
        </div>
        <div class="flex-bte">
          <span style="line-height: 32px">旭日图_数值单位：</span>
          <el-input
            v-model="dialog_info.value_unit"
            @change="endEmpty(dialog_info, 'value_unit')"
            maxlength="10"
            placeholder="请输入，10字内"
          ></el-input>
        </div>
      </div>

      <div
        style="
          font-weight: 500;
          line-height: 24px;
          height: 24px;
          font-size: 16px;
          margin-top: 24px;
        "
      >
        视频列表
      </div>
      <div class="cut-line"></div>
      <div class="video-list-n">
        <div class="img-list" v-for="(item, index) in img_list" :key="index">
          <div class="img-box">
            <video
              v-if="item.status == 'beforeupload'"
              style="height: 100%; width: 100%"
              :src="item.url"
              muted
            ></video>

            <div v-if="item.status == 'uploadding'" class="uploadding">
              <img src="../assets/cycle.png" alt="" />
              <div>上传中</div>
            </div>

            <div v-if="item.status == 'afterupload'" class="afterupload">
              <div>上传中</div>
              <el-progress
                :show-text="false"
                :stroke-width="4"
                :percentage="show_time"
              >
              </el-progress>
            </div>
            <div class="edit-box">
              <!-- <i class="edit-icon"></i> -->
              <el-upload
                action=""
                list-type="picture"
                :show-file-list="false"
                :before-upload="(ele) => beforeUpload(ele, 't', index)"
                :http-request="(file) => reupLoad(file, index)"
                :on-change="imgSaveToUrl"
              >
                <i class="edit-icon"></i>
              </el-upload>
              <i class="del-icon" @click="delAction(index)"></i>
            </div>
          </div>
          <el-input
            v-model="item.desc"
            @change="endEmpty(item, 'desc')"
            type="textarea"
            :rows="3"
            resize="none"
            maxlength="100"
            placeholder="请输入，100字内"
          ></el-input>
        </div>
        <div style="position: relative; height: 265px; width: 282px">
          <el-upload
            action=""
            list-type="picture-card"
            :file-list="video_list"
            :before-upload="beforeUpload"
            :http-request="upLoad"
            :on-change="imgSaveToUrl"
            :show-file-list="false"
          >
            <div v-if="upload_status == 'beforeupload'" class="upload-text">
              <div style="padding-top: 100px">
                请上传500M以内的<br />mp4格式文件
              </div>
            </div>
          </el-upload>
          <uploadStatus
            v-if="upload_status != 'beforeupload'"
            :show_time="show_time"
            :video_list="video_list"
            :upload_status="upload_status"
            @beforeupload="beforeUpload"
            @httprequest="upLoad"
            @onchange="imgSaveToUrl"
          />
        </div>
      </div>
      <div
        style="
          font-weight: 500;
          line-height: 24px;
          height: 24px;
          font-size: 16px;
          margin-top: 24px;
        "
      >
        图表数据
      </div>
      <div class="cut-line"></div>
      <div class="top-title">
        <span style="width: 97px">序号</span>
        <span
          style="width: 481px; border-left: 1px solid rgba(255, 255, 255, 0.19)"
          >全称</span
        >
        <span
          style="width: 194px; border-left: 1px solid rgba(255, 255, 255, 0.19)"
          >简称</span
        >
        <span
          style="width: 90px; border-left: 1px solid rgba(255, 255, 255, 0.19)"
          >数值</span
        >
        <span
          style="width: 121px; border-left: 1px solid rgba(255, 255, 255, 0.19)"
          >操作</span
        >
      </div>
      <!-- {index_n:'1'} -->
      <!-- @node-drag-enter="dragEnter"-->
      <el-tree
        :data="data"
        v-show="!data || data.length > 0"
        node-key="id"
        default-expand-all
        draggable
        style="poaition: relative"
        @node-drag-start="handleDragStart"
        @node-drop="nodeDrop"
        :allow-drop="dragEnter"
        :expand-on-click-node="false"
      >
        <span class="custom-tree-node top-title-1" slot-scope="{ node, data }">
          <span style="width: 97px">{{ data.index_n }}</span>
          <span
            class="width481"
            @dragstart.stop="dragStart"
            @move.stop
            style="
              width: 481px;
              border-left: 1px solid rgba(255, 255, 255, 0.19);
              position: absolute;
              left: 97px;
            "
          >
            <el-input
              v-model="data.name"
              :class="{ errorbd: check_type && !data.name }"
              @change="endEmpty(data, 'name')"
              maxlength="30"
              placeholder="请输入，30字内"
            ></el-input>
          </span>
          <span
            class="width194"
            style="
              width: 194px;
              border-left: 1px solid rgba(255, 255, 255, 0.19);
              position: absolute;
              left: 578px;
            "
          >
            <el-input
              v-model="data.short_name"
              @change="endEmpty(data, 'short_name')"
              maxlength="10"
              placeholder="请输入，10字内"
            ></el-input>
          </span>
          <span
            class="width90"
            style="
              width: 90px;
              border-left: 1px solid rgba(255, 255, 255, 0.19);
              position: absolute;
              left: 772px;
            "
          >
            <el-input
              v-model="data.value"
              maxlength="9"
              @change="formatN(data)"
              @input="formatVal(data)"
              placeholder=""
            ></el-input>
          </span>
          <span
            style="
              width: 121px;
              border-left: 1px solid rgba(255, 255, 255, 0);
              position: absolute;
              left: 862px;
              margin-top: -16px;
              height: 32px;
            "
          >
            <i class="del-icon" @click="() => remove(node, data)"></i>
            <el-tooltip
              class="item"
              effect="dark"
              content="添加同级"
              v-if="node.level < 4"
              placement="top"
            >
              <i class="add-icon" @click="() => appendLeve(node, data)"></i>
            </el-tooltip>
            <i class="p-icon" v-else></i>
            <el-tooltip
              class="item"
              effect="dark"
              content="添加子级"
              v-if="node.level < 3"
              placement="top"
            >
              <i class="drag-icon" @click="() => append(node, data)"></i>
            </el-tooltip>
          </span>
        </span>
      </el-tree>
      <div
        style="
          height: 64px;
          background: rgba(255, 255, 255, 0.12);
          width: 100%;
          text-align: center;
          line-height: 64px;
        "
        v-if="data && data.length == 0"
      >
        <img
          src="../assets/emptyicon.png"
          alt=""
          style="height: 15px; width: 24px; vertical-align: middle"
        />
        暂无参数，
        <span
          style="text-decoration: underline; cursor: pointer"
          class="hover-color"
          @click="addData"
          >添加</span
        >
      </div>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="dialog_visible"
      :modal-append-to-body="true"
      :append-to-body="true"
      :close-on-click-modal="false"
      width="418px"
      top="30vh"
      class="home-edit-dialog small-d"
    >
      <span>删除当前数据后无法找回，是否确认删除？</span>
      <span slot="footer" class="app-dialog-footer">
        <span class="cancel-btn" @click="dialog_visible = false">取消</span>
        <span class="submit-btn" type="primary" @click="submitClic">确认</span>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import moment from moment
import md5 from "js-md5";
import { Loading } from "element-ui";
import uploadStatus from "./uploadstatus.vue";
import { introduceinter, uploadoss, uploadfile } from "../api/getdata";
export default {
  components: { uploadStatus },
  props: {
    map_info: { legend: [], data: [] },
  },
  watch: {
    map_info() {
      this.dialog_info = this.map_info || {};

      this.map_info.videos = this.map_info.videos.map((item) => {
        return { ...item, status: "beforeupload" };
      });
      this.img_list = this.map_info.videos || [];
      this.reSetIndex();
    },
  },
  data() {
    return {
      edit_type: false,
      dialog_visible: false,
      upload_mode: false,
      loading: false,
      edit_dialog_visible: false,
      dialog_info: this.map_info || {},
      now_data: { values: [], date: "" },
      active_index: "",
      img_list: this.map_info.videos || [],
      video_list: [],
      active_url: "",
      del_index: "",
      upload_imginfo: {},
      // {name: '',short_name:'', value:0,children: []}
      data: [],
      check_type: false,
      check: true,
      upload_status: "beforeupload",
      show_time: 0,
    };
  },
  computed: {
    legend() {
      return this.dialog_info.legend || [];
    },
  },
  methods: {
    addLegend() {
      if (!this.dialog_info.legend) {
        this.$set(this.dialog_info, "legend", []);
      }
      if (!this.dialog_info.data) {
        this.$set(this.dialog_info, "data", []);
        this.dialog_info.data.map((ele) => {
          ele.values = [];
        });
      }
      let index = this.dialog_info.legend.length + 1;
      let arr = [...this.dialog_info.legend, "图例名称" + index];
      this.$set(this.dialog_info, "legend", arr);
      this.dialog_info.data.map((ele) => {
        if (ele.values) {
          ele.values = [...ele.values, ""];
        } else {
          ele.values = [""];
        }
      });
      // this.now_data.values =  [...this.now_data.values,'']
    },
    addData() {
      this.data.push({ index_n: "1" });
    },
    addDate() {
      let date = new Date();
      let full_year = date.getFullYear();
      let full_month = date.getMonth() + 1;
      if (!this.dialog_info.data) {
        this.$set(this.dialog_info, "data", []);
      }
      let values = this.legend.map((ele) => {
        return "";
      });
      this.$set(this.dialog_info, "data", [
        ...this.dialog_info.data,
        { date: full_year + "-" + full_month, values },
      ]);
      // this.dialog_info.data.push({date:full_year+'-'+full_month,value})
    },
    deleItem(index) {
      this.dialog_info.legend.splice(index, 1);
      this.dialog_info.data.forEach((ele) => {
        ele.splice(index, 1);
      });
    },
    saveEdit() {
      this.check_type = true;
      if (!this.dialog_info.title) {
        this.$message.error({
          showClose: true,
          message: "失败：标题不得为空",
        });
        return;
      }
      if (!this.dialog_info.slogan) {
        this.$message.error({
          showClose: true,
          message: "失败：企业标语不得为空",
        });
        return;
      }
      if (!this.dialog_info.chart_name) {
        this.$message.error({
          showClose: true,
          message: "失败：旭日图_图名不得为空",
        });
        return;
      }
      let videos = this.img_list.map((ele) => {
        return { url: ele.file?.file_key || ele.url, desc: ele.desc };
      });
      this.check = true;
      this.setName(this.data);
      if (!this.check) {
        return;
      }
      let arg = {
        ...this.dialog_info,
        videos,
        chart_data: this.data,
      };

      introduceinter("update", arg).then((ele) => {
        this.$message.success({
          showClose: true,
          message: "成功：操作成功",
        });
        setTimeout(() => {
          this.$emit("updateDate");
        }, 800);
        this.$emit("update:visible", false);
      });
    },
    append(node, data) {
      if (!data.children) {
        this.$set(data, "children", []);
      }
      let index_n = data.index_n + "." + (data.children.length + 1);
      let newChild = {
        index_n,
        name: "",
        short_name: "",
        value: null,
        children: [],
      };
      data.children.push(newChild);
    },
    appendLeve(node, data) {
      let newChild = { name: "", short_name: "", value: null, children: [] };
      const parent = node.parent;
      const parent_data = parent.data;
      if (node.level === 1) {
        newChild.index_n = this.data.length + 1;
        parent_data.push(newChild);
      } else {
        if (!parent_data.children) {
          this.$set(parent_data, "children", []);
        }
        newChild.index_n =
          parent_data.index_n + "." + (parent_data.children.length + 1);
        parent_data.children.push(newChild);
      }
    },
    remove(node, data) {
      // if (this.data.length < 2) {
      //   return;
      // }
      const parent = node.parent;
      const children = parent.data.children || parent.data;
      const index = children.findIndex((d) => d.index_n === data.index_n);
      children.splice(index, 1);
      this.reSetIndex();
    },
    beforeUpload(ele, t, index) {
      let file_type = ele.name.split(".").pop();
      let arr = ["mp4"];
      if (arr.indexOf(file_type) == -1) {
        this.$message.error("文件格式只支持mp4类型");
        return false;
      }
      const isLt2M = ele.size / 1024 / 1024 < 500;
      if (!isLt2M) {
        this.$message.error("上传文件大小不能超过 500M");
        return false;
      }
      let type = "introduce_video";
      if (!t) {
        this.upload_status = "uploadding";
      }
      return new Promise((resolve, reject) => {
        //从后台获取第一步所需的数据
        //getOssToken 获取OSS秘钥的接口地址
        uploadfile({ type })
          .then((response) => {
             
            if (index>=0) {
             this.$set(this.img_list[index], "status", "uploadding");
            }
            this.upload_imginfo = response?.data || {};
            resolve(true);
          })
          .catch(() => {
            this.upload_status = "beforeupload";
            this.$message.error("获取授权失败，请检查网络或联系系统管理员");
            reject(false);
          });
      });
    },
    upLoad(file, file_list) {
      const _self = this;
      let formdate = new FormData();
      let file_name = md5(new Date().getTime + file.file.name);
      let file_type = file.file.name.split(".").pop();
      let file_key = _self.upload_imginfo.dir + file_name + "." + file_type;
      formdate.append("key", file_key);
      formdate.append("OSSAccessKeyId", _self.upload_imginfo.access_key_id); //accessKeyId
      formdate.append("policy", _self.upload_imginfo.policy); //policy
      formdate.append("Signature", _self.upload_imginfo.signature); //签名
      formdate.append("success_action_status", 200);
      formdate.append("file", file.file);
      file.file.file_key = file_key;
      this.upload_mode = true;
      // this.loading = Loading.service({background:"rgba(0, 0, 0, 0.4)"})

      uploadoss(_self.upload_imginfo.host, formdate)
        .then(() => {
          let t = setInterval(() => {
            this.show_time += 10;
            if (this.show_time >= 100) {
              this.show_time == 100;
            }
          }, 100);
          this.upload_status = "afterupload";
          setTimeout(() => {
            this.upload_status = "beforeupload";
            clearInterval(t);
            this.show_time = 0;
            file.url = this.active_url;
             file.status = "beforeupload";
            this.img_list.push(file);
          }, 1200);
        })
        .catch(() => {
          // this.uploadStatus = "afterupload";
          this.upload_status = "uploadError";
          this.$message.error("上传失败");
          // this.loading.close();
        });
    },
    reupLoad(file, index) {
      const _self = this;
      let formdate = new FormData();
      let file_name = md5(new Date().getTime + file.file.name);
      let file_type = file.file.name.split(".").pop();
      let file_key = _self.upload_imginfo.dir + file_name + "." + file_type;
      formdate.append("key", file_key);
      formdate.append("OSSAccessKeyId", _self.upload_imginfo.access_key_id); //accessKeyId
      formdate.append("policy", _self.upload_imginfo.policy); //policy
      formdate.append("Signature", _self.upload_imginfo.signature); //签名
      formdate.append("success_action_status", 200);
      formdate.append("file", file.file);
      file.file.file_key = file_key;
      this.upload_mode = true;
    //   this.loading = Loading.service({ background: "rgba(0, 0, 0, 0.4)" });
      uploadoss(_self.upload_imginfo.host, formdate)
        .then(() => {
          let t = setInterval(() => {
            if (this.show_time >= 100) {
              this.show_time == 100;
              return;
            }
            this.show_time += 10;
          }, 100);

          this.$set(this.img_list[index], "status", "afterupload");
          setTimeout(() => {
            this.$set(this.img_list[index], "status", "beforeupload");
            clearInterval(t);
            this.show_time = 0;
            file.url = this.active_url;
            let item = this.img_list[index];
            this.$set(this.img_list, index, { ...item, ...file });
          }, 1200);
          //   file.url = this.active_url;
          //   this.$set(this.img_list, index, file);
          //   this.loading.close();
        })
        .catch(() => {
        //   this.loading.close();
          this.$message.error("图片上传失败");
        });
    },
    imgSaveToUrl(file) {
      this.active_url = file.url;
    },
    delAction(index) {
      this.del_index = index;
      this.dialog_visible = true;
    },
    submitClic() {
      this.img_list.splice(this.del_index, 1);
      this.dialog_visible = false;
    },
    dragEnter(node, enter, event) {
      if (enter.level > 2) {
        return false;
      } else {
        return true;
      }
      // else if(node.level==2){
      //     console.log(node.childNodes);
      //     if(node.childNodes){
      //         return false
      //     }else{
      //         return true
      //     }
      // }else if(node.level==1){
      //     if(node.childNodes){
      //         if(node.childNodes.length>0){

      //         }
      //     }else{
      //         return true
      //     }
      //     // console.log(node.childNodes,node.childNodes.childNodes);
      //     // if(node.childNodes&&node.childNodes.childNodes){
      //     //     console.log(22222211111);
      //     //     return false
      //     // }else{
      //     //     return true
      //     // }
      // }else{
      //     return true
      // }
    },
    handleDragStart(node, event) {
      if (event.clientX > 315) {
        return false;
      }
    },
    nodeDrop() {
      this.reSetIndex();
    },

    formatVal(data) {
      data.value =
        data.value
          .replace(/[^-\d.]/g, "")
          .replace(".", "$#$")
          .replace(/\./g, "")
          .replace("$#$", ".") || "";
      if ([".", "-", "0"].includes(data.value.slice(-1))) {
        return;
      }
      if (!data.value || isNaN(data.value)) {
        data.value = null;
        return;
      }
      if (data.value == null) {
        return;
      }
      data.value = parseFloat(data.value);
    },
    formatN(data) {
      if (data.value == null) {
        return;
      } else {
        data.value = Number(data.value);
      }
    },
    setIndexN(array, p_index) {
      p_index = p_index ? p_index + "." : "";
      array.forEach((ele, index) => {
        ele.index_n = p_index + (index + 1);
        if (!ele.children) {
          return;
        }
        this.setIndexN(ele.children, ele.index_n);
      });
    },
    setName(array) {
      for (let ele of array) {
        if (!ele.name) {
          this.$message.error("序号 " + ele.index_n + " 全称未填写");
          this.check = false;
          break;
        }
        if (ele.children) {
          this.setName(ele.children);
        }
      }
      // array.map(ele=>{
      //     if(!ele.name){ele.name=String(ele.index_n||'--')}
      //     if(!ele.children){
      //         return
      //     }
      //     this.setName(ele.children)
      // })
    },
    reSetIndex() {
      if (this.map_info?.chart_data || this.map_info?.chart_data) {
        this.setIndexN(this.map_info?.chart_data || []);
        this.data = this.map_info?.chart_data;
      } else {
        this.setIndexN(this.data);
      }
      //
    },
  },
  mounted() {
    this.reSetIndex();
  },
};
</script>

<style lang="less">
.edit-homel1 {
  .flex-bte .con-l div.con-l-b {
    height: auto;
    max-height: 290px;
    overflow-y: auto;
    padding: 0;
    &::-webkit-scrollbar {
      display: none;
    }
    div {
      cursor: pointer;
      padding: 0;
      &:hover {
        opacity: 0.85;
      }
    }
    .itemlist {
      padding: 0 16px;
    }
  }
  .el-input__icon {
    line-height: 1;
  }
  .el-date-editor input.el-input__inner {
    width: 193px;
  }
  .img-list {
    width: 282px;
    height: 265px;
    opacity: 1;
    background: rgba(255, 255, 255, 0.12);
    border-radius: 6px;
    padding: 16px;
    box-sizing: border-box;
    margin-right: 16px;
    margin-bottom: 16px;
    .img-box {
      width: 249px;
      height: 138px;
      opacity: 1;
      border-radius: 10px;
      background: rgba(0, 0, 0, 0.15);
      position: relative;
      .uploadding {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
          width: 12px;
          height: 12px;
          animation: turn 1s linear infinite;
        }
        @keyframes turn {
          0% {
            -webkit-transform: rotate(0deg);
          }
          100% {
            -webkit-transform: rotate(360deg);
          }
        }
      }
      .afterupload {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .el-progress {
          margin-top: 18px;
          width: 110px;
        }
      }
      &:hover {
        .edit-box {
          opacity: 1;
        }
      }
      .edit-box {
        opacity: 0;
        display: flex;
        justify-content: center;
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.45);
        width: 100%;
      }
      i {
        height: 24px;
        width: 24px;
        margin-top: 55px;
        display: inline-block;
        cursor: pointer;
        &:hover {
          opacity: 0.85;
        }
      }
      i.del-icon {
        background-image: url("../assets/delicon.png");
      }
      i.edit-icon {
        margin-right: 8px;
        background-image: url("../assets/editicon1.png");
      }
    }
    .el-textarea__inner {
      width: 250px;
      height: 85px;
      opacity: 1;
      background: rgba(0, 0, 0, 0.65);
      border-radius: 4px;
      padding: 6px;
      margin-top: 10px;
      box-sizing: border-box;
      color: #fff;
      border: 1px solid transparent;
    }
  }
  .el-upload.el-upload--picture-card {
    width: 282px;
    height: 265px;
    line-height: 245px;
    opacity: 1;
    background: rgba(255, 255, 255, 0.12);
    border-radius: 6px;
    border: none;
    padding: 16px;
    // box-sizing: border-box;
  }
  .upload-text {
    width: 244px;
    height: 233px;
    opacity: 1;
    border: 1px dashed #ffffff;
    box-shadow: 0px 0px 48px 0px rgba(25, 39, 89, 0.06);
    text-align: CENTER;
    color: rgba(255, 255, 255, 0.85);
    line-height: 16px;
    text-shadow: 0px 0px 48px 0px rgba(25, 39, 89, 0.06);

    // &:hover{
    //    border: 1px solid #129BFF;
    //    color: #129BFF;
    // }
    // &:active{
    //     box-shadow: 0px 0px 48px 0px rgba(25,39,89,0.06), 0px 0px 4px 3px rgba(18,155,255,0.45) inset;
    // }
  }
  .el-upload-list.el-upload-list--picture-card {
    display: none;
  }
  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
    .del-icon,
    .add-icon,
    .drag-icon,
    .p-icon {
      height: 24px;
      width: 24px;
      margin-top: 12px;
      display: inline-block;
    }
    .add-icon,
    .p-icon {
      margin: 12px 6px 0 6px;
    }
    .del-icon {
      background-image: url("../assets/delicon.png");
      margin-left: 4px;
      &:hover {
        // opacity: 0.85;
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
    .add-icon {
      background-image: url("../assets/addicon.png");
      &:hover {
        // opacity: 0.85;
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
    .drag-icon {
      background-image: url("../assets/dragicon.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      &:hover {
        // opacity: 0.85;
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
  }
  .top-title,
  .top-title-1 {
    width: 100%;
    height: 36px;
    opacity: 1;
    background: rgba(0, 0, 0, 0.3);
    span {
      display: inline-block;
      line-height: 36px;
      padding-left: 8px;
      box-sizing: border-box;
    }
  }
  .top-title-1 {
    background-color: transparent;
    font-size: 14px;
    span {
      padding-left: 0;
    }
  }
  .el-tree-node:focus > .el-tree-node__content,
  .el-tree-node__content:hover {
    background-color: transparent;
    // opacity: 0.8;
  }
  .el-tree {
    background-color: #464b54;
    // background-color: rgba(255,255,255,0.12);
    color: rgba(255, 255, 255, 0.85);
    margin-bottom: 20px;
  }
  .el-tree-node__content {
    // height: 36px;
    height: 31px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.19);
  }

  .status2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px dashed rgba(255, 255, 255, 0.65);
    width: 100%;
    height: 100%;
    // background-color: #f00;
    .el-progress {
      margin-top: 18px;
      width: 110px;
    }
  }
}
.video-list-n {
  height: 315px;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  &::-webkit-scrollbar {
    width: 0 !important;
  }
}
.home-edit-dialog .el-dialog .el-dialog__body .edit-homel1 .cut-line {
  width: 982px;
}
.home-edit-dialog .el-dialog .width481 .el-input__inner {
  width: 480px;
}
.home-edit-dialog .el-dialog .width194 .el-input__inner {
  width: 194px;
}
.home-edit-dialog .el-dialog .width90 .el-input__inner {
  width: 90px;
}
.home-edit-dialog .el-dialog .width481 .el-input__inner {
  width: 481px;
}
.hover-color {
  &:hover {
    opacity: 0.85;
  }
  &:active {
    opacity: 0.9;
  }
}
.custom-tree-node {
  .el-input .el-input__inner {
    border: 1px solid rgba(255, 255, 255, 0.19);
    background-color: #464b54;
    //    border-bottom: 1px solid transparent;
    &:focus {
      background-color: #1c1f25;
    }
  }
}
</style>