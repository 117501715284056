<template>
  <div class="l1-bd">
    <div class="p-title">
      <span class="span">{{ data0.title }}</span><span v-if="data0.value_unit">({{ data0.value_unit }})</span>
      <img
        @click="goedit"
        v-if="showedit"
        src="../assets/editicon.png"
        alt=""
      />
    </div>
     <nodata  v-if="data0.data.length==0"></nodata>
    <div v-else class="dataList">
      <div
        class="dataList_item"
        v-for="(item, index) in data0.data"
        :key="index"
      >
        <div class="text">
          <!-- {{ item.name }}<span class="span2">{{ item.min }}~{{ item.max }}</span> -->
          <span class="span1">{{ item.name }}</span><span class="span2">{{ item.min }}~{{ item.max }}</span>
        </div>
        <img src="../assets/liudong.png" alt="" />
        <div class="num">{{ item.value }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import nodata from "./detail-nodata.vue"
export default {
  props: {
    data0: {
      type: Object,
      default:{}
    },
    showedit: {
      type: Boolean,
      default:false
    },
  },
    methods: {
    goedit() {
      this.$emit("fatheredit");
    }
  },
   components:{
    nodata
  }
};
</script>
<style lang="less">
.l1-bd {
  margin-bottom: 88px;
  height: 400px;
  width: 289px;

  margin-left: 64px;
  &::-webkit-scrollbar {
    width: 0 !important;
  }
  .dataList::-webkit-scrollbar {
    display: none;
  }

  .dataList {
    // padding-top: 22px;
    height: 370px;
    overflow-y: scroll;
    .dataList_item {
      margin-top: 16px;
      height: 102px;
    }
    .text {
     
     
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 600;
      text-align: LEFT;
      color: #ffffff;
      letter-spacing: 0px;
      display: flex;
      .span1{
        display: inline-block;
         max-width: 170px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      
      }
      .span2 {
        width: 95px;
        font-size: 16px;
        font-family: DIN Alternate, DIN Alternate-Bold;
        font-weight: 700;
        text-align: LEFT;
        color: #31fbfb;
        margin-left: 5px;
      }
    }
    img {
      width: 92px;
      height: 18px;
      opacity: 1;
      margin-top: 8px;
      margin-bottom: 4px;
    }
    .num {
      font-size: 34px;
      font-family: DIN Alternate, DIN Alternate-Bold;
      font-weight: 700;
      text-align: LEFT;
      color: #31fbfb;
      letter-spacing: 0px;
    }
  }
}
</style>