<template>
<div class="homer-1">
    <div class="hr1-action" v-if="tab_data.length>1||edit_type">
        <template v-for="(item,index) in tab_data">
            <span  v-show="(tab_data.length>1&&item.tab_name)||edit_type" :key="index" @click="changeActive(index)" :class="{active:action_img===index}">
                {{item.tab_name}}
                <i class="dlh-edit-icon" style="height:16px;width: 16px;margin-bottom: 5px;" v-if="edit_type" @click="showEdit(index)"></i>
            </span>
        </template>
    </div>
    <div v-if="tab_data.length<2&&!edit_type" style="margin-bottom:22px"></div>
    <div v-for="(item,index) in tab_data" :key="index" style="position:relative">
        <div v-show="action_img===index" >
             <!-- v-show="item.show_canvas" -->
            <div :id="'hr'+index" :ref="'hr'+index" :style="{height:(tab_data.length>1&&!edit_type)?'525px':'540px',width:'472px'}"></div>
            <!-- style="position: absolute;top: -130px;background: #000;width: 80%;" -->
             <!-- -->
            <div v-show="item.no_data" class="no-data-l" style="position: absolute;top: -130px;width: 80%;">
                <div class="no-data-l-img" style="margin-top:143px"></div>
                <div>暂无数据<span v-if="edit_type">,  <span style="color:#129BFF;cursor:pointer" @click="showEdit(action_img)">去添加</span></span></div>
            </div>
        </div>
    </div>
    <!-- <div v-show="action_img===1" id="hr1" ref="hr1" style="height:525px;width:472px"></div>
    <div v-show="action_img===2"  id="hr11" ref="hr11" style="height:525px;width:472px"></div> -->
</div>
  
</template>

<script>
// import retjson1 from "../assets/nutrients"
// import retjson from "../assets/nutrients1"
import {parallelmapinter} from "../api/getdata"
import * as echarts from 'echarts';
export default {
    props:{
        edit_type:{type:Boolean,default:false},
        map_data:{data:[]},
        retjson:[],

    },
    watch:{
        map_data(){
            this.tab_data=(this.map_data.data||[]).map(ele=>{
                if(ele?.parallel){
                    if(ele.parallel?.length>1){
                        ele.no_data = false
                    }else{
                        ele.no_data = true
                    }
                }
                return ele || {}
            })
            let retjson = []
            let schema=[]
            let format_data = this.tab_data.map(ele=>{
                let max_length = 0
                schema = (ele.parallel||[]).map((ele1,index)=>{
                    let name_l = ele1.name.length
                    if(name_l>max_length){
                        max_length = name_l
                    }
                    let name = ele1.name
                    if(name_l>5){
                        name = name.slice(0,5)+"\n"+name.slice(5)
                    }
                    
                    if(ele1.unit){
                        name = name+"\n" + "("+ele1.unit+ ")"
                    }
                    let min = null
                    let max = null
                    if(ele1.min!=0){
                        min=ele1.min
                    }
                    if(ele1.max!=0){
                        max=ele1.max
                    }
                    
                    return {dim: index+2, name, nameLocation: 'start',min,max}
                })
                retjson = (ele.values||[]).map(ele1=>{
                    return ['',ele.category[ele1.c_index],...ele1.values]
                })
                return {schema,retjson,max_length}
            })
            format_data.forEach((ele,index)=>{
                setTimeout(() => {
                    let left_w = 263
                    let left_l = 67
                    // if(ele.max_length<5){
                    //     canvas_l = 260
                    //     left_l = 80
                    // }
                    this.initChart(ele.retjson,'hr'+index,ele.schema,left_w,left_l,this.tab_data[index])
                }, 1000);
            })
        }
    },
    data(){
        return {
            tab_data:this.map_data.data||[],
            hlInstance:'',
            action_img:0,
            map_info:{data:[]},
            map_title_data:[],
            tab_list:[],
            show_canvas:true
        }
    },
    methods:{
        initChart(data,id,schema,left_w,left_l,item){
            if(schema.length<2){
                schema = []
            }
            let retjson = data
            var indices = {
                name: 0,
                group: 1,
                id: 16
            };

            // var groupCategories = [];
            let groupCategories = [...(item?.category||[])]
            let groupColors = ['#7FCCE5','#7FD2E5','#84DCC8','#84DCAE','#84DB7C','#98DC6C','#B7EC74','#DFF078','#F0DD78','#EAC977','#E1A173','#E18773','#DF647A','#CD64BF','#B161D8','#985FD8','#7F5DD8','#685CD7',
            '#5E68D8','#6583D9','#74A8E7','#7CBFFE','#63C9FF','#3BB4FF','#129BFF'];
            if(groupCategories.length<6){
                groupColors = ['#7FCCE5','#84DB7C','#E1A173','#CD64BF','#129BFF']
            }else if(groupCategories.length<11){
                groupColors = ['#7FCCE5','#84DCC8','#98DC6C','#F0DD78','#E1A173','#DF647A','#CD64BF','#685CD7','#7CBFFE','#129BFF']
            }
            normalizeData(retjson);
            let option = getOption(retjson,this)
            function normalizeData(originData) {
                var groupMap = {};
                originData.forEach(function (row) {
                    var groupName = row[indices.group];
                    if (!groupMap.hasOwnProperty(groupName)) {
                        groupMap[groupName] = 1;
                    }
                });
                originData.forEach(function (row) {
                    row.forEach(function (item, index) {
                        if (index !== indices.name
                            && index !== indices.group
                            && index !== indices.id
                        ) {
                            // row[index] = parseFloat(item) || '';
                            row[index] = item;
                        }
                    });
                });
            }
            function getOption(data,_this) {
                var lineStyle = {
                    normal: {
                        width: 2,
                        opacity: 0.8,
                        // width: 0.5,
                        // opacity: 0.7
                    },
                    emphasis:{
                        // 普通图表的高亮颜色
                        "color": "#FFF",
                        width:6
                    }
                };
                return {
                    tooltip: {
                        padding: 0,
                        // backgroundColor: '#222',
                        borderColor: 'transparent',
                        borderWidth: 0,
                        backgroundColor:"rgba(5,125,133,0.6)",
                        formatter: (params,data)=>{
                            let tipHtml = ''
                            params.data.forEach((ele,index)=>{
                                let parallel = _this.tab_data[_this.action_img]?.parallel[index-2]||{}
                                // console.log(ele);
                                let item_data = ele
                                if(!item_data){
                                    item_data = 0
                                }
                                if(index===0){
                                    return
                                }else if(index===1){
                                    tipHtml+='<div style="font-size:12px;color:#FFF;">'+ele+'</div>'
                                }else{
                                    tipHtml+='<div style="font-size:12px;color:#FFF;display:flex;justify-content:space-between;padding:0 5px"><span>'+parallel.name+' </span><span style="font-size:12px;margin-left:10px;color:#FFCF5F;"> '+item_data +' '+ parallel.unit+'</span></div>'

                                }
                            })
                            tipHtml='<div style="backdrop-filter: blur(5px);padding: 10px">'+ tipHtml +'</div>'
                            return tipHtml
                        }
                    },
                    legend:{
                        icon:'circle',
                        textStyle:{
                            color:'#FFF',
                            fontSize:12,
                            width: 30,
                            height:30,
                            overflow: "break"
                        },
                    },
                    visualMap: {
                        show: true,
                        symbol:'circle',
                        type: 'piecewise',
                        align:'left',
                        
                        categories: groupCategories,
                        dimension: indices.group,
                        hoverLink:true,
                        y:'left',
                        itemHeight:18,
                        itemWidth:10,
                        textGap:4,
                        itemGap:15,
                        itemSymbol:'circle',
                        inRange: {
                            color: groupColors, //['#d94e5d','#eac736','#50a3ba']
                        },
                        formatter: function (params) {
                            if(!params){
                                return
                            }
                        let newParamsName = "";// 最终拼接成的字符串
                        let paramsNameNumber = params?.length||0;// 实际标签的个数
                        let provideNumber = 6;// 每行能显示的字的个数
                        let rowNumber = Math.ceil(paramsNameNumber / provideNumber);// 换行的话，需要显示几行，向上取整
                        
                        // 条件等同于rowNumber>1
                        if (paramsNameNumber > provideNumber) {
                            for (let p = 0; p < rowNumber; p++) {
                                let tempStr = "";// 表示每一次截取的字符串
                                let start = p * provideNumber;// 开始截取的位置
                                let end = start + provideNumber;// 结束截取的位置
                                // 此处特殊处理最后一行的索引值
                                if (p == rowNumber - 1) {
                                    // 最后一次不换行
                                    tempStr = params.substring(start, paramsNameNumber);
                                } else {
                                    // 每一次拼接字符串并换行
                                    tempStr = params.substring(start, end) + "\n";
                                }
                                newParamsName += tempStr;// 最终拼成的字符串
                            }
                        } else {
                            // 将旧标签的值赋给新标签
                            newParamsName = params;
                        }
                        //将最终的字符串返回
                        return newParamsName
                        },
                        top: 20,
                        left:"82%",
                        padding: [0,100,0,0],
                        textStyle: {
                            fontSize:12,
                            color: '#fff',
                            lineHeight:160,
                            width: 100,
                            height:10,
                            
                            
                            overflow: "break"
                        },
                        realtime: false
                    },
                    parallelAxis:schema,
                    parallel: {
                        // left:left_l,
                        left:95,
                        
                        top: 20,
                        bottom:20,
                        // top: 150,
                        // height: 300,
                        // width: left_w,
                        width: 272,
                        layout: 'vertical',
                        textStyle: {
                            fontSize:19,
                            width: 20,
                            overflow: "break",
                            color: '#fff'
                        },
                        parallelAxisDefault: {
                            type: 'value',
                            name: 'nutrients',
                            nameLocation: 'end',
                            nameGap: 48,
                            nameTextStyle: {
                                color: '#fff',
                                fontSize: 12,
                                width: 20,
                                overflow: "break",
                                align:'center'
                            },
                            axisLine: {
                                lineStyle: {
                                    color: '#aaa'
                                }
                            },
                            axisTick: {
                                lineStyle: {
                                    color: '#777'
                                }
                            },
                            splitLine: {
                                show: false
                            },
                            axisLabel:{
                                color: '#fff',
                                show:false,
                                inside:true
                            },
                            realtime: false
                        }
                    },
                    animation: false,
                    series: [
                        {
                            type: 'parallel',
                            lineStyle: lineStyle,
                            inactiveOpacity:0.05,
                            activeOpacity: 100,
                            progressive: 100,
                            smooth: true,
                            data: data,
                            
                        }
                    ]
                };
            }
            this.hlInstance = echarts.init(document.getElementById(id))
            this.hlInstance.setOption(option);
        },
        getdata(){
            parallelmapinter('detail').then(ele=>{
                this.map_info = ele.data||{data:[]}
                this.tab_list = new Array(this.map_info.number||0).fill({})
                this.map_info.data.forEach((ele,index)=>{
                    if(!ele){
                        ele={}
                    }
                    this.tab_list[index].title = ele.title||''
                })
            })
        },
        showEdit(index){
            this.$emit("showEditR1", index);
        },
        changeActive(index){
            this.action_img=index
        }
    },
    mounted(){
        // this.getdata()
        // this.initChart(retjson,'hr1',this.schema,250)
        // this.initChart(retjson1,'hr11',this.schema1,240)
    }
}
</script>

<style lang="less">
.homer-1{
    .hr1-action{
        height:45px;
        line-height: 55px;
        color:#FFF;
        font-size: 12px;
        margin-left: 12px;
        span{
            display: inline-block;
            height: 25px;
            line-height: 25px;
            padding: 0 8px;
            background-color: rgba(18,155,255,0.25);
            cursor: pointer;
            margin-right: 5px;
            border-radius: 2px;
            &:hover{
                opacity: 0.9;
            }
             &:active{
                opacity: 0.95;
            }
            &.active{
                background-color: #63c9ff;
                color: #000;
            }
        }
    }
    
}
</style>