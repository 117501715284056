<template>
  <div class="policy-files">
      <div class="left-p" style="position:relative">
        <el-dropdown placement="bottom" @command="handleCommand">
            <span class="el-dropdown-link">
                {{policy_category[active_category]}}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(item,index) in policy_category" :command="index" :key="index">{{item}}</el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>

        <div class="policy-box" style="margin-top:20px">
            <div class="policy-li" :class="{'active-item':active_index===index}" @click="changeActive(item,index)" v-for="(item,index) in reshow_file" :key="index">
                <i class="doll-icon" :style="{background:doll_color[item.c_index]}"></i>
                <span>{{item.name}}</span>
            </div>
            <div v-if="!reshow_file||reshow_file.length==0" style="text-align:center;margin-top:305px">
                <img src="../assets/nodata1.png" alt="">
                <div style="color:#FFF">暂无数据</div>
            </div>
        </div>
        <img style="height:198px;z-index: -1;width:198px;margin:0 auto;display: block;position: absolute;bottom:70px;left:62px" src="../assets/backper.png" alt="">
        <el-pagination
        background
        
        layout="prev, pager, next"
        :current-page.sync="current_page"
        :total="show_file.length"
        :pager-count='5'
        style="width:100%;">
        </el-pagination>
      </div>
      <div class="right-p">
        <embed width="1037" style="margin-top:-3px;margin-left: -8px;" height="900" v-if="active_item.pdf_url" :src="active_item.pdf_url+'#view=FitH,top&toolbar=0'" type="application/pdf" >
        <div v-if="!active_item.pdf_url" style="text-align:center;display: flex;justify-content: center;align-items: center;height: 100%;">
            <div>
                <img src="../assets/emptyicon.png" alt="">
                <div style="color:#FFF">暂无数据</div>
            </div>
            
        </div>
      </div>
  </div>
</template>

<script>
export default {
    props:{
        show_policy:{policy_category:[],policy_files:[]}
    },
    watch:{
        show_policy(){
            this.show_file=this.show_policy.policy_files ||[]
            this.policy_category=['全部',...this.show_policy.policy_category] ||[]
            if(this.show_file.length>0){
                this.active_item = this.show_file[0]
            }
        }
    },
    data(){
        return{
            active_category:0,
            active_item:this.show_policy.policy_files[0]||{},
            active_index:0,
            policy_category:['全部',...this.show_policy.policy_category] ||[],
            doll_color:['#129BFF','#3C8DAD','#00C3C3','#A3DA6E','#3EDBF0','#4120E6','#77ACF1','#F85F64','#F5A962','#FFCF5F','#129BFF','#C6C6C6'],
            show_file:this.show_policy.policy_files ||[],
            current_page:1
        }
    },
    methods:{
        handleCommand(command){
            this.active_category = command
            if(command===0){
                this.$set(this,'show_file',this.show_policy.policy_files)
            }else{
                let show_file = this.show_policy.policy_files.filter(ele=>{
                   return ele.c_index==this.active_category-1
                })
                this.show_file = [...show_file]
            }
            this.active_index = 0
            this.active_item = this.show_file[0] ||{}
        },
        changeActive(item,index){
            this.active_index = index
            this.active_item = item
        },
    },
    mounted(){
        if(this.show_file.length>0){
            this.active_item = this.show_file[0]
        }
    },
    computed:{
        reshow_file(){
            return this.show_file.slice((this.current_page-1)*10,(this.current_page)*10)
        }
    }
}
</script>

<style lang="less">
.policy-files{
    display: flex;
    justify-content:space-between;
    .left-p{
        display: inline-block;
        width: 321px;
        height: 901px;
        padding: 24px 16px;
        background: rgba(255,255,255,0.12);
        border-radius: 10px;
        box-sizing: border-box;
        .el-dropdown{
            color: #FFF;
            cursor: pointer;
        }
        .policy-box{
            // height: 570px;
            height: 745px;
            overflow: auto;
            &::-webkit-scrollbar {
                width: 0 !important;
            }
        }
    }
    .right-p{
        display: inline-block;
        width: 1009px;
        height: 901px;
        background: rgba(255,255,255,0.12);
        border-radius: 10px;
        box-shadow: 0px 1px 4px 1px rgba(255,255,255,0.45) inset;
        overflow: hidden;
    }
    .policy-li{
        width: 240px;
        height: 44px;
        padding: 10px 24px;
        margin-top:8px;
        background: rgba(255,255,255,0.10);
        font-size: 14px;
        color: #FFF;
        display: flex;
        align-items: center;
        cursor: pointer;
        box-shadow: 1px 1px 4px 0px transparent inset;
        border: 1px solid transparent;
        &.active-item,&:hover{
            background: rgba(18,155,255,0.50);
            border: 1px solid #129bff;
            box-shadow: 1px 1px 4px 0px rgba(255,255,255,0.45) inset;
        }
        &:active{
            opacity: 0.9;
        }
    }
    .doll-icon{
        display: inline-block;
        height: 10px;
        width: 10px;
        background-color: #fff;
        border-radius: 50%;
        position: relative;
        left: -12px;
    }
}
</style>