<template>
  <div class="l2-bd">
    <div class="p-title">
      {{ data1.title
      }}<span v-if="data1.value_unit">（{{ data1.value_unit }}）</span>
      <img
        @click="goedit"
        v-if="showedit"
        src="../assets/editicon.png"
        alt=""
      />
    </div>
    <nodata v-if="data1.data.length == 0"></nodata>
    <div v-else class="dataList">
      <div
        class="dataList_item"
        v-for="(item, index) in data1.data"
        :key="index"
      >
        <div class="item_left">
          <div class="p1">{{ item.name }}</div>
          <div class="p2">{{ item.min }}~{{ item.max }}</div>
        </div>
        <div class="item_right">{{ item.value }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import nodata from "./detail-nodata.vue";
export default {
  props: {
    data1: {
      type: Object,
      default: {},
    },
    showedit: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    goedit() {
      this.$emit("fatheredit");
    },
  },
  components: {
    nodata,
  },
};
</script>
<style lang="less">
.l2-bd {
  margin-bottom: 88px;
  height: 400px;
  width: 289px;

  margin-left: 64px;
  &::-webkit-scrollbar {
    width: 0 !important;
  }
  .dataList::-webkit-scrollbar {
    display: none;
  }
  .dataList {
    width: 100%;
    height: 370px;
    overflow-y: scroll;

    margin-top: 6px;
    .dataList_item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 18px;
      height: 44px;
      .item_left {
        .p1 {
          width: 170px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Semibold;
          font-weight: 600;
          text-align: LEFT;
          color: #ffffff;
          letter-spacing: 0px;
        }
        .p2 {
          font-size: 16px;
          font-family: DIN Alternate, DIN Alternate-Bold;
          font-weight: 700;
          text-align: LEFT;
          color: #31fbfb;
          letter-spacing: 0px;
        }
      }
      .item_right {
        font-size: 34px;
        font-family: DIN Alternate, DIN Alternate-Bold;
        font-weight: 700;
        text-align: RIGHT;
        color: #31fbfb;
        letter-spacing: 0px;
      }
    }
  }
}
</style>