<template>
  <div class="about-pg">
    <div class="go-back">
      <div @click="goHome">
        <i class="go-back-icon"></i>
      </div>
    </div>
    <div
      class="editc-btn no-select"
      v-if="Manage"
      @dblclick="changeEdit"
    ></div>
    <span v-if="edit_type" @click.stop="exitEdit" class="editc-btn-txt"
      >退出编辑</span
    >
    <div class="save-title">
      {{ edit_title.title || ""
      }}<i
        class="dlh-edit-icon"
        style="vertical-align: middle"
        @click="changeTitle"
        v-show="edit_type == 1"
      ></i>
    </div>
    <div style="margin-left: 283px; margin-bottom: 17px">
      <div
        :class="{ active: active_item === index, 'input-x': true }"
        @click="changeActiveTab(index)"
        v-for="(item, index) in tab_list"
        :key="index"
      >
        <span class="input">{{ item }}</span>
      </div>
      <i
        class="dlh-edit-icon"
        style="vertical-align: middle"
        @click="actionTab"
        v-show="edit_type == 1"
      ></i>
    </div>

    <div v-show="active_item === 0" class="ap-content ap-content1">
      <embed
        style="margin-top: -3px; margin-left: -8px"
        v-show="show_pdf.pdf_url"
        width="1364"
        height="877"
        :src="show_pdf.pdf_url + '#view=FitH,top&toolbar=0'"
        type="application/pdf"
      />
      <img src="../assets/nodata.png" style="margin-top: 320px" alt="" />
    </div>
    <div
      v-show="active_item === 1"
      class="ap-content"
      style="overflow-y: auto; overflow-x: hidden; width: auto"
    >
      <comview :show_img="show_img" />
    </div>
    <div v-show="active_item === 2" class="ap-content">
      <!-- <img src="../assets/compviet4321.jpg" alt="" style="height: 100%;width: 100%;"> -->
      <policyfiles :show_policy="show_policy" />
    </div>

    <el-dialog
      title="信息编辑"
      v-if="dialog_visible"
      :visible.sync="dialog_visible"
      :close-on-click-modal="false"
      :modal-append-to-body="true"
      :append-to-body="true"
      width="418px"
      top="30vh"
      class="home-edit-dialog small-d"
    >
      <div style="position: relative; margin-bottom: 20px; margin-right: -42px">
        <span
          class="title-text"
          style="display: inline-block; margin-left: -10px; margin-right: 10px"
          >标题：</span
        >
        <el-input
          class="width-291"
          v-model="title_info.title"
          @change="endEmpty(title_info, 'title')"
          :class="{ errorbd: title_check&&!title_info.title }"
          placeholder="请输入，30字内"
          maxlength="30"
        ></el-input>
        <div class="error-txt" style="left: 48px" v-if="title_check&&!title_info.title">
          页面标题不得为空
        </div>
      </div>
      <div style="position: relative">
        <span class="title-text" style="margin-right: 10px; margin-left: -37px"
          >管理政策：</span
        >
        <el-radio-group v-model="title_info.show_policy">
          <el-radio :label="1" style="margin-right: 24px">显示</el-radio>
          <el-radio :label="2">不显示</el-radio>
        </el-radio-group>
      </div>
      <span slot="footer" class="app-dialog-footer">
        <span class="cancel-btn" @click="dialog_visible = false;title_check=false">取消</span>
        <span class="submit-btn" type="primary" @click="submitClic">确认</span>
      </span>
    </el-dialog>
    <el-dialog
      title="上传企业简介"
      :visible.sync="file_dialog_visible"
      :close-on-click-modal="false"
      :modal-append-to-body="true"
      :append-to-body="true"
      width="418px"
      top="30vh"
      class="home-edit-dialog small-d about-d upload-file"
    >
      <div style="position: relative">
        <div v-if="!pdf_info.pdf_name && !pdf_info.pdf_errname">
          <el-upload
            action=""
            list-type="text"
            :show-file-list="false"
            :before-upload="beforeUpload"
            :http-request="upLoad"
          >
            <div class="upload-text">
              <div class="upload-box">请上传20M以内的pdf<br />格式文件</div>
            </div>
          </el-upload>
        </div>
        <div
          v-else
          :class="{
            'upload-txtbd': true,
            uploaded: true,
            'upload-false': pdf_info.pdf_errname,
          }"
        >
          <div class="txt-content red">
            <div style="width: 275px; display: flex">
              <div
                style="max-width: 200px; min-width: 100px; margin-right: 8px"
                class="one-line"
              >
                <i class="el-icon-paperclip" style="margin-right: 8px"></i>
                <el-tooltip
                  v-if="pdf_info.pdf_name.length > 10"
                  class="item"
                  effect="dark"
                  :content="pdf_info.pdf_errname || pdf_info.pdf_name"
                  placement="top-start"
                >
                  <span class="one-line">{{
                    pdf_info.pdf_errname || pdf_info.pdf_name
                  }}</span>
                </el-tooltip>
                <span v-else>{{
                  pdf_info.pdf_name || pdf_info.pdf_errname
                }}</span>
              </div>
              {{ resetSize(pdf_info.pdf_size) }}M
            </div>
            <el-upload
              action=""
              list-type="text"
              :show-file-list="false"
              :before-upload="beforeUpload"
              :http-request="upLoad"
              ><span style="color: #ffffff">重新上传</span>
            </el-upload>
          </div>
        </div>
        <div
          v-show="comp_uploading"
          class="upload-txtbd"
          style="
            height: 66px;
            width: 375px;
            top: 0;
            text-align: left;
            position: absolute;
            display: flex;
            box-sizing: border-box;
            background: #2c313c;
          "
        >
          <div
            style="position: absolute; left: 9px; width: 250px"
            class="one-line"
          >
            <img src="../assets/cycle.png" class="turn" alt="" />
            {{ uppdf_name }}
          </div>
          <el-progress
            style="width: 259px; margin: auto; position: relative; top: 12px"
            v-if="percentage_line"
            :percentage="percentage"
            color="#1890FF"
            :stroke-width="2"
            :show-text="false"
          ></el-progress>
        </div>
      </div>

      <span slot="footer" class="app-dialog-footer">
        <span
          class="cancel-btn"
          @click="(pdf_info = {}), (file_dialog_visible = false)"
          >取消</span
        >
        <span class="submit-btn" type="primary" @click="submitFile">确认</span>
      </span>
    </el-dialog>
    <el-dialog
      title="信息编辑"
      :visible.sync="img_dialog_visible"
      :close-on-click-modal="false"
      :modal-append-to-body="true"
      :append-to-body="true"
      width="1000px"
      top="10vh"
      class="home-edit-dialog small-d about-d"
    >
      <div class="upload-img">
        <div
          class="upload-img-li"
          v-for="(item, index) in img_list"
          :key="index"
        >
          <!--  -->
          <div class="img-box">
            <img
              v-if="item.status == 'beforeupload'"
              style="width: 100%; height: 100%; object-fit: contain"
              :src="item.url"
              alt=""
            />
            <div v-if="item.status == 'uploadding'" class="uploadding">
              <img src="../assets/cycle.png" alt="" />
              <div>上传中</div>
            </div>
            <div v-if="item.status == 'afterupload'" class="afterupload">
              <div>上传中</div>
              <el-progress
                :show-text="false"
                :stroke-width="4"
                :percentage="show_time"
              >
              </el-progress>
            </div>

            <div class="edit-box">
              <el-upload
                action=""
                list-type="picture"
                :show-file-list="false"
                :before-upload="(ele) => beforeUpload1(ele, 's', index)"
                :http-request="(file) => reupLoad(file, index)"
                :on-change="imgSaveToUrl"
              >
                <i class="edit-icon"></i>
              </el-upload>
              <i class="del-icon" @click="delAction(index)"></i>
            </div>
          </div>
          <div style="position: relative">
            <el-input
              style="width: 193px"
              :class="{ errorbd: img_check_type && !item.title }"
              v-model="item.title"
              @change="endEmpty(item, 'title')"
              placeholder="请输入15字内标题"
              maxlength="15"
            ></el-input>
            <!-- <div class="error-txt" style="left: 72px;" v-if="!title_info.title">页面标题不得为空</div> -->
          </div>

          <el-input
            v-model="item.desc"
            @change="endEmpty(item, 'desc')"
            type="textarea"
            :rows="3"
            resize="none"
            maxlength="100"
            placeholder="请输入，100字内"
          ></el-input>
        </div>
        <div v-if="false">
          <img
            style="width: 100%; height: 100%; object-fit: contain"
            :src="active_url"
            alt=""
          />
          <el-progress
            style="width: 259px; margin: auto"
            :percentage="percentage"
            color="#1890FF"
            :stroke-width="2"
            :show-text="false"
          ></el-progress>
        </div>
        <div class="upload-img-li" style="position: relative">
          <el-upload
            action=""
            list-type="picture"
            :show-file-list="false"
            :before-upload="beforeUpload1"
            :http-request="upLoad1"
            :on-change="imgSaveToUrl"
            v-if="upload_status == 'beforeupload'"
          >
            <div class="upload-style">
              请上传10M以内的<br />png/jpg/jpeg<br />格式文件
            </div>
          </el-upload>
          <uploadStatus
            style="margin-left: -16px"
            v-if="upload_status != 'beforeupload'"
            :show_time="show_time"
            :list_type="'picture'"
            :upload_status="upload_status"
            @beforeupload="beforeUpload1"
            @httprequest="upLoad1"
            @onchange="imgSaveToUrl"
          />
        </div>
      </div>
      <span slot="footer" class="app-dialog-footer">
        <span class="cancel-btn" @click="cancelImg">取消</span>
        <span class="submit-btn" type="primary" @click="submitImg">确认</span>
      </span>
    </el-dialog>
    <el-dialog
      title="信息编辑"
      :visible.sync="policy_dialog_visible"
      :close-on-click-modal="false"
      :modal-append-to-body="true"
      :append-to-body="true"
      width="830px"
      top="10vh"
      class="home-edit-dialog small-d about-d"
    >
      <div style="margin: 0 auto; width: 710px">
        <div
          style="
            font-weight: 500;
            line-height: 24px;
            height: 24px;
            font-size: 14px;
          "
        >
          文本信息
        </div>
        <div class="cut-line" style="width: 710px; margin-bottom:20px"></div>
        <div
          class="set-list"
          style="width: 666px; display: flex; flex-wrap: wrap"
        >
          <span
            v-for="(item, index) in policy_list.policy_category || []"
            :class="returnClass(index)"
            :key="index"
            style="position: relative"
          >
            <el-input
              :id="'legend_' + index"
              maxlength="10"
              v-model="policy_list.policy_category[index]"
              @change="endEmpty(policy_list.policy_category, index)"
            >
              <i
                slot="suffix"
                class="el-icon-error"
                @click="deleItem(index)"
              ></i>
            </el-input>
            <div
              class="error-txt"
              style="left: 5px; top: 26px"
              v-if="!policy_list.policy_category[index]"
            >
              分类名称不得为空
            </div>
          </span>
          <span
            class="add-btn"
            @click="addPolicylist"
            v-if="policy_list.policy_category.length < 10"
            >+添加分类</span
          >
        </div>
        <div
          style="
            font-weight: 500;
            line-height: 24px;
            height: 24px;
            font-size: 16px;
            margin-top: 22px;
          "
        >
          文本信息
        </div>
        <div class="cut-line " style="width: 710px;margin-bottom:20px"></div>
        <el-input
          v-model.trim="search_key"
          placeholder="请输入内容"
          maxlength="30"
        ></el-input>
        <i
          class="el-icon-search"
          style="
            font-size: 14px;
            margin-left: 10px;
            margin-right: 10px;
            cursor: pointer;
          "
          @click="searchFile"
        ></i>
        <span>共找到{{ search_file }}个文件</span>
        <div
          style="
            display: flex;
            justify-content: space-between;
            margin-top: 13px;
          "
        >
          <div
            style="
              width: 321px;
              height: 365px;
              background: rgba(255, 255, 255, 0.12);
              padding-top: 16px;
            "
          >
            <div
              v-if="
                policy_list.policy_files && policy_list.policy_files.length > 0
              "
            >
              <div class="policy-file-box" id="policy-file-box">
                <div
                  v-for="(item, index) in policy_list.policy_files || []"
                  :key="index"
                  style="cursor: pointer"
                >
                  <div
                    @click="activePolicy(index)"
                    v-show="item.name.search(search_key) != -1"
                    :class="{
                      'active-po': active_policy_i == index,
                      lidiv: true,
                    }"
                  >
                    <i
                      class="doll-icon"
                      :style="{ background: doll_color[item.c_index] }"
                    ></i>
                    <span>{{ item.name }}</span>
                  </div>
                </div>
              </div>
              <div class="add-file-btn no-select" @click="addPolicyFile">
                添加文件
              </div>
            </div>
            <div v-else class="no-data-l" style="margin-top: 40px">
              <div class="no-data-l-img"></div>
              <div>还没有添加数据</div>
              <div
                class="add-file-btn no-select"
                @click="addPolicyFile"
                style="width: 80px"
              >
                + 添加
              </div>
            </div>
          </div>
          <div
            v-if="JSON.stringify(active_policy) != '{}'"
            style="
              width: 340px;
              height: 349px;
              background: rgba(255, 255, 255, 0.12);
              padding: 16px;
              position: relative;
            "
          >
            <i
              class="del-icon"
              @click="delDate"
              style="position: absolute; right: 21px; top: 21px"
            ></i>
            <div style="margin-top: 18px">文件名称</div>
            <div style="position: relative">
              <el-input
                class="width325"
                :class="{ errorbd: check_type && !active_policy.name }"
                maxlength="20"
                v-model="active_policy.name"
                placeholder="请输入，20字内"
              ></el-input>
              <div
                class="error-txt"
                style="left: 15px; bottom: 4px"
                v-if="check_type && !active_policy.name"
              >
                文件名称不得为空
              </div>
            </div>

            <div style="margin-bottom: 8px">文件分类</div>
            <el-select
              class="width326"
              v-model="active_policy.c_index"
              placeholder="请选择"
            >
              <el-option
                v-for="item in policy_category"
                :key="item.index"
                :label="item.name"
                :value="item.index"
              >
              </el-option>
            </el-select>
            <div style="margin-top: 26px; margin-bottom: 8px">文件上传</div>
            <el-upload
              action=""
              list-type="text"
              :show-file-list="false"
              :before-upload="(ele) => beforeUpload(ele, 'policy_pdf')"
              :http-request="upLoad2"
              v-show="!active_policy.pdf_name && !active_policy.err_name"
            >
              <div :class="{ 'upload-text': true }">
                <div class="upload-box" style="width: 325px">
                  <span class="blue-text">
                    请上传20M以内的pdf<br />格式文件</span
                  >
                </div>
              </div>
            </el-upload>
            <div
              v-show="active_policy.pdf_name || active_policy.err_name"
              :class="{
                'upload-txtbd': true,
                uploaded: active_policy.pdf_name,
                'upload-false': active_policy.upload_stat == 'upload_false',
              }"
              style="width: 307px"
            >
              <div class="txt-content" style="width: 294px">
                <div class="red" style="width: 222px; display: flex">
                  <div
                    style="
                      max-width: 165px;
                      min-width: 100px;
                      margin-right: 8px;
                    "
                    class="one-line"
                  >
                    <i class="el-icon-paperclip" style="margin-right: 8px"></i>
                    <el-tooltip
                      v-if="active_policy.pdf_name.length > 10"
                      class="item"
                      effect="dark"
                      :content="
                        active_policy.err_name || active_policy.pdf_name
                      "
                      placement="top-start"
                    >
                      <span class="one-line">{{
                        active_policy.err_name || active_policy.pdf_name
                      }}</span>
                    </el-tooltip>
                    <span v-else>{{
                      active_policy.err_name || active_policy.pdf_name
                    }}</span>
                  </div>
                  {{
                    resetSize(active_policy.err_size || active_policy.pdf_size)
                  }}M
                </div>
                <el-upload
                  action=""
                  list-type="text"
                  :show-file-list="false"
                  :before-upload="(ele) => beforeUpload(ele, 'policy_pdf')"
                  :http-request="upLoad2"
                  ><span>重新上传</span>
                </el-upload>
              </div>
            </div>
            <div
              v-if="active_policy_uploading"
              class="upload-txtbd"
              style="
                height: 66px;
                width: 325px;
                text-align: left;
                position: relative;
                display: flex;
                box-sizing: border-box;
                top: -66px;
                background: #464b54;
              "
            >
              <div
                style="position: absolute; left: 9px; width: 250px"
                class="one-line"
              >
                <img src="../assets/cycle.png" class="turn" alt="" />
                {{ uppdf_name }}
              </div>
              <el-progress
                style="
                  width: 259px;
                  margin: auto;
                  position: relative;
                  top: 12px;
                "
                v-if="percentage_line"
                :percentage="percentage"
                color="#1890FF"
                :stroke-width="2"
                :show-text="false"
              ></el-progress>
            </div>
          </div>
          <div
            v-else
            style="
              width: 340px;
              height: 349px;
              background: rgba(255, 255, 255, 0.12);
              padding: 16px;
              position: relative;
            "
          >
            <div class="no-data-r" style="margin-top: 66px">
              <div class="no-data-r-img"></div>
              <div>暂无数据</div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="app-dialog-footer">
        <span class="cancel-btn" @click="cancelSP">取消</span>
        <span class="submit-btn" type="primary" @click="submitPolicy"
          >确认</span
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import comview from "../components/comview.vue";
import policyfiles from "../components/policyfiles.vue";
import { Loading } from "element-ui";
import uploadStatus from "../components/uploadstatus.vue";
import { enterpriseinter, uploadoss, uploadfile } from "../api/getdata";
import md5 from "js-md5";
export default {
  components: { comview, policyfiles, uploadStatus },
  data() {
    return {
      active_item: 0,
      user_auths: {},
      edit_type: 0,
      percentage: 0,
      percentage_line: false,
      dialog_visible: false,
      img_dialog_visible: false,
      policy_dialog_visible: false,
      title_info: { show_policy: 1, title: "" },
      edit_title: { title: "", show_policy: 1 },
      file_dialog_visible: false,
      file_list: [],
      img_list: [],
      show_img: [],
      pdf_info: {},
      pdf_size: 0,
      show_pdf: {},
      upload_imginfo: {},
      active_policy: {},
      active_policy_i: null,
      active_url: "",
      show_policy: { policy_category: [], policy_files: [] },
      policy_list: { policy_category: [], policy_files: [] },
      search_key: "",
      check_type: false,
      title_check: false,
      img_check_type: false,
      cleartime: "",
      uppdf_name: "",
      show_time: 0,
      upload_stat: "beforeupload",
      upload_status: "beforeupload",
      tab_list: ["企业简介", "公司风采", "管理政策"],
      doll_color: [
        "#129BFF",
        "#3C8DAD",
        "#00C3C3",
        "#A3DA6E",
        "#3EDBF0",
        "#4120E6",
        "#77ACF1",
        "#F85F64",
        "#F5A962",
        "#FFCF5F",
        "#129BFF",
        "#C6C6C6",
      ],
      active_policy_uploading: false,
      comp_uploading: false,
    };
  },
  computed: {
    search_file() {
      let ative_l = [];
      let arr = this.policy_list.policy_files.filter((ele, index) => {
        if (ele.name.search(this.search_key) != -1) {
          ative_l.push(index);
          return true;
        } else {
          return false;
        }
      });
      if (ative_l[0]) {
        this.activePolicy(ative_l[0]);
      }
      return arr.length;
    },
    policy_category() {
      let plic = this.policy_list.policy_category.map((ele, index) => {
        return { index, name: ele };
      });
      return [{ index: -1, name: "未选择" }, ...plic];
    },
  },
  watch: {
    // search_file(){
    //     this.active_policy_i = 0
    //     this.active_policy = {}
    // }
  },
  methods: {
    changeActiveTab(index) {
      if (index === 1) {
        if (this.img_list.length === 0) {
          enterpriseinter("imagesdetail").then((ele) => {
            this.show_img = ele.data || [];
            this.img_list = this.show_img.map((item) => {
              return { ...item, status: "beforeupload" };
            });
          });
        }
      } else if (index === 2) {
        if (
          !this.show_policy.policy_files ||
          this.show_policy.policy_files.length === 0
        ) {
          this.getPolicyList();
        }
      }
      this.active_item = index;
    },
    resetSize(val) {
      if (val) {
        return (val / 1024 / 1024)?.toFixed(2)?.replace(".00", "") || 0;
      } else {
        return 0;
      }
    },
    changeEdit() {
      this.edit_type = 1;
      if (this.edit_title.show_policy == 2) {
        this.tab_list = ["企业简介", "公司风采"];
      } else {
        this.tab_list = ["企业简介", "公司风采", "管理政策"];
      }
    },
    exitEdit() {
      this.edit_type = 0;
      if (this.edit_title.show_policy == 2) {
        this.tab_list = ["企业简介", "公司风采"];
      } else {
        this.tab_list = ["企业简介", "公司风采", "管理政策"];
      }
    },
    goHome() {
      // if(this.edit_type){
      //     this.$router.push({path: '/', query: {edit: "1"}})
      // }else{
      //     this.$router.push({path: '/'})
      // }
      let query_id = this.$route.query.id;
      this.$router.push({ path: "/", query: { id: query_id } });
    },
    submitClic() {
      this.title_check=false
      if (!this.title_info.title) {
        this.$message.error({
          showClose: true,
          message: "失败：标题不得为空",
        });
        this.title_check=true
        return;
      }
      let arg = this.title_info;
      enterpriseinter("save", arg).then((ele) => {
        this.edit_title = { ...this.title_info };
        this.getTitle();
        this.dialog_visible = false;
        this.$message.success({
          showClose: true,
          message: "成功：操作成功",
        });
        this.active_item = 0;
      });
    },
    changeTitle() {
      this.title_info = JSON.parse(JSON.stringify(this.edit_title));
      this.dialog_visible = true;
    },
    delDate() {
      if (this.policy_list?.policy_files?.length == 0) {
        return;
      }
      this.policy_list?.policy_files?.splice(this.active_policy_i, 1);
      if (this.policy_list?.policy_files?.length == 0) {
        this.active_policy_i = null;
        this.active_policy = {};
      } else {
        this.$nextTick(() => {
          if (this.active_policy_i === 0) {
            this.activePolicy(0);
          } else {
            this.activePolicy(this.active_policy_i - 1);
          }
        });
      }
    },
    beforeUpload(ele, typename) {
      let file_type = ele.name.split(".").pop();
      this.uppdf_name = ele.name;
      let arr = ["pdf", "PDF"];
      if (arr.indexOf(file_type) == -1) {
        this.$message.error("文件格式只支持pdf类型");
        return false;
      }
      const isLt2M = ele.size / 1024 / 1024 < 20;
      if (!isLt2M) {
        this.$message.error("上传文件大小不能超过 20M");
        return false;
      }
      this.pdf_size = ele.size;

      let type = typename || "introduce_pdf";
      if (typename) {
        this.active_policy_uploading = true;
      } else {
        this.comp_uploading = true;
      }
      return new Promise((resolve, reject) => {
        //从后台获取第一步所需的数据
        //getOssToken 获取OSS秘钥的接口地址
        uploadfile({ type })
          .then((response) => {
            this.upload_imginfo = response.data;
            resolve(true);
          })
          .catch((error) => {
            this.$message.error("获取授权失败，请检查网络或联系系统管理员");
            if (typename) {
              this.active_policy_uploading = false;
              this.$set(this.active_policy, "upload_stat", "beforeupload");
            } else {
              this.comp_uploading = false;
            }
            reject(false);
          });
      });
    },
    // 企业简介
    upLoad(file, file_list) {
      const _self = this;
      let formdate = new FormData();
      let file_name = md5(new Date().getTime + file.file.name);
      let file_type = file.file.name.split(".").pop();
      let file_key = _self.upload_imginfo.dir + file_name + "." + file_type;
      formdate.append("key", file_key);
      formdate.append("OSSAccessKeyId", _self.upload_imginfo.access_key_id); //accessKeyId
      formdate.append("policy", _self.upload_imginfo.policy); //policy
      formdate.append("Signature", _self.upload_imginfo.signature); //签名
      formdate.append("success_action_status", 200);
      formdate.append("file", file.file);
      file.file.file_key = file_key;
      let file_name_r = file.file.name || "";
      if (file_name_r.length > 30) {
        file_name_r = file.file.name.slice(0, 27) + ".pdf";
      }
      uploadoss(_self.upload_imginfo.host, formdate)
        .then(() => {
          this.pdf_info.pdf_errname = null;
          this.pdf_info = {
            pdf_name: file_name_r,
            pdf_url: file_key,
            pdf_size: this.pdf_size,
          };
          this.comp_uploading = false;
        })
        .catch(() => {
          this.comp_uploading = false;
          this.pdf_info.pdf_errname = file_name_r;
          this.pdf_info.pdf_size = this.pdf_size;
          this.$message.error("文件上传失败");
        });
    },
    upLoad2(file, file_list) {
      const _self = this;
      let formdate = new FormData();
      let file_name = md5(new Date().getTime + file.file.name);
      let file_type = file.file.name.split(".").pop();
      let file_key = _self.upload_imginfo.dir + file_name + "." + file_type;
      formdate.append("key", file_key);
      formdate.append("OSSAccessKeyId", _self.upload_imginfo.access_key_id); //accessKeyId
      formdate.append("policy", _self.upload_imginfo.policy); //policy
      formdate.append("Signature", _self.upload_imginfo.signature); //签名
      formdate.append("success_action_status", 200);
      formdate.append("file", file.file);
      file.file.file_key = file_key;
      // this.upload_stat = 'uploading'
      // this.$set(this.active_policy,'upload_stat','uploading')
      let file_name_r = file.file.name;
      if (file_name_r.length > 30) {
        file_name_r = file.file.name.slice(0, 27) + ".pdf";
      }
      uploadoss(_self.upload_imginfo.host, formdate)
        .then(() => {
          this.percentage_line = true;
          this.cleartime = setInterval(() => {
            if (this.percentage >= 100) {
              this.percentage = 100;
              return;
            }
            this.percentage += 10;
          }, 100);
          setTimeout(() => {
            clearInterval(this.cleartime);
            this.$set(this.active_policy, "pdf_name", file_name_r);
            this.$set(this.active_policy, "err_name", null);
            this.$set(this.active_policy, "pdf_url", file_key);
            this.$set(this.active_policy, "pdf_size", this.pdf_size);
            this.$set(this.active_policy, "upload_stat", "uploaded");
            this.active_policy_uploading = false;
            this.percentage = 0;
            this.percentage_line = false;
          }, 1200);
        })
        .catch(() => {
          this.$set(this.active_policy, "err_name", file_name_r);
          // this.$set(this.active_policy,'pdf_url',file_key)
          this.$set(this.active_policy, "err_size", this.pdf_size);
          this.$set(this.active_policy, "upload_stat", "upload_false");
          this.percentage = 0;
          this.percentage_line = false;
          this.active_policy_uploading = false;
          clearInterval(this.cleartime);
          this.$message.error("文件上传失败");
        });
    },
    // 公司风采
    beforeUpload1(ele, t, index) {
      let file_type = ele.name.split(".").pop();
      let arr = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"];
      if (arr.indexOf(file_type) == -1) {
        this.$message.error("文件格式只支持png/jpg/jpeg类型");
        return false;
      }
      const isLt2M = ele.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error("上传文件大小不能超过 10M");
        return false;
      }
      let type = "introduce_image";
      if (!t) {
        this.upload_status = "uploadding";
      }
      return new Promise((resolve, reject) => {
        //从后台获取第一步所需的数据
        //getOssToken 获取OSS秘钥的接口地址

        uploadfile({ type })
          .then((response) => {
            if (index) {
              this.$set(this.img_list[index], "status", "uploadding");
            }

            // this.upload_status = "uploadding";
            this.upload_imginfo = response.data;
            resolve(true);
          })
          .catch(() => {
            this.$message.error("获取授权失败，请检查网络或联系系统管理员");
            this.upload_status = "beforeupload";
            reject(false);
          });
      });
    },
    upLoad1(file) {
      const _self = this;
      let formdate = new FormData();
      let file_name = md5(new Date().getTime + file.file.name);
      let file_type = file.file.name.split(".").pop();
      let file_key = _self.upload_imginfo.dir + file_name + "." + file_type;
      formdate.append("key", file_key);
      formdate.append("OSSAccessKeyId", _self.upload_imginfo.access_key_id); //accessKeyId
      formdate.append("policy", _self.upload_imginfo.policy); //policy
      formdate.append("Signature", _self.upload_imginfo.signature); //签名
      formdate.append("success_action_status", 200);
      formdate.append("file", file.file);
      file.file.file_key = file_key;
      // this.loading = Loading.service({background:"rgba(0, 0, 0, 0.4)"})
      uploadoss(_self.upload_imginfo.host, formdate)
        .then(() => {
          let t = setInterval(() => {
            if (this.show_time >= 100) {
              this.show_time == 100;
              return;
            }
            this.show_time += 10;
          }, 100);
          this.upload_status = "afterupload";
          setTimeout(() => {
            this.upload_status = "beforeupload";
            clearInterval(t);
            this.show_time = 0;
            file.url = this.active_url;
            file.status = "beforeupload";
            this.img_list.push(file);
          }, 1200);

          // this.loading.close();
        })
        .catch(() => {
          // this.loading.close();
          this.upload_status = "uploadError";
          this.$message.error("图片上传失败");
        });
    },
    reupLoad(file, index) {
      const _self = this;
      let formdate = new FormData();
      let file_name = md5(new Date().getTime + file.file.name);
      let file_type = file.file.name.split(".").pop();
      let file_key = _self.upload_imginfo.dir + file_name + "." + file_type;
      formdate.append("key", file_key);
      formdate.append("OSSAccessKeyId", _self.upload_imginfo.access_key_id); //accessKeyId
      formdate.append("policy", _self.upload_imginfo.policy); //policy
      formdate.append("Signature", _self.upload_imginfo.signature); //签名
      formdate.append("success_action_status", 200);
      formdate.append("file", file.file);
      file.file.file_key = file_key;
      // this.loading = Loading.service({background:"rgba(0, 0, 0, 0.4)"})
      uploadoss(_self.upload_imginfo.host, formdate)
        .then(() => {
          let t = setInterval(() => {
            if (this.show_time >= 100) {
              this.show_time == 100;
              return;
            }
            this.show_time += 10;
          }, 100);

          this.$set(this.img_list[index], "status", "afterupload");
          setTimeout(() => {
            this.$set(this.img_list[index], "status", "beforeupload");
            clearInterval(t);
            this.show_time = 0;
            file.url = this.active_url;
            let item = this.img_list[index];
            this.$set(this.img_list, index, { ...item, ...file });
          }, 1200);

          // this.loading.close();
        })
        .catch(() => {
          // this.loading.close();
          this.$message.error("图片上传失败");
        });
    },
    imgSaveToUrl(file) {
      let url = URL.createObjectURL(file.raw);
      this.active_url = url;
    },
    actionTab() {
      if (this.active_item == 0) {
        this.file_dialog_visible = true;
      } else if (this.active_item == 1) {
        this.img_dialog_visible = true;
      } else if (this.active_item == 2) {
        this.policy_dialog_visible = true;
        if (this.policy_list?.policy_files?.length > 0) {
          this.activePolicy(0);
        }
      }
    },
    submitFile() {
      if (!this.pdf_info.pdf_url) {
        this.$message.error({
          showClose: true,
          message: "失败：请选择文件再提交",
        });
        return;
      }
      let arg = this.pdf_info;
      enterpriseinter("intsave", arg).then((ele) => {
        this.$message.success({
          showClose: true,
          message: "成功：操作成功",
        });
        this.file_dialog_visible = false;
        // this.show_pdf = {...this.upload_imginfo}
        // location.reload()
        // this.loading = Loading.service({background:"rgba(0, 0, 0, 0.4)"})
        setTimeout(() => {
          enterpriseinter("intdetail").then((ele) => {
            this.show_pdf = ele.data || { pdf_name: "", pdf_url: "" };
            this.pdf_info = { ...this.show_pdf };
            // this.loading.close();
          });
        }, 500);
      });
    },
    submitImg() {
      for (let i in this.img_list) {
        this.img_check_type = true;
        if (!this.img_list[i].title) {
          this.$message.error({
            showClose: true,
            message: "失败：图片标题不得为空",
          });
          return;
        }
      }
      let arg = this.img_list.map((ele) => {
        return {
          title: ele.title || "--",
          desc: ele.desc || "暂无简介",
          url: ele.file?.file_key || ele.url,
        };
      });
      enterpriseinter("saveimages", { images: arg }).then((ele) => {
        this.$message.success({
          showClose: true,
          message: "成功：操作成功",
        });

        enterpriseinter("imagesdetail").then((ele) => {
          this.show_img = ele.data || [];
          this.img_list = [...this.show_img];
        });
        this.img_dialog_visible = false;
      });
    },
    cancelImg() {
      enterpriseinter("imagesdetail").then((ele) => {
        // this.show_img = ele.data || [];
        // this.img_list = [...this.show_img];
         this.show_img = ele.data || [];
            this.img_list = this.show_img.map((item) => {
              return { ...item, status: "beforeupload" };
            });
      });
      this.img_dialog_visible = false;
    },
    delAction(index) {
      this.img_list.splice(index, 1);
    },
    deleItem(index) {
      this.policy_list.policy_category.splice(index, 1);
      this.policy_list.policy_files.forEach((ele) => {
        if (ele.c_index === index) {
          ele.c_index = -1;
        } else if (ele.c_index > index) {
          ele.c_index -= 1;
        }
      });
    },
    addPolicylist() {
      if (!this.policy_list.policy_category) {
        this.$set(this.policy_list, "policy_category", []);
      }

      let index = this.policy_list.policy_category.length;
      if (index > 10) {
        return;
      }
      this.policy_list.policy_category.push("分类" + (index + 1));
      this.$nextTick(() => {
        document.querySelector("#legend_" + index).focus();
      });
    },
    submitPolicy() {
      for (let item of this.policy_list.policy_category || []) {
        if (!item) {
          this.$message.error({
            showClose: true,
            message: "失败：图例名称不得为空",
          });
          return;
        }
      }
      for (let index in this.policy_list.policy_files || []) {
        if (!this.policy_list.policy_files[index].name) {
          this.activePolicy(index);
          this.check_type = true;
          this.$message.error({
            showClose: true,
            message: "失败：文件名称不得为空",
          });
          return;
        }
      }

      let arg = this.policy_list;
      enterpriseinter("savepolicy", arg)
        .then((ele) => {
          this.$message.success({
            showClose: true,
            message: "成功：操作成功",
          });
          this.policy_dialog_visible = false;
          setTimeout(() => {
            this.getPolicyList();
          }, 1000);
          // this.$set(this,'show_policy',{...this.policy_list})
        })
        .catch(() => {
          this.$message.error({
            showClose: true,
            message: "失败：提交失败",
          });
        });
    },
    cancelSP() {
      this.getPolicyList();
      this.policy_dialog_visible = false;
      this.active_policy_i = null;
      setTimeout(() => {
        this.active_policy = {};
      }, 2000);
    },
    addPolicyFile() {
      this.policy_list?.policy_files?.push({
        name: "新文件",
        c_index: -1,
        pdf_name: "",
        pdf_url: "",
      });
      let index = this.policy_list?.policy_files?.length;
      this.$nextTick(() => {
        this.activePolicy(index - 1);
        let lidv = document.getElementById("policy-file-box");
        lidv.scrollTop = lidv.scrollHeight;
      });
    },
    returnClass(index) {
      return "item-color" + index;
    },
    activePolicy(index) {
      if (
        !this.policy_list.policy_files ||
        this.policy_list.policy_files.length === 0
      ) {
        this.active_policy_i = null;
        this.active_policy = {};
        return;
      }
      this.active_policy_i = index;
      this.active_policy = this.policy_list.policy_files[index];
      if (!this.active_policy.upload_stat) {
        this.active_policy.upload_stat = "beforeupload";
      }
    },
    getPolicyList() {
      enterpriseinter("policydetail").then((ele) => {
        this.show_policy = ele.data || {
          policy_category: [],
          policy_files: [],
        };
        if (!this.show_policy.policy_category) {
          this.show_policy.policy_category = [];
        }
        if (!this.show_policy.policy_files) {
          this.show_policy.policy_files = [];
        }
        this.policy_list = { ...this.show_policy };
      });
    },
    getTitle() {
      enterpriseinter("detail").then((ele) => {
        this.edit_title = ele.data || { title: "", show_policy: 1 };
        if (!this.edit_title.show_policy) {
          this.edit_title.show_policy = 1;
        }
        if (this.edit_title.show_policy == 2) {
          this.tab_list = ["企业简介", "公司风采"];
        } else {
          this.tab_list = ["企业简介", "公司风采", "管理政策"];
        }
      });
    },
    searchFile() {},
  },
  mounted() {
    this.getTitle();
    enterpriseinter("intdetail").then((ele) => {
      this.show_pdf = ele.data || { pdf_name: "", pdf_url: "" };
      this.pdf_info = { ...this.show_pdf };
    });

    let user_auths = localStorage.getItem("user_auths");
    this.user_auths = JSON.parse(user_auths || "{}");
  },
};
</script>

<style lang="less">
.about-pg {
  width: 1920px;
  height: 1080px;
  position: absolute;
  background-image: url("../assets/bgblock1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .editc-btn {
    height: 80px;
    width: 80px;
    position: absolute;
    right: 0;
    cursor: pointer;
    color: #fff;
    line-height: 80px;
    &:hover {
      .editc-btn-txt {
        display: block;
        opacity: 0.85;
      }
    }
  }
  .save-title {
    font-size: 46px;
    color: #fff;
    font-weight: 400;
    text-align: center;
    margin-top: 24px;
    line-height: 60px;
    height: 60px;
  }
  .n-top-tab {
    color: #fff;
    margin-left: 283px;
    span {
      display: inline-block;
      text-align: center;
      height: 34px;
      line-height: 34px;
      width: 99px;
      background: #129bff;
      border-radius: 3px;
    }
  }
  .go-back {
    margin: 41px;
    position: absolute;
    top: 0;
    div {
      height: 60px;
      width: 60px;
      line-height: 66px;
      text-align: center;
      background-image: url("../assets/bchb.png");
      background-size: contain;
      background-repeat: no-repeat;
      cursor: pointer;
      transform: scale(0.7);
      transform-origin: 0 0;
      &:hover {
        .go-back-icon {
          background-image: url("../assets/bkicon2.png");
        }
      }
      &:active {
        .go-back-icon {
          background-image: url("../assets/bkicon3.png");
        }
      }
    }

    .go-back-icon {
      display: inline-block;
      height: 22px;
      width: 24px;
      background-image: url("../assets/bkicon1.png");
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
  .top-tab {
    font-size: 24px;
    color: #fff;
    width: 668px;
    height: 58px;
    margin: 32px auto;
    margin-bottom: 53px;
    display: flex;
    justify-content: space-between;
    // border-bottom: 4px solid rgba(0,0,0,0.4);
    span {
      flex: 1;
      cursor: pointer;
      text-align: center;
      height: 58px;
      display: block;
      line-height: 58px;
      border-bottom: 4px solid rgba(255, 255, 255, 0.3);
      &.active,
      &:hover {
        opacity: 1;
        border-bottom: 6px solid #129bff;
        &::after {
          width: 206px;
          height: 6px;
          opacity: 1;
          border: 6px solid #129bff;
          box-shadow: 0px 0px 34px 0px #129bff;
        }
      }
      &:hover {
        opacity: 0.85;
      }
      &:active {
        opacity: 0.9;
      }
    }
  }
  .ap-content {
    height: 901px;
    width: 1350px;
    margin: 0 auto;
    &::-webkit-scrollbar {
      width: 0 !important;
    }
  }
  .ap-content1 {
    border: 12px solid rgba(255, 255, 255, 0.05);
    text-align: center;
    box-sizing: border-box;
    border-radius: 10px;
    box-shadow: 0px 0px 14px 0px rgba(255, 255, 255, 0.45);
    overflow: hidden;
  }
  .input-x {
    display: inline-block;
    position: relative;
    z-index: 0;
    margin-right: 12px;
    font-size: 16px;
    color: #fff;
  }
  .active.input-x::before {
    background: #129bff;
  }
  .input-x::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    background: #002e66;
    border-radius: 4px;
    transform: skewX(-10deg);
    z-index: -1;
    &.active {
      background-color: #fff;
    }
  }
  .input {
    display: inline-block;
    height: 34px;
    line-height: 34px;

    width: 99px;
    border: 0;
    text-align: center;
    background: none;
    cursor: pointer;
  }
}
.home-edit-dialog.small-d.upload-file .el-dialog {
  .el-dialog__header {
    text-align: left;
    padding: 15px 24px;
  }
  .el-dialog__footer {
    text-align: right;
  }
}
.upload-txtbd {
  border: 1px dashed rgba(255, 255, 255, 0.65);
  padding: 21px 8px;
  &.uploaded {
    border: 1px solid rgba(255, 255, 255, 0.65);
  }
  &.errorload {
    border: 1px solid #f85f64;
  }
  // &:hover {
  //     border: 1px solid #129BFF;
  // }
  .txt-content {
    display: flex;

    width: 343px;
    overflow: hidden;
    height: 22px;
    line-height: 22px;
    justify-content: space-between;
    padding-left: 8px;
    padding-right: 6px;
    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }
  }
}
.home-edit-dialog.small-d.about-d {
  .el-dialog__body {
    padding: 22px;
    color: #fff;
  }
  .el-dialog__footer {
    text-align: center;
  }
  .el-radio {
    color: #fff;
  }
  .error-txt {
    position: absolute;
    color: red;
  }
  .errorbd {
    .el-input__inner,
    .el-textarea__inner {
      border: 1px solid #f85f64;
      font-family: Arial;
    }
  }
}
.upload-false.upload-txtbd {
  border: 1px solid #f85f64 !important;
  .red {
    color: #f85f64;
  }
}
.upload-box {
  width: 370px;
  border: 1px dashed rgba(255, 255, 255, 0.65);
  height: 65px;
  line-height: 20px;
  padding: 11px 0;
  box-sizing: border-box;
  &.uploaded {
    border: 1px solid rgba(255, 255, 255, 0.65);
  }
  &.errorload {
    border: 1px solid #f85f64;
  }
  // &:hover{
  //     .blue-text{
  //         color: #129BFF;
  //     }
  //    border: 1px solid #129BFF;

  // }
  // &:active{
  //     box-shadow: 0px 0px 48px 0px rgba(25,39,89,0.06), 0px 0px 4px 3px rgba(18,155,255,0.45) inset;
  // }
}
.upload-img {
  height: 585px;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  .upload-img-li:not(:nth-child(4n)) {
    margin-right: 16px;
  }
  .upload-img-li {
    display: inline-block;
    width: 225px;
    height: 274px;
    margin-bottom: 16px;
    background: rgba(255, 255, 255, 0.12);
    border-radius: 6px;
    padding: 16px;
    box-sizing: border-box;
    vertical-align: top;
    .img-box {
      width: 193px;
      height: 109px;
      opacity: 1;
      border-radius: 10px;
      background: rgba(0, 0, 0, 0.15);
      position: relative;
      .uploadding {
        width: 193px;
        height: 109px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
          width: 12px;
          height: 12px;
          animation: turn 1s linear infinite;
        }
        @keyframes turn {
          0% {
            -webkit-transform: rotate(0deg);
          }
          100% {
            -webkit-transform: rotate(360deg);
          }
        }
      }
      .afterupload {
        width: 193px;
        height: 109px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .el-progress {
          margin-top: 18px;
          width: 110px;
        }
      }
      &:hover {
        .edit-box {
          display: flex;
          justify-content: center;
          align-items: center;

          div {
            margin: 0;
            width: 24px;
            height: 24px;
          }
          i {
            margin: 0;
          }
        }
      }
      .edit-box {
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.45);
        width: 100%;
        display: none;
      }
      i {
        height: 24px;
        width: 24px;
        margin-top: 55px;
        display: inline-block;
        cursor: pointer;
        &:hover {
          opacity: 0.85;
        }
      }
      i.del-icon {
        background-image: url("../assets/delicon.png");
      }
      i.edit-icon {
        margin-right: 8px;
        background-image: url("../assets/editicon1.png");
      }
    }
    .el-textarea__inner {
      width: 193px;
      height: 85px;
      opacity: 1;
      font-family: Arial;
      background: rgba(0, 0, 0, 0.65);
      border-radius: 4px;
      padding: 6px 15px;
      margin-top: 10px;
      box-sizing: border-box;
      color: #fff;
      border: 1px solid transparent;
    }
  }
}
.home-edit-dialog .el-dialog .upload-img-li .el-input__inner {
  width: 193px;
  border: 1px solid rgba(0, 0, 0, 0.65);
  margin-top: 8px;
}
.upload-style {
  height: 242px;
  width: 193px;
  border: 1px dashed #fff;
  padding-top: 85px;
  box-sizing: border-box;
  // &:hover{
  // border: 1px solid #129BFF;
  // // color: #129BFF;
  // }
  // &:active{
  //     box-shadow: 0px 0px 48px 0px rgba(25,39,89,0.06), 0px 0px 4px 3px rgba(18,155,255,0.45) inset;
  // }
}

.policy-file-box {
  max-height: 300px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0 !important;
  }
  .lidiv.active-po {
    background: rgba(18, 155, 255, 0.5);
    border: 1px solid #129bff;
    box-shadow: 1px 1px 4px 0px rgba(255, 255, 255, 0.45) inset;
  }
  .lidiv {
    width: 321px;
    height: 64px;
    background: rgba(255, 255, 255, 0.1);
    padding: 10px 10px 10px 22px;
    border-bottom: 1px solid #2c323c;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    &:hover {
      background: rgba(18, 155, 255, 0.5);
      border: 1px solid #129bff;
      box-shadow: 1px 1px 4px 0px rgba(255, 255, 255, 0.45) inset;
    }
    &:active {
      opacity: 0.9;
    }
  }
  .doll-icon {
    display: inline-block;
    height: 10px;
    width: 10px;
    background-color: #fff;
    border-radius: 50%;
    position: relative;
    left: -12px;
  }
}
.add-file-btn {
  width: 289px;
  height: 30px;
  border: 1px dashed #fff;
  line-height: 30px;
  text-align: center;
  margin: 20px auto;
  cursor: pointer;
  color: #fff;
  background: linear-gradient(180deg, rgba(66, 71, 80, 0) 0%, #424750 100%);
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.9;
  }
}
i.del-icon {
  height: 24px;
  width: 24px;
  display: inline-block;
  cursor: pointer;
  &:hover {
    opacity: 0.85;
  }
  background-image: url("../assets/delicon.png");
}

.cancel-btn {
  border-radius: 2px;
  margin-right: 8px;
  background: rgba(255,255,255,0.85);
  color: rgba(0,0,0,0.85);
  &:hover {
    background-color: #FFF;
    opacity: 1;
  }
  &:active{
    background-color: rgba(255,255,255,0.65)
  }
}
.one-line {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.home-edit-dialog.small-d .el-dialog {
  .el-dialog__title {
    color: #fff;
    font-size: 16px;
  }
}
.el-radio {
  color: #fff;
}
.turn {
  width: 12px;
  height: 12px;
  animation: turn 1s linear infinite;
}
@keyframes turn {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

// @colorarr:rgba(18,155,255,0.4),rgba(60,141,173,0.4),rgba(0,195,195,0.4),rgba(163,218,110,0.4),rgba(62,219,240,0.45),rgba(65,32,230,0.4),rgba(119,172,241,0.4),rgba(248,95,100,0.4),rgba(245,169,98,0.4),rgba(255,207,95,0.4),rgba(198,198,198,0.4);
// @colorBArr: #129bff, #3c8dad, #00c3c3, #a3da6e, #3edbf0, #4120e6, #77acf1,#f85f64, #f5a962, #ffcf5f, #c6c6c6;

// @len: length(@colorArr);
// .loopDomP(@index) when(@index<@len) {
//   .item-color@{index} .el-input__inner {
//     background: extract(@colorArr, @index) !important;
//     border: 1px solid extract(@colorBArr, @index) !important;
//   }
//   //递归调用 达到循环目的
//   .loopDomP(@index+1);
// }
// .loopDomP(0);
</style>