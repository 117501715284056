<template>
  <div class="edit-homer2">
      <div>
        <div style="font-weight: 500;line-height: 24px;height:24px;font-size:14px">文本信息</div>
        <div class="cut-line" style="width:880px"></div>
        <div class="flex-bte" style="margin-bottom:24px;position: relative;" v-show="show_tabn">
            <div class="flex-bte"><span style="line-height:32px">选项卡名称：</span>
                <div>
                    <el-input v-model="dialogtab_name" :class="{errorbd:!dialogtab_name}" maxlength="10" placeholder="请输入，10字内"></el-input>
                    <div class="error-txt" style="left:80px" v-if="!dialogtab_name">选项卡名称不得为空</div>
                </div>
            </div>
        </div>
        <div class="img-set" style="display:flex;">
            <span style="width:200px">数据分类设置:（1～20项）</span>
            <div class="set-list" style="width:588px">
                <span v-for="(item,index) in legend" :key="index" style="position:relative;">
                    <el-input :id="'legend_'+index" maxlength="10" style="margin-bottom:8px" placeholder="请输入，10字内" :class="{errorbd:check_type&&!legend[index]}" @change="endEmpty(legend,index)" v-model="legend[index]"><i slot="suffix" v-show="legend.length>1" class="el-icon-error" @click="deleItem(index)"></i></el-input>
                    <div class="error-txt" style="left:5px;top:24px" v-if="check_type&&!legend[index]">分类设置名称不得为空</div>
                </span>
                <span @click="addLegend" v-if="legend.length<20" class="add-btn" style="display: inline-block;background: rgba(255,255,255,0.40);border: 1px solid #d9d9d9;padding-right:11px">+添加</span>
            </div>
        </div>
        <div class="img-set" style="display:flex;margin-top:24px">
            <span style="width:200px">平行坐标设置:（1～12项）<br/><span style="font-size: 12px;color: rgba(255,255,255,0.65);margin-left:40px">坐标范围不设则为自动</span></span>
            <div class="set-list" style="width:588px">
                <div v-for="(item,index) in legendy" :key="index" style="position:relative;display:flex;margin-bottom:8px">
                    <el-input :id="'legendy_'+index"  :maxlength="10" :class="{errorbd:check_type&&!item.name}" placeholder="名称：10字内" v-model="item.name" @change="endEmpty(item,'name')"></el-input>
                    <el-input :maxlength="5" placeholder="单位：5字内" v-model="item.unit" @change="endEmpty(item,'unit')"></el-input>
                    <div class="two-input-box" style="width:180px">
                        <el-input v-model="item.min" placeholder="起始值" maxlength="9" @input="formatVal1(item,'min')" class="input-with-select"></el-input>
                        <i class="el-icon-right"></i>
                        <el-input v-model="item.max" placeholder="结束值" @input="formatVal1(item,'max')" maxlength="9" class="input-with-select"></el-input>
                    </div>
                    <i class="el-icon-close" v-if="legendy.length>1"  @click="delLegendy(index)"></i>
                    <div class="error-txt" style="left:5px;top:27px" v-if="check_type&&!item.name">平行坐标名称不得为空</div>
                </div>
                <span class="add-btn" v-if="legendy.length<12" @click="addLegendy" style="display: inline-block;background: rgba(255,255,255,0.40);border: 1px solid #d9d9d9;padding-right:11px">+添加</span>
            </div>
        </div>

        <div style="font-weight: 500;line-height: 24px;height:24px;font-size:14px;margin-top:24px">图表数据</div>
        <div class="cut-line" style="width:880px"></div>
        <el-select v-model="search_value" clearable placeholder="请选择">
            <el-option
            v-for="(item,index) in legend"
            :key="index"
            :label="item"
            :value="index">
            </el-option>
        </el-select>
        <div class="submit-btn dlh-primary-btn" v-if="add_list.length>0" style="float:right" @click="clearAll">清空数据</div>
        <div class="disable-btn dlh-primary-btn" v-else style="float:right">清空数据</div>
        <div class="flex-bte" style="margin-top:12px">
            <div class="con-l" style="height:392px;padding-bottom:0;padding-top:0">
                <div v-if="add_list.length>0" style="position:relative">
                    <div :class="{'box-shadow':box_shadow}" style="top:0"></div>
                    <div class="list-box" id="lidvs" style="max-height:325px" @scroll="orderScroll">
                        <span v-for="(item,index) in add_list" :key="index" >
                            <div v-show="item.c_index === search_value || !search_value" :class="{active:active_index===index,itemlist:true,lidiv:true}" @click="changeActive(index)">{{legend[item.c_index]}}</div>
                        </span>
                    </div>
                    <div class="add-btn no-select" @click="addDateList">+ 添加</div>
                </div>
                <div v-else class="no-data-l" style="margin-top: 61px;">
                    <div class="no-data-l-img"></div>
                    <div>还没有添加数据</div>
                    <div class="add-btn no-select" @click="addDateList" style="width:80px">+ 添加</div>
                </div>
            </div>
            <div class="con-r" v-if="JSON.stringify(now_data)!='{}'" style="height:392px;flex-wrap: wrap;width:620px;padding: 16px 0px 16px 12px;position:relative">
                <i class="del-icon" v-show="active_index===0||active_index" @click="delDate" style="position:absolute;right:21px;top:14px"></i>
                数据分类
                <div style="margin-top:6px;margin-bottom:20px">
                    <el-select v-model="now_data.c_index" @change="changeCindex" placeholder="请选择">
                        <el-option
                        v-for="(item,index) in legend"
                        :key="index"
                        :label="item"
                        :value="index">
                        </el-option>
                    </el-select>
                </div>
                <div style="flex-wrap: wrap;display:flex" class="third-right-n">
                    <div v-for="(item,index) in legendy" :key="index">
                        <div style="height:20px">{{item.name}}</div>
                        <el-input class="width150" maxlength="9" v-model="now_data.values[index]"  @change="formatVal(now_data.values,index)" @input="formatVal(now_data.values,index)" :disabled="!active_index&&active_index!=0" placeholder=""></el-input>
                    </div>
                </div>
            </div>
            <div class="con-r" v-else  style="height:392px;flex-wrap: wrap;width:620px;padding: 16px 0px 16px 12px;position:relative">
                <div class="no-data-r" style="margin-top: 69px;">
                    <div class="no-data-r-img"></div>
                    <div>暂无数据</div>
                </div>
            </div>
        </div>

    </div>
    <div></div>
  </div>
</template>

<script>
import {parallelmapinter} from "../api/getdata"
export default {
    props:{
        map_info:{tab_name:'',value_unit:'',category:[],parallel:[],values:[]},
        r1edit_index:null,
        show_tabn:true
    },
    watch:{
        r1edit_index(){
                this.dialog_info=this.map_info||{}
                this.dialogtab_name=this.dialog_info.tab_name||'',
                this.legend=this.dialog_info.category||[]
                this.legendy=this.dialog_info.parallel||[]
                this.add_list=this.map_info?.values || []
                this.changeActive(0)
        }
    },
    data(){
        return{
            edit_type:false,
            edit_dialog_visible:false,
            dialog_info:this.map_info||{},
            dialogtab_name:this.map_info?.tab_name||'',
            legend:this.map_info?.category||[],
            legendy:this.map_info?.parallel||[],
            active_index:'',
            search_value:'',
            // {c_index:[],values:[]}
            add_list:this.map_info?.values || [],
            now_data:{},
            check_type:false
        }
    },
    methods:{
        getName(index){
            return this.legend[index]
        },
        addLegend(){
            let index_l = this.legend.length
            if(index_l>20){
                return
            }
            // this.legend.push('')
            if(!this.legend){
                this.legend = []
            }
            let arr = [...this.legend,'']
            this.$set(this,'legend',arr)
            this.$nextTick(()=>{
                document.querySelector('#legend_'+index_l).focus()
            })
        },
        addLegendy(){
            let index = this.legendy.length
            if(index>12){
                return
            }
            this.legendy.push({name:'',unit:'',min:null,max:null})
            
            this.$nextTick(()=>{
                document.querySelector('#legendy_'+index).focus()
            })
            this.add_list.forEach(ele=>{
                ele.values.push('')
            })
            
        },
        deleItem(index){
            this.legend.splice(index,1)
            this.add_list = this.add_list.map(ele=>{
                if(ele.c_index==index){
                    ele.c_index=0
                }else if(ele.c_index>index){
                    ele.c_index-=1
                    
                }
                return ele
            })
            // console.log(this.add_list);
        },
        delLegendy(index){
            this.legendy.splice(index,1)
            this.add_list = this.add_list.map(ele=>{
                if(ele.values){
                    ele.values.splice(index,1)
                }
                return ele
            })
        },
        changeActive(index){
            if(this.add_list.length==0){
                this.now_data={}
                return
            }
            this.active_index = index;
            this.now_data = this.add_list[index] ||{c_index:'',values:[]}
        },
        formatVal(now_data,index){
            now_data[index] = now_data[index].replace(/[^-\d.]/g, '').replace(".", "$#$").replace(/\./g, "").replace("$#$", ".") ||'' 
            if(['.','-','0'].includes(now_data[item].slice(-1))){
                return
            }
            if(!now_data[index]||isNaN(now_data[index])){
                now_data[index] = null
                return
            }
            if(now_data[index] == null){
                return
            }
            now_data[index] = parseFloat(now_data[index])
        },
        formatN(now_data,index){
            if(now_data[index]===null){
                return
            }else{
                now_data[index] = Number(now_data[index])
            }
        },
        formatVal1(data,item){
            data[item] = data[item].replace(/[^-\d.]/g, '').replace(".", "$#$").replace(/\./g, "").replace("$#$", ".") ||''
            if(['.','-','0'].includes(data[item].slice(-1))){
                return
            }
            if(!data[item]||isNaN(data[item])){
                data[item] = null
                return
            }
            data[item] = parseFloat(data[item])
        },
        saveEdit(){
            if(this.show_tabn&&!this.dialogtab_name){
                this.$message.error({
                    showClose: true,
                    message: "失败：选项卡名称不得为空"
                });
                this.check_type = true
                return
            }else{
                this.dialogtab_name= this.dialogtab_name.trim()
            }
            if(this.legend.length<1){
                this.$message.error({
                    showClose: true,
                    message: "失败：*数据分类*至少需添加一项"
                });
                this.check_type = true
                return
            }
            for(let i of this.legend){
                if(!i){
                    this.$message.error({
                        showClose: true,
                        message: "失败：数据分类名称不得为空"
                    });
                    this.check_type = true
                    return
                }
            }
            if(this.legendy.length<1){
                this.$message.error({
                    showClose: true,
                    message: "失败：*平行坐标设置*至少需添加一项"
                });
                this.check_type = true
                return
            }
            for(let i of this.legendy){
                if(!i.name){
                    this.$message.error({
                        showClose: true,
                        message: "失败：平行坐标名称不得为空"
                    });
                    this.check_type = true
                    return
                }
            }
            

            let arg = {
                index:this.r1edit_index,
                data:{
                    tab_name:this.dialogtab_name,
                    category:this.legend,
                    parallel:this.legendy||[],
                    values:this.add_list || []
                }
            }
            arg.data.parallel.forEach(ele=>{
               if(ele.min===""||ele.min==null){
                   ele.min = null
               }else{
                   ele.min= Number(ele.min)
               }

               if(ele.max===""||ele.max==null){
                   ele.max = null
               }else{
                   ele.max= Number(ele.max)
               }
            })
            arg.data.values.forEach(ele=>{
                if(ele.values.length<arg.data.parallel.length){
                    ele.values.length = arg.data.parallel.length
                }
                ele.values = ele.values.map(ele1=>{
                    if(ele1===""||ele1==null||isNaN(ele1)){
                        ele1 = null
                    }else{
                        ele1= Number(ele1)
                    }
                    return ele1
                })
            })
            parallelmapinter('save',arg).then(ele=>{
                this.$message.success({
                    showClose: true,
                    message: "成功：操作成功"
                });
                setTimeout(() => {
                    this.$emit('updateDate')
                }, 800);
                this.$emit('update:visible',false)
            })
        },
        addDateList(){
            this.add_list.push({c_index:0,values:[]})
            this.active_index = this.add_list.length-1;
            this.now_data = this.add_list[this.active_index]
            this.$nextTick(()=>{
                let lidv = document.getElementById('lidvs')
                lidv.scrollTop = lidv.scrollHeight;
            })
        },
        delDate(){
            if(this.add_list.length==0 ){
                return
            }
            this.add_list.splice(this.active_index,1)
            if(this.active_index===0){
                this.changeActive(0)
            }else{
                this.changeActive(this.active_index-1)
            }
        },
        clearAll(){
            this.add_list = []
            this.now_data={}
        },
        changeCindex(val){
            
        },
        changeSCindex(val){

        }
    },
    mounted(){
        // this.getDate()
        this.changeActive(0)
    }
}
</script>

<style lang="less">
.edit-homer2{
    .con-l div.itemlist{
        cursor: pointer;
        &.active,&:hover{
            background: rgba(18,155,255,0.40);
            border: 1px solid #129bff;
        }
    }
    .con-l .list-box{
        height: auto;
        max-height: 309px;
        overflow-y: auto;
        overflow-x: hidden;
        &::-webkit-scrollbar {
        width: 0 !important;
      }
    }
    .third-right-n{
        >div:not(:nth-child(3n)){
            margin-right:60px ;
        }
    }
    .el-input-group__append, .el-input-group__prepend{
        padding: 0;
        border: none;
        background-color:transparent;
    }
    .set-list .el-input-group--prepend .el-input-group__prepend .el-input .el-input__inner{
        margin: 0;
    }
    .set-list .el-icon-close{
        height: 28px;
        width: 28px;
        line-height: 28px;
        text-align: center;
        margin-left:4px;
        background: rgba(255,255,255,0.10);
        cursor: pointer;
        &:hover{
            background: rgba(0,0,0,0.10);
        }
        &:active{
            background: rgba(0,0,0,0.20);
        }
    }
    .set-list .two-input-box{
        width: 166px;
        height: 26px;
        line-height: 26px;
        display: flex;
        justify-content: space-between;
        align-items:center;
        background-color: rgba(18,155,255,0.4);
        border: 1px solid #129bff;
        .el-input input.el-input__inner{
            width: 75px;
            border: none;
            background-color: transparent;
            margin: 0;
        }
        
    }
    i.del-icon{
        height: 24px;
        width: 24px;
        display: inline-block;
        cursor: pointer;
        &:hover{
            opacity: 0.85;
        }
        background-image: url('../assets/delicon.png');
    }
}

</style>