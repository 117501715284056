<template>
  <div id="hm2" ref="hm2" style="height: 257px; width: 660px"></div>
</template>

<script>
export default {
  props: { map_data: { legend: [] } },
  watch: {
    map_data() {
      let data = this.map_data?.data || [];
      let legend = this.map_data.legend || [];
      this.value_unit = this.map_data?.value_unit || "";
      let format_data = [];
      data.forEach((ele) => {
        ele.values.forEach((eles, index) => {
          if (eles == null) {
            eles = 0;
          }
          format_data.push([ele.date, eles, legend[index]]);
        });
      });
      this.data = format_data;
      this.initChart();
    },
  },
  data() {
    return {
      hmInstance: "",
      data: [],
      value_unit: "",
    };
  },
  methods: {
    initChart() {
      this.hmInstance = this.$echarts.init(this.$refs.hm2);
      let option = {
        tooltip: {
          trigger: "axis",
          borderColor: "transparent",
          borderWidth: 0,
          backgroundColor: "rgba(5,125,133,0.68)",
          axisPointer: {
            type: "line",
          },
          padding: 5,
          formatter: (params, data) => {
            let tipHtml =
              '<div style="backdrop-filter: blur(5px);font-size:12px;min-width:99px">' +
              params[0].data[0] +
              "</div>";
            params.forEach((ele) => {
              tipHtml +=
                '<div style="backdrop-filter: blur(5px);font-size:12px;display:flex;justify-content:space-between;padding:0 5px"><span>' +
                ele.data[2] +
                ' </span><span style="font-size:12px;margin-left:5px;color:#FFCF5F"> ' +
                (ele.data[1] || 0) +
                this.value_unit +
                "</span></div>";
            });

            // +'<div style="color:#fff;font-size:12px;padding:0 5px;margin:0">'+this.value_name+params+'</div>'
            // +'<div style="display:flex;justify-content:space-between;padding:0 5px"><span>'+ +'</span><span style="color:#FFCF5F">'+this.value_unit+'</span></div>'
            // +'</div>';
            return tipHtml;
          },
        },
        textStyle: {
          color: "#FFF",
        },
        color: [
          "#4120E6",
          "#75CC83",
          "#FFCF5F",
          "#F85F64",
          "#129BFF",
          "#31FBFB",
        ],
        legend: {
          // type: 'scroll',
          icon: "circle",
          top: 0,
          textStyle: {
            color: "#FFF",
          },
          data: this.map_data.legend || [],
        },
        grid: {
          top: 0,
          bottom: 0,
          containLable: true,
          showMaxLabel: true,
        },
        singleAxis: {
          top: 42,
          bottom: 50,
          left: 25,
          right: 25,
          type: "time",
          splitLine: {
            show: false,
            lineStyle: {
              type: "dashed",
              opacity: 0.2,
            },
          },
          axisTick: {
            inside: true,
          },
          axisLabel: {
            containLabel: true,
            showMaxLabel: true,
            formatter: function (value, index) {
              let date = new Date(value);
              let year = date.getFullYear();
              let month = date.getMonth() + 1;
              if (month < 10) {
                month = "0" + month;
              }
              return year + "-" + month;
            },
          },
        },
        series: [
          {
            type: "themeRiver",
            label: {
              show: false,
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 25,
                shadowColor: "rgba(0, 0, 0, 0.8)",
              },
            },

            data: this.data,
            // data: [
            //   ["2015/11/08", 10, "7"],
            //   ["2015/12/18", 0, "7"],
            //   ["2016/1/08", 0, "7"],
            //   ["2016/2/08", 30, "7"],
            //   ["2016/3/08", 0, "7"],
            //   ["2016/4/08", 0, "7"],
            //   ["2016/5/08", 19, "7"],
            //   ["2016/6/08", 17, "7"],
            //   ["2016/7/08", 6, "7"],
            //   ["2016/8/08", 4, "7"],
            //   ["2016/9/08", 5, "7"],
            //   ["2016/10/08", 17, "7"],
            
              
            // ],
          },
        ],
      };
      this.hmInstance.setOption(option);
    },
  },
  mounted() {},
};
</script>

<style>
</style>