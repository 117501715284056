<template>
  <div class="r1-bd">
          <div class="p-title">
            {{ data3.title }}<span v-if="data3.value_unit">（{{ data3.value_unit }}）</span>
            <img
              @click="goedit"
              v-if="showedit"
              src="../assets/editicon.png"
              alt=""
            />
          </div>
           <nodata  v-if="data3.data.length==0"></nodata>
           <div v-else>

         
          <div class="explain">
            <div class="explain1">参数</div>
            <div class="explain1">当前值</div>
            <div class="explain1">阈值</div>
          </div>
          <div class="r1-bd_list">
            <div
              class="r1-bd_list_item"
              v-for="(item, index) in data3.data"
              :key="index"
            >
              <div class="item1">{{ item.name }}</div>
              <div class="item2">{{ item.value }}</div>
              <div class="item3">{{ item.min }}~{{ item.max }}</div>
            </div>
          </div>
            </div>
        </div>
</template>
<script>
import nodata from "./detail-nodata.vue"
export default {
  props: {
    data3: {
      type: Object,
      default:{}
    },
     showedit: {
      type: Boolean,
      default:false
    },
  },
   methods: {
    goedit() {
      this.$emit("fatheredit");
    }
  },
   components:{
    nodata
  }
};
</script>
<style lang="less">
  .r1-bd {
        height: 400px;
        width: 289px;
     
        margin: 0 auto;
        &::-webkit-scrollbar {
          width: 0 !important;
        }
        margin-bottom: 88px;

        .explain {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 289px;
          height: 37px;
          box-sizing: border-box;
          padding: 0 8px;
          opacity: 1;
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #ffffff;
          border-radius: 4px;
          margin-top: 14px;
        }
        .r1-bd_list::-webkit-scrollbar {
          display: none;
        }
        .r1-bd_list {
          height: 321px;
          overflow-y: scroll;
          > div {
            margin-top: 4px;
          }
          .r1-bd_list_item {
            display: flex;
            align-items: center;
            width: 289px;
            height: 36px;
            opacity: 1;
            // background: rgba(18, 155, 255, 0.1);
            border-radius: 4px;
            align-items: center;
            >div{
                overflow: hidden;
text-overflow:ellipsis;
white-space: nowrap;

            }

            .item1 {
              width: 113px;
              margin-left: 5px;
              font-size: 14px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              text-align: LEFT;
              color: #ffffff;
              line-height: 14px;
            }
            .item2 {
              width: 50px;
              margin-left: 6px;
              font-size: 16px;
              font-family: DIN Alternate, DIN Alternate-Bold;
              font-weight: 700;
              text-align: LEFT;
              color: #31fbfb;
              letter-spacing: 0px;
            }
            .item3 {
              width: 102px;
              margin-left: 8px;
              font-size: 14px;
              font-family: DIN Alternate, DIN Alternate-Bold;
              font-weight: 700;
              text-align: RIGHT;
              color: #31fbfb;
              letter-spacing: 0px;
            }
          }
          .r1-bd_list_item:nth-child(odd) {
            background: rgba(18, 155, 255, 0.1);
          }
          .r1-bd_list_item:nth-child(even) {
            background: rgba(255, 255, 255, 0.1);
          }
        }
      }
</style>