<template>
  <div class="upingimg3">
    <!-- 上传前 -->
    <div class="before_upload">
      <el-upload
        action=""
        list-type="picture"
        :show-file-list="false"
        :before-upload="beforeUpload"
        :http-request="upLoad"
        :on-change="imgSaveToUrl"
        class="box"
      >
        <img
          v-if="filename == '' && !isdone"
          src="../assets/shangchuan.png"
          alt=""
        />
        <div class="filename">
          {{ filename }}
        </div>
      </el-upload>
    </div>
  </div>
</template>

<script>
import { uploadfile, uploadoss } from "../api/getdata"; //引入接口
import md5 from "js-md5";
export default {
  props: ["backup_filename", "typeimg"],
  data() {
    return {
      upload_imginfo: "", //上传图片的信息
      showtime: 0, //上传进度 表示百分比
      uploadStatus: "upload", //上传图片的状态
      file_key: "", //传给后台的图片的信息
      uploaddingimage: "",
      active_url: "",
      type_img: "portrait_image",
      filename: "",
      isdone: false,
    };
  },
  mounted() {
    // console.log(this.backup_filename);
    if (this.backup_filename) {
      this.filename = this.backup_filename;
    }
    // console.log(this.typeimg);
    if (this.typeimg) {
      this.type_img = this.typeimg;
    }
  },

  methods: {
    //获取授权
    beforeUpload(ele) {
      console.log(ele);

      let file_type = ele.name.split(".").pop();
      let arr = ["jpg", "JPG", "jpeg", "JPEG", "png", "PNG", "gif", "GIF"];
      if (arr.indexOf(file_type) == -1) {
        this.$message.error("文件大小或格式不匹配");
        return false;
      }
      const isLt2M = ele.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传文件大小不能超过 2M");
        return false;
      }
      this.filename = ele.name;
      let type = this.type_img;
      return new Promise((resolve, reject) => {
        this.uploadStatus = "uploadding";
        uploadfile({ type })
          .then((response) => {
            console.log(response);
            this.upload_imginfo = response.data;
            console.log(this.upload_imginfo);
            resolve(true);
          })
          .catch(() => {
            this.$message.error("获取授权失败，请检查网络或联系系统管理员");
            reject(false);
          });
      });
    },
    // 上传文件
    upLoad(file) {
      const _self = this;
      let formdate = new FormData();
      let file_name = md5(new Date().getTime() + file.file.name);
      let file_type = file.file.name.split(".").pop();
      let file_key = _self.upload_imginfo.dir + file_name + "." + file_type;
      console.log(file_key);
      formdate.append("key", file_key);
      formdate.append("OSSAccessKeyId", _self.upload_imginfo.access_key_id); //accessKeyId
      formdate.append("policy", _self.upload_imginfo.policy); //policy
      formdate.append("Signature", _self.upload_imginfo.signature); //签名
      formdate.append("success_action_status", 200);
      formdate.append("file", file.file);
      file.file.file_key = file_key;
      this.file_key = file_key;
      uploadoss(_self.upload_imginfo.host, formdate)
        .then(() => {
          this.isdone = true;
          this.sendMsg();
        })
        .catch(() => {
          this.isdone = false;
          this.$message.error("上传失败");
        });
    },
    //选择上传的文件
    imgSaveToUrl(file) {
      let url = URL.createObjectURL(file.raw);
      this.uploaddingimage = url;
    },
    // 给父组件传值
    sendMsg() {
      console.log(this.filename);
      this.$emit("getMsg", this.file_key, this.filename); // 调用父组件传递过来的方法，同时把数据传递出去
      this.$emit("get");
      console.log(this.file_key, this.filename);
    },
  },
};
</script>

<style lang="less">
.upingimg3 {
  height: 100%;
  width: 100%;
  // background-color: #f00;
  .box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    .el-upload {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      width: 17px;
      height: 17px;
    }
    .filename {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #0a6ed1;
    }
  }
}
</style>