<template>
  <div class="edit-homem1">
      <div>
        <div style="font-weight: 500;line-height: 24px;height:24px;font-size:14px">图例区间</div>
        <div class="cut-line"></div>
        <div class="flex-bte input-left" style="margin-bottom:24px;position: relative;">
            <div>
                <!-- line-height:32px; -->
                <div style="margin-bottom:9px">全国：</div>
                <div style="line-height:32px;margin-bottom:12px;" v-for="(item,index) in province_color" :key="index">
                    <span class="block-span" :style="{'background':item.color}"></span>
                    <div class="disable-nuinput" v-if="index===0" >1</div>
                    <el-input-number v-else v-model.number="item.min" size="small" controls-position="right" :min='resetMin(index)'></el-input-number>
                    -
                    <div class="disable-nuinput" v-if="index===3" >/</div>
                    <!-- <el-input-number v-else :value="resetMax(index)" size="small" controls-position="right" disabled :min='1'></el-input-number> -->
                    <div class="disable-nuinput" v-else > {{resetMax(index)}}</div>
                </div>
            </div>
            <div style="margin-right:46px">
                <!-- line-height:32px; -->
                <div style="margin-bottom:9px">省份：</div>
                <div style="line-height:32px;margin-bottom:12px;" v-for="(item,index) in city_color" :key="index">
                    <span class="block-span" :style="{'background':item.color}"></span>
                    <div class="disable-nuinput" v-if="index===0" >1</div>
                    <el-input-number v-else v-model="item.min" size="small" controls-position="right" :min='resetCityMin(index)'></el-input-number>
                    -
                    <div class="disable-nuinput" v-if="index===3" >/</div>
                    <!-- <el-input-number v-else :value="resetCityMax(index)"  size="small" controls-position="right" disabled :min='1'></el-input-number> -->
                    <div class="disable-nuinput" v-else > {{resetCityMax(index)}}</div>
                </div>
            </div>
        </div>
        <div style="font-weight: 500;line-height: 24px;height:24px;font-size:14px;margin-top:24px">项目列表</div>
        <div class="cut-line"></div>
        <el-input v-model.trim="search_key" @input="searchClick" placeholder="请输入内容" maxlength="30"></el-input>
        <i class="el-icon-search" style="font-size:14px;margin-left:10px;margin-right:10px;cursor:pointer"></i>
        <span>共找到{{search_file}}个项目</span>
        <div class="flex-bte" style="margin-top:12px">
            <div class="con-l" style="padding-bottom:0;padding-top:0">
                <div v-if="platforms.length==0" class="no-data-l">
                    <div class="no-data-l-img"></div>
                    <div>还没有添加数据</div>
                    <div class="add-btn no-select" @click="addPro" style="width:80px">+ 添加</div>
                </div>
                <div v-else style="position:relative">
                    <div :class="{'box-shadow':box_shadow}" style="top:0"></div>
                    <div class="con-l-b lidiv" id="lidvs" style="height:auto;max-height:325px;overflow-y:auto" @scroll="orderScroll">
                    <div v-for="(item,index) in platforms" :key="index" :class="'livd'+index">
                        <div v-show="item.name.search(search_key)!=-1" :class="{active:active_index===index,itemlist:true,lidiv:true}" style="cursor:pointer" @click="changeActive(index)">{{item.name}}</div>
                    </div>
                </div>
                <div class="add-btn no-select" @click="addPro">+ 添加</div>
                </div>
                
            </div>
            <div class="con-r" id="scroll-top" style="overflow-x: hidden;overflow-y: auto;;position:relative">
                <div v-if="JSON.stringify(now_data)!='{}'">
                    <i class="del-icon" @click="delDate" style="z-index: 2;position:absolute;right:21px;top:14px"></i>
                <div style="position:relative">
                    <div style="margin-bottom:6px">项目名称</div>
                    <el-input class="width188" :class="{errorbd:error_type&&!now_data.name}" maxlength="20" v-model="now_data.name" placeholder="请输入，20字内"></el-input>
                    <div class="error-txt" style="left:15px;bottom: 4px;" v-if="error_type&&!now_data.name">项目名称不得为空</div>
                </div>
                <div>
                    <div style="margin-bottom:6px">项目地址</div>
                    <el-cascader
                        v-model="now_data.address_r"
                        :options="options"
                        popper-class="le-cascader"
                        :change-on-select="true"
                        :props="{ expandTrigger: 'hover' }"
                    ></el-cascader>
                </div>
                <div>
                    <div style="margin-bottom:6px;margin-top:21px">项目简介</div>
                        <el-input
                        v-model="now_data.desc"
                        type="textarea"
                        :rows="2"
                        resize="none"
                        maxlength="500"
                        placeholder="请输入，500字内"
                        ></el-input>
                    </div>
                    <div style="margin-top:20px" >
                        提供详细介绍：
                        <el-radio-group v-model="now_data.show_detail">
                            <el-radio :label="1">是</el-radio>
                            <el-radio :label="2">否</el-radio>
                        </el-radio-group>
                    </div>
                    <div style="margin-top:20px;display: flex;" v-show="now_data.show_detail=='1'">
                        <span style="display: inline-block;width: 102px;">详细介绍方式：</span>
                        <el-radio-group v-model="now_data.way" style="padding-top: 3px;">
                            <el-radio :label="1">大屏页面</el-radio><div style="margin-bottom:19px"/>
                            <el-radio :label="2" v-show='has_plant' class="width-174">智慧运维项目
                                <el-select :disabled="now_data.way!=2" :class="{errorbd:error_type&&!now_data.platform_id&&now_data.way==2}" v-model="now_data.platform_id" style="margin-left:10px" placeholder="请选择">
                                    <el-option
                                    v-for="item in plat_options"
                                    :key="item.id"
                                    placeholder="请输入，500字内"
                                    :label="item.name"
                                    :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-radio>
                            <div style="margin-bottom:12px"/>
                            <el-radio :label="3">自定义链接&nbsp;
                            <el-input :disabled="now_data.way!=3" placeholder="请输入" maxlength="150" :class="{errorbd:(now_data.link_check)&&now_data.way==3}" v-model="now_data.link" @change="checkLink(now_data.link,now_data)" style="margin-left:20px" class="width-174"></el-input>
                            </el-radio>
                        </el-radio-group>
                    </div>
                </div>
                <div v-else class="no-data-r" style="margin-top: 39px;">
                    <div class="no-data-r-img"></div>
                    <div>暂无数据</div>
                </div>
            </div>
            
        </div>
    </div>
    <div></div>
  </div>
</template>

<script>
// import moment from moment
import {platforminter} from "../api/getdata"
import options from "../assets/country-level3-data.js";
import addressList from "../assets/20201201.js";
const _province_color = [{min:1,max:9,color:'#4FA0FF'},{min:10,max:29,color:'#2C5AFF'},{min:30,max:49,color:'#2405E3'},{min:50,max:100,color:'#1407A3'}]
const _city_color = [{min:1,max:9,color:'#4FA0FF'},{min:10,max:29,color:'#2C5AFF'},{min:30,max:49,color:'#2405E3'},{min:50,max:100,color:'#1407A3'}]
export default {
    props:{
        map_info:{platforms:[],province_color:[],city_color:[]}
    },
    watch:{
        map_info(){
            this.dialog_info=this.map_info||{}
            let color_list_p = (this.dialog_info?.province_color)?.slice(-4) ||[..._province_color]
            this.province_color=color_list_p.map((ele,index)=>{
                ele.color = _province_color[index].color
                return ele
            })
            this.city_color=(this.dialog_info?.city_color)?.slice(-4) ||[..._city_color]
            this.city_color.forEach((ele,index)=>{
                ele.color = _city_color[index].color
            })
            this.changeActive(0)
        }
    },
    data(){
        return{
            dialog_info:this.map_info||{},
            platforms:this.map_info?.platforms ||[],
            now_data:{},
            active_index:'',
            address_r:'',
            num:'',
            search_key:'',
            options:options,
            search_value:'',
            province_color:(this.map_info?.province_color)?.slice(-4) || _province_color,
            city_color:(this.map_info?.city_color)?.slice(-4) || _city_color,
            error_type:false,
            plat_options:[],
            link_check:false,
            has_plant:true
        }
    },
    computed:{
        rplatforms(){
            if(this.search_value){
                return this.platforms.filter(ele=>{
                    return ele.name === this.search_value
                })
            }else{
                return this.platforms
            }
        },
        search_file(){
            let arr = this.platforms.filter(ele=>{
                return ele.name.search(this.search_key)!=-1
            })
            return arr.length
        },
    },
    methods:{
        addPro(){
            let index = this.platforms.length
            this.$set(this,'platforms',[...this.platforms,{name:'项目名称'+(index+1),show_detail:1,way:1,desc:''}])
            this.changeActive(index)
            if(index<5){
                return
            }
            this.$nextTick(()=>{
                let lidv = document.getElementById('lidvs')
                lidv.scrollTop = lidv.scrollHeight;
            })
        },
        delDate(){
            if(this.platforms.length==0){
                return
            }
            this.platforms.splice(this.active_index,1)
            if(this.platforms.length==0){
                this.now_data={}
                this.active_index = ''
            }else{
                if(this.active_index===0){
                    this.changeActive(0)
                }else{
                    this.changeActive(this.active_index-1)
                }
                
            }
            
        },
        changeActive(index){
            if(this.platforms.length==0){
                return
            }
            this.active_index = index;
            this.now_data = this.rplatforms[index]
            if(this.now_data?.platform_id===0){
                this.now_data.platform_id=null
            }
            if(!this.now_data?.link){
                this.now_data.link='http://'
            }
            if(!this.now_data.way){
                this.now_data.way = 1
            }

            let lidv = document.getElementById("scroll-top")
            lidv.scrollTop = 0
        },
        changeMin(key,item,index){
            if(index!==0){
                this[key][index-1].max=item.min-1
            }
        },
        changeMax(key,item,index){
            if(index!==this[key].length){
                if(!item.max||isNaN(item.max)){
                    let max = this[key][index+1].min-1
                    this[key][index].max=max
                    this.$set(this[key],index,{...item,max})
                    this.$forceUpdate()
                }
                this[key][index+1].min=item.max+1
            }else{
                if(!item.max||isNaN(item.max)){
                    item.max = item.max*100
                }
            }
            
        },
        saveEdit(){
            // if(this.platforms.length===0){
            //     this.$message.error({
            //         showClose: true,
            //         message: "失败：请添加项目再提交"
            //     });
            //     return
            // }
            for(let i in this.platforms){
                let ele = this.platforms[i] || {}
                if(!ele.name){
                    this.$message.error({
                        showClose: true,
                        message: "失败：项目名称不得为空"
                    });
                    this.error_type=true
                    this.changeActive(Number(i))
                    let lidv = document.getElementById("scroll-top")
                    lidv.scrollTop = lidv.scrollHeight;
                    return
                }else if(ele.way==3){
                    this.checkLink(ele.link,ele)
                    if(this.link_check){
                        this.$message.error({
                            showClose: true,
                            message: "失败：请输入正确的链接地址"
                        });
                        this.error_type=true
                        this.changeActive(Number(i))
                        let lidv = document.getElementById("scroll-top")
                        lidv.scrollTop = lidv.scrollHeight;
                        return
                    }
                }else if(ele.way==2){
                    if(!ele.platform_id){
                    this.$message.error({
                        showClose: true,
                        message: "失败：请选择智慧运维项目"
                    });
                    this.error_type=true
                    this.changeActive(Number(i))
                    let lidv = document.getElementById("scroll-top")
                    lidv.scrollTop = lidv.scrollHeight;
                    let activelidv = document.getElementsByClassName('livd'+i)[0]
                    activelidv.scrollIntoView()
                    return
                }
            }
            }
            this.error_type=false
            function findKey(obj,value){
                return Object.keys(obj).find((k)=>{
                    return obj[k]==value
                })
            }

            let platforms = this.platforms.map(eles=>{
                let ele= eles.address_r||[]
                let province_code = findKey(addressList.province_list,ele[0])
                let city_code = ''
                if(ele[1]==='市辖区'&&ele[2]){
                    city_code = findKey(addressList.county_list,ele[2])
                }else{
                    city_code = findKey(addressList.city_list,ele[1])
                }
                let item  = {...eles}
                delete item.address_r
                item.city_code = city_code || null
                item.province_code = province_code || null
                if(!item.way){
                    item.way=1
                }
                return item
            })
           
            
            function formatArray(array){
                let length = array.length
                let format_array = array.map((ele,index)=>{
                    if(index===0){
                        let max = array[1].min-1
                        return {...ele,max,min:1}
                    }else if(index ==length-1){
                        return {...ele,max:10000}
                    }else{
                        let max = array[index+1].min-1
                        return {...ele,max}
                    }
                })
                return format_array
            }
            let province_colord = formatArray(this.province_color)
            let city_colord = formatArray(this.city_color)
             //#74C3E1 #87E2FF
            let city_color = [{min:0,max:0,color:'#87E2FF'},...city_colord]
            let province_color = [{min:0,max:0,color:'#87E2FF'},...province_colord]
            let arg = {
                province_color,
                city_color,
                platforms
            }
            // this.checkLink(val)
            // if(this.link_check){
            //     this.$message.error({
            //         showClose: true,
            //         message: "失败：请添加正确的链接再提交"
            //     });
            //     return
            // }
            platforminter('save',arg).then(ele=>{
                this.$message.success({
                    showClose: true,
                    message: "成功：操作成功"
                });
                setTimeout(() => {
                    this.$emit('updateDate')
                }, 800);
                this.$emit('update:visible',false)
                
            })
            
        },
        searchClick(){
            this.active_index = '';
            this.now_data = {}
        },
        checkLink(val,item){
  
            if ( /^((https|http)(:\/\/))(([A-Za-z0-9-~]+)\.)+([A-Za-z0-9-~/])+$/i.test(
          val
          )) {
                if(item){
                    this.$set(item,'link_check',false)
                }
                this.link_check =  false;
            } else {
                if(item){
                    this.$set(item,'link_check',true)
                }
                this.link_check =  true;
            }
        },
        resetMax(index){
            if(this.province_color[index+1].min){
                return Number((this.province_color[index+1].min))-1
            }else{
                return null
            }
           
        },
        resetCityMax(index){
            if(this.city_color[index+1].min){
                return Number((this.city_color[index+1].min))-1
            }else{
                return null
            }
           
        },
        resetCityMin(index){
            if(this.city_color[index-1].min){
                return Number((this.city_color[index-1].min))+1
            }else{
                return null
            }
           
        },
        resetMin(index){
            if(this.province_color[index-1].min){
                return Number((this.province_color[index-1].min))+1
            }else{
                return null
            }
            
        }
    },
    mounted(){
        (this.map_info.platforms||[]).forEach(ele => {
            let city_code = addressList.city_list[ele.city_code] ||''
            if(!city_code){
                ele.address_r = [addressList.province_list[ele.province_code],'市辖区',addressList.county_list[ele.city_code]]
            }else{
                ele.address_r = [addressList.province_list[ele.province_code],city_code]
            }
            this.changeActive(0)
        });
        platforminter('platforms').then(ele=>{
            if(ele.code===5001){
                this.has_plant=false
            }else{
                this.has_plant=true
            }
            this.plat_options = ele.data ||[]
        })
    }
}
</script>

<style lang="less">
.edit-homem1{
    .flex-bte .con-l div.con-l-b{
        height:auto;max-height:290px;overflow-y:auto;
        padding: 0;
        &::-webkit-scrollbar {
            display: none;
        }
        div{
            cursor: pointer;
            padding: 0;
            &:hover{
                opacity: 0.85;
            }
        }
        .itemlist{
            padding: 0 16px;
        }
    }
    .el-input__icon{
        line-height: 1;
    }
    .el-date-editor input.el-input__inner{
        width: 193px;
    }
    .block-span{
        display: inline-block;
        width: 32px;
        height: 14px;
        margin-right: 8px;
        margin-left: 13px;
        vertical-align: middle;
    }
    .el-textarea__inner{
        height:120px;
        background: rgba(0,0,0,0.40);
        border: 1px solid rgba(255,255,255,0.65);
        border-radius: 2px;
        color:#FFF;
    }
    .el-radio{
        color: rgba(255,255,255,0.85);
    }
    i.del-icon{
        height: 24px;
        width: 24px;
        display: inline-block;
        cursor: pointer;
        &:hover{
            opacity: 0.85;
        }
        background-image: url('../assets/delicon.png');
    }
}
.home-edit-dialog .el-dialog .edit-homem1 .el-input-number .el-input__inner{
    width: 110px;
}
.el-input-number--small{
    width: 110px;
}
.edit-homem1 {
    .el-input-number{
        width: 110px;
        line-height: 32px;
    }
    .el-input-number__decrease, .el-input-number__increase{
        width: 20px;
        background-color: transparent;
    }
}
.el-cascader-panel{
    height: 240px;
}
.le-cascader{
    .el-scrollbar__wrap {
        overflow-x: hidden;
    }
    .el-cascader-panel .el-radio {
    width: 100%;
    height: 100%;
    z-index: 10;
    position: absolute;
    top: 10px;
    /* margin-right: 10px; */
    /* right: 10px; */
  }
.el-cascader-node__label{
  margin-left: 10px;
}
.el-cascader-menu__list{
    overflow: hidden;
}
}
.input-max{
    display: inline-block;
    height: 34px;
    width: 110px;
    vertical-align: middle;
    background-image: url('../assets/inputmax.png');
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    &::after{
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.2);
    }
    
}
.disable-nuinput{
    height: 32px;
    width: 90px;
    padding: 0 10px;
    display: inline-block;
    vertical-align: top;
    background-color: rgba(255,255,255,0.2);
    border-radius: 2px;
    color: rgba(255,255,255,0.65);
    cursor: not-allowed;
}
.input-left .el-input-number .el-input__inner{
    padding-left: 10px;
    text-align: left;
}
</style>