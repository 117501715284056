<template>
  <div class="project-pg2">
    <div
      class="pg2-left"
      :style="{ 'background-image': 'url(' + file_url + ')' }"
    >
      <div
        v-show="Manage"
        @dblclick="
          edit_mode = true;
          img_smore = true;
        "
        class="edit-block"
      ></div>
      <div
        v-if="!edit_mode"
        class="colose-text text-btn no-select"
        @click="closeWindow"
      >
        关闭页面
      </div>
      <div v-else>
        <div
          v-show="edit_mode"
          class="colose-text no-select text-btn"
          @click="outEdit"
        >
          退出编辑
        </div>
        <div
          v-show="edit_mode"
          class="colose-text no-select text-btn"
          style="right: 115px; width: 100px"
          @click="show_setingModel = true"
        >
          设置页面模版
        </div>
      </div>

      <div class="pg2-title">
        {{ pro_name || "" }}
      </div>
      <div class="pg2-imgul">
        <span
          class="pg2-imgul-icon"
          @click="img_smore = !img_smore"
          v-show="proj_list_h.length + proj_list_z.length > 1"
        >
          <i class="iconfont dlh-icon_zhankai_1"></i>
        </span>
        <div>
          <span
            :class="{
              'pg2-imgli': true,
              'edit-mode': edit_mode,
              'active-img': active_img === 0,
            }"
            style="margin-right: 0"
            v-if="img_smore"
            @click="changeActiveImg(0)"
            >{{ main_name
            }}<i class="iconfont dlh-Frame" @click.stop="showmainEdit"></i
          ></span>
          <span
            :class="{
              'pg2-imgli': true,
              'active-img': true,
            }"
            v-else
            style="margin-right: 0"
            >{{ active_img === 0 ? main_name : active_item }}</span
          >
        </div>
        <div style="overflow: hidden">
          <transition name="slide-fade">
            <div v-show="img_smore" style="display: flex">
              <span
                :class="{
                  'pg2-imgli': true,
                  'edit-mode': edit_mode,
                  'active-img': active_img === 'x' + index,
                }"
                v-for="(item, index) in proj_list_h"
                style="margin-left: 10px; margin-right: 0"
                :key="index"
                @click="changeActiveImg('x', item, index)"
                >{{ item
                }}<i
                  class="iconfont dlh-Frame"
                  @click.stop="showsecondEdit(1, item, index)"
                ></i
              ></span>
            </div>
          </transition>
        </div>
        <div class="pg2-imgli-z">
          <transition name="slide-down-fade">
            <div v-show="img_smore" class="pg2-imgli-z-box">
              <span
                :class="{
                  'pg2-imgli': true,
                  'edit-mode': edit_mode,
                  'active-img': active_img === 'y' + index,
                }"
                v-for="(item, index) in proj_list_z"
                :key="index"
                @click="changeActiveImg('y', item, index)"
                >{{ item
                }}<i
                  class="iconfont dlh-Frame"
                  @click.stop="showsecondEdit(2, item, index)"
                ></i
              ></span>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <div class="pg2-right">
      <div class="box_right">
      <div class="pg2r-box1">
        <div class="pg2r-box1-bg"></div>
        <div class="pg2r1-title">污水性质：</div>
        <div class="pg2r1-type1">{{ water_quality || "--" }}</div>
        <div class="pg2r1-title" style="margin-top: 16px">设计水量：</div>
        <div class="pg2r1-type1">{{ water_yield || "--" }}</div>
        <div class="pg2r1-box1" style="margin-top: 20px">
          <div
            class="pg2r1-title"
            style="margin-top: 0; margin-left: 0; margin-bottom: -10px"
          >
            处理标准：
          </div>
          <pre><div style="" class="pg2r1-box1-text scrollw2px" v-html="water_standard||'--'">
          </div></pre>
        </div>
        <i
          class="edit-icon"
          v-if="edit_mode"
          @click="show_projectOverview = true"
        ></i>
      </div>
      <div class="pg2r-box2">
        <div class="pg2r2-text">进出水水质对比</div>
        <div class="pg2r2-ph" v-if="show_ph == 1">
          <span>PH</span>
          <div class="div-rule">
            <div class="div-rule-pointer">
              <span v-for="(item, index) in rule_pointer" :key="index"></span>
            </div>
            <div class="div-rule-pointer_no">
              <span
                v-for="(item, index) in rule_pointer_no"
                :style="{
                  left: ((item - rule_min) / (rule_max - rule_min)) * 100 + '%',
                }"
                :key="index"
                >{{ item }}</span
              >
            </div>
            <div class="div-rule-line">
              <div
                v-for="(item, index) in rule_pointer_relno"
                :key="index"
                :style="{
                  width:
                    ((item.max - item.min) / (rule_max - rule_min)) * 100 + '%',
                  left:
                    ((item.min - rule_min) / (rule_max - rule_min)) * 100 + '%',
                }"
                :class="{ 'out-line': index == 1, 'in-line': index == 0 }"
              ></div>
            </div>
          </div>
          <div style="display: flex; align-items: center">
            <i class="red-doll"></i
            ><span style="margin-right: 20px">进水</span>
            <i class="blue-doll"></i><span>出水</span>
          </div>

          <div class="rule-mask" @mousemove="showPHinfo"></div>
          <div
            class="pg2r2-ph-info chart-hover-bg"
            :style="{ left: ph_screenx, top: ph_screeny }"
          >
            <div>PH值</div>
            <div class="flex-between">
              <span><i class="red-doll"></i>进水</span
              ><span
                >{{ (rule_pointer_relno[0].min || 0).toFixed(1) }}-{{
                  (rule_pointer_relno[0].max || 0).toFixed(1)
                }}</span
              >
            </div>
            <div class="flex-between">
              <span><i class="blue-doll"></i>出水</span
              ><span
                >{{ (rule_pointer_relno[1].min || 0).toFixed(1) }}-{{
                  (rule_pointer_relno[1].max || 0).toFixed(1)
                }}</span
              >
            </div>
          </div>
        </div>
        <div class="pg2r2-quality">
          <div
            v-for="(item, index) in tableList"
            :key="index"
            :class="{
              'pg2r2-q-listle4': tableList.length < 5,
              'pg2r2-q-listgt4': tableList.length > 4,
            }"
          >
            <div class="charts-div">
              <div
                style="
                  height: 100%;
                  width: 100%;
                  overflow: hidden;
                  border-radius: 19px;
                  display: flex;
                  flex-direction: column-reverse;
                "
              >
                <div
                  class="pg2r2-charts-content"
                  :style="{
                    height: 'calc( 100% - ' + formatWater(item, 'h') + ')',
                  }"
                ></div>
                <div class="charts-move"></div>
              </div>

              <div class="charts-div-info">
                <div class="charts-div-infot">去除率</div>
                <div>{{ formatWater(item) }}</div>
              </div>
            </div>
            <div class="charts-info">
              <div class="charts-info-t">{{ item.name }}</div>
              <div>({{ item.unit || " / " }})</div>
            </div>
            <div class="div-mask" @mousemove="(e) => showQulinfo(e, index)">
              <span>进</span>
            </div>
            <div
              class="pg2r2-qua-info chart-hover-bg"
              :style="{ left: qua_screenx, top: qua_screeny }"
            >
              <div>{{ item.name }} ({{ item.unit }})</div>
              <div class="flex-between">
                <span><i class="red-doll"></i>进水</span
                ><span style="color: #fff">{{ item.in }}</span>
              </div>
              <div class="flex-between">
                <span><i class="blue-doll"></i>出水</span
                ><span style="color: #fff">{{ item.out }}</span>
              </div>
              <div class="flex-between">
                <span><i class="blue-doll"></i>去除率</span
                ><span style="color: #fff">{{ formatWater(item) }}</span>
              </div>
            </div>
          </div>
        </div>
        <i
          class="edit-icon"
          v-if="edit_mode"
          @click="show_waterQuality = true"
        ></i>
      </div>
      <div class="pg2r-box3">
        <div class="pg2r-box3-t">
          <span>建设成本</span><span>运营成本</span>
        </div>
        <div class="pg2r-box3-tno">
          <div class="pg2r-box3-tno-bk" style="color: #129bff">
            <div style="margin: 0 auto">
              <span v-for="(item, index) in totalCost.split('')" :key="index">{{
                item
              }}</span>
              {{ totalCost_units1 == 1 ? "万元" : "亿元" }}
            </div>
          </div>
          <div class="pg2r-box3-tno-bk" style="color: #f85f64">
            <div style="margin: 0 auto">
              <span
                v-for="(item, index) in operatingCosts.split('')"
                :key="index"
                >{{ item }}</span
              >
              元/吨
            </div>
          </div>
        </div>
        <div class="pg2r-box3-charts">
          <div>
            <div id="pg2r-box3-charts1" ref="box3charts1"></div>
            <div>建设成本分布</div>
          </div>
          <div class="dash-line"></div>
          <div>
            <div id="pg2r-box3-charts2" ref="box3charts2"></div>
            <div>运营成本分布</div>
          </div>
        </div>
        <i
          class="edit-icon"
          v-if="edit_mode"
          @click="show_projectCost = true"
        ></i>
      </div>
      </div>
    </div>

    <!-- 进出水质弹窗 -->
    <el-dialog
      class="waterQuality"
      title="进出水水质"
      :visible.sync="show_waterQuality"
      :append-to-body="true"
      :close-on-click-modal="false"
      width="830px"
    >
      <div class="dialog-body" v-if="show_waterQuality">
        <div class="title">PH值</div>
        <div class="show">
          <div class="show_text">是否显示：</div>
          <el-radio-group v-model="show_ph">
            <el-radio :label="1">显示</el-radio>
            <el-radio :label="2">不显示</el-radio>
          </el-radio-group>
        </div>
        <div class="inflow" v-if="show_ph == 1">
          <div class="inflow_left">
            <div>进水</div>
            <el-tooltip
              class="item"
              effect="dark"
              content="允许一位小数"
              placement="top"
            >
              <i class="el-icon-warning-outline"></i>
            </el-tooltip>

            <span class="span1">:</span>
            <input
              :class="{
                inflow_input: true,
                input1: true,
                border: inflow_toast,
              }"
              type="number"
              oninput="if(value.length>4)value=value.slice(0,4)"
              v-model="inwater_min"
              @blur="loseblur"
            />
            <span class="span2">-</span>
            <input
              :class="{
                inflow_input: true,

                border: inflow_toast,
              }"
              type="number"
              oninput="if(value.length>4)value=value.slice(0,4)"
              v-model="inwater_max"
              @blur="loseblur"
            />
          </div>
          <div class="inflow_right">
            <div>出水</div>
            <el-tooltip
              class="item"
              effect="dark"
              content="允许一位小数"
              placement="top"
            >
              <i class="el-icon-warning-outline"></i>
            </el-tooltip>

            <span class="span1">:</span>
            <input
              :class="{
                inflow_input: true,
                input1: true,
                border: inflow_toast,
              }"
              type="number"
              oninput="if(value.length>4)value=value.slice(0,4)"
              v-model="outwater_min"
              @blur="loseblur"
            />
            <span class="span2">-</span>
            <input
              :class="{
                inflow_input: true,
                border: inflow_toast,
              }"
              type="number"
              oninput="if(value.length>4)value=value.slice(0,4)"
              v-model="outwater_max"
              @blur="loseblur"
            />
          </div>
        </div>
        <!-- <div class="inflow" style="opacity: 0" v-else></div> -->
        <div class="title">其他指标</div>
        <div class="table">
          <div class="th">
            <div class="th_1">序号</div>
            <div class="th_2">指标</div>
            <div class="th_3">单位</div>
            <div class="th_4">进水</div>
            <div class="th_5">出水</div>
            <div class="th_6">去除率</div>
            <div class="th_7">操作</div>
          </div>
          <div class="tds" v-if="tableList.length != 0">
            <div class="td" v-for="(item, index) in tableList" :key="index">
              <div class="td_1">{{ index + 1 }}</div>
              <div class="td_2">
                <input
                  class="table1_input"
                  type="text"
                  placeholder="请输入，10字内"
                  maxlength="10"
                  v-model="item.name"
                  @blur="loseblur"
                />
              </div>
              <div class="td_3">
                <input
                  type="text"
                  placeholder="请输入，5字内"
                  maxlength="5"
                  v-model="item.unit"
                />
              </div>
              <div class="td_4">
                <input
                  class="table1_input"
                  type="number"
                  oninput="if(value.length>9)value=value.slice(0,9)"
                  placeholder="请输入"
                  v-model="item.in"
                  @blur="loseblur"
                />
              </div>
              <div class="td_5">
                <input
                  class="table1_input"
                  type="number"
                  oninput="if(value.length>9)value=value.slice(0,9)"
                  placeholder="请输入"
                  v-model="item.out"
                  @blur="loseblur"
                />
              </div>
              <div class="td_6">
                {{ formatWater(item) }}
              </div>
              <div class="td_7">
                <img
                  class="tavle1_delect"
                  @click="delect_list(index)"
                  src="./img/delect0.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="nodata" v-else>
            <img src="./img/nodata.png" alt="" />
            <div class="text">
              暂无数据，<span @click="add_list">添加一行</span>
            </div>
          </div>
        </div>
        <div v-if="tableList.length != 0">
          <div v-if="overnum" class="table_add" @click="add_list">
            + 添加一行
          </div>
          <div
            v-else
            class="table_add"
            style="color: rgba(0, 0, 0, 0.25); cursor: no-drop"
          >
            + 添加一行
          </div>
        </div>
      </div>

      <div slot="footer" class="dialog-footer">
        <span class="span1" @click="water_cancel">取 消</span>
        <span class="span2" @click="water_save">保 存</span>
      </div>
    </el-dialog>
    <!-- 项目成本弹窗 -->
    <el-dialog
      class="projectCost"
      title="项目成本"
      :visible.sync="show_projectCost"
      :append-to-body="true"
      :close-on-click-modal="false"
      width="624px"
      @closed="project_cancel"
    >
      <div class="dialog-body" v-if="show_projectCost">
        <div class="title">建设成本</div>
        <div class="totalCost">
          <div class="totalCost_text">总成本:</div>
          <div class="totalCost_box">
            <input
              class="totalCost_input"
              :class="{
                totalCost_input: true,
                border: totalCost_box_toast,
              }"
              type="number"
              oninput="if(value.length>5)value=value.slice(0,5)"
              v-model="totalCost"
              placeholder="请输入"
              @focus="get_totalCost_box_toast(1)"
            />
            <el-select
              class="totalCost_select"
              popper-class="select"
              size="mini"
              v-model="totalCost_units1"
              slot="append"
              placeholder="请选择"
            >
              <el-option label="万元" :value="1"></el-option>
              <el-option label="亿元" :value="2"></el-option>
            </el-select>
          </div>
          <div v-if="totalCost_box_toast" class="totalCost_toast">
            此项不得为空
          </div>
        </div>
        <div class="table_tit">
          <div class="left">成本分布</div>
          <div class="right">
            <span>单位：</span>
            <el-select
              class="totalCost_select"
              popper-class="select"
              size="mini"
              v-model="totalCost_units2"
              slot="append"
              placeholder="请选择"
            >
              <el-option label="万元" :value="1"></el-option>
              <el-option label="亿元" :value="2"></el-option>
            </el-select>
          </div>
        </div>

        <div class="table">
          <div class="th">
            <div class="th_1">序号</div>
            <div class="th_2">分类</div>
            <div class="th_3">成本</div>
            <div class="th_4">占比</div>
            <div class="th_5">操作</div>
          </div>
          <div class="tds" v-if="tableList2.length != 0">
            <div class="td" v-for="(item, index) in tableList2" :key="index">
              <div class="td_1">{{ index + 1 }}</div>
              <div class="td_2">
                <input
                  class="tableList2_input"
                  type="text"
                  maxlength="10"
                  placeholder="请输入，10字内"
                  v-model="item.name"
                  @blur="loseblur"
                />
              </div>
              <div class="td_3">
                <input
                  class="tableList2_input"
                  type="number"
                  oninput="if(value.length>5)value=value.slice(0,5)"
                  placeholder="请输入，5字内"
                  v-model="item.cost"
                  @blur="loseblur"
                />
              </div>

              <div class="td_4">{{ formatPercent(item, "tableList2") }}</div>
              <div class="td_5">
                <img
                  @click="delect_list2(index)"
                  src="./img/delect0.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="nodata" v-else>
            <img src="./img/nodata.png" alt="" />
            <div class="text">
              暂无数据，<span @click="add_list2">添加一行</span>
            </div>
          </div>
        </div>

        <div v-if="tableList2.length != 0">
          <div class="table_add" v-if="tableList2_overnum" @click="add_list2">
            + 添加一行
          </div>
          <div
            class="table_add"
            v-else
            style="color: rgba(0, 0, 0, 0.25); cursor: no-drop"
          >
            + 添加一行
          </div>
        </div>
        <div class="title">运营成本</div>
        <div class="operatingCosts">
          <div class="operatingCosts_text">总成本:</div>
          <input
            :class="{
              operatingCosts_input: true,
              border: operatingCosts_toast,
            }"
            type="number"
            oninput="if(value.length>5)value=value.slice(0,5)"
            v-model="operatingCosts"
            placeholder="请输入"
            @focus="get_totalCost_box_toast(2)"
          />
          <div class="right">元/吨</div>
          <div v-if="operatingCosts_toast" class="operatingCosts_toast">
            此项不得为空
          </div>
        </div>

        <div class="table_unit">
          <div class="left">成本分布</div>
          <div class="right">单位 :&nbsp;&nbsp;元/吨</div>
        </div>
        <div class="table">
          <div class="th">
            <div class="th_1">序号</div>
            <div class="th_2">分类</div>
            <div class="th_3">成本</div>
            <div class="th_4">占比</div>
            <div class="th_5">操作</div>
          </div>
          <div class="tds" v-if="tableList3.length != 0">
            <div class="td" v-for="(item, index) in tableList3" :key="index">
              <div class="td_1">{{ index + 1 }}</div>
              <div class="td_2">
                <input
                  class="tableList3_input"
                  type="text"
                  maxlength="10"
                  placeholder="请输入，10字内"
                  v-model="item.name"
                  @blur="loseblur"
                />
              </div>
              <div class="td_3">
                <input
                  class="tableList3_input"
                  type="number"
                  oninput="if(value.length>5)value=value.slice(0,5)"
                  placeholder="请输入，5字内"
                  v-model="item.cost"
                  @blur="loseblur"
                />
              </div>

              <div class="td_4">{{ formatPercent(item, "tableList3") }}</div>
              <div class="td_5">
                <img
                  @click="delect_list3(index)"
                  src="./img/delect0.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="nodata" v-else>
            <img src="./img/nodata.png" alt="" />
            <div class="text">
              暂无数据，<span @click="add_list3">添加一行</span>
            </div>
          </div>
        </div>
        <div v-if="tableList3.length != 0">
          <div class="table_add" v-if="tableList3_overnum" @click="add_list3">
            + 添加一行
          </div>
          <div
            class="table_add"
            v-else
            style="color: rgba(0, 0, 0, 0.25); cursor: no-drop"
          >
            + 添加一行
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <span class="span1" @click="project_cancel">取 消</span>
        <span class="span2" @click="project_save">保 存</span>
      </div>
    </el-dialog>

    <!-- 主展示图编辑 -->
    <el-dialog
      class="mainEdit"
      title="主展示图编辑"
      :visible.sync="show_mainEdit"
      :append-to-body="true"
      :close-on-click-modal="false"
      width="624px"
      @closed="main_cancel"
    >
      <div class="dialog-body">
        <div class="photo_name">
          <div class="left">
            <div class="top">主展示图名</div>

            <input
              :class="{
                bottom: true,
                border: editmain_name_toast,
              }"
              type="text"
              v-model="editmain_name"
              maxlength="10"
              placeholder="请输入，10字内"
              @focus="getfocus(1)"
            />
            <div v-if="editmain_name_toast" class="error_toast">
              此项不得为空
            </div>
          </div>
          <div class="right">
            <div class="top_r">展开图数量</div>
            <div class="bottom_r">
              <div class="bottom_r_left">
                <span>横向</span>
                <el-select
                  class="name_select"
                  popper-class="select"
                  size="mini"
                  v-model="crosswise"
                  slot="append"
                  placeholder="请选择"
                >
                  <el-option label="不显示" :value="0"></el-option>
                  <el-option label="1 个 " :value="1"></el-option>
                  <el-option label="2 个 " :value="2"></el-option>
                  <el-option label="3 个 " :value="3"></el-option>
                  <el-option label="4 个 " :value="4"></el-option>
                  <el-option label="5 个 " :value="5"></el-option>
                </el-select>
              </div>
              <div class="bottom_r_right">
                <span>纵向</span>
                <el-select
                  class="name_select"
                  popper-class="select"
                  size="mini"
                  v-model="lengthways"
                  slot="append"
                  placeholder="请选择"
                >
                  <el-option label="不显示" :value="0"></el-option>
                  <el-option label="1 个 " :value="1"></el-option>
                  <el-option label="2 个 " :value="2"></el-option>
                  <el-option label="3 个 " :value="3"></el-option>
                  <el-option label="4 个 " :value="4"></el-option>
                  <el-option label="5 个 " :value="5"></el-option>
                  <el-option label="6个 " :value="6"></el-option>
                  <el-option label="7 个 " :value="7"></el-option>
                  <el-option label="8 个 " :value="8"></el-option>
                  <el-option label="9 个 " :value="9"></el-option>
                  <el-option label="10 个 " :value="10"></el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>
        <div class="name">主展示图上传</div>
        <div class="requirements">
          <i class="el-icon-warning" style="color: orange"></i>
          <div>请选择10M内的png/jpg/jpeg/gif格式文件，建议比例4:3</div>
        </div>

        <Upload @getMsg="childMsg" ref="upload_child"></Upload>
      </div>
      <div slot="footer" class="dialog-footer">
        <span class="span1" @click="main_cancel">取 消</span>
        <span class="span2" @click="main_save">保 存</span>
      </div>
    </el-dialog>

    <!-- 次展开图编辑 -->
    <el-dialog
      class="secondEdit"
      title="展开图编辑"
      :visible.sync="show_secondEdit"
      :append-to-body="true"
      :close-on-click-modal="false"
      width="418px"
    >
      <div class="dialog-body">
        <div class="photo">
          <div class="text">图片名称</div>
          <input
            type="text"
            class=""
            :class="{
              input: true,
              border: editsecond_name_toast,
            }"
            v-model="secone_name"
            maxlength="10"
            placeholder="请输入，10字内"
            @focus="getfocus(2)"
          />

          <div v-if="editsecond_name_toast" class="error_toast">
            此项不得为空
          </div>
        </div>
        <div class="name">展开图上传</div>
        <div class="requirements">
          <i class="el-icon-warning" style="color: orange"></i>
          <div>请选择10M内的png/jpg/jpeg/gif格式文件，建议比例4:3</div>
        </div>

        <Upload @getMsg="childMsg" ref="upload_child"></Upload>
      </div>
      <div slot="footer" class="dialog-footer">
        <span class="span1" @click="second_cancel">取 消</span>
        <span class="span2" @click="second_save">保 存</span>
      </div>
    </el-dialog>

    <!-- 设置模板 -->
    <el-dialog
      class="seting_model"
      title="设置页面模板"
      :visible.sync="show_setingModel"
      :append-to-body="true"
      :close-on-click-modal="false"
      width="418px"
      @closed="model_cancel"
    >
      <div class="dialog-body">
        <div class="model">
          <div class="left" @click="select_model1">
            <div class="left_picture">
              <img src="./img/model1.png" alt="" />
            </div>
            <div class="left_name">模板 1</div>
            <img
              v-if="show_select == 1"
              class="left_select_img"
              src="./img/select_model.png"
              alt=""
            />
          </div>
          <div class="right" @click="select_model2">
            <div class="right_picture">
              <img src="./img/model2.png" alt="" />
            </div>
            <div class="right_name">模板 2</div>
            <img
              v-if="show_select == 2"
              class="right_select_img"
              src="./img/select_model.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <span class="span1" @click="model_cancel">取 消</span>
        <span class="span2" @click="model_save">保 存</span>
      </div>
    </el-dialog>
    <!-- 项目概况 -->
    <el-dialog
      class="projectOverview"
      title="项目概况"
      :visible.sync="show_projectOverview"
      :append-to-body="true"
      :close-on-click-modal="false"
      width="418px"
      @closed="projectOverview_cancel"
    >
      <div class="dialog-body">
        <div class="title">污水性质</div>
        <input
          :class="{
            projectOverview_input: true,
            projectoverview: true,
            border: projectOverview_input1_toast,
          }"
          maxlength="10"
          placeholder="请输入，10字内"
          type="text"
          v-model="water_quality"
          @focus="getfocus_input_toast(1)"
        />
        <div v-if="projectOverview_input1_toast" class="error_toast">
          此项不得为空
        </div>
        <div class="title">设计水量</div>
        <input
          :class="{
            projectOverview_input: true,
            projectoverview: true,
            border: projectOverview_input2_toast,
          }"
          maxlength="10"
          placeholder="请输入，10字内"
          type="text"
          v-model="water_yield"
          @focus="getfocus_input_toast(2)"
        />
        <div v-if="projectOverview_input2_toast" class="error_toast">
          此项不得为空
        </div>
        <div class="title">处理标准</div>
        <el-input
          type="textarea"
          placeholder="请输入，100字内"
          :class="{
            projectOverview_textarea: true,
            projectoverview: true,
            border: projectOverview_input3_toast,
          }"
          maxlength="100"
          show-word-limit
          resize="none"
          @change="endEmpty(water_standard)"
          v-model="water_standard"
          @focus="getfocus_input_toast(3)"
        >
        </el-input>
        <div v-if="projectOverview_input3_toast" class="error_toast">
          此项不得为空
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <span class="span1" @click="projectOverview_cancel">取 消</span>
        <span class="span2" @click="projectOverview_save">保 存</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Upload from "./upload.vue";
import { userinfo, template1 } from "../../api/getdata"; //引入接口
export default {
  components: {
    Upload,
  },
  data() {
    return {
      a: "",
      show_select: 1, //控制显示地模板
      pro_name: "北京未来科技城再生水厂项目", //项目名称
      uni_code: null, //项目id
      // editall: false, //项目id
      active_item: "", //选中的item
      proj_list_h: [
        "混凝一体设备",
        "调节池调节池调节池调",
        "混凝一体设备",
        "调节池调节池调节池调",
        "混凝一体设备",
      ], //项目图集列表-横向
      proj_list_himg: {}, //横向图片url
      proj_list_zimg: {}, //纵向图片url
      proj_list_z: [
        "混凝一体设备",
        "调节池调节池调节池调",
        "混凝一体设备",
        "调节池调节池调节池调",
        "混凝一体设备",
        "调节池调节池调节池调",
        "混凝一体设备",
        "调节池调节池调节池调",
        "混凝一体设备",
        "调节池调节池调节池调",
      ], //项目图集列表-纵向
      edit_mode: false, //编辑模式
      img_smore: false, //显示项目图集
      file_key: "", //传给后台的图片信息
      file_url: require("./img/Frame1258.png"), //背景图
      rule_min: 0, //PH标尺最小值
      rule_max: 14, //PH标尺最大值
      rule_pointer: [], //PH标尺刻度定位
      rule_pointer_no: [], //PH标尺刻度数字定位
      rule_pointer_relno: [{}, {}], //PH标尺刻度宽度
      ph_screenx: 0, //PH鼠标坐标
      ph_screeny: 0, //PH鼠标坐标
      qua_screenx: 0, //质量指标鼠标坐标
      qua_screeny: 0, //质量指标鼠标坐标
      show_waterQuality: false, //控制进出水弹窗是否显示
      show_projectCost: false, //控制项目成本弹窗是否显示
      show_mainEdit: false, //主图编辑
      show_secondEdit: false, //次图编辑
      show_setingModel: false, //选择模板的
      show_projectOverview: false, //项目概括
      projectOverview_input1_toast: false, //项目概况输入框1错误提示
      projectOverview_input2_toast: false, //项目概况输入框2错误提示
      projectOverview_input3_toast: false, //项目概况输入框3错误提示
      show_ph: 1, //是否显示
      tableList: [{}, {}, {}], //进出水质的数据
      inflow_toast: false, //进出水质错误提示
      tableList2: [], //项目成本的数据1
      tableList3: [{}, {}], //项目成本的数据2
      totalCost_box_toast: false, //建设成本错误提示
      operatingCosts_toast: false, //运营成本错误提示
      totalCost_units1: 1, //选择的单位
      totalCost_units2: 1, //选择的单位
      main_name: "", //主展示图的名称
      editmain_name: "", //编辑框主展示图的名称
      editmain_name_toast: false, //主展示图错误提示
      editsecond_name_toast: false, //次展示图错误提示
      images_detail: {}, //主图信息
      active_img: 0, //当前展示图
      edit_img: {}, //当前编辑图片信息
      crosswise: 0, //横向显示的个数
      lengthways: 0, //纵向显示的个数
      secone_name: "", //  次展开图的名称
      inwater_max: "", //进水上线
      inwater_min: "", //进水下线
      outwater_max: "", //出水上线
      outwater_min: "", //出水下线
      overnum: true, // 指标是否超过九个
      tableList2_overnum: true,
      tableList3_overnum: true,
      pdcost_detail: {}, //建设成本&运营成本
      totalCost: "", //建设总成本
      operatingCosts: "", //运营总成本
      error_toast: 0,
      pd_desc_detail: {}, //项目详情
      water_quality: "", //水性质
      water_yield: "", // 水量
      water_standard: "", //处理标准
    };
  },
  watch: {
    show_mainEdit(newVal, oldVal) {
      if (!newVal) {
        this.editmain_name_toast = false;
        this.editmain_name = "";
        this.crosswise = "不显示";
        this.lengthways = "不显示";
        this.file_key = "";
        // this.listen();
      } else {
        this.$nextTick(() => {
          this.getimagesDetail();
          this.listen();
        });
      }
    },
    show_secondEdit(newVal, oldVal) {
      if (!newVal) {
        this.editsecond_name_toast = false;
        this.secone_name = "";
        this.file_key = "";
        // this.listen();
      } else {
        this.$nextTick(() => {
          this.listen();
        });
      }
    },
    show_projectOverview(newVal, oldVal) {
      if (!newVal) {
        this.projectOverview_input1_toast = false; //项目概况输入框1错误提示
        this.projectOverview_input2_toast = false; //项目概况输入框2错误提示
        this.projectOverview_input3_toast = false; //项目概况输入框3错误提示
      }
    },
    show_projectCost(newVal, oldVal) {
      if (!newVal) {
        this.totalCost_box_toast = false; //建设成本错误提示
        this.operatingCosts_toast = false; //运营成本错误提示
      } else {
        if (this.tableList2.length >= 5) {
          this.tableList2_overnum = false;
        } else {
          this.tableList2_overnum = true;
        }
        if (this.tableList3.length >= 5) {
          this.tableList3_overnum = false;
        } else {
          this.tableList3_overnum = true;
        }
      }
    },
    show_waterQuality(newVal, oldVal) {
      if (!newVal) {
        this.getwaterqOverview();
      } else {
        if (this.tableList.length >= 9) {
          this.overnum = false;
        } else {
          this.overnum = true;
        }
      }
    },
  },
  methods: {
    // 显示ph信息
    showPHinfo(e) {
      if (e.offsetX > 247) {
        this.ph_screenx = e.offsetX - 126 + "px";
      } else {
        this.ph_screenx = e.offsetX + 20 + "px";
      }

      this.ph_screeny = e.offsetY + 20 + "px";
    },
    // 显示质量指标信息
    showQulinfo(e, index) {
      let no_t = index + 1;
      let cut_no = 2;

      if (this.tableList.length > 4) {
        cut_no = 3;
      }
      if (no_t % cut_no === 0) {
        if ((cut_no = 2 && e.offsetX > 70)) {
          this.qua_screenx = e.offsetX - 126 + "px";
        } else if ((cut_no = 3)) {
          this.qua_screenx = e.offsetX - 126 + "px";
        } else {
          this.qua_screenx = e.offsetX + 20 + "px";
        }
      } else {
        this.qua_screenx = e.offsetX + 20 + "px";
      }
      this.qua_screeny = e.offsetY + 20 + "px";
    },

    // 初始化图表
    initChart(item, source, type) {
      let hmInstance = this.$echarts.init(this.$refs[item]);
      let data = source.map((ele) => {
        return { value: ele.cost, name: ele.name };
      });
      let option = {
        tooltip: {
          position: "top",
          borderColor: "transparent",
          borderWidth: 0,
          padding: 0,
          formatter: (params, data) => {
            let count_unit = "万元";
            if (this.totalCost_units2 == 2) {
              count_unit = "亿元";
            }
            if (type === 2) {
              count_unit = "元/吨";
            }

            // rgba(43,130,165,0.55)
            let tipHtml =
              '<div style="border-radius: 4px;background: rgba(5,125,133,0.55);padding:8px;backdrop-filter: blur(100px);font-size:12px;min-width:130px">' +
              '<div style="color:#fff;font-size:12px;text-align: LEFT;margin:0">' +
              params.name +
              "</div>" +
              '<div style="display:flex;justify-content:space-between;"><span>' +
              params.marker +
              params.value +
              count_unit +
              '</span><span style="font-weight: 600;">' +
              params.percent +
              "%</span></div>" +
              "</div>";
            return tipHtml;
          },
        },
        grid: {
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
        },
        color: ["#F85F64", "#129BFF", "#31FBFB", "#FFCF5F", "#00C3C3"],
        series: [
          {
            name: "Access From",
            type: "pie",
            data: data,
            label: {
              color: "rgba(255,255,255,0.65)",
              fontSize: 14,
              formatter: (data) => {
                if (data.name.length > 5) {
                  return data.name.slice(0, 5) + "\n" + data.name.slice(5);
                } else {
                  return data.name;
                }
              },
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      hmInstance.setOption(option);
    },

    // 初始化项目概况
    getprojectOverview() {
      let arg = { uni_code: this.uni_code };
      template1("pdDescDetail", arg).then((ele) => {
        this.pd_desc_detail = ele.data || {};
        this.water_quality = this.pd_desc_detail.sewage;
        this.water_yield = this.pd_desc_detail.water;
        this.water_standard = this.pd_desc_detail.handle;
      });
    },

    // 初始化进出水质详情
    getwaterqOverview() {
      let arg = { uni_code: this.uni_code };
      template1("pdwaterQualityDetail", arg).then((ele) => {
        let arg = ele.data || { ph: [] };
        this.show_ph = arg.show;
        this.tableList = arg.other || [{}];
        this.inwater_min = arg.ph[0]?.min || "";
        this.inwater_max = arg.ph[0]?.max || "";
        this.outwater_min = arg.ph[1]?.min || "";
        this.outwater_max = arg.ph[1]?.max || "";
        if (arg.ph[0]?.min === 0) {
          this.inwater_min = 0;
        }
        if (arg.ph[0]?.max === 0) {
          this.inwater_max = 0;
        }
        if (arg.ph[1]?.min === 0) {
          this.outwater_min = 0;
        }
        if (arg.ph[1]?.max === 0) {
          this.outwater_max = 0;
        }

        //设置ph坐标信息
        this.rule_min = Math.floor(
          this.inwater_min > this.outwater_min
            ? this.outwater_min
            : this.inwater_min
        );
        this.rule_max = Math.ceil(
          this.inwater_max > this.outwater_max
            ? this.inwater_max
            : this.outwater_max
        );
        this.rule_pointer = [this.rule_min];
        for (let i = this.rule_min + 1; i < this.rule_max; i++) {
          this.rule_pointer.push(i);
        }
        this.rule_pointer.push(this.rule_max);
        // this.rule_pointer_no = [this.rule_min,this.rule_min+2,this.rule_max-2,this.rule_max]
        this.rule_pointer_no = [
          Math.floor(this.inwater_min),
          Math.floor(this.outwater_min),
          Math.ceil(this.inwater_max),
          Math.ceil(this.outwater_max),
        ].sort();
        this.rule_pointer_relno = arg.ph || [];
      });
    },

    // 初始化项目成本
    getprojectcostOverview() {
      let arg = { uni_code: this.uni_code };
      template1("pdcostDetail", arg).then((ele) => {
        let data = ele.data || {};
        this.pdcost_detail = JSON.parse(JSON.stringify(data));
        this.tableList2 = data.build_desc || [];
        this.tableList3 = data.operation_desc || [];
        this.totalCost = (data.build_cost || "").toString().substring(0, 5);
        this.totalCost_units1 = data.build_unit || 1;
        this.totalCost_units2 = data.distr_unit || 1;
        this.operatingCosts = (data.operation_cost || "")
          .toString()
          .substring(0, 5);

        // 初始化右侧饼图
        this.initChart("box3charts1", this.tableList2);
        this.initChart("box3charts2", this.tableList3, 2);
      });
    },

    // 初始化项目图集
    getimagesDetail() {
      let arg = { uni_code: this.uni_code };
      template1("imagesDetail", arg).then((ele) => {
        this.images_detail = ele.data || {};
        this.proj_list_h = this.images_detail.x_images || [];
        this.proj_list_z = this.images_detail.y_images || [];
        this.main_name = this.images_detail.name || "";
        this.crosswise = this.images_detail.x_num || 0;
        this.lengthways = this.images_detail.y_num || 0;

        if (this.active_img === 0) {
          this.file_url = this.images_detail.url;
          this.file_key = this.images_detail.url;
          if (this.show_mainEdit) {
            this.listen();
          }
        } else {
          let { xy, item, index } = this.edit_img;
          if (xy === 1) {
            xy = "x";
          } else {
            xy = "y";
          }
          this.changeActiveImg(xy, item, index);
        }
      });
    },

    // 切换当前图片
    changeActiveImg(xy, item, index) {
      if (xy === 0) {
        this.active_img = 0;
        // this.file_url = this.images_detail.url;
        // this.file_key = this.images_detail.url;
        this.getimagesDetail();
      } else {
        let imglist = this.proj_list_himg;
        this.active_item = item;
        if (xy === "y") {
          imglist = this.proj_list_zimg;
        }
        // if (imglist[index]) {
        //   this.file_url = imglist[index];
        //   this.file_key = imglist[index];
        //   this.active_img = xy + index;
        // } else

        let xyin = 1;
        if (xy === "y") {
          xyin = 2;
        }
        let arg = {
          uni_code: this.uni_code,
          index,
          xy: xyin,
        };
        template1("imageDetail", arg).then((ele) => {
          imglist[index] = ele.data?.url || "";
          this.file_url = imglist[index];
          this.file_key = imglist[index];
          this.active_img = xy + index;
        });
      }
    },

    //显示次图编辑框
    showsecondEdit(xy, item, index) {
      let active_img = "x" + index;
      let imglist = this.proj_list_himg;
      if (xy === 2) {
        active_img = "y" + index;
        imglist = this.proj_list_zimg;
      }

      // if (imglist[index]) {
      //   this.file_url = imglist[index];
      //   this.file_key = imglist[index];
      //   this.secone_name = item;
      //   this.edit_img = { item, index, xy };
      //   this.active_img = active_img;
      //   this.show_secondEdit = true;
      // } else {
      let arg = {
        uni_code: this.uni_code,
        index,
        xy,
      };
      template1("imageDetail", arg).then((ele) => {
        this.secone_name = item;
        imglist[index] = ele.data?.url || "";
        this.file_url = imglist[index];
        this.file_key = imglist[index];
        this.edit_img = { item, index, xy };
        this.active_img = active_img;
        this.show_secondEdit = true;
      });
    },

    //显示主图编辑框
    showmainEdit() {
      this.active_img = 0;
      // this.file_url = this.images_detail.url;
      // this.file_key = this.images_detail.url;

      this.editmain_name = this.main_name;
      this.show_mainEdit = true;
    },

    // 计算净化率
    formatWater(item, h) {
      let res = "";
      if ((!item.in && item.in != 0) || (!item.out && item.out != 0)) {
        res = "0";
      } else {
        let val = 1 - item.out / item.in;
        res = Math.round(val * 100);
      }
      if (h) {
        if (res > 90) {
          res = 90;
        }
        if (this.tableList.length < 5) {
          return res + "% - 27px";
        } else {
          return res + "% - 10px";
        }
      } else {
        return res + "%";
      }
    },

    // 计算占比
    formatPercent(item, items) {
      if (!item.cost && item.cost != 0) {
        return "-";
      } else {
        let totl = 0;
        this[items].forEach((ele) => {
          totl += Number(ele.cost);
        });
        let persont = (item.cost / totl).toString().substring(0, 7);

        return (persont * 100).toFixed(2) + "%";
      }
    },

    //退出编辑
    outEdit() {
      location.reload();
      // this.edit_mode = false;
      // this.img_smore = false;
    },
    //关闭页面
    closeWindow() {
      window.close();
    },
    //接受上传图片后 子组件传来的图片信息
    childMsg(value) {
      this.file_key = value;
    },
    listen() {
      this.$refs.upload_child.$emit("childMethod", this.file_url); //触发监听事件
    },

    //  进出水质弹窗事件-------------------------------------------------------------------------------------------------------------
    //点击添加数据
    add_list() {
      this.tableList.push({});
      this.$nextTick(() => {
        let lidv = document.getElementsByClassName("tds")[0]; // 要滚动的元素
        lidv.scrollTop = lidv.scrollHeight;
      });
      if (this.tableList.length >= 9) {
        this.overnum = false;
        return;
      }
    },
    //点击水质删除
    delect_list(index) {
      this.tableList.splice(index, 1);
      if (this.tableList.length < 9) {
        this.overnum = true;
        return;
      }
    },
    //水质取消
    water_cancel() {
      this.show_waterQuality = false;
    },
    //水质保存
    water_save() {
      let elements = document.getElementsByClassName("inflow_input");
      let elements1 = document.getElementsByClassName("table1_input");
      for (let i = 0; i < elements.length; i++) {
        if (elements[i].value === "") {
          elements[i].style = `border: 2px solid rgb(255, 0, 0);`;
          this.$message.error("此项不得为空");
          return;
        } else {
          elements[i].style = "none";
        }

        if (Number(elements[i].value) > 14 || Number(elements[i].value) < 0) {
          elements[i].style = `border: 2px solid rgb(255, 0, 0);`;
          this.$message.error("数值应为0~14之间的最多一位小数");
          return;
        }
        let arr = elements[i].value.split(".");
        console.log(arr);
        console.log(elements[i].value);
        if (arr[1]) {
          if (arr[1].length > 1) {
            elements[i].style = `border: 2px solid rgb(255, 0, 0);`;
            this.$message.error("数值应为0~14之间的最多一位小数");
            return;
          }
        }

        if (Number(elements[0].value) >= Number(elements[1].value)) {
          elements[0].style = `border: 2px solid rgb(255, 0, 0);`;
          this.$message.error("数值范围下限应小于上限");
          return;
        }
        if (Number(elements[2].value) >= Number(elements[3].value)) {
          elements[2].style = `border: 2px solid rgb(255, 0, 0);`;
          this.$message.error("数值范围下限应小于上限");
          return;
        }
      }

      for (let i = 0; i < elements1.length; i++) {
        if (elements1[i].value === "") {
          elements1[i].style = `border: 2px solid rgb(255, 0, 0);`;
          this.$message.error("此项不得为空");
          return;
        } else {
          elements1[i].style = "none";
        }
      }

      if (this.tableList.length < 2) {
        this.$message.error("至少需添加两条数据");
        return;
      }
      // 保存数据
      let other = this.tableList.map((ele) => {
        return {
          name: ele.name,
          unit: ele.unit,
          in: Number(ele.in),
          out: Number(ele.out),
        };
      });

      let arg = {
        uni_code: this.uni_code,
        show: this.show_ph,
        ph: [
          { min: Number(this.inwater_min), max: Number(this.inwater_max) },
          { min: Number(this.outwater_min), max: Number(this.outwater_max) },
        ],
        other,
      };
      template1("pdwaterSualitySave", arg).then((ele) => {
        console.log(ele);
        if (ele.code === 2000) {
          this.$message.success("操作成功");
          this.getwaterqOverview();
          this.show_waterQuality = false;
        } else {
          this.$message.error("操作失败，请稍后重试");
        }
      });
    },

    //项目成本弹窗事件-----------------------------------------------------------------------------------------------------------------
    //项目成本取消
    project_cancel() {
      let data = JSON.parse(JSON.stringify(this.pdcost_detail));
      this.tableList2 = data.build_desc || [];
      this.tableList3 = data.operation_desc || [];
      this.totalCost = (data.build_cost || "").toString().substring(0, 5);
      this.totalCost_units1 = data.build_unit || 1;
      this.totalCost_units2 = data.distr_unit || 1;
      this.operatingCosts = (data.operation_cost || "")
        .toString()
        .substring(0, 5);
      this.show_projectCost = false;
    },
    //项目成本保存
    project_save() {
      // 校验建设成本

      if (this.totalCost == "") {
        this.totalCost_box_toast = true;
        return;
      }

      //校验上面地表格
      let elements3 = document.getElementsByClassName("tableList2_input");
      for (let i = 0; i < elements3.length; i++) {
        if (elements3[i].value === "") {
          elements3[i].style = `border: 2px solid rgb(255, 0, 0);`;
          this.$message.error("此项不得为空");
          return;
        } else {
          elements3[i].style = "none";
        }
        if (Number(elements3[i].value < 0) === 1) {
          elements3[i].style = `border: 2px solid rgb(255, 0, 0);`;
          this.$message.error("此项不得为负");
          return;
        } else {
          elements3[i].style = "none";
        }
      }

      // 校验运营成本
      if (this.operatingCosts == "") {
        this.operatingCosts_toast = true;
        return;
      }
      //校验下面地表格
      let elements4 = document.getElementsByClassName("tableList3_input");
      for (let i = 0; i < elements4.length; i++) {
        if (elements4[i].value === "") {
          elements4[i].style = `border: 2px solid rgb(255, 0, 0);`;
          this.$message.error("此项不得为空");
          return;
        } else {
          elements4[i].style = "none";
        }

        if (Number(elements4[i].value < 0) === 1) {
          elements4[i].style = `border: 2px solid rgb(255, 0, 0);`;
          this.$message.error("此项不得为负");
          return;
        } else {
          elements4[i].style = "none";
        }
      }

      let build_desc = this.tableList2.map((ele) => {
        return {
          name: ele.name,
          cost: Number(ele.cost),
        };
      });
      let operation_desc = this.tableList3.map((ele) => {
        return {
          name: ele.name,
          cost: Number(ele.cost),
        };
      });
      if (build_desc.length === 0 || operation_desc.length === 0) {
        this.$message.error("至少需添加一条数据");
        return;
      }
      let arg = {
        uni_code: this.uni_code,
        build_cost: Number(this.totalCost),
        build_unit: this.totalCost_units1,
        distr_unit: this.totalCost_units2,
        operation_cost: Number(this.operatingCosts),
        build_desc,
        operation_desc,
      };
      console.log(arg);
      template1("pdcostSave", arg).then((ele) => {
        if (ele.code === 2000) {
          this.$message.success("操作成功");
          this.getprojectcostOverview();
          this.show_projectCost = false;
        } else {
          this.$message.error("操作失败，请稍后重试");
        }
      });
    },
    get_totalCost_box_toast(type) {
      if (type == 1) {
        this.totalCost_box_toast = false;
      }
      if (type == 2) {
        this.operatingCosts_toast = false;
      }
    },
    loseblur(event) {
      event.target.style = "none";
    },

    //点击项目成本添加1
    add_list2() {
      this.tableList2.push({ cost: "" });
      this.$nextTick(() => {
        let lidv = document.getElementsByClassName("tds")[0]; // 要滚动的元素
        lidv.scrollTop = lidv.scrollHeight;
      });
      if (this.tableList2.length >= 5) {
        this.tableList2_overnum = false;
        return;
      }
    },
    //点击项目成本删除1
    delect_list2(index) {
      this.tableList2.splice(index, 1);
      if (this.tableList2.length < 5) {
        this.tableList2_overnum = true;
        return;
      }
    },
    //点击项目成本添加2
    add_list3() {
      this.tableList3.push({ cost: "" });
      this.$nextTick(() => {
        let lidv = document.getElementsByClassName("tds")[0]; // 要滚动的元素
        lidv.scrollTop = lidv.scrollHeight;
      });
      if (this.tableList3.length >= 5) {
        this.tableList3_overnum = false;
        return;
      }
    },
    //点击项目成本删除2
    delect_list3(index) {
      this.tableList3.splice(index, 1);
      if (this.tableList3.length < 5) {
        this.tableList3_overnum = true;
        return;
      }
    },

    //主展示图弹窗事件-----------------------------------------------------------------------------------------------------------------
    getfocus(type) {
      if (type == 1) {
        this.editmain_name_toast = false;
      }

      if (type == 2) {
        this.editsecond_name_toast = false;
      }
    },
    //主展示图取消
    main_cancel() {
      // this.getimagesDetail();
      this.show_mainEdit = false;
    },
    //主展示图保存
    main_save() {
      if (this.editmain_name == "") {
        // this.$message.error({
        //   showClose: true,
        //   message: "请输入图片名称",
        // });
        this.editmain_name_toast = true;
        return;
      }

      if (this.file_key == "") {
        this.$message.error({
          showClose: true,
          message: "请上传图片再提交",
        });
        return;
      }
      let arg = {
        uni_code: this.uni_code,
        name: this.editmain_name,
        url: this.file_key,
        x_num: this.crosswise,
        y_num: this.lengthways,
      };
      template1("imagesSave", arg).then((ele) => {
        console.log(ele);
        if (ele.code == 2000) {
          this.$message.success({
            message: "操作成功",
          });
          this.getimagesDetail();
          this.show_mainEdit = false;
        } else {
          this.$message.error({
            showClose: true,
            message: "操作失败，请稍后重试",
          });
        }
      });
    },

    //次展开图弹窗事件-----------------------------------------------------------------------------------------------------------------
    //次展开图取消
    second_cancel() {
      this.show_secondEdit = false;
    },
    //次展开图保存
    second_save() {
      if (this.secone_name == "") {
        this.editsecond_name_toast = true;
        return;
      }
      console.log(this.file_key);
      if (this.file_key == "") {
        this.$message.error({
          showClose: true,
          message: "请上传图片再提交",
        });
        return;
      }
      let arg = {
        uni_code: this.uni_code,
        index: this.edit_img.index,
        xy: this.edit_img.xy,
        name: this.secone_name,
        url: this.file_key,
      };
      template1("saveImage", arg).then((ele) => {
        if (ele.code == 2000) {
          this.$message.success({
            message: "操作成功",
          });
          this.getimagesDetail();
          this.show_secondEdit = false;
        } else {
          this.$message.error({
            showClose: true,
            message: "操作失败，请稍后重试",
          });
        }
      });
    },
    //模板弹窗事件-----------------------------------------------------------------------------------------------------------------

    model_cancel() {
      this.show_select = 1;
      this.show_setingModel = false;
    },
    model_save() {
      let arg = {
        uni_code: this.uni_code,
        template: this.show_select,
      };
      template1("templatesave", arg).then((ele) => {
        if (ele.code === 2000) {
          if (this.show_select == 2) {
            this.$router.push({
              path: "/project",
              query: { uni_code: this.uni_code, name: this.pro_name },
            });
          }

          this.show_setingModel = false;
        } else {
          this.$message.error("操作失败，请稍后重试");
        }
      });
    },
    select_model1() {
      this.show_select = 1;
    },
    select_model2() {
      this.show_select = 2;
    },
    //项目概况弹窗事件-----------------------------------------------------------------------------------------------------------------

    projectOverview_cancel() {
      this.water_quality = this.pd_desc_detail.sewage;
      this.water_yield = this.pd_desc_detail.water;
      this.water_standard = this.pd_desc_detail.handle;
      this.show_projectOverview = false;
    },
    projectOverview_save() {
      let elements = document.getElementsByClassName("projectoverview");
      if (this.water_quality == "") {
        this.projectOverview_input1_toast = true;
        return;
      }
      if (this.water_yield == "") {
        this.projectOverview_input2_toast = true;
        return;
      }

      if (this.water_standard == "") {
        this.projectOverview_input3_toast = true;
        return;
      }

      let arg = {
        uni_code: this.uni_code,
        sewage: this.water_quality,
        water: this.water_yield,
        handle: this.water_standard,
      };
      template1("pddescSave", arg).then((ele) => {
        console.log(ele);
        if (ele.code == 2000) {
          this.$message.success("操作成功");
          this.getprojectOverview();
          this.show_projectOverview = false;
        } else {
          this.$message.error("操作失败，请稍后重试");
        }
      });
    },
    getfocus_input_toast(type) {
      if (type == 1) {
        this.projectOverview_input1_toast = false;
      }
      if (type == 2) {
        this.projectOverview_input2_toast = false;
      }
      if (type == 3) {
        this.projectOverview_input3_toast = false;
      }
    },
  },
  destroyed() {
    document.body.style.backgroundColor = "";
  },
  mounted() {
    document.body.style.backgroundColor = "#FFF";
    this.uni_code = this.$route.query.uni_code;
    this.pro_name = this.$route.query.name;
    userinfo("info").then((ele) => {
      let user_auths = ele.data?.auths || {};
    });
    // let length = this.rule_max - this.rule_min

    // 初始化数据
    // 初始化项目图集
    this.getimagesDetail();
    // --项目概况详情
    this.getprojectOverview();
    // 初始化进出水质详情
    this.getwaterqOverview();
    // 初始化项目成本
    this.getprojectcostOverview();
  },
};
</script>

<style lang="less">
.project-pg2 {
  width: 1920px;
  height: 1080px;
  background-color: #fff;
  display: flex;
  .pg2-left {
    width: 1460px;
    height: 100%;
    position: relative;
    background-size: 100% 100%;
    background-repeat: no-repeat;

    .colose-text {
      position: absolute;
      display: inline-block;
      text-align: center;
      font-size: 14px;
      top: 27px;
      right: 40px;
      width: 72px;
      height: 32px;
      line-height: 32px;
      border-radius: 4px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.65);
    }
    .edit-block {
      position: absolute;
      top: 0;
      right: 0;
      height: 80px;
      width: 80px;
      opacity: 0;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.65);
    }
    .pg2-title {
      margin-top: 24px;
      margin-left: 64px;
      margin-bottom: 19px;
      font-size: 46px;
      font-family: YouSheBiaoTiHei, YouSheBiaoTiHei-Regular;
      color: rgba(0, 0, 0, 0.85);
      height: 60px;
      line-height: 60px;
    }
    .pg2-imgul {
      margin-left: 64px;
      display: flex;
      align-items: flex-start;
      width: max-content;
      overflow: hidden;
      height: 100%;
      position: relative;
      .pg2-imgul-icon {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 36px;
        height: 34px;
        margin-right: 10px;
        background: rgba(255, 255, 255, 1);
        border: 1px solid #0a6ed1;
        color: #6a6d70;
        border-radius: 2px;
        backdrop-filter: blur(4px);
        z-index: 20000;
        cursor: pointer;
        i {
          font-size: 20px;
          cursor: pointer;
        }

        // &:hover {
        //   opacity: 0.85;
        // }
        // &:active {
        //   opacity: 0.95;
        // }
      }

      .pg2-imgli-z {
        position: absolute;
        top: 36px;
        left: 48px;
        overflow: hidden;
        // padding-top: 10px;
        .pg2-imgli-z-box {
          display: flex;
          flex-direction: column;
        }
        .pg2-imgli {
          margin-top: 10px;
        }
      }
    }
    .pg2-imgli {
      width: 147px;
      height: 34px;
      margin-right: 12px;
      // margin-left: 12px;
      line-height: 34px;
      text-align: center;
      padding: 0 10px;
      display: inline-block;
      background: rgba(255, 255, 255, 0.85);
      border: 1px solid #0a6ed1;
      border-radius: 2px;
      backdrop-filter: blur(4px);
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
      cursor: pointer;
      &.active-img {
        background: #0a6ed1;
        color: #fff;
      }
      &.edit-mode.active-img {
        i {
          color: #fff;
        }
      }
      i {
        display: none;
      }
      &.edit-mode {
        padding-right: 41px;
        position: relative;
        i {
          display: block;
          position: absolute;
          right: 0;
          top: 0;
          font-size: 34px;
          color: #6a6d70;
          &:hover {
            color: #2f8cde;
          }
          &:active {
            color: #0050ab;
          }
        }
      }
    }
  }
  .pg2-right {
    width: 450px;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    // padding: 24px;
    box-shadow: -10px 0px 20px 0px rgba(0, 0, 0, 0.08);
    // backdrop-filter: blur(200px);
    background: rgba(255, 255, 255, 0.35);
    // border: 1px solid;
    overflow-y: hidden;
    overflow-x: hidden;
    .box_right {
      height: calc(100% - 24px);
      margin-top: 24px;
      overflow: auto;
        &::-webkit-scrollbar {
      width: 0;
     
    }
      
    }
    .edit-icon {
      width: 31px;
      height: 31px;
      background-image: url("./img/edit_icon.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      display: block;
      position: absolute;
      top: 16px;
      right: 16px;
      cursor: pointer;
      &:hover {
        opacity: 0.85;
      }
      &:active {
        opacity: 0.9;
      }
    }
   

    //滚动条的设置
    &::-webkit-scrollbar-thumb {
      background-color: #dddddd;

      background-clip: padding-box;

      min-height: 28px;
    }
    // &::-webkit-scrollbar {
    //     width: 13px ;
    //   }
    /*定义滚动条的轨道颜色、内阴影及圆角*/
    // &::-webkit-scrollbar-track{
    //   width: 0;
    //   background-color: blue !important;
    //   border-radius: 3px;
    // }
    /*定义滑块颜色、内阴影及圆角*/
    &::-webkit-scrollbar-thumb {
      border-radius: 7px;
      width: 2px;
      margin-right: 20px;
      height: 140px;
      background-color: #000;
    }
    .pg2r-box1 {
      width: 412px;
      overflow: hidden;
      padding-bottom: 10px;
      background: #424242;
      border-radius: 10px;
      margin-bottom: 10px;
      // margin-top: 24px;
      position: relative;
      // bgimgl1.png
      .pg2r1-title {
        font-size: 14px;
        height: 20px;
        line-height: 20px;
        color: rgba(255, 255, 255, 0.65);
        margin: 20px 20px 6px 20px;
      }
      .pg2r1-type1 {
        margin-left: 20px;
        height: 28px;
        font-size: 20px;
        color: #ffffff;
      }
      .pg2r1-box1 {
        width: 372px;
        // height: 124px;
        background: rgba(255, 255, 255, 0.07);
        border-radius: 4px;
        margin: 0 auto;
        padding: 10px;
        font-size: 14px;
        color: #ffffff;
        line-height: 20px;
        .pg2r1-box1-text {
          overflow: auto;
          overflow-y: auto;
          white-space: pre-wrap;
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          line-height: 20px;
        }
        pre {
          margin: 1em 0 0;
        }
      }
      .pg2r-box1-bg {
        height: 220px;
        width: 319px;
        position: absolute;
        right: 0;
        background-image: url("./img/bgimgr1.svg");
      }
    }
    .pg2r-box2 {
      width: 412px;
      min-height: 338px;
       overflow: hidden;
      background: #424242;
      border-radius: 10px;
      margin-bottom: 10px;
      position: relative;
      .div-mask {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10000;
        span {
          color: #8c8c8c;
          position: relative;
          cursor: default;
        }
      }
      .pg2r2-text {
        height: 20px;
        font-size: 14px;
        color: #ffffff;
        line-height: 20px;
        margin: 20px;
      }
      .pg2r2-ph {
        width: 341px;
        height: 48px;
        font-size: 12px;
        padding: 0 22px 0 10px;
        border: 1px solid #606060;
        border-radius: 4px;
        margin: 0 auto;
        margin-bottom: 18px;
        color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        // cursor: pointer;
        .rule-mask {
          height: 100%;
          width: 100%;
          position: absolute;
          // cursor: pointer;
          z-index: 1000;
        }
        .pg2r2-ph-info {
          position: absolute;
          width: 110px;
          padding: 8px;
          opacity: 0;
          pointer-events: none;
          div {
            height: 20px;
            font-size: 12px;
            color: rgba(255, 255, 255, 0.85);
            line-height: 20px;
          }
        }
        .div-rule {
          width: 198px;
          height: 1px;
          background: #fff;
          position: relative;
          z-index: 101;
          // pointer-events: none;

          &::before {
            content: "";
            display: block;
            position: absolute;
            height: 5px;
            width: 1px;
            top: -5px;
            background-color: #fff;
          }
          &::after {
            content: "";
            display: block;
            position: absolute;
            height: 5px;
            width: 1px;
            top: -5px;
            right: 0;
            background-color: #fff;
          }
          .div-rule-line {
            position: relative;
            top: -12px;
            opacity: 0.8;
            div {
              position: absolute;
            }
          }
          .in-line {
            height: 6px;
            background-color: #bd4e44;
            top: 5px;
            z-index: 100;
          }
          .out-line {
            height: 6px;
            top: -3px;
            background-color: #159afb;
          }
          .div-rule-pointer {
            display: flex;
            // font-size: 13px;
            // margin-top: 2px;
            justify-content: space-between;
            position: relative;
            top: -5px;
            z-index: 101;
            span {
              display: block;
              height: 5px;
              width: 1px;
              top: -5px;
              left: 0;
              background-color: #fff;
            }
          }
          .div-rule-pointer_no {
            position: relative;
            font-size: 12px;
            margin-top: -4px;
            width: 97%;
            span {
              position: absolute;
            }
          }
          // background-color: rgba(255,255,255,0.65);
          // border: 3px solid rgba(255,255,255,0.65);;
        }
        &:hover {
          background: rgba(255, 255, 255, 0.1);
          .pg2r2-ph-info {
            transition: opacity 1s;
            opacity: 1;
          }
        }
        .red-doll {
          height: 6px;
          width: 6px;
          display: inline-block;
          background: #bd4e44;
          border-radius: 50%;
          vertical-align: middle;
          margin-right: 3px;
        }
        .blue-doll {
          height: 6px;
          width: 6px;
          display: inline-block;
          background: #129bff;
          border-radius: 50%;
          vertical-align: middle;
          margin-right: 3px;
        }
      }
      .pg2r2-ot1 {
        width: 117px;
        height: 156px;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 4px;
      }
      .pg2r2-ot2 {
        width: 117px;
        height: 156px;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 4px;
      }
      .pg2r2-ot3 {
        width: 117px;
        height: 156px;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 4px;
      }
      .pg2r2-quality {
        padding: 0 20px 20px 20px;
        line-height: 0;
        .charts-div {
          position: relative;
          display: flex;
          flex-direction: column-reverse;
          line-height: 0;
          background: radial-gradient(rgba(42, 61, 70, 0), #385666);
          .charts-div-info {
            position: absolute;
            width: 100%;
            text-align: center;
            color: rgba(255, 255, 255, 0.85);
            z-index: 1;
            .charts-div-infot {
              color: rgba(255, 255, 255, 0.65);
            }
          }
          .pg2r2-charts-content {
            width: 100%;
            background: linear-gradient(90deg, #129bff 0%, #31fbfb 100%);
            z-index: 1;
          }
          .charts-move {
            background-image: url("./img/Group1196.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            margin-bottom: -2px;
            z-index: 1;
          }
          &::before {
            content: "";
            display: block;
            position: absolute;
            background-image: url("./img/Vector320.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            color: #8c8c8c;
            text-align: end;
          }
          &::after {
            content: "出";
            display: block;
            position: absolute;
            background-image: url("./img/Vector321.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            color: #8c8c8c;
            z-index: 1;
            line-height: 1;
          }
        }
        .charts-info {
          width: 100%;
          text-align: center;
          color: rgba(255, 255, 255, 0.85);
        }
        .pg2r2-qua-info {
          position: absolute;
          min-width: 129px;
          max-width: 160px;
          overflow: hidden;
          padding: 8px;
          opacity: 0;
          pointer-events: none;
          div {
            height: 20px;
            font-size: 12px;
            color: rgba(255, 255, 255, 0.85);
            line-height: 20px;
          }
        }
        .pg2r2-q-listle4,
        .pg2r2-q-listgt4 {
          position: relative;
          &:hover {
            background: rgba(255, 255, 255, 0.1);
            .pg2r2-qua-info {
              opacity: 1;
            }
          }
        }
        .pg2r2-q-listle4 {
          display: inline-block;
          width: 181px;
          height: 241px;
          border-radius: 6px;
          font-size: 19px;
          .div-mask {
            span {
              top: 34px;
              left: 28px;
            }
          }
          .charts-div-info {
            // top: 47px;
            top: 56px;
            .charts-div-infot {
              height: 19px;
              margin-bottom: 6px;
            }
          }
          .charts-div {
            width: 152px;
            height: 152px;
            margin: 12px 17px 12px 12px;
            border-radius: 19px;
            .pg2r2-charts-content {
              border-bottom-right-radius: 19px;
              border-bottom-left-radius: 19px;
            }
            .charts-move {
              width: 100%;
              height: 27px;
            }
            &::before {
              width: 42px;
              height: 116px;
              top: 18px;
              left: -7px;
              background-size: 18px 116px;
            }
            &::after {
              width: 38px;
              height: 15px;
              bottom: 15px;
              right: -11px;
              background-size: 12px 12px;
              background-position: right;
            }
          }
          .charts-info {
            line-height: 26px;
          }
          .charts-info-t {
            height: 26px;
            margin-top: 12px;
          }
          &:nth-child(2n) {
            margin-left: 10px;
          }
        }
        .pg2r2-q-listgt4 {
          display: inline-block;
          width: 117px;
          height: 156px;
          border-radius: 4px;
          font-size: 12px;
          .div-mask {
            span {
              top: 20px;
              left: 18px;
            }
          }
          .charts-div-info {
            top: 32px;
            .charts-div-infot {
              height: 13px;
              margin-bottom: 4px;
            }
          }
          .charts-div {
            width: 98px;
            height: 98px;
            margin: 8px 11px 8px 8px;
            border-radius: 12px;
            .pg2r2-charts-content {
              border-bottom-right-radius: 12px;
              border-bottom-left-radius: 12px;
            }
            .charts-move {
              width: 100%;
              height: 10px;
            }
            &::before {
              width: 28px;
              height: 78px;
              top: 12px;
              left: -6px;
              background-size: 12px 78px;
              line-height: 4px;
            }
            &::after {
              width: 27px;
              height: 15px;
              bottom: 4px;
              right: -8px;
              background-size: 9px 9px;
              background-position: right;
            }
          }
          .charts-info {
            line-height: 17px;
          }
          .charts-info-t {
            height: 17px;
            margin-top: 8px;
          }
          &:not(:nth-child(3n)) {
            margin-right: 10px;
          }
        }
      }
    }
    .pg2r-box3 {
      width: 412px;
      height: 744px;
       overflow: hidden;
      background: #424242;
      border-radius: 10px;
      margin-bottom: 20px;
      text-align: center;
      position: relative;
      .pg2r-box3-t {
        font-size: 14px;
        color: #fff;
        height: 20px;
        margin: 20px;
        display: flex;
        justify-content: space-between;
        span {
          flex: 1;
        }
      }
      .pg2r-box3-tno {
        display: flex;
        justify-content: space-between;
        margin: 20px;
        .pg2r-box3-tno-bk {
          width: 181px;
          height: 46px;
          font-size: 12px;
          display: flex;
          align-items: flex-end;
          padding-bottom: 14px;
          justify-content: right;
          background: #424242;
          box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.3),
            -10px -10px 20px 0px rgba(103, 103, 103, 0.35),
            -1px -1px 0px 0px rgba(113, 113, 113, 0.35);
          span {
            display: inline-block;
            width: 24px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            background: #129bff;
            font-size: 24px;
            font-weight: 700;
            color: #fff;
            font-family: DIN Alternate, DIN Alternate-Bold;
            &:not(:first-child) {
              margin-left: 4px;
            }
            &:last-child {
              margin-right: 2px;
            }
          }
          &:last-child {
            span {
              background-color: #bd4e44;
            }
          }
        }
      }
      .dash-line {
        width: 100%;
        margin: 15px 0;
        border-bottom: 2px dashed #717171;
      }
      .pg2r-box3-charts {
        width: 372px;
        margin: 0 auto;
        color: #fff;
        font-size: 14px;
        #pg2r-box3-charts1,
        #pg2r-box3-charts2 {
          height: 248px;
          width: 372px;
        }
      }
    }
  }
  .text-btn {
    cursor: pointer;
    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
    &:active {
      background: rgba(0, 0, 0, 0.4);
    }
  }

  .scrollw2px {
    &::-webkit-scrollbar {
      width: 2px;
      height: 2px;
    }
    //滚动条的设置
    &::-webkit-scrollbar-thumb {
      background-color: #dddddd;
      background-clip: padding-box;
      min-height: 28px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 7px;
      width: 2px;
      margin-right: 20px;
      height: 20px;
      background-color: #000;
    }
  }

  .chart-hover-bg {
    background: rgba(5, 125, 133, 0.55);
    border-radius: 4px;
    backdrop-filter: blur(100px);
    z-index: 10001;
  }
  .slide-fade-slow-enter-active {
    // width: 0;
    opacity: 0;
    transition: all 0.2s;
  }
  .slide-fade-slow-enter,
  .slide-fade-slow-leave-to {
    // width: 0;
    opacity: 0;
  }
  .slide-fade-slow-leave-active {
    width: 0;
    opacity: 0;
    transition: all 0.2s cubic-bezier(0.42, 0, 0.58, 1);
  }

  .slide-fade-leave-active,
  .slide-fade-enter-active {
    transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
  }
  .slide-fade-enter,
  .slide-fade-leave-to {
    transform: translateX(-100%);
  }
  .slide-down-fade-leave-active,
  .slide-down-fade-enter-active {
    transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
  }
  .slide-down-fade-enter,
  .slide-down-fade-leave-to {
    transform: translateY(-100%);
  }
}
</style>
<style lang="less">
// 进出水质的样式-------------------------------------------------------------------------------------
.waterQuality {
  box-sizing: border-box;
  input {
    outline-color: #0a6ed1;
    color: rgba(0, 0, 0, 0.65);
  }
  /* Chrome浏览器 */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  /* Firefox浏览器 */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  .el-dialog {
    .el-dialog__header {
      padding: 16px 24px;
      .el-dialog__close {
        color: #909399;
        &:hover {
          color: #409eff;
        }
      }
    }
    .el-dialog__body {
      padding: 0;
      .dialog-body {
        padding: 0 60px;
        // min-height: 730px;
        overflow: auto;
        .title {
          height: 20px;
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 600;
          text-align: LEFT;
          color: rgba(0, 0, 0, 0.85);
          line-height: 20px;
          padding-bottom: 8px;
          border-bottom: 2px solid #d8d9da;
          margin-bottom: 20px;
        }
        .show {
          display: flex;
          // margin-top: 24px;
          margin-bottom: 24px;

          .show_text {
            height: 20px;
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            text-align: RIGHT;
            color: rgba(0, 0, 0, 0.85);
            line-height: 20px;
            margin-right: 24px;
          }
          .el-radio-group {
            span.el-radio__input.is-checked + .el-radio__label {
              color: rgba(0, 0, 0, 0.85);
            }
            .el-radio {
              height: 20px;
              font-size: 14px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              text-align: RIGHT;
              color: rgba(0, 0, 0, 0.85);
              line-height: 20px;
            }
            .el-radio__inner {
              width: 20px;
              height: 20px;
            }
            .el-radio__inner::after {
              width: 10px;
              height: 10px;
            }
          }
        }
        .inflow {
          display: flex;
          align-items: center;
          justify-content: space-around;
          width: 598px;
          height: 48px;
          opacity: 1;
          border: 1px solid rgba(106, 109, 112, 0.45);
          border-radius: 2px;
          margin-left: 84px;
          margin-bottom: 24px;
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;

          color: rgba(0, 0, 0, 0.65);
          input {
            width: 90px;
            height: 28px;
            box-sizing: border-box;
            // outline-color: #0a6ed1;
            border: 1px solid #cccccc;
            border-radius: 2px;
            padding: 0 12px;
          }

          .inflow_left {
            display: flex;
            align-items: center;
            i {
              width: 12px;
              height: 12px;
              margin: 0 2px;
            }
            .span1 {
              margin-left: 2px;
            }
            .span2 {
              display: inline-block;
              width: 20px;
              height: 20px;
              line-height: 20px;
              text-align: center;
            }
            .input1 {
              margin-left: 24px;
            }
            .border {
              border: 2px solid red;
            }
          }
          .inflow_right {
            display: flex;
            align-items: center;
            i {
              width: 12px;
              height: 12px;
              margin: 0 2px;
            }
            .span1 {
              margin-left: 2px;
            }
            .span2 {
              display: inline-block;
              width: 20px;
              height: 20px;
              line-height: 20px;
              text-align: center;
            }
            .input1 {
              margin-left: 24px;
            }
            .border {
              border: 2px solid red;
            }
          }
        }
        .table {
          input {
            outline-color: #c8c8c8;
          }

          .th {
            display: flex;
            // justify-content: space-between;

            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;

            color: #ffffff;
            line-height: 20px;
            > div {
              // flex-grow:1;
              height: 28px;
              line-height: 28px;
              background: rgba(10, 110, 209, 0.65);
              text-align: center;
              box-sizing: border-box;
              border-bottom: 1px solid #fff;
            }
            > div:nth-child(2n + 2) {
              border-left: 2px solid #fff;
              border-right: 2px solid #fff;
            }
            .th_1 {
              width: 50px;
            }
            .th_2 {
              width: 160px;
            }
            .th_3 {
              width: 110px;
            }
            .th_4 {
              width: 100px;
            }
            .th_5 {
              width: 120px;
            }
            .th_6 {
              width: 100px;
            }
            .th_7 {
              width: 70px;
            }
          }
          .tds {
            max-height: 160px;
            overflow: auto;
            &::-webkit-scrollbar {
              width: 0;
            }
            .td {
              display: flex;
              // justify-content: space-between;

              font-size: 14px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;

              > div {
                position: relative;

                height: 32px;
                line-height: 32px;
                background: #f3f3f3;
                text-align: center;
                box-sizing: border-box;
                border-bottom: 2px solid #fff;

                input {
                  width: 100%;
                  height: 100%;
                  padding: 0;
                  margin: 0;
                  border: none;

                  background: #f3f3f3;
                  color: rgba(0, 0, 0, 0.65);
                  font-size: 14px;
                  font-family: PingFang HK, PingFang HK-Medium;
                  font-weight: 400;
                  box-sizing: border-box;
                  // outline-color: #0a6ed1;
                  padding: 0 4px;
                  text-align: center;
                }
                input:focus {
                  background-color: #fff;
                }
              }
              > div:nth-child(2n + 2) {
                border-left: 2px solid #fff;
                border-right: 2px solid #fff;
              }
              .td_1 {
                width: 50px;
              }
              .td_2 {
                width: 160px;
              }
              .td_3 {
                width: 110px;
              }
              .td_4 {
                width: 100px;
                // overflow: hidden;
              }
              .td_5 {
                width: 120px;
              }
              .td_6 {
                width: 100px;
              }
              .td_7 {
                width: 70px;
                img {
                  width: 24px;
                  height: 24px;
                  margin-top: 4px;
                  cursor: pointer;
                }
              }
            }
          }
          .nodata {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 710px;
            height: 113px;

            background: #f3f3f3;
            img {
              width: 64px;
              height: 40px;
            }
            .text {
              width: 178px;
              height: 22px;
              opacity: 1;
              font-size: 14px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              text-align: CENTER;
              color: rgba(0, 0, 0, 0.25);
              line-height: 22px;
              span {
                color: #0a6ed1;
                cursor: pointer;
              }
            }
          }
        }
        .table_add {
          height: 22px;
          width: 82px;
          opacity: 1;

          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;

          color: #0a6ed1;
          line-height: 22px;
          margin-top: 13px;
          cursor: pointer;
        }
      }
    }
    .el-dialog__footer {
      padding: 0;
      .dialog-footer {
        width: 830px;
        height: 64px;
        text-align: center;
        overflow: hidden;

        // margin-top: 21px;
        span {
          display: inline-block;
          width: 65px;
          height: 32px;
          margin-top: 16px;
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: CENTER;
          color: rgba(0, 0, 0, 0.85);
          line-height: 32px;

          border-radius: 2px;
          background: rgba(0, 0, 0, 0.2);
          cursor: pointer;
        }
        .span1:hover {
        }
        .span1:active {
        }
        .span2 {
          background: #0a6ed1;
          color: #fff;
          margin-left: 8px;
        }
        .span2:hover {
        }
        .span2:active {
        }
      }
    }
  }
}

//项目成本的样式----------------------------------------------------------------------------------------
.projectCost {
  box-sizing: border-box;
  input {
    outline-color: #0a6ed1;
  }
  /* Chrome浏览器 */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  /* Firefox浏览器 */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  .el-dialog {
    .el-dialog__header {
      padding: 16px 24px;
      .el-dialog__close {
        color: #909399;
        &:hover {
          color: #409eff;
        }
      }
    }
    .el-dialog__body {
      padding: 0;
      .dialog-body {
        padding: 0 60px;
        .title {
          height: 20px;
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 600;
          text-align: LEFT;
          color: rgba(0, 0, 0, 0.85);
          line-height: 20px;
          padding-bottom: 8px;
          border-bottom: 2px solid #d8d9da;
          margin-bottom: 20px;
        }
        .totalCost {
          position: relative;
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          box-sizing: border-box;

          .totalCost_text {
            width: 46px;
            height: 20px;
            opacity: 1;

            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            text-align: RIGHT;
            color: rgba(0, 0, 0, 0.85);
            line-height: 20px;
            margin-right: 24px;
          }
          .totalCost_box {
            display: flex;
            align-items: center;
            width: 251px;
            height: 28px;
            opacity: 1;
            overflow: hidden;
            box-sizing: border-box;
            // border: 1px solid #c8c8c8;
            background-color: #f3f3f3;
            border-radius: 2px;
            .totalCost_input {
              box-sizing: border-box;
              width: 160px;
              height: 24px;
              border: none;

              margin-right: 1px;
              margin-left: 2px;

              padding: 0 10px;
            }
            .border {
              border: 2px solid red;
            }
            .totalCost_select {
              width: 87px;
              height: 28px;
              background-color: #f3f3f3;

              .el-input__inner {
                height: 28px;
                border: none;
                text-align: center;
                background-color: #f3f3f3;
              }
            }
          }
          .totalCost_toast {
            position: absolute;
            bottom: -20px;
            left: 72px;
            z-index: 10;
            height: 20px;
            line-height: 20px;
            padding: 0 10px;
            font-size: 12px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            color: red;
          }
        }
        .table_tit {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;
          .right {
            .totalCost_select {
              width: 87px;
              height: 28px;
              border: 1px solid #c8c8c8;
              border-radius: 2px;
              .el-input__inner {
                height: 28px;
                border: none;
                text-align: center;
                // background-color: #f3f3f3;
              }
            }
          }
        }
        .table {
          .th {
            display: flex;
            // justify-content: space-between;

            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;

            color: #ffffff;
            line-height: 20px;
            > div {
              // flex-grow:1;
              height: 28px;
              line-height: 28px;
              background: rgba(10, 110, 209, 0.65);
              text-align: center;
              box-sizing: border-box;
            }
            > div:nth-child(2n + 2) {
              border-left: 2px solid #fff;
              border-right: 2px solid #fff;
            }
            .th_1 {
              width: 60px;
            }
            .th_2 {
              width: 140px;
            }
            .th_3 {
              width: 130px;
            }
            .th_4 {
              width: 92px;
              overflow: hidden;
            }
            .th_5 {
              width: 82px;
            }
          }
          .tds {
            max-height: 160px;
            overflow: auto;
            &::-webkit-scrollbar {
              width: 0;
            }
            .td {
              display: flex;
              // justify-content: space-between;

              font-size: 14px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              color: #606266;

              > div {
                position: relative;

                height: 32px;
                line-height: 32px;
                background: #f3f3f3;
                text-align: center;
                box-sizing: border-box;
                border-bottom: 2px solid #fff;

                input {
                  width: 99%;
                  height: 100%;
                  padding: 0;
                  margin: 0;
                  border: none;

                  background: #f3f3f3;
                color: #606266;
                  font-size: 14px;
                  font-family: PingFang HK, PingFang HK-Medium;
                  font-weight: 400;
                  box-sizing: border-box;
                  outline-color: #c8c8c8;
                  // padding: 0 4px;
                  text-align: center;
                }
                input:focus {
                  background-color: #fff;
                }
              }
              > div:nth-child(2n + 2) {
                border-left: 2px solid #fff;
                border-right: 2px solid #fff;
              }
              .td_1 {
                width: 60px;
              }
              .td_2 {
                width: 140px;
                input {
                  font-size: 13px;
                }
              }
              .td_3 {
                width: 130px;
              }
              .td_4 {
                width: 92px;
                overflow: hidden;
              }

              .td_5 {
                width: 82px;
                img {
                  width: 24px;
                  height: 24px;
                  margin-top: 4px;
                  cursor: pointer;
                }
              }
            }
          }
          .nodata {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 504px;
            height: 113px;
            margin-bottom: 24px;

            background: #f3f3f3;
            img {
              width: 64px;
              height: 40px;
            }
            .text {
              width: 178px;
              height: 22px;
              opacity: 1;
              font-size: 14px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              text-align: CENTER;
              color: rgba(0, 0, 0, 0.25);
              line-height: 22px;
              span {
                color: #0a6ed1;
                cursor: pointer;
                &:hover {
                  color: #2f8cde;
                }
              }
            }
          }
        }
        .table_add {
          height: 22px;
          width: 82px;
          opacity: 1;
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #0a6ed1;
          line-height: 22px;
          margin-top: 13px;
          cursor: pointer;
          margin-bottom: 24px;
          &:hover {
            color: #2f8cde;
          }
        }
        .operatingCosts {
          position: relative;
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;

          color: rgba(0, 0, 0, 0.85);
          .operatingCosts_text {
            width: 46px;
            height: 20px;
            opacity: 1;

            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            text-align: RIGHT;
            color: rgba(0, 0, 0, 0.85);
            line-height: 20px;
            margin-right: 24px;
          }
          input {
            width: 247px;
            height: 28px;
            opacity: 1;
            background: rgba(255, 255, 255, 0.4);
            border: 1px solid #c8c8c8;
            border-radius: 2px;
            padding: 0 10px;
            box-sizing: border-box;
          }
          .border {
            border: 2px solid red;
          }
          .right {
            margin-left: 8px;
          }
          .operatingCosts_toast {
            position: absolute;
            bottom: -20px;
            left: 72px;
            z-index: 10;
            height: 20px;
            line-height: 20px;
            padding: 0 10px;
            font-size: 12px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            color: red;
          }
        }
        .table_unit {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
        }
      }
    }
    .el-dialog__footer {
      padding: 0;
      .dialog-footer {
        width: 100%;
        height: 64px;
        text-align: center;
        overflow: hidden;

        // margin-top: 21px;
        span {
          display: inline-block;
          width: 65px;
          height: 32px;
          margin-top: 16px;
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: CENTER;
          color: rgba(0, 0, 0, 0.85);
          line-height: 32px;

          border-radius: 2px;
          background: rgba(0, 0, 0, 0.2);
          cursor: pointer;
        }
        .span1:hover {
        }
        .span1:active {
        }
        .span2 {
          background: #0a6ed1;
          color: #fff;
          margin-left: 8px;
        }
        .span2:hover {
        }
        .span2:active {
        }
      }
    }
  }
}

//主展示图的样式-------------------------------------------------------------------------------------------
.mainEdit {
  box-sizing: border-box;
  input {
    // outline-color: #c8c8c8;
  }
  .el-dialog {
    .el-dialog__header {
      padding: 16px 24px;
      .el-dialog__close {
        color: #909399;
        &:hover {
          color: #409eff;
        }
      }
    }
    .el-dialog__body {
      padding: 0;
      .dialog-body {
        padding: 0 24px;
        .photo_name {
          display: flex;
          justify-content: space-between;
          height: 57px;
          .left {
            width: 272px;
            height: 54px;
            // box-sizing: border-box;

            .top {
              width: 70px;
              height: 20px;
              opacity: 1;
              font-size: 14px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              text-align: LEFT;
              color: rgba(0, 0, 0, 0.85);
              line-height: 20px;
              margin-bottom: 4px;
            }
            .bottom {
              width: 272px;
              height: 28px;
              box-sizing: border-box;
              font-size: 14px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              text-align: LEFT;
              color: rgba(0, 0, 0, 0.65);
              border: 1px solid #c8c8c8;
              border-radius: 2px;
              padding: 0 10px;
              margin-bottom: 20px;
              outline-color: #0a6ed1;
            }
            .border {
              border: 2px solid red;
            }
            .error_toast {
              height: 20px;
              line-height: 20px;
              padding: 0 10px;
              font-size: 12px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              color: red;
            }
          }
          .right {
            width: 272px;
            height: 54px;
            box-sizing: border-box;
            .top_r {
              width: 70px;
              height: 20px;
              opacity: 1;
              font-size: 14px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              text-align: LEFT;
              color: rgba(0, 0, 0, 0.85);
              line-height: 20px;
              margin-bottom: 4px;
            }
            .bottom_r {
              display: flex;

              justify-content: space-between;
              .bottom_r_left {
                display: flex;
                align-items: center;
                > span {
                  margin-right: 10px;
                }
                .name_select {
                  width: 83px;
                  height: 28px;
                  border: 1px solid #c8c8c8;
                  border-radius: 2px;

                  .el-input__inner {
                    height: 28px;
                    border: none;
                    text-align: left;
                  }
                }
              }
              .bottom_r_right {
                display: flex;
                align-items: center;
                > span {
                  margin-right: 10px;
                }
                .name_select {
                  width: 83px;
                  height: 28px;
                  border: 1px solid #c8c8c8;
                  border-radius: 2px;

                  .el-input__inner {
                    height: 28px;
                    border: none;
                    text-align: left;
                  }
                }
              }
            }
          }
        }
        .name {
          opacity: 1;

          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: rgba(0, 0, 0, 0.85);
          line-height: 20px;
          margin-top: 20px;
          margin-bottom: 10px;
        }
        .requirements {
          width: 576px;
          height: 36px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          opacity: 1;
          background: #fffbe6;
          border-radius: 2px;
          margin-bottom: 10px;

          padding: 0 16px;
          > div {
            height: 36px;
            line-height: 36px;

            opacity: 1;

            font-size: 12px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            text-align: LEFT;
            color: rgba(0, 0, 0, 0.85);
            margin-left: 8px;
          }
        }
      }
    }
    .el-dialog__footer {
      padding: 0;
      .dialog-footer {
        width: 100%;
        height: 64px;
        text-align: center;
        overflow: hidden;

        // margin-top: 21px;
        span {
          display: inline-block;
          width: 65px;
          height: 32px;
          margin-top: 16px;
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: CENTER;
          color: rgba(0, 0, 0, 0.85);
          line-height: 32px;

          border-radius: 2px;
          background: rgba(0, 0, 0, 0.2);
          cursor: pointer;
        }
        .span1:hover {
        }
        .span1:active {
        }
        .span2 {
          background: #0a6ed1;
          color: #fff;
          margin-left: 8px;
        }
        .span2:hover {
        }
        .span2:active {
        }
      }
    }
  }
}
//展开图的样式-----------------------------------------------------------------------------------------
.secondEdit {
  box-sizing: border-box;
  input {
    // outline-color: #c8c8c8;
  }
  .el-dialog {
    .el-dialog__header {
      padding: 16px 24px;
      .el-dialog__close {
        color: #909399;
        &:hover {
          color: #409eff;
        }
      }
    }
    .el-dialog__body {
      padding: 0;
      .dialog-body {
        padding: 0 24px;
        .photo {
          position: relative;
          > div {
            width: 63px;
            height: 20px;
            opacity: 1;

            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            text-align: LEFT;
            color: rgba(0, 0, 0, 0.85);
            line-height: 20px;
            margin-bottom: 4px;
          }
          .input {
            width: 100%;
            height: 28px;
            box-sizing: border-box;
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            text-align: LEFT;
            color: rgba(0, 0, 0, 0.65);
            border: 1px solid #c8c8c8;
            border-radius: 2px;
            padding: 0 10px;
            margin-bottom: 20px;
            outline-color: #0a6ed1;
          }
          .border {
            border: 2px solid red;
          }

          .error_toast {
            position: absolute;
            top: 54px;
            height: 20px;
            width: 100px;
            line-height: 20px;
            padding: 0 10px;
            font-size: 12px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            color: red;
          }
        }

        .name {
          opacity: 1;

          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: rgba(0, 0, 0, 0.85);
          line-height: 20px;
          // margin-top: 20px;
          margin-bottom: 10px;
        }
        .requirements {
          width: 100%;
          height: 36px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          opacity: 1;
          background: #fffbe6;
          border-radius: 2px;
          margin-bottom: 10px;

          padding: 0 16px;
          > div {
            height: 36px;
            line-height: 36px;

            opacity: 1;

            font-size: 12px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            text-align: LEFT;
            color: rgba(0, 0, 0, 0.85);
            margin-left: 8px;
          }
        }
      }

     
    }
    .el-dialog__footer {
      padding: 0;
      .dialog-footer {
        width: 100%;
        height: 64px;
        text-align: center;
        overflow: hidden;

        // margin-top: 21px;
        span {
          display: inline-block;
          width: 65px;
          height: 32px;
          margin-top: 16px;
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: CENTER;
          color: rgba(0, 0, 0, 0.85);
          line-height: 32px;

          border-radius: 2px;
          background: rgba(0, 0, 0, 0.2);
          cursor: pointer;
        }
        .span1:hover {
        }
        .span1:active {
        }
        .span2 {
          background: #0a6ed1;
          color: #fff;
          margin-left: 8px;
        }
        .span2:hover {
        }
        .span2:active {
        }
      }
    }
  }
}
//设置模板------------------------------------------------------------------------------------------------------------
.seting_model {
  box-sizing: border-box;
  .el-dialog {
    .el-dialog__header {
      padding: 16px 24px;
      .el-dialog__close {
        color: #909399;
        &:hover {
          color: #409eff;
        }
      }
    }

    .el-dialog__body {
      padding: 0;
      .dialog-body {
        padding: 0 24px;
        .model {
          width: 100%;
          display: flex;
          justify-content: space-between;
          > div {
            width: 177px;
            height: 160px;
            opacity: 1;
            background: #ffffff;
            border-radius: 6px;
            overflow: hidden;
            box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
          }
          .left {
            box-sizing: border-box;
            position: relative;
            &:hover {
              border: 2px solid #129bff;
            }
            .left_picture {
              width: 100%;
              height: 104px;
              box-sizing: border-box;
              padding: 10px 8px 4px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .left_name {
              height: 56px;
              line-height: 56px;
              background: #f8f9fa;
              box-sizing: border-box;
              padding-left: 10px;
              font-size: 16px;
              font-family: PingFang SC, PingFang SC-Medium;
              font-weight: 600;
              text-align: LEFT;
              color: rgba(0, 0, 0, 0.85);
            }
            .left_select_img {
              width: 30px;
              height: 30px;
              position: absolute;
              bottom: -2px;
              right: -2px;
            }
          }
          .right {
            position: relative;
            box-sizing: border-box;
            &:hover {
              border: 2px solid #129bff;
            }
            .right_picture {
              width: 100%;
              height: 104px;
              box-sizing: border-box;
              padding: 10px 8px 4px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .right_name {
              height: 56px;
              line-height: 56px;
              background: #f8f9fa;
              box-sizing: border-box;
              padding-left: 10px;
              font-size: 16px;
              font-family: PingFang SC, PingFang SC-Medium;
              font-weight: 600;
              text-align: LEFT;
              color: rgba(0, 0, 0, 0.85);
            }
            .right_select_img {
              width: 30px;
              height: 30px;
              position: absolute;
              bottom: -2px;
              right: -2px;
            }
          }
        }
      }
    }
    .el-dialog__footer {
      padding: 0;
      .dialog-footer {
        width: 100%;
        height: 64px;
        text-align: center;
        overflow: hidden;

        // margin-top: 21px;
        span {
          display: inline-block;
          width: 65px;
          height: 32px;
          margin-top: 16px;
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: CENTER;
          color: rgba(0, 0, 0, 0.85);
          line-height: 32px;

          border-radius: 2px;
          background: rgba(0, 0, 0, 0.2);
          cursor: pointer;
        }
        .span1:hover {
        }
        .span1:active {
        }
        .span2 {
          background: #0a6ed1;
          color: #fff;
          margin-left: 8px;
        }
        .span2:hover {
        }
        .span2:active {
        }
      }
    }
  }
}
//项目概况------------------------------------------------------------------------------------------------------------
.projectOverview {
  box-sizing: border-box;
  input {
    outline-color: #0a6ed1;
  }
  .el-dialog {
    .el-dialog__title {
      font-size: 16px;
      font-weight: 600;
    }
    .el-dialog__header {
      padding: 16px 24px;
      .el-dialog__close {
        color: #909399;
        &:hover {
          color: #409eff;
        }
      }
    }

    .el-dialog__body {
      padding: 0;
      input::-webkit-input-placeholder {
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;

        color: rgba(0, 0, 0, 0.65);
      }
      input::-moz-placeholder {
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;

        color: rgba(0, 0, 0, 0.65);
      }
      input:-ms-input-placeholder {
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;

        color: rgba(0, 0, 0, 0.65);
      }

      textarea::-webkit-input-placeholder {
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;

        color: rgba(0, 0, 0, 0.65);
      }
      textarea::-moz-placeholder {
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;

        color: rgba(0, 0, 0, 0.65);
      }
      textarea:-ms-input-placeholder {
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;

        color: rgba(0, 0, 0, 0.65);
      }
      .dialog-body {
        padding: 0 24px;
        .title {
          height: 20px;
          opacity: 1;
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: rgba(0, 0, 0, 0.85);
          line-height: 20px;
          margin-bottom: 4px;
        }
        .projectOverview_input {
          width: 100%;
          box-sizing: border-box;
          height: 28px;
          opacity: 1;
          // background: rgba(255, 255, 255, 0.4);
          border: 2px solid #c8c8c8;
          border-radius: 2px;
          padding: 0 10px;
          margin-bottom: 20px;
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: rgba(0, 0, 0, 0.65);
        }
        .border {
          border: 2px solid red;
        }
        .error_toast {
          height: 20px;
          line-height: 20px;
          padding: 0 10px;
          font-size: 12px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: red;
          margin-top: -20px;
        }

        .projectOverview_textarea {
          width: 370px;
          height: 90px;
      box-sizing: border-box;
      
          border-radius: 2px;
          color: rgba(0, 0, 0, 0.65);
          margin-bottom: 20px;
          .el-textarea__inner {
            height: 100%;
 border: 2px solid #c8c8c8;
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            text-align: LEFT;
            color: rgba(0, 0, 0, 0.65);
          }
          .el-textarea__inner:focus {
            //  outline-color: #0a6ed1;
            border: 2px solid#0a6ed1;
          }
        }
        .nobd {
          .el-textarea__inner {
            border: 2px solid red;
          }
        }
      }
    }

    .el-dialog__footer {
      padding: 0;
      .dialog-footer {
        width: 100%;
        height: 64px;
        text-align: center;
        overflow: hidden;

        // margin-top: 21px;
        span {
          display: inline-block;
          width: 65px;
          height: 32px;
          margin-top: 16px;
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: CENTER;
          color: rgba(0, 0, 0, 0.85);
          line-height: 32px;

          border-radius: 2px;
          background: rgba(0, 0, 0, 0.2);
          cursor: pointer;
        }
        .span1:hover {
        }
        .span1:active {
        }
        .span2 {
          background: #0a6ed1;
          color: #fff;
          margin-left: 8px;
        }
        .span2:hover {
        }
        .span2:active {
        }
      }
    }
  }
}

.select {
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #f3f3f3;
  color: rgba(0, 0, 0, 0.85);
  .el-select-dropdown__item.selected {
    background-color: #f3f3f3;
    color: rgba(0, 0, 0, 0.85);
  }
  .el-select-dropdown__item {
    color: rgba(0, 0, 0, 0.85);
  }
  .el-select-dropdown__item.hover {
    background-color: #c8c8c8;
    color: rgba(0, 0, 0, 0.85);
  }
  .popper__arrow {
    /* border-bottom-color: #282e38; */
    border: none;
  }
  .popper__arrow::after {
    content: none;
    border-bottom-color: #ccc !important;
  }
}
.flex-between {
  display: flex;
  justify-content: space-between;
}
.el-dialog {
  .el-dialog__title {
    font-size: 16px;
    font-weight: 600;
  }
  .el-dialog__header {
    padding: 16px 24px;
    .el-dialog__close {
      color: #909399;
      &:hover {
        color: #409eff;
      }
    }
  }
}
</style>

