<template>
  <div class="home-page">
    <div
      class="editc-btn no-select"
      v-if="Manage"
      @dblclick="changeEdit"
    ></div>
    <span v-if="edit_type" @click.stop="exitEdit" class="editc-btn-txt"
      >退出编辑</span
    >
    <div class="home-bd">
      <div class="home-title">
        <div class="title-1" style="position: relative">
          {{ title_info.title
          }}<i
            style="width: 38px; height: 38px; margin-left: 16px"
            v-if="edit_type"
            class="dlh-edit-icon"
            @click="editTitle"
          ></i>
        </div>
        <!-- <div class="title-secend">Economic and Social Data Analysis Platform</div> -->
        <div class="title-secend">{{ title_info.subtitle }}</div>
      </div>
      <div class="home-line2">
        <div class="left-bd">
          <div class="ld-1">
            <div class="flex-between" style="height: 31px; position: relative">
              <div>
                <span class="title-text">{{ ld_1_info.title || "" }}</span
                ><i
                  class="dlh-edit-icon"
                  v-if="edit_type"
                  @click="showEdit('ld1')"
                ></i>
              </div>
              <div class="title-line title-line-167"></div>
              <colorLine />
            </div>
            <div class="title-line" style="margin-bottom: 24px"></div>
            <homel1 :edit_type="edit_type" :map_data="ld_1_info" />
          </div>
          <div class="ld-2">
            <div class="flex-between" style="height: 31px; position: relative">
              <div>
                <span class="title-text">{{ ld_2_info.title || "" }}</span
                ><i
                  class="dlh-edit-icon"
                  v-if="edit_type"
                  @click="showEdit('ld2')"
                ></i>
              </div>
              <div class="title-line title-line-167"></div>
              <colorLine />
            </div>
            <div class="title-line"></div>
            <homel2 :map_data="ld_2_info" />
          </div>
        </div>
        <div class="mid-bd">
          <div class="md-1" style="position: relative">
            <homem1 :map_data="md_1_info" />
            <i
              class="dlh-edit-icon"
              style="position: absolute; right: 0; top: -12px"
              v-if="edit_type"
              @click="showEdit('md1')"
            ></i>
          </div>
          <div class="md-2">
            <homem2 :map_data="rivermap_info" />
            <i
              class="dlh-edit-icon"
              style="position: absolute; right: 0; top: -12px"
              v-if="edit_type"
              @click="showEdit('md2')"
            ></i>
          </div>
          <div
            style="
              font-size: 16px;
              color: #fff;
              text-align: center;
              margin-top: -23px;
            "
          >
            <span class="l-line"></span>{{ rivermap_info.title
            }}<span class="r-line"></span>
          </div>
        </div>
        <div class="right-bd">
          <div class="rd-1">
            <div class="flex-between" style="height: 31px; position: relative">
              <div>
                <span class="title-text">{{ rd_1_info.title || "" }}</span
                ><i class="dlh-edit-icon" v-if="edit_type" @click="editRt"></i>
              </div>
              <div class="title-line title-line-167"></div>
              <colorLine />
            </div>
            <div class="title-line"></div>
            <homer1
              :edit_type="edit_type"
              @showEditR1="showEditR1"
              :map_data="rd_1_info"
            />
          </div>
          <div class="rd-2">
            <div class="flex-between" style="height: 31px; position: relative">
              <div>
                <span class="title-text">{{ rd_2_info.title || "" }}</span
                ><i
                  class="dlh-edit-icon"
                  v-if="edit_type"
                  @click="showEdit('rd2')"
                ></i>
              </div>
              <div class="title-line title-line-167"></div>
              <colorLine />
            </div>
            <div class="title-line"></div>
            <homer2 :map_data="rd_2_info" />
          </div>
        </div>
      </div>
    </div>
    <!-- :append-to-body="false" -->
    <!-- :modal-append-to-body="false" -->
    <el-dialog
      title="信息编辑"
      :close-on-click-modal="false"
      :modal-append-to-body="true"
      :append-to-body="true"
      :visible.sync="edit_dialog_visible"
      :width="
        edit_bl === 'rd1' ? '1000px' : edit_bl === 'ld1' ? '1108px' : '830px'
      "
      class="home-edit-dialog"
      center
    >
      <edithomem2
        v-if="edit_bl === 'md2'"
        @updateDate="getRiverMap"
        :visible.sync="edit_dialog_visible"
        ref="child"
        :map_info="rivermap_info"
      />
      <edithomem1
        v-if="edit_bl === 'md1'"
        @updateDate="getMd1Info"
        :visible.sync="edit_dialog_visible"
        ref="child"
        :map_info="md_1_info"
      />
      <edithomer2
        v-if="edit_bl === 'rd2'"
        @updateDate="getRd2Info"
        :visible.sync="edit_dialog_visible"
        ref="child"
        :map_info="rd_2_info"
      />
      <edithomel2
        v-if="edit_bl === 'ld2'"
        @updateDate="getLd2Info"
        :visible.sync="edit_dialog_visible"
        ref="child"
        :map_info="ld_2_info"
      />
      <edithomel1
        v-if="edit_bl === 'ld1'"
        @updateDate="getLd1Info"
        :visible.sync="edit_dialog_visible"
        ref="child"
        :map_info="ld_1_info"
      />
      <edithomer1
        v-if="edit_bl === 'rd1'"
        @updateDate="getRd1Info"
        :visible.sync="edit_dialog_visible"
        ref="child"
        :r1edit_index="r1edit_index"
        :show_tabn="rd_1_info.number > 1"
        :map_info="rd1_date"
      />
      <span slot="footer" class="home-dialog-footer">
        <span class="cancel-btn" @click="cancelEdit">取消</span>
        <span class="submit-btn" type="primary" @click="saveEdit">保存</span>
      </span>
    </el-dialog>
    <el-dialog
      title="信息编辑"
      v-if="titledit_dialog_visible"
      :visible.sync="titledit_dialog_visible"
      :close-on-click-modal="false"
      width="418px"
      class="title-edit-dialog"
      :modal-append-to-body="true"
      :append-to-body="true"
      center
    >
      <div class="flex-bte" style="position: relative">
        <span class="title-text">页面标题：</span>
        <el-input
          v-model="title_info_edit.title"
          @change="endEmpty(title_info_edit, 'title')"
          :class="{ errorbd: title_check && !title_info_edit.title }"
          placeholder="请输入，20字内"
          maxlength="20"
        ></el-input>
        <div class="error-txt" v-if="title_check && !title_info_edit.title">
          页面标题不得为空
        </div>
      </div>
      <div class="flex-bte" style="margin-top: 20px">
        <span
          style="
            line-height: 32px;
            width: 120px;
            text-align: right;
            color: rgba(255, 255, 255, 0.85);
            margin-right: 8px;
          "
          >页面副标题：</span
        >
        <el-input
          v-model="title_info_edit.subtitle"
          @change="endEmpty(title_info_edit, 'subtitle')"
          type="textarea"
          :rows="3"
          resize="none"
          maxlength="50"
          placeholder="请输入，50字内"
        ></el-input>
      </div>
      <span slot="footer" class="home-dialog-footer">
        <span class="cancel-btn dlh-primary-txt-btn" @click="canclChangeTitle"
          >取消</span
        >
        <span
          class="submit-btn dlh-primary-btn"
          type="primary"
          @click="changeTitle"
          >保存</span
        >
      </span>
    </el-dialog>
    <el-dialog
      title="信息编辑"
      :visible.sync="mapr1_dialog_visible"
      :close-on-click-modal="false"
      width="456px"
      class="title-edit-dialog right-dialog-footer"
      :modal-append-to-body="true"
      :append-to-body="true"
      center
    >
      <div class="flex-bte" style="position: relative">
        <span
          class="title-text"
          style="line-height: 32px; width: 92px; text-align: right"
          >区块标题：</span
        >
        <el-input
          v-model="rd_1_info_edit.title"
          @change="endEmpty(rd_1_info_edit, 'title')"
          style="margin-left: 10px"
          :class="{ errorbd: check_type && !rd_1_info_edit.title }"
          placeholder="请输入，10字内"
          maxlength="10"
        ></el-input>
        <div class="error-txt" v-if="check_type && !rd_1_info_edit.title">
          区块标题不得为空
        </div>
      </div>
      <div class="flex-bte" style="margin-top: 20px">
        <span
          style="
            line-height: 32px;
            width: 86px;
            text-align: left;
            color: rgba(255, 255, 255, 0.85);
            margin-right: 0px;
          "
          >图表数量：</span
        >
        <el-radio-group
          v-model="rd_1_info_edit.number"
          style="display: flex; align-items: center"
        >
          <el-radio :label="1">1个</el-radio>
          <el-radio :label="2">2个</el-radio>
          <el-radio :label="3">3个</el-radio>
          <el-radio :label="4">4个</el-radio>
          <el-radio :label="5">5个</el-radio>
        </el-radio-group>
      </div>
      <span slot="footer" style="text-align: right" class="home-dialog-footer">
        <span class="cancel-btn dlh-primary-txt-btn" @click="cancelchangeRd1"
          >取消</span
        >
        <span
          class="submit-btn dlh-primary-btn"
          type="primary"
          @click="changeRd1"
          >保存</span
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import colorLine from "../components/colorline";
import homel1 from "../components/homel1";
import edithomel1 from "../components/edithomel1";
import homel2 from "../components/homel2";
import edithomel2 from "../components/edithomel2";
import homem1 from "../components/homem1";
import edithomem1 from "../components/edithomem1";
import homem2 from "../components/homem2";
import edithomem2 from "../components/edithomem2";
import homer1 from "../components/homer1";
import edithomer1 from "../components/edithomer1";
import homer2 from "../components/homer2";
import edithomer2 from "../components/edithomer2";
import {
  titleinter,
  rivermapinter,
  heapmapinter,
  heatmapinter,
  parallelmapinter,
  platforminter,
  introduceinter,
  userinfo,
} from "../api/getdata";

export default {
  components: {
    colorLine,
    homel1,
    homel2,
    homem1,
    homem2,
    homer1,
    homer2,
    edithomem2,
    edithomer2,
    edithomel2,
    edithomer1,
    edithomem1,
    edithomel1,
  },
  data() {
    return {
      edit_type: false,
      edit_dialog_visible: false,
      edit_bl: "",
      titledit_dialog_visible: false,
      mapr1_dialog_visible: false,
      title_info: {},
      title_info_edit: {},
      rivermap_info: {},
      rd_2_info: {},
      ld_2_info: {},
      rd_1_info: {},
      rd_1_info_edit: {},
      ld_1_info: {},
      md_1_info: {},
      rd_1_info_radio: 0,
      r1edit_index: 0,
      user_auths: {},
      check_type: false,
      title_check: false,
      rd1_date: {},
    };
  },
  methods: {
    changeEdit() {
      console.log(1111);
      this.edit_type = true;
    },
    getTitle() {
      titleinter("detail").then((ele) => {
        this.title_info = ele?.data || {};
      });
    },
    showEdit(item) {
      this.edit_bl = item;
      this.getDlogData();
      this.edit_dialog_visible = true;
    },
    showEditR1(index) {
      this.edit_bl = "rd1";
      this.r1edit_index = index;
      this.edit_dialog_visible = true;
      this.rd1_date = this.rd_1_info?.data[this.r1edit_index];
    },
    changeTitle() {
      if (!this.title_info_edit.title) {
        this.$message.error({
          showClose: true,
          message: "失败：页面标题不得为空",
        });
        this.title_check = true;
        return;
      } else {
        this.title_info_edit.title = this.title_info_edit.title.trim();
      }
      if (this.title_info_edit.subtitle) {
        this.title_info_edit.subtitle = this.title_info_edit.subtitle.trim();
      }
      this.titledit_dialog_visible = false;
      this.title_check = false;
      titleinter("update", this.title_info_edit).then((ele) => {
        this.$message.success({
          showClose: true,
          message: "成功：操作成功",
        });

        setTimeout(() => {
          this.getTitle();
        }, 500);
      });
    },
    canclChangeTitle() {
      this.titledit_dialog_visible = false;
      this.title_check = false;
      this.title_info_edit = {};
    },
    editTitle() {
      this.title_info_edit = { ...this.title_info };
      this.titledit_dialog_visible = true;
    },
    getRiverMap() {
      rivermapinter("detail").then((ele) => {
        this.rivermap_info = ele?.data || {};
      });
    },
    saveEdit() {
      this.$refs.child.saveEdit();
    },
    getDlogData() {
      let t = this.edit_bl;
      this.edit_bl = null;
      if (t === "md2") {
        this.getRiverMap();
      } else if (t === "md1") {
        this.getMd1Info();
      } else if (t === "rd2") {
        this.getRd2Info();
      } else if (t === "ld2") {
        this.getLd2Info();
      } else if (t === "ld1") {
        this.getLd1Info();
      } else if (t === "rd1") {
        this.r1edit_index = null;
        this.getRd1Info();
      }
      this.$nextTick(() => {
        this.edit_bl = t;
      });
    },
    cancelEdit() {
      this.edit_dialog_visible = false;
      setTimeout(() => {
        this.getDlogData();
      }, 500);
    },
    getRd2Info() {
      heapmapinter("detail").then((ele) => {
        this.rd_2_info = ele?.data || {};
      });
    },
    getLd2Info() {
      heatmapinter("detail").then((ele) => {
        this.ld_2_info = ele?.data || {};
      });
    },
    getLd1Info() {
      introduceinter("detail").then((ele) => {
        this.ld_1_info = ele?.data || {};
      });
    },
    getRd1Info() {
      parallelmapinter("detail").then((ele) => {
        let data = ele?.data || {};
        if (!data.number) {
          data.number = 1;
        }
        this.$set(this, "rd_1_info", data);
      });
    },
    cancelchangeRd1() {
      this.mapr1_dialog_visible = false;
      this.rd_1_info_edit = { ...this.rd_1_info };
    },
    changeRd1() {
      this.check_type = true;
      if (!this.rd_1_info_edit.title) {
        this.$message.error({
          showClose: true,
          message: "失败：区块标题不得为空",
        });
        return;
      } else {
        this.rd_1_info_edit.title = this.rd_1_info_edit.title.trim();
      }
      let arg = {
        title: this.rd_1_info_edit.title,
        number: this.rd_1_info_edit.number,
      };
      parallelmapinter("update", arg).then((ele) => {
        this.getRd1Info();
        this.mapr1_dialog_visible = false;
        this.$message.success({
          showClose: true,
          message: "成功：操作成功",
        });
      });
    },
    getMd1Info() {
      // md_1_info
      platforminter("detail").then((ele) => {
        this.md_1_info = ele?.data || {};
      });
    },
    exitEdit() {
      this.edit_type = false;
      // this.$router.push({ query: {} })
    },
    editRt() {
      this.mapr1_dialog_visible = true;
      this.rd_1_info_edit = { ...this.rd_1_info };
    },
  },
  computed: {
    // rd1_date(){
    //   return this.rd_1_info?.data[this.r1edit_index] || {}
    // }
  },
  mounted() {
    userinfo("info").then((ele) => {
      let user_auths = ele.data?.auths || {};

      if (this.$route.query.edit && this.$route.query.edit == 1) {
        this.edit_type = true;
      }
      // let user_auths = localStorage.getItem("user_auths");
      if (!user_auths) {
        location.href = "/";
      }
      this.user_auths = user_auths;
      this.getTitle();
      this.getRiverMap();
      this.getRd2Info();
      this.getLd2Info();
      this.getLd1Info();
      this.getRd1Info();
      this.getMd1Info();
    });
  },
};
</script>

<style lang="less">
.home-page {
  width: 1920px;
  height: 1080px;
  position: absolute;
  background-image: url("../assets/bgblock.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .home-bd {
    .home-title {
      padding-top: 20px;
      font-size: 46px;
      font-weight: 400;
      text-align: CENTER;
      color: #ffffff;
      letter-spacing: 0px;
      line-height: 60px;
      height: 110px;
      overflow: hidden;
      box-sizing: border-box;
      .title-1 {
        max-width: 1414px;
        overflow: hidden;
        margin: 0 auto;
      }
      .title-secend {
        max-width: 1414px;
        height: 26px;
        overflow: hidden;
        margin: 0 auto;
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 2px;
        color: rgba(133, 196, 255, 0.8);
      }
    }
    .home-line2 {
      box-sizing: border-box;
      margin: 0 24px;
      display: flex;
      flex: 1;
      // >div{
      //     border: 1px solid blue;
      // }
      .title-line {
        display: block;
        height: 1px;
        background-color: #129bff;
        position: relative;
        margin-top: 2px;
        &::before,
        &::after {
          content: "";
          display: inline-block;
          width: 3px;
          height: 3px;
          opacity: 1;
          background: #129bff;
          border-radius: 50%;
          position: absolute;
          top: -1px;
        }
        &::after {
          right: 0;
        }
      }
      .title-line-167 {
        width: 167px;
      }
      .title-text {
        font-size: 24px;
        color: #75bdff;
        margin-left: 16px;
        display: inline-block;
        max-width: 240px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .left-bd {
        width: 472px;
        .ld-1 {
          height: 610px;
          margin-bottom: 26px;
          background: linear-gradient(180deg, #04080e 0%, #04080e 100%);
        }
        .ld-2 {
          height: 291px;
        }
      }
      .mid-bd {
        width: 860px;
        margin: 0 34px;
        .md-1 {
          height: 635px;
          margin-top: 16px;
          margin-bottom: 14px;
        }
        .md-2 {
          height: 258px;
          width: 660px;
          margin: 0 auto;
          position: relative;
        }
        .l-line,
        .r-line {
          width: 60px;
          height: 1px;
          display: inline-block;
          border: 1px solid;
          border-image: linear-gradient(
              90deg,
              rgba(18, 155, 255, 0) 0%,
              #129bff 100%,
              rgba(18, 155, 255, 0.5) 100%
            )
            1 1;
          vertical-align: middle;
          margin-right: 8px;
        }
        .r-line {
          border-image: linear-gradient(
              270deg,
              rgba(18, 155, 255, 0) 0%,
              #129bff 100%,
              rgba(18, 155, 255, 0.5) 100%
            )
            1 1;
          margin-right: 0;
          margin-left: 8px;
        }
      }
      .right-bd {
        width: 472px;
        .rd-1 {
          height: 610px;
          margin-bottom: 26px;
        }
        .rd-2 {
          height: 291px;
        }
      }
      .red-bd {
        border: 1px solid red;
      }
    }
  }
  .editc-btn {
    height: 80px;
    width: 80px;
    position: absolute;
    right: 0;
    cursor: pointer;
    color: #fff;
    line-height: 80px;
    z-index: 1;
  }
}

.el-dialog__headerbtn .el-dialog__close {
  color: #fff;
}
.cancel-btn {
  // color: #fff;
  margin-right: 8px;
  background: rgba(255, 255, 255, 0.85);
  color: rgba(0, 0, 0, 0.85);
  &:hover {
    background-color: #fff;
  }
  &:active {
    background-color: rgba(255, 255, 255, 0.65);
  }
}
.submit-btn {
  color: #fff;
  background-color: #0a6ed1;
  &:hover {
    background-color: #2f8cde;
  }
  &:active {
    background-color: #0050ab;
  }
}
.right-dialog-footer {
  .el-dialog__footer {
    text-align: right;
  }
  .el-radio {
    margin-right: 17px;
    color: rgba(255, 255, 255, 0.85);
  }
}
</style>
