<template>
  <div :class="{'upload-status':true,error:upload_status == 'uploadError'}">
      <div v-show="upload_status == 'uploadding'"  class="status22">
            <img src="../assets/cycle.png" alt="" style="margin-top: 70px;margin-bottom: 15px;" />
            <div>上传中</div>
        </div>
        <div v-show="upload_status == 'afterupload'">
        <div style="margin-top:95px">上传中</div>
        <el-progress
            :show-text="false"
            v-if="(upload_status == 'afterupload')&&!isNaN(show_time)"
            style="width:60%;margin:20px auto"
            :percentage="Number(show_time)"
            :stroke-width='4'
        ></el-progress>
        </div>
        <div v-show="upload_status == 'uploadError'" class="status3 ">
            <div class="img">
              <img src="../assets/uperror.png" alt="" />
              <div>image.png</div>
            </div>
            <div class="pop">
              <div>
                  <!-- :list-type="list_type" -->
                <el-upload
                    action=""
                    
                    :file-list="video_list"
                    :before-upload="beforeUpload"
                    :http-request="upLoad"
                    :on-change="imgSaveToUrl"
                    :show-file-list="false"
                >
                  <img src="../assets/xiugai.png" alt="" />
                </el-upload>
              </div>
            </div>
        </div>
  </div>
</template>

<script>
export default {
    props:{
        video_list:{},
        upload_status:'',
        show_time:0,
        // list_type:'picture-card'
    },
    methods:{
        beforeUpload(ele){
            this.$emit('beforeupload',ele)
        },
        upLoad(file,file_list){
            this.$emit('httprequest',file)
        },
        imgSaveToUrl(file){
            this.$emit('imgSaveToUrl',file)
        }
    }
}
</script>

<style lang="less">
.upload-status{
    width: 100%;
    height: 100%;
    border: 1px dashed #FFF;
    border-radius: 8px;
    position: absolute;
    top: 0;
    text-align: center;
    box-sizing: border-box;
    &.error{
        border: 1px solid #F85F64;
    }
.status22 {
    @keyframes turn {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
    }
    img {
    animation: turn 1s linear infinite;
    }
}
.status3 {
    // background-color: pink;
    display: flex;
    align-items: center;
    justify-content: center;
    // border: 1px solid #F85F64;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    .pop {
    display: none;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    > div {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        img {
        width: 40px;
        height: 40px;
        }
    }
    }
    .img {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    img {
        width: 30px;
        height: 30px;
    }
    > div {
        width: 88px;
        height: 22px;
        opacity: 1;
        margin-top: 16px;
        font-size: 14px;
        font-family: PingFang HK, PingFang HK-Regular;
        font-weight: 400;
        text-align: CENTER;
        color: #f85f64;
        line-height: 22px;
    }
    }
}
.status3:hover .pop {
    display: block;
}
}

</style>