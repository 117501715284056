<template>
  <div id="hl2" ref="hl2" style="height:258px;width:460px"></div>
</template>

<script>
export default {
    props:{
        map_data:{data:[],value_name:'',value_unit:'',x:[],x_unit:'',y:[]}
    },
    watch:{
        map_data(){
            let data = [];
            
                (this.map_data?.x||[]).forEach((ele,ind)=>{
                    (this.map_data.data||[]).forEach((element,index) => {
                        data.push([ind,index,element[ind]])
                
                })
                
            });

            this.data = data
            this.hours=this.map_data.x
            this.days=this.map_data.y
            this.value_name=this.map_data.value_name
            this.value_unit=this.map_data.value_unit
            this.initChart()
        }
    },
    data(){
        return {
            // data:[[0,0,0],[0,1,200],[0,2,400],[0,3,501],[0,4,507],[1,0,0],[1,1,253],[1,2,397],[1,3,504],[1,4,504],[2,0,0],[2,1,260],[2,2,407],[2,3,503],[2,4,499],[3,0,0],[3,1,251],[3,2,405],[3,3,487],[3,4,520],[4,0,0],[4,1,303],[4,2,398],[4,3,505],[4,4,512],[5,0,0],[5,1,305],[5,2,402],[5,3,506],[5,4,497],[6,0,0],[6,1,312],[6,2,406],[6,3,522],[6,4,0],[7,0,0],[7,1,345],[7,2,424],[7,3,508],[7,4,0],[8,0,100],[8,1,363],[8,2,452],[8,3,523],[8,4,0],[9,0,120],[9,1,387],[9,2,450],[9,3,510],[9,4,0],[10,0,155],[10,1,380],[10,2,440],[10,3,503],[10,4,0],[11,0,180],[11,1,389],[11,2,480],[11,3,512],[11,4,0]],
            data:[],
            hlInstance:'',
            // hours: ['1', '2','3', '4', '5', '6','7', '8', '9','10','11','12'],
            // days: ['COD', 'TN', 'TP', 'NH4-N', 'BOD', 'NH4-N', 'BOD', 'TN', 'TP', 'SS', 'NH4-N', 'BOD']
            hours: [],
            days: [],
            value_name:'',
            value_unit:''

        }
    },
    methods:{
        initChart(){
            let data_min = this.data[0]?.[0] || null
            let data_max = this.data[0]?.[0] || null
            let data_range = {}
            let data = this.data.map(function (item) {
                item.forEach(ele=>{
                    if(data_min===null){
                        data_max = data_min=ele
                    }
                    if(ele<data_min){
                        data_min = ele
                    }
                    if(ele>data_max){
                        data_max = ele
                    }
                })
                return [item[0], item[1], item[2]];
            });
            if(data_min!==null&&data_max!==null){
                data_range = {min:data_min,max:data_max}
            }
            this.hlInstance = this.$echarts.init(this.$refs.hl2)
            let option = {
                    tooltip: {
                        position: 'top',
                        borderColor: 'transparent',
                        borderWidth: 0,
                        backgroundColor:"rgba(5,125,133,0.6)",
                        formatter: (params)=>{
                           let tipHtml = '<div style="backdrop-filter: blur(5px);font-size:12px;min-width:99px">'
                  +'<div style="color:#fff;font-size:12px;padding:0 5px;margin:0">'+this.value_name+'</div>'
                  +'<div style="display:flex;justify-content:space-between;padding:0 5px"><span>'+ params.data[2] +'</span><span style="color:#FFCF5F">'+this.value_unit+'</span></div>'
                  +'</div>';
                            return tipHtml
                        }
                    },
                    grid: {
                        top: '24px',
                        bottom:30,
                        right:0,
                        left:58
                    },
                    xAxis: {
                        type: 'category',
                        data: this.hours,
                        interval: 0,
                        splitNumber:0,
                        axisTick:false,
                        splitArea: {
                            show: false,
                        },
                        axisLabel: {
                            show: true,
                            interval: 0,
                            textStyle: {
                                textAlign:"left",
                                fontSize:'10px',
                                width:10,
                                overflow:'breakAll',
                                color: '#FFF'
                            },
                            formatter: (value, index)=> {
                                let length = 10
                                if(this.hours.length>12){
                                    length = 2
                                }else if(this.hours.length>8){
                                    length = 4
                                }else if(this.hours.length>6){
                                    length = 5
                                }else if(this.hours.length>4){
                                    length = 7
                                }else if(this.hours.length>2){
                                    length = 9
                                }
                                if(value.length>5){
                                    value = value.slice(0,length)+ "\n" +value.slice(length,length+length);
                                }
                                return value;
                            }
                        },
                    },
                    yAxis: {
                        type: 'category',
                        data: this.days,
                        axisTick:false,
                        splitArea: {
                            show: true,
                            interval :1,
                            areaStyle:{color:'rgba(255,255,255,0.16)',}
                        },
                        splitLine:{
                            show:true,
                            lineStyle:{
                                width:2,
                                color:'#000'
                            }
                        },
                        axisLabel: {
                            show: true,
                            textStyle: {
                                fontSize:'10px',
                                color: '#FFF'
                            },
                            formatter: (value, index)=> {
                                value = value.slice(0,5);
                                return value;
                            }
                        },
                    },
                    visualMap: {
                        ...data_range,
                        calculable: true,
                        orient: 'horizontal',
                        left: 'center',
                        bottom: '15%',
                        show:false,
                        backgroundColor:'rgba(255,0,0,1)',
                        // splitNumber: 3,
                        // borderRadius: 30,
                        // 'rgba(255,255,255,0.16)',
                        // inRange:{
                        //     // ,'#B5EAFF','#63C9FF','#129BFF'
                        //     color:['rgba(255,255,255,0.16)','rgba(0, 46, 102,0.75)']
                        // },
                        inRange:{
                            color:['#B5EAFF','#129BFF','#002E66']
                        },
                    },
                    series: [{
                        name: '污水处理量（万方）',
                        type: 'heatmap',
                        data: data,
                        label: {
                            show: false
                        },
                        itemStyle: {
                            width:3,
                            borderWidth: 2,
                            borderColor:"#04080f",
                            borderJoin:"round",
                            borderCap:'round',
                             opacity:1,
                            textStyle: {
                            show:false,
                                fontSize:'14px',
                                color: '#FFF'
                            },
                        },
                    }]
                };
            this.hlInstance.setOption(option);
        }
    },
    mounted(){
        
    }
}
</script>

<style>

</style>