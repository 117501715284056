<template>
  <div class="comp-view">
      <!-- style="background-image:url(../assets/compviet.jpg)" -->
      <div class="cv-top-line" :style="{'background-image':'url('+first_img_l.url+')'}" v-show="first_img_l.url">
          <div class="cv-top-line-mask"></div>
          <div class="cv-top-line-text">
            <div style="padding:90px 0 24px 64px;width:386px;font-size: 24px;" class="one-line">{{first_img_l.title}}</div>
            <div style="color:rgba(255,255,255,1);margin-left:64px;width:480px;line-height:24px">{{first_img_l.desc}}</div>
          </div>
          <el-image
            class="cv-top-img"
            style="cursor:pointer"
            :src="first_img_l.url"
            @click="showImg(0)">
        </el-image>
      </div>
      <div v-if="!first_img_l.url" style="text-align:center;margin-top:20%">
          <img src="../assets/emptyicon.png" alt="">
          <div style="color:#FFF">暂无数据</div>
      </div>
      <div class="cv-img-list">
          <div v-for="(item,index) in img_list.slice(1)" :key="index" class="li-img">
                <el-image
                    class="cil-img"
                    style="cursor:pointer"
                    :src="item.url"
                    @click="showImg(index+1)"
                    >
                </el-image>
              <div class="cil-img-txt one-line">{{item.title}}</div>
              <div class="cil-img-txt1">{{item.desc}}</div>
          </div>
      </div>
        <el-dialog  class="img-preview" :show-close="false" :visible.sync="dialogVisible" top="10vh" width="1150px" :modal-append-to-body="false"
      :append-to-body="false">
            <img width="100%" :src="dialogImage.url" alt="">
            <div style="width: 1100px;box-sizing: border-box;letter-spacing: 2px;padding:24px 45px;font-weight:400;background:rgba(255,255,255,0.2);opacity: 0.85;font-size: 16px;color:#FFF;backdrop-filter: blur(40px);margin-top: -4px;">
                <div style="letter-spacing: 2px;text-align:center;font-size: 20px;font-weight:500;font-weight: 500;margin-bottom:10px;">{{dialogImage.title}}</div>
                {{dialogImage.desc}}
            </div>
        </el-dialog>
  </div>
</template>

<script>
export default {
    props:{show_img:[]},
    data(){
        return{
            dialogVisible:false,
            dialogImage:''
            // img_list:[
            //     {text:'由50多名经验丰富的水处理工程师和60名经专业机构认证的持证水处理专工组成职业的核心技工团队，带领500余名熟练操作工，承担日常运维业务。',
            //     title:'职业运营团队',
            //     img:require('../assets/compviet1.jpg')},
            //     {text:'公司拥有完善的试验设备和先进的检测仪器，设备总值两千余万元，正在筹建省级工程技术研究中心，检测能力相当于省级标准化环保监测站。',
            //     title:'科研实力',
            //     img:require('../assets/compvi1.jpg')},
            //     {text:'为每位业主建立独立的远程运营平台和全天候视频监控系统，确保项目现场信息实时上传指挥中心，实现整个运营操作的远程管控。',
            //     title:'智慧运营平台',
            //     img:require('../assets/compviet2.jpg')},
            // ]
        }
    },
    computed:{
        img_list(){
            return this.show_img||[{}]
        },
        first_img_l(){
            return this.show_img[0]||{}
        }
        
    },
    methods:{
        showImg(index){
            this.dialogVisible = true
            this.dialogImage=this.img_list[index] ||{}
        }
    }
}
</script>

<style lang="less">
.comp-view{
    width: 1350px;
    margin: 0 auto;
    .cv-top-line{
        height: 315px;
        width: 1350px;
        // background-image: url('../assets/compviet.jpg');
        background-size: cover;
        background-position: center;
        backdrop-filter: blur(4px);
        position: relative;
        .cv-top-img{
            position: absolute;
            width: 665px;
            height: 374px;
            right:0;
            top:0;
            // top: -7px;
        }
        .cv-top-line-mask{
            width: 691px;
            height: 315px;
            background: rgba(52,52,52,0.70);
            color: #FFF;
            position: absolute;
        }
        .cv-top-line-text{
            width: 691px;
            height: 315px;
            color: #FFF;
            position: absolute;
        }
    }
    .cv-img-list{
        &::-webkit-scrollbar { width: 0 !important }
        margin-top: 77px;
        height: 500px;
        // overflow-y: auto;
        .li-img:nth-child(3n){
            margin-right: 0;
        }
        .li-img{
            display: inline-block;
            height: 384px;
            width: 434px;
            background: rgba(255,255,255,0.12);
            border-radius: 10px;
            margin-right:24px;
            margin-bottom: 24px;
            vertical-align: top;
        }
        .cil-img{
            height: 217px;
            width: 386px;
            border-radius: 16px;
            margin: 24px 24px 17px 24px;
            
        }
        .cil-img-txt{
                font-size: 20px;
                font-weight: 500;
                width: 386px;
                padding: 0 24px;
                color: #ffffff;
                margin-bottom: 10px;
            }
            .cil-img-txt1{
                width: 386px;
                font-size: 16px;
                font-weight: 400;
                color: rgba(255,255,255,0.65);
                padding: 0 24px;
            }

    }
}
.img-preview{

    .el-dialog{
        background-color: transparent;
    }
    .el-dialog__body{
        height: auto;
        max-height: fit-content;
        img{
            max-height: 600px;
            max-width: 1100px;
        }
    }
}
</style>