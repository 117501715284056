<template>
  <div >
      <img style="width:100%;height:100%" :src="active_url" alt="">
  </div>
</template>

<script>
export default {
    data(){
        return {
            img_url:[require('../assets/mode1.png'),require('../assets/mode2.png'),require('../assets/mode11.png'),require('../assets/mode4.png')],
            active_url:require('../assets/mode1.png')
        }
    },
    destroyed(){
        document.body.style.backgroundColor = ""
    },
    mounted() {
        let id = this.$route.query.id;
        console.log(this.$route.query);
        this.active_url = this.img_url[id]
        if(id ==1 || id==2) {
            document.body.style.backgroundColor = "#FFF"
        }
    }
}
</script>

<style>

</style>