<template>
  <div class="newhome_page">
    <div v-show="Manage" class="editc-btn no-select" @dblclick="changeEdit"></div>
    <span
      @click.stop="exitEdit"
      v-if="edit_type"
      style="color: black"
      class="editc-btn-txt"
      >退出编辑</span
    >
    <div class="newhome_title">
      <div class="title" style="position: relative">
        {{ ruleForm.name
        }}<i
          style="width: 38px; height: 38px; margin-left: 16px"
          class="dlh-edit-icon"
          v-if="edit_type"
          @click="editTitle"
        ></i>
      </div>
    </div>
    <div class="newhome_bd">
      <div class="newhome_bd_l">
        <div class="company_avatar">
          <i
            style="width: 38px; height: 38px; margin-left: 16px"
            class="dlh-edit-icon edit_newhome"
            v-if="edit_type"
            @click="edit_company_img"
          ></i>
          <div class="brief">
            <div class="brief_left">
              <div class="company_info">
                <img class="company_img" :src="logo_file_key" alt="" />
                <!-- <div class="company_name">{{ ruleForm.name }}</div> -->
              </div>
              <div class="slogan">
                <pre>{{ ruleForm.slogan }}</pre>
              </div>
            </div>
            <div class="brief_right">
              <img :src="badge_file_key" alt="" />
            </div>
          </div>
          <div class="achievement">
            <img class="achievement_img1" src="../assets/jiangbei.png" alt="" />
            <div class="achievement_text">
              <div
                class="list"
                @click="previewPic(item, index)"
                v-for="(item, index) in achievement_list"
                :key="index"
              >
                {{ item.name }}
                <el-image
                  class="list_img"
                  :src="item.image.url"
                  :preview-src-list="srcList"
                  ref="previewImg"
                >
                </el-image>
              </div>
            </div>
            <!-- <img
              class="achievement_img2"
              src="../assets/triangleup.png"
              alt=""
            /> -->
          </div>
        </div>
        <div class="introduce">
          <div class="navgation">
            <div
              class="nav_items"
              :class="activeindex == index ? 'activeclass' : ''"
              v-for="(item, index) in introduce_list"
              :key="index"
              @click="changenav(item, index)"
            >
              <span :class="{ span: edit_type }">{{ item.module }}</span>
              <img
                @click.stop="showdetail(item, index)"
                v-if="edit_type"
                src="../assets/xiugai2.png"
                alt=""
              />
            </div>
            <div class="select" v-if="over_introduce_list.length > 0">
              <el-dropdown :hide-on-click="false" placement="bottom">
                <img class="more" src="../assets/threedoll.png" alt="" />

                <el-dropdown-menu class="moreselect" slot="dropdown">
                  <el-dropdown-item
                    placement="top-start"
                    v-for="(item, index) in over_introduce_list"
                    :key="index"
                  >
                    <span @click="changenav(item, index + 5)">{{
                      item.module
                    }}</span>
                    <img
                      @click="showdetail(item, index)"
                      v-if="edit_type"
                      src="../assets/xiugai2.png"
                      alt=""
                    />
                  </el-dropdown-item>

                  <el-dropdown-item class="moreadd"
                    ><div style="width: 100%" @click="add_detail">
                      添加
                    </div></el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div
              class="navadd"
              @click="add_detail"
              v-if="over_introduce_list.length <= 0 && edit_type"
            >
              <img class="more" src="../assets/upadd1.png" alt="" />
            </div>
          </div>
          <div class="nav_detail">
            <div
              :class="{ detail_box: item.type == 4 }"
              v-for="(item, index) in content"
              :key="index"
            >
              <div class="title" v-if="item.type == 1">
                <span></span>
                {{ item.title }}
              </div>
              <div class="text" v-if="item.type == 2">
                <pre>{{item.paragraph}}</pre>
              </div>
              <div class="picture" v-if="item.type == 3">
                <!-- <img :src="item.big_image" alt="" /> -->
                <el-image class="pic_el-img" :src="item.big_image" :preview-src-list="[item.big_image]"> </el-image>
              </div>

              <div class="littlepic" v-if="item.type == 4">
                <!-- <img :src="item.small_image" alt="" /> -->
                 <el-image class="pic_el-img" :src="item.small_image" :preview-src-list="[item.small_image]"> </el-image>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="newhome_bd_r">
        <div class="bd_r_top">
          <div class="map" style="width: 926px; height: 664px">
            <ma :key="timer" :prodata="project_alldata"></ma>
            <i
              style="width: 38px; height: 38px; margin-left: 16px"
              class="dlh-edit-icon edit_newhome"
              v-if="edit_type"
              @click="project_distribution"
            ></i>
          </div>
          <div class="area">
            <el-carousel
              class="shuffling"
              :autoplay="false"
              indicator-position="none"
              :arrow="showarea_list.length > 1 ? 'true' : 'never'"
             
            >
              <el-carousel-item
                v-for="(item, index) in showarea_list"
                :key="index"
              >
                <div class="project">
                  <div
                    class="common project1"
                    v-for="(item1, index1) in item"
                    :key="index1"
                    @click="itemdetial(item1)"
                  >
                    <div class="child_columns1">{{ item1.name }}</div>
                    <div class="child_columns2">
                      <div class="left">
                        <div class="top">项目数</div>
                        <div class="bottom">{{ item1.project.length }}+</div>
                      </div>
                      <div class="right">
                        <div class="top">营业额</div>
                        <div class="bottom" v-if="item1.allrevenue > 10000">
                          {{ item1.allrevenue / 10000 }}亿+
                        </div>
                        <div class="bottom" v-else>
                          {{ item1.allrevenue }}万+
                        </div>
                      </div>
                    </div>
                    <div class="child_columns3">
                      <div
                        v-for="(item2, index2) in item1.provinces"
                        :key="item2"
                      >
                        <div
                          class="provinces"
                          :class="'provinces' + (index2 + 1)"
                          v-if="index2 < 4"
                        >
                          {{ item2 }}
                        </div>
                      </div>
                    </div>
                    <div
                      :id="'' + index + '' + index1"
                      class="child_columns4"
                      style="width: 181px; height: 132px"
                    ></div>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <div class="bd_r_bottom">
          <el-carousel
            class="course"
            :autoplay="false"
            indicator-position="none"
            :arrow="transform_develop_list.length > 1 ? 'true' : 'never'"
          >
            <el-carousel-item
              class="course_item"
              v-for="(item, index) in transform_develop_list"
              :key="index"
            >
              <div class="aaaa">
                <div
                  class="son"
                  :class="
                    index1 == 0
                      ? 'son0'
                      : index1 == 1
                      ? 'son1'
                      : index1 == 2
                      ? 'son2'
                      : index1 == 3
                      ? 'son3'
                      : index1 == 4
                      ? 'son4'
                      : index1 == 5
                      ? 'son5'
                      : index1 == 6
                      ? 'son6'
                      : index1 == 7
                      ? 'son7'
                      : 'son8'
                  "
                  v-for="(item1, index1) in item"
                  :key="index1"
                >
                  <img
                    class="img0"
                    v-if="index1 == 0 && index == 0"
                    src="../assets/start.png"
                    alt=""
                  />
                  <img class="imgs" v-else src="../assets/shang.png" alt="" />
                  <img
                    class="imgx"
                    v-if="index1 % 2 == 1"
                    src="../assets/xia.png"
                    alt=""
                  />

                  <div class="img" v-if="item1.image.url">
                    <!-- <img class="peitu" :src="item1.image.url" alt="" /> -->
                     <el-image class="pic_el-img" :src="item1.image.url" :preview-src-list="[item1.image.url]"> </el-image>
                  </div>

                  <div style="display: inline-block; max-width: 100%">
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="item1.desc"
                      placement="top"
                      
                    >
                      <div class="introduction">{{ item1.desc }}</div>
                    </el-tooltip>
                  </div>
                  <div class="time">{{ item1.date }}</div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
          <i
            style="width: 38px; height: 38px; margin-left: 16px"
            class="dlh-edit-icon edit_newhome"
            v-if="edit_type"
            @click="show_development_dialog"
          ></i>
        </div>
      </div>
    </div>

    <!-- 修改标题弹窗 -->
    <el-dialog
      title="企业名称"
      :visible.sync="title_dialog"
      width="418px"
      class="public_dialog edit_title"
      :close-on-click-modal="false"
      :modal-append-to-body="true"
      :append-to-body="true"
    >
      <div class="dialog-content">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
          <el-form-item label="" prop="name">
            <el-input
              v-model="ruleForm.name"
              placeholder="请输入，20字内"
              maxlength="20"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancel" @click="title_dialog_cancel">取 消</el-button>
        <el-button class="submit-btn" type="primary" @click="title_dialog_save"
          >保存</el-button
        >
      </span>
    </el-dialog>
    <!-- 企业画像弹窗 -->
    <el-dialog
      title="企业画像"
      :visible.sync="company_img_dialog"
      width="654px"
      class="public_dialog edit_company_img"
      :close-on-click-modal="false"
      :modal-append-to-body="true"
      :append-to-body="true"
    >
      <div class="dialog-content">
        <div class="up_img">
          <div class="logo">
            <div class="tit">logo</div>
            <div class="limit">
              <i style="color: orange" class="el-icon-warning"></i>
              <div class="limit_text">
                请选择2M内的png/jpg/jpeg/gif格式文件，建议比例4:1
              </div>
            </div>
            <div class="img">
              <Upload
                :back_file_key="logo_file_key"
                @getMsg="childMsg"
                ref="upload_child"
              ></Upload>
            </div>
          </div>
          <div class="badge logo">
            <div class="tit">徽章</div>
            <div class="limit">
              <i style="color: orange" class="el-icon-warning"></i>
              <div class="limit_text">
                请选择2M内的png/jpg/jpeg/gif格式文件，建议比例1:1
              </div>
            </div>
            <div class="img">
              <Upload2
                :back_file_key="badge_file_key"
                @getMsg="childMsg"
                ref="upload_child"
              ></Upload2>
            </div>
          </div>
        </div>
        <div class="slogan">
          <div class="slogan_name">标语 slogan</div>

          <el-form :model="ruleForm" :rules="rules" ref="slogan">
            <el-form-item label="" prop="slogan">
              <el-input
                type="textarea"
                :rows="2"
                v-model="ruleForm.slogan"
                maxlength="30"
                placeholder="请输入，30字内"
                class="slogan_text"
              >
              </el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="company_chengjiu">
          <div class="achievement_name">企业成就</div>
          <div>
            <el-table
              :data="achievement_list"
              border
              style="width: 100%"
              max-height="321"
              class="achievement_table"
              :header-cell-style="{ background: '#60a1e1', color: '#fff' }"
              :cell-style="{ borderColor: '#fff' }"
            >
              <template slot="empty">
                <div class="achievement_table_empty">
                  <img src="../assets/newhomeempty.png" alt="" />
                  <div class="empty_text">
                    暂无数据，<span @click="achievement_table_add"
                      >添加一行</span
                    >
                  </div>
                </div>
              </template>
              <el-table-column label="序号" width="72px" align="center">
                <template slot-scope="scope">
                  {{ scope.$index + 1 }}
                </template>
              </el-table-column>
              <el-table-column label="成就项" width="220px" align="center">
                <template slot-scope="scope">
                  <el-input
                    v-model="scope.row.name"
                    placeholder="请输入，10字内"
                    maxlength="10"
                  ></el-input>
                </template>
              </el-table-column>

              <el-table-column
                label="配图"
                width="216px"
                align="center"
                class-name="peitu"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <!-- :name="scope.row.image.name" -->
                  <small1
                    :backup_filename="scope.row.image.name"
                    @getMsg="received_mesage"
                    @get="received(scope.row)"
                  >
                  </small1>
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                width="105px"
                align="center"
                class-name="operation"
              >
                <template slot-scope="scope">
                  <img
                    @click="achievement_table_del(scope.$index)"
                    src="../assets/newdel.png"
                    alt=""
                  />
                </template>
              </el-table-column>
            </el-table>
            <div class="add">
              <i v-if="achievement_list.length > 0" class="el-icon-plus"></i>
              <span
                class="span"
                v-if="achievement_list.length > 0"
                @click="achievement_table_add"
              >
                添加一行
              </span>
              <span v-else style="color: rgba(0, 0, 0, 0.85)"> 添加一行 </span>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancel" @click="company_img_dialog_cancel"
          >取 消</el-button
        >
        <el-button
          class="submit-btn"
          type="primary"
          @click="company_img_dialog_save"
          >保存</el-button
        >
      </span>
    </el-dialog>
    <!--添加详细介绍 -->
    <el-dialog
      :title="up_new == 'new' ? '添加详细介绍' : '编辑详细介绍'"
      :visible.sync="add_detail_dialog"
      width="830px"
      class="public_dialog edit_detail"
      :close-on-click-modal="false"
      :modal-append-to-body="true"
      :append-to-body="true"
    >
      <div class="dialog-content">
        <el-form :model="ruleForm" :rules="rules" ref="introduce_deail">
          <div class="module_name">模块名称</div>
          <el-form-item label="" prop="module">
            <el-input
              v-model="ruleForm.module"
              maxlength="10"
              placeholder="请输入,10字内"
            ></el-input>
          </el-form-item>
          <div class="module_name">介绍文案</div>
          <div class="typography">
            <div class="left">
              <div class="box">
                <div class="left_son input" @click="addhtml(1)">
                  <img src="../assets/pei1.png" alt="" />
                  <div class="neirong">小标题</div>
                </div>
                <div class="left_son textarea" @click="addhtml(2)">
                  <img src="../assets/pei2.png" alt="" />
                  <div class="neirong">文字段落</div>
                </div>
                <div class="left_son big" @click="addhtml(3)">
                  <img src="../assets/pei3.png" alt="" />
                  <div class="neirong">配图（大）</div>
                </div>
                <div class="left_son little" @click="addhtml(4)">
                  <img src="../assets/pei4.png" alt="" />
                  <div class="neirong">配图（小）</div>
                </div>
                <div class="pic_tip">
                  <div class="box1">
                    <i style="color: orange" class="el-icon-warning"></i>
                    <div class="limit_text1">
                      请选择2M内的png/jpg/jpeg/gif格式文件，建议比例16:9
                      展示时占据整行
                    </div>
                    <div class="limit_text2">
                      请选择2M内的png/jpg/jpeg/gif格式文件，建议比例16:9
                      展示时占据半行
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="ul" class="right">
              <div
                :class="{
                  box_right: item.type == 4,
                  active_right: active_right_index == index,
                }"
                v-for="(item, index) in ruleForm.detail_list"
                :key="index"
                @click="click_right(index)"
              >
                <el-tooltip
                  effect="dark"
                  content="Top Center 提示文字"
                  placement="top"
                  popper-class="del_tooltop"
                  :enterable="enterable"
                  v-if="item.type == 1"
                >
                  <div slot="content">
                    <img
                      @click="detali_del(index, 1)"
                      src="../assets/newxia.png"
                      alt=""
                    />
                    <img
                      @click="detali_del(index, 2)"
                      src="../assets/newshang.png"
                      alt=""
                    />
                    <img
                      @click="detali_del(index, 3)"
                      src="../assets/newdel.png"
                      alt=""
                    />
                  </div>
                  <div class="type1">
                    <span></span>

                    <el-form-item
                      label=""
                      :prop="'detail_list.' + index + '.title'"
                      :rules="rules.title"
                    >
                      <el-input
                        maxlength="30"
                        placeholder="请输入小标题,30字内"
                        v-model="item.title"
                      ></el-input>
                    </el-form-item>
                  </div>
                </el-tooltip>
                <el-tooltip
                  effect="dark"
                  content="Top Center 提示文字"
                  placement="top"
                  popper-class="del_tooltop"
                  v-if="item.type == 2"
                >
                  <div slot="content">
                    <img
                      @click="detali_del(index, 1)"
                      src="../assets/newxia.png"
                      alt=""
                    />
                    <img
                      @click="detali_del(index, 2)"
                      src="../assets/newshang.png"
                      alt=""
                    />
                    <img
                      @click="detali_del(index, 3)"
                      src="../assets/newdel.png"
                      alt=""
                    />
                  </div>

                  <div class="type2">
                    <el-form-item
                      label=""
                      :prop="'detail_list.' + index + '.paragraph'"
                      :rules="rules.paragraph"
                    >
                      <el-input
                        type="textarea"
                        maxlength="300"
                        :rows="4"
                        placeholder="请输入介绍文案，300字内"
                        v-model="item.paragraph"
                      >
                      </el-input>
                    </el-form-item>
                  </div>
                </el-tooltip>
                <el-tooltip
                  effect="dark"
                  content="Top Center 提示文字"
                  placement="top"
                  popper-class="del_tooltop"
                  v-if="item.type == 3"
                >
                  <div slot="content">
                    <img
                      @click="detali_del(index, 1)"
                      src="../assets/newxia.png"
                      alt=""
                    />
                    <img
                      @click="detali_del(index, 2)"
                      src="../assets/newshang.png"
                      alt=""
                    />
                    <img
                      @click="detali_del(index, 3)"
                      src="../assets/newdel.png"
                      alt=""
                    />
                  </div>
                  <div class="type3">
                    <imgdetail
                      :key="timer"
                      :back_img="
                        item.local_image ? item.local_image : item.big_image
                      "
                      @getMsg="detail_mesage"
                      @get="detail(item)"
                    ></imgdetail>
                  </div>
                </el-tooltip>
                <el-tooltip
                  effect="dark"
                  content="Top Center 提示文字"
                  placement="top"
                  popper-class="del_tooltop"
                  v-if="item.type == 4"
                >
                  <div slot="content">
                    <img
                      @click="detali_del(index, 1)"
                      src="../assets/newxia.png"
                      alt=""
                    />
                    <img
                      @click="detali_del(index, 2)"
                      src="../assets/newshang.png"
                      alt=""
                    />
                    <img
                      @click="detali_del(index, 3)"
                      src="../assets/newdel.png"
                      alt=""
                    />
                  </div>
                  <div class="type4">
                    <imgdetail
                      :key="timer"
                      :back_img="
                        item.local_image ? item.local_image : item.small_image
                      "
                      @getMsg="detail_mesage"
                      @get="detail(item)"
                    ></imgdetail>
                  </div>
                </el-tooltip>
              </div>
              <div class="right_empty" v-if="ruleForm.detail_list.length <= 0">
                <img src="../assets/empty.png" alt="" />
                <div>请先选择左侧数据类型</div>
              </div>
            </div>
          </div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          v-if="up_new == 'up'"
          class="cancel"
          @click="add_detail_dialog_del"
          >删除当前模块</el-button
        >
        <el-button class="cancel" @click="add_detail_dialog_cancel"
          >取 消</el-button
        >
        <el-button
          class="submit-btn"
          type="primary"
          @click="add_detail_dialog_save"
          >保存</el-button
        >
      </span>
    </el-dialog>
    <!-- 项目详情弹窗 -->
    <el-dialog
      :title="detail_name"
      :visible.sync="project_detial_dialog"
      width="830px"
      class="public_dialog project_detial"
      :close-on-click-modal="false"
      :modal-append-to-body="true"
      :append-to-body="true"
    >
      <div class="dialog-content">
        <div
          class="provincename"
          v-for="(item, index) in area_project_detail"
          :key="index"
        >
          <div class="tit">{{ item.province }}</div>
          <div class="earnings">
            项目数：<span>{{ item.project_num }}+</span> 营收：<span
              >{{ item.allrevenue }}万+</span
            >
          </div>
          <el-carousel
            class="project_type"
            :autoplay="false"
            indicator-position="none"
            :arrow="item.project_list.length > 1 ? 'true' : 'never'"
            v-if="item.project_list.length > 0"
          >
            <el-carousel-item
              v-for="(item1, index1) in item.project_list"
              :key="index1"
            >
              <div class="project_box">
                <div
                  class="project_type_list"
                  :class="{ margin: index2 == 1 }"
                  v-for="(item2, index2) in item1"
                  :key="index2"
                  @click="gopage(item2)"
                >
                  <div v-if="item2.show_detail == 1">
                    <div class="list1">
                      <img
                        v-if="item2.image.url"
                        :src="item2.image.url"
                        alt=""
                      />
                      <img
                        v-else
                        src="../assets/newdetail_default.png"
                        alt=""
                      />
                    </div>
                    <div class="list2">{{ item2.name }}</div>
                    <div class="list3">
                      项目类型：<span>{{ item2.category }}</span>
                    </div>
                    <div class="list4">
                      营收：<span>{{ item2.revenue }}万+</span>
                    </div>
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
          <div v-else class="empty">
            <img src="../assets/noproject.png" alt="" />
            <div>无展示项目</div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          class="submit-btn"
          type="primary"
          @click="project_dialog_save"
          >关 闭</el-button
        >
      </span>
    </el-dialog>

    <!-- 项目分布弹窗 -->
    <el-dialog
      title=" 项目分布"
      :visible.sync="project_distribution_dialog"
      width="830px"
      class="public_dialog edit_distribution"
      :close-on-click-modal="false"
      :modal-append-to-body="true"
      :append-to-body="true"
    >
      <div class="dialog-content">
        <div class="tit"><span></span>区域列表</div>
        <div>
          <el-table
            :data="area_list"
            border
            style="width: 100%"
            max-height="321"
            class="area_list_table"
            :header-cell-style="{ background: '#60a1e1', color: '#fff' }"
            :cell-style="{ borderColor: '#fff' }"
          >
            <template slot="empty">
              <div class="achievement_table_empty">
                <img src="../assets/newhomeempty.png" alt="" />
                <div class="empty_text">
                  暂无数据，<span @click="area_list_table_add">添加一行</span>
                </div>
              </div>
            </template>
            <el-table-column label="序号" width="72px" align="center">
              <template slot-scope="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column label="区域名称" width="200px" align="center">
              <template slot-scope="scope">
                <el-input
                  :class="{ errorclass: scope.row.tag }"
                  @focus="inputfocus(scope.row, 0)"
                  v-model="scope.row.name"
                  placeholder="请输入，10字内"
                  maxlength="10"
                ></el-input>
              </template>
            </el-table-column>

            <el-table-column
              label="包含省份"
              width="417px"
              align="center"
              class-name="province"
            >
              <template slot-scope="scope">
                <!-- v-for="(item, index) in scope.row.province" :key="index" -->
                <div class="shengfen">
                  <div
                    class="shengfen_box"
                    v-for="(item, index) in scope.row.provinces"
                    :key="index + item"
                    :class="{
                      errorclass2:
                        (!scope.row.provinces[index] ||
                          scope.row.provinces[index] == '') &&
                        provinceerror,
                      errorclass1:
                        scope.row.province_i == index && provinceerror,
                    }"
                  >
                    <el-select
                      class="shengfen_select"
                      v-model="scope.row.provinces[index]"
                      placeholder="请选择"
                      @change="selectcode(scope.row, index)"
                    >
                      <el-option
                        v-for="(item1, index1) in options"
                        :key="index1"
                        :label="item1.label"
                        :value="item1.value"
                      >
                      </el-option>
                    </el-select>
                    <div class="icon_i">
                      <i
                        @click="delprovince(scope.row.provinces, index)"
                        class="el-input__icon el-icon-error"
                      ></i>
                    </div>
                  </div>
                  <div
                    class="addprovince"
                    @click="addprovince(scope.row.provinces)"
                  >
                    <i class="el-icon-plus"></i>
                    添加
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              width="100px"
              align="center"
              class-name="operation"
            >
              <template slot-scope="scope">
                <img
                  @click="area_list_table_del(scope.$index)"
                  src="../assets/newdel.png"
                  alt=""
                />
              </template>
            </el-table-column>
          </el-table>
          <div class="add">
            <i v-if="area_list.length > 0" class="el-icon-plus"></i>
            <span
              class="span"
              v-if="area_list.length > 0"
              @click="area_list_table_add"
            >
              添加一行
            </span>
            <span v-else style="color: rgba(0, 0, 0, 0.85)"> 添加一行 </span>
          </div>
        </div>
        <div class="tit"><span></span>项目数据</div>
        <div class="deal_with">
          <div class="dealwith_l">
            <!-- v-model.trim="" -->
            <el-input
              placeholder="请输入关键词"
              v-model="keyword"
              @change="searchevent"
            >
              <i
                slot="suffix"
                class="el-input__icon el-icon-search"
                style="cursor: pointer"
                @click="filter"
              ></i>
            </el-input>
            <el-popover
              placement="bottom"
              width="418"
              title="筛选设置"
              popper-class="filter_l_pop"
              v-model="show_filter"
            >
              <div class="content">
                <div class="name">所在省份</div>
                <el-select
                  class="select2"
                  v-model="pro_province"
                  placeholder="全部"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.label"
                  >
                  </el-option>
                </el-select>
                <div class="name">项目类型</div>
                <el-select
                  class="select2"
                  v-model="pro_type"
                  placeholder="全部"
                >
                  <el-option
                    v-for="item in all_category"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
                <div class="name">是否提供大屏</div>
                <el-checkbox-group class="checkbox" v-model="checkList">
                  <el-checkbox :label="1">提供</el-checkbox>
                  <el-checkbox :label="2">不提供</el-checkbox>
                </el-checkbox-group>
              </div>

              <div class="btn">
                <span class="btn1" @click="restore_default">恢复默认</span>
                <span class="btn2" @click="filter">筛 选</span>
              </div>
              <div slot="reference" class="filter">
                <i class="iconfont dlh-shaixuan"></i>

                <div>筛选</div>
              </div>
            </el-popover>

            <!-- <div class="searchicon">
              <i class="el-icon-refresh"></i>
            </div> -->
          </div>

          <div class="dealwith_r">
            <div class="son r3" @click="project_list_add">添加</div>
            <div class="son r2">
              <file @accept="accept"></file>
            </div>
            <div class="son r1" @click="download_template">下载导入模板</div>
            <div class="son r1" @click="cleardata">清空数据</div>
          </div>
        </div>
        <div>
          <el-table
            :data="project_list_table"
            border
            style="width: 100%"
            class="project_list_table"
            :header-cell-style="{ background: '#60a1e1', color: '#fff' }"
            :cell-style="{ borderColor: '#fff' }"
            :key="Math.random()"
          >
            <template slot="empty">
              <div class="achievement_table_empty">
                <img src="../assets/newhomeempty.png" alt="" />
                <div class="empty_text">
                  暂无数据，<span @click="project_list_add">添加一行</span>
                </div>
              </div>
            </template>
            <el-table-column label="序号" width="72px" align="center">
              <template slot-scope="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column
              label="项目名称"
              width="150px"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.name"
                  placeholder="请输入，20字内"
                  maxlength="20"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              label="所在省份"
              width="150px"
              align="center"
              class-name="province"
            >
              <template slot-scope="scope">
                <!-- v-for="(item, index) in scope.row.province" :key="index" -->
                <el-select
                  class="shengfen_select"
                  v-model="scope.row.province"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item1, index1) in options"
                    :key="index1"
                    :label="item1.label"
                    :value="item1.value"
                  >
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="项目类型" width="150px" align="center">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.category"
                  placeholder="请输入，10字内"
                  maxlength="10"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              label="项目营收（万元）"
              width="150px"
              align="center"
            >
              <template slot-scope="scope">
                <el-input
                  type="number"
                  oninput="if(value.length>10)value=value.slice(0,10)"
                  v-model="scope.row.revenue"
                  placeholder="请输入，10字内"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              label="是否提供项目大屏"
              width="150px"
              align="center"
            >
              <template slot-scope="scope">
                <el-checkbox
                  v-model="scope.row.show_detail"
                  :checked="scope.row.show_detail == 1 ? true : false"
                  :false-label="2"
                  :true-label="1"
                ></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column
              label="项目配图"
              width="150px"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <small1
                  :key="small_timer"
                  :backup_filename="scope.row.image.name"
                  typeimg="company_platform_image"
                  @getMsg="devement_mesage"
                  @get="devement(scope.row)"
                >
                </small1>
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              width="100px"
              align="center"
              fixed="right"
              class-name="operation"
            >
              <template slot-scope="scope">
                <img
                  @click="project_list_del(scope.$index)"
                  src="../assets/newdel.png"
                  alt=""
                />
              </template>
            </el-table-column>
          </el-table>
          <div class="page">
            <pageNation
              :rawdata="project_list"
              :errorindex="error_index"
              @listChange="listChange"
              @project_del="project_del"
              ref="pagechild"
            ></pageNation>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancel" @click="distribution_dialog_cancel"
          >取 消</el-button
        >
        <el-button
          class="submit-btn"
          type="primary"
          @click="distribution_dialog_save"
          >保存</el-button
        >
      </span>
    </el-dialog>

    <!-- 发展历程弹窗 -->
    <el-dialog
      title="发展历程"
      :visible.sync="development_dialog"
      width="830px"
      class="public_dialog edit_development"
      :close-on-click-modal="false"
      :modal-append-to-body="true"
      :append-to-body="true"
    >
      <div class="dialog-content">
        <div>
          <el-table
            :data="development_list"
            border
            style="width: 100%"
            class="development_table"
            :header-cell-style="{ background: '#60a1e1', color: '#fff' }"
            :cell-style="{ borderColor: '#fff' }"
          >
            <template slot="empty">
              <div class="achievement_table_empty">
                <img src="../assets/newhomeempty.png" alt="" />
                <div class="empty_text">
                  暂无数据，<span @click="development_table_add">添加一行</span>
                </div>
              </div>
            </template>
            <el-table-column label="序号" width="72px" align="center">
              <template slot-scope="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column
              label="时间"
              width="150px"
              align="center"
              class-name="data"
            >
              <template slot-scope="scope">
                <el-date-picker
                  :class="{ errorclass: scope.row.tag }"
                  @focus="inputfocus(scope.row, 0)"
                  v-model="scope.row.date"
                  type="month"
                  format="yyyy年MM月"
                  value-format="yyyy-MM"
                  placeholder="选择日期"
                  ref="datedate"
                  class="data_picker"
                  popper-class="development_picker"
                >
                  <i slot="suffix" class="el-input__icon el-icon-date"></i>
                </el-date-picker>
              </template>
            </el-table-column>
            <el-table-column label="描述" width="312px" align="center">
              <template slot-scope="scope">
                <el-input
                  :class="{ errorclass: scope.row.tag1 }"
                  @focus="inputfocus(scope.row, 1)"
                  v-model="scope.row.desc"
                  placeholder="请输入，50字内"
                  maxlength="50"
                ></el-input>
              </template>
            </el-table-column>

            <el-table-column
              label="配图"
              width="150px"
              align="center"
              class-name="peitu"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <small1
                  :backup_filename="scope.row.image.name"
                  typeimg="develop_image"
                  @getMsg="devement_mesage"
                  @get="devement(scope.row)"
                >
                </small1>
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              width="105px"
              align="center"
              class-name="operation"
            >
              <template slot-scope="scope">
                <img
                  @click="development_table_del(scope.$index)"
                  src="../assets/newdel.png"
                  alt=""
                />
              </template>
            </el-table-column>
          </el-table>
          <div class="add">
            <i v-if="development_list.length > 0" class="el-icon-plus"></i>
            <span
              class="span"
              v-if="development_list.length > 0"
              @click="development_table_add"
            >
              添加一行
            </span>
            <span v-else style="color: rgba(0, 0, 0, 0.85)"> 添加一行 </span>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancel" @click="development_dialog_cancel"
          >取 消</el-button
        >
        <el-button
          class="submit-btn"
          type="primary"
          @click="development_dialog_save"
          >保存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { newintroduce, template1 } from "../api/getdata"; //引入接口
import Sortable from "sortablejs";
import Upload from "../components/company_img_upfile";
import Upload2 from "../components/company_img_upfile2";
import small1 from "../components/figure_caption";
import imgdetail from "../components/imgdetail";
import * as echarts from "echarts";
import pageNation from "../components/pagenation";
import file from "../components/upfile";

import ma from "../components/map";
export default {
  components: {
    Upload,
    Upload2,
    small1,
    imgdetail,
    pageNation,
    file,
    ma,
  },
  inject: ["reload"], //注入依赖
  data() {
    return {
      enterable: true,

      //调试数据----------------------------------------------------------------

      options: [
        {
          value: "110000",
          label: "北京",
        },
        {
          value: "120000",
          label: "天津",
        },
        {
          value: "130000",
          label: "河北",
        },
        {
          value: "140000",
          label: "山西",
        },
        {
          value: "150000",
          label: "内蒙古",
        },
        {
          value: "210000",
          label: "辽宁",
        },
        {
          value: "220000",
          label: "吉林",
        },
        {
          value: "230000",
          label: "黑龙江",
        },
        {
          value: "310000",
          label: "上海",
        },
        {
          value: "320000",
          label: "江苏",
        },
        {
          value: "330000",
          label: "浙江",
        },
        {
          value: "340000",
          label: "安徽",
        },
        {
          value: "350000",
          label: "福建",
        },
        {
          value: "360000",
          label: "江西",
        },
        {
          value: "370000",
          label: "山东",
        },
        {
          value: "410000",
          label: "河南",
        },
        {
          value: "420000",
          label: "湖北",
        },
        {
          value: "430000",
          label: "湖南",
        },
        {
          value: "440000",
          label: "广东",
        },
        {
          value: "450000",
          label: "广西",
        },
        {
          value: "460000",
          label: "海南",
        },
        {
          value: "500000",
          label: "重庆",
        },
        {
          value: "510000",
          label: "四川",
        },
        {
          value: "520000",
          label: "贵州",
        },
        {
          value: "530000",
          label: "云南",
        },
        {
          value: "540000",
          label: "西藏",
        },
        {
          value: "610000",
          label: "陕西",
        },
        {
          value: "620000",
          label: "甘肃",
        },
        {
          value: "630000",
          label: "青海",
        },
        {
          value: "640000",
          label: "宁夏",
        },
        {
          value: "650000",
          label: "新疆",
        },
        {
          value: "710000",
          label: "台湾",
        },
        {
          value: "810000",
          label: "香港",
        },
        {
          value: "820000",
          label: "澳门",
        },
      ],

      value: "",
      checkList: [],
      //-------------------------------------------------------------------------------------------------------------

      ruleForm: {
        name: "", //企业名称
        slogan: "", //企业口号
        module: "",
        detail_list: [],
      },
      rules: {
        name: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
          {
            min: 1,
            max: 20,
            message: "长度在 1 到 20 个字符",
            trigger: "blur",
          },
        ],
        slogan: [{ required: true, message: "请填写标语", trigger: "blur" }],
        module: [
          { required: true, message: "请输入模块名称", trigger: "blur" },
          {
            min: 1,
            max: 10,
            message: "长度在 1 到 10 个字符",
            trigger: "blur",
          },
        ],
        title: [
          { required: true, message: "请输入小标题", trigger: "blur" },
          {
            min: 1,
            max: 30,
            message: "长度在 1 到 30 个字符",
            trigger: "blur",
          },
        ],
        paragraph: [
          { required: true, message: "请输入介绍文案", trigger: "blur" },
        ],
      },

      edit_type: false,
      title_dialog: false, //控制编辑title的 弹窗

      badge_file_key_backup: "", //企业徽章图片信息地址
      badge_file_key: "", //企业徽章图片信息地址
      logo_file_key_backup: "",
      logo_file_key: "", //企业logo图片信息地址

      peitu_name: "", //临时保存成就上传图片名称
      peitu_url: "", //临时保存成就上传图片地址
      //成就列表
      achievement_list: [
        {
          name: "高新企业证书高新高新",
          image: {
            name: "",
            url: "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
          },
        },
        {
          name: "环保总包资质",
          image: {
            name: "",
            url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
          },
        },
        {
          name: "ISO系列认证",
          image: {
            name: "",
            url: "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
          },
        },
        {
          name: "ISO系列认证IS",
          image: {
            name: "",
            url: "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
          },
        },
      ],
      srcList: [
        "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
        "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
        "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      ],
      company_img_dialog: false, //企业画像弹窗控制
      activeindex: 0, //控制显示nav

      detail_filekey: "", //临时保存详细信息上传后的图片名称
      local_image: "", //临时保存详细信息上传后的图片地址

      active_right_index: -1, //添加模块时 选中的哪一个
      all_introduce_list: [], //所有企业介绍模块列表
      introduce_list: [], //不超过的企业介绍模块列表
      over_introduce_list: [], //超过一定的企业介绍模块列表
      id: "", //模块详情id
      content: [], //模块详情列表
      timer: "", //时间戳
      up_new: "", //更新还是添加
      small_timer: "", // 配图时间戳
      add_detail_dialog: false,
      project_detial_dialog: false, //项目详情弹窗
      project_distribution_dialog: false, //项目分布弹窗
      provinceerror: false, //没用选择省份时控制报错
      province_i: -1, // 哪个省份重复了
      project_alldata: {}, // 传给地图子组件的数据
      //项目分布的区域分布
      area_list: [
        { name: "", provinces: ["540000", "710000"] },
        { name: "", provinces: ["530000", "500000"] },
        { name: "", provinces: ["810000", "650000"] },
        { name: "", provinces: ["610000", "340000"] },
      ],
      //项目分布的项目列表
      project_list: [
        {
          name: "",
          province: "",
          category: "",
          revenue: null,
          show_detail: 1,
          image: { name: "", url: "" },
        },
        {
          name: "",
          province: "",
          category: "",
          revenue: null,
          show_detail: 2,
          image: { name: "", url: "" },
        },
        {
          name: "",
          province: "",
          category: "",
          revenue: null,
          show_detail: 2,
          image: { name: "", url: "" },
        },
        {
          name: "",
          province: "",
          category: "",
          revenue: null,
          show_detail: 1,
          image: { name: "", url: "" },
        },
      ],
      //分页后的table数据
      project_list_table: [],
      project_list_filter: [], //筛选备用数据
      // 项目分布的页面展示的区域数据
      showarea_list: [],
      project_list_index: 0, //删除的索引
      area_project_detail: [], //点击详情之后数据
      detail_name: "", // 详情弹窗的title
      show_filter: false, // 显示筛选框
      keyword: "", //筛选的关键词
      pro_type: "", // 项目分类
      pro_province: "", //项目省份
      all_category: [], //所有分类
      error_index: 0, //校验时看那个数据有问题

      development_dialog: false, // 发展历程弹窗控制
      fazhan_name: "", //临时保存发展历程上传图片名称
      fazhan_url: "", //临时保存发展历程上传图片地址
      development_list: [
        { date: "", desc: "", image: { name: "", url: "" } },
        { date: "", desc: "", image: { name: "", url: "" } },
        { date: "", desc: "", image: { name: "", url: "" } },
        { date: "", desc: "", image: { name: "", url: "" } },
        { date: "", desc: "", image: { name: "", url: "" } },
        { date: "", desc: "", image: { name: "", url: "" } },
        { date: "", desc: "", image: { name: "", url: "" } },
        { date: "", desc: "", image: { name: "", url: "" } },
        { date: "", desc: "", image: { name: "", url: "" } },
      ],
      transform_develop_list: [], //变形后的发展历史数组
    };
  },
  destroyed() {
    document.body.style.backgroundColor = "";
  },
  created() {
    this.companyplatform_detail(); //企业项目分布详情
  },
  mounted() {
    document.body.style.backgroundColor = "#FFF";
    this.companyname_detail(); // 获取企业名称
    this.companyportrait_detail(); //获取企业画像详情
    this.companydevelop_detail(); //获取公司发展历程详情
    this.companyintroduce_list(); //获取介绍公司详情列表

    // this.init_map();
  },
  watch: {
    title_dialog(newVal, oldVal) {
      this.companyname_detail();
      if (!newVal) {
        this.$refs.ruleForm.clearValidate();
      }
    },
    company_img_dialog() {
      this.companyportrait_detail();
    },
    add_detail_dialog(newVal, oldVal) {
      this.active_right_index = -1;
      // console.log(this.detail_list);
      // setTimeout((ele) => {
      //   if (newVal) {
      //     console.log(document.getElementById("ul"));
      //     new Sortable(document.getElementById("ul"), {
      //       animation: 150,
      //       ghostClass: "blue-background-class",
      //       sort: true,
      //       onEnd: this.onend,
      //     });
      //   }
      // }, 500);
      if (newVal) {
      } else {
        this.ruleForm.module = "";
        this.ruleForm.detail_list = [];
      }
    },
    project_distribution_dialog() {
      this.provinceerror = false;
      this.companyplatform_detail();
    },
    development_dialog(newVal, oldVal) {
     
        this.companydevelop_detail();
      
    },
  },
  methods: {
    //点击标题修改按钮
    editTitle() {
      this.title_dialog = true;
    },
    // 显示编辑按钮
    changeEdit() {
      this.edit_type = true;
    },
    //退出编辑
    exitEdit() {
      console.log(1);
      this.edit_type = false;
    },
    //标题弹窗取消
    title_dialog_cancel() {
      this.title_dialog = false;
    },
    //获取企业名称
    companyname_detail() {
      newintroduce("companyname_detail").then((ele) => {
        if (ele.code == 2000) {
          this.ruleForm.name = ele.data?.name || "";
        }
      });
    },
    //标题弹窗保存
    title_dialog_save() {
      this.$refs.ruleForm.validate((valid) => {
        console.log(valid);
        if (valid) {
          newintroduce("companyname_save", {
            name: this.ruleForm.name,
          }).then((ele) => {
            if (ele.code == 2000) {
              this.$message.success({
                showClose: true,
                message: "成功：操作成功",
                duration: 1000,
              });
              this.title_dialog = false;
            }
            console.log(ele);
          });
        } else {
          console.log(2);
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 点击按钮预览图片
    previewPic(item, index) {
      console.log(item);
      console.log(this.$refs.previewImg[0].$el);
      if (item.image.url) {
        this.srcList = [item.image.url];
        this.$refs.previewImg[index].showViewer = true;
      }
    },
    edit_company_img() {
      this.company_img_dialog = true;
    },
    //企业画像详情
    companyportrait_detail() {
      newintroduce("companyportrait_detail").then((ele) => {
        if (ele.code == 2000) {
          // console.log(ele);
          this.logo_file_key = ele.data?.logo || "";
          this.badge_file_key = ele.data?.badge || "";
          this.ruleForm.slogan = ele.data?.slogan || "";
          this.achievement_list = ele.data?.achievement || [];
          this.achievement_list.forEach((item) => {
            if (!item.image) {
              item.image = {
                name: "",
                url: "",
              };
            }
          });
        }
        // console.log(ele);
      });
    },
    //企业画像保存
    company_img_dialog_save() {
      this.$refs.slogan.validate((valid) => {
        if (valid) {
          let arg = {};
          console.log(this.logo_file_key_backup);
          console.log(this.logo_file_key);
          console.log(this.badge_file_key);
          console.log(this.badge_file_key_backup);
          if (
            this.logo_file_key_backup == "" &&
            (this.logo_file_key != "" || this.logo_file_key)
          ) {
            arg.logo = this.logo_file_key;
          } else {
            arg.logo = this.logo_file_key_backup;
          }

          if (
            this.badge_file_key_backup == "" &&
            (this.badge_file_key != "" || this.badge_file_key)
          ) {
            arg.badge = this.badge_file_key;
          } else {
            arg.badge = this.badge_file_key_backup;
          }
          arg.slogan = this.ruleForm.slogan;
          arg.achievement = this.achievement_list;
          console.log(arg);
          if (!arg.logo || arg.logo == "") {
            this.$message.error({
              showClose: true,
              message: "企业logo不能为空",
            });

            return;
          }
          if (!arg.badge || arg.badge == "") {
            this.$message.error({
              showClose: true,
              message: "企业徽章不能为空",
            });
            return;
          }
          if (arg.achievement.length <= 0) {
            this.$message.error({
              showClose: true,
              message: "企业成就不能为空",
            });
            return;
          }
          for (let item of arg.achievement) {
            if (item.name == "" || !item.name) {
              this.$message.error({
                showClose: true,
                message: "成就项不能为空",
              });
              return;
            }
          }

          arg.achievement = this.jsonClone(arg.achievement);
          for (let item of arg.achievement) {
            if (!item.image.url || item.image.url == "") {
              item.image = null;
            }
          }

          newintroduce("companyportrait_save", arg).then((ele) => {
            if (ele.code == 2000) {
              this.$message.success({
                showClose: true,
                message: "保存成功",
                duration: 1000,
              });
              this.companyportrait_detail();
              this.company_img_dialog = false;
            } else {
              this.$message.error({
                message: "保存失败请稍后重试",
              });
            }
            console.log(ele);
          });
        } else {
          console.log("error submit!!");
          return false;
        }
        console.log(valid);
      });
    },
    // 企业画像取消
    company_img_dialog_cancel() {
      this.company_img_dialog = false;
    },
    // 企业画像传来的信息
    childMsg(value, type) {
      console.log(value);
      if (type == 1) {
        this.logo_file_key_backup = value;
      }
      if (type == 2) {
        this.badge_file_key_backup = value;
      }
    },
    //企业成就传来的信息
    received_mesage(url, name) {
      this.peitu_url = url;
      this.peitu_name = name;
    },
    received(scoperow) {
      scoperow.image.name = this.peitu_name;
      scoperow.image.url = this.peitu_url;
      console.log(scoperow);
    },
    //发展历程传来的信息
    devement_mesage(url, name) {
      this.fazhan_url = url;
      this.fazhan_name = name;
    },
    devement(scoperow) {
      scoperow.image.name = this.fazhan_name;
      scoperow.image.url = this.fazhan_url;
    },

    //介绍详情传来的信息
    detail_mesage(file_key, local_image) {
      this.detail_filekey = file_key;
      this.local_image = local_image;
      console.log(file_key);
    },
    detail(item) {
      if (item.type == 3) {
        item.big_image = this.detail_filekey;
        item.local_image = this.local_image;
      }
      if (item.type == 4) {
        item.small_image = this.detail_filekey;
        item.local_image = this.local_image;
      }
      console.log(item);
    },

    //企业画像表格添加
    achievement_table_add() {
      this.achievement_list.push({ name: "", image: { url: "", name: "" } });
      this.$nextTick(() => {
        let lidv = document.querySelector(
          ".achievement_table .el-table__body-wrapper"
        ); // 要滚动的元素
        lidv.scrollTop = lidv.scrollHeight;
      });
    },
    //企业画像表格删除
    achievement_table_del(index) {
      this.achievement_list.splice(index, 1);
    },
    //点击nav
    changenav(item, index) {
      this.activeindex = index;
      console.log(item);
      this.id = item.id;
      this.companyintroduce_detail(); //企业详细介绍模块详情
    },
    //显示编辑弹窗
    showdetail(item, index) {
      console.log(item, index);
      this.up_new = "up";
      this.id = item.id;
      newintroduce("companyintroduce_detail", { id: item.id }).then((ele) => {
        if (ele.code == 2000) {
          this.ruleForm.module = ele.data.module;
          this.ruleForm.detail_list = ele.data.content;
          this.add_detail_dialog = true;
        } else {
        }
        console.log(ele);
      });
    },
    // 打开添加详细介绍弹窗
    add_detail() {
      this.up_new = "new";
      this.add_detail_dialog = true;
    },
    // 列表拖拽重新定义数据
    onend(evt) {
      console.log(this.detail_list);

      let itemEl = evt.item; // dragged HTMLElement
      let oldIndex = evt.oldIndex;
      let newIndex = evt.newIndex;
      let temp = this.ruleForm.detail_list[oldIndex];
      if (oldIndex < newIndex) {
        //向下移动调整顺序
        for (var i = oldIndex; i < newIndex; i++) {
          this.ruleForm.detail_list[i] = this.ruleForm.detail_list[i + 1];
        }
      } else if (oldIndex > newIndex) {
        //向上移动时调整顺序
        for (var i = oldIndex; i > newIndex; i--) {
          this.ruleForm.detail_list[i] = this.ruleForm.detail_list[i - 1];
        }
      }
      this.ruleForm.detail_list[newIndex] = temp;
      console.log(this.ruleForm.detail_list);
    },
    //点击文案
    click_right(index) {
      console.log(index);
      this.active_right_index = index;
    },
    detali_del(index, type) {
          // this.$refs.introduce_deail.resetFields();
         this.$refs.introduce_deail.clearValidate();
      this.active_right_index = -1;
      let length = this.ruleForm.detail_list.length;
      console.log(this.ruleForm.detail_list);

      if (type == 1) {
        if (index != 0) {
          this.$nextTick(() => {
            let temp = this.ruleForm.detail_list[index];
            let temp1 = this.ruleForm.detail_list[index - 1];
            this.$set(this.ruleForm.detail_list, index, temp1);
            this.$set(this.ruleForm.detail_list, index - 1, temp);
            this.$forceUpdate();
          });
        }
      }
      if (type == 2) {
        if (index + 1 < length) {
          this.$nextTick(() => {
            let temp = this.ruleForm.detail_list[index];
            let temp1 = this.ruleForm.detail_list[index + 1];
            this.$set(this.ruleForm.detail_list, index, temp1);
            this.$set(this.ruleForm.detail_list, index + 1, temp);
            this.$forceUpdate();
          });
        }
      }
      if (type == 3) {
        this.ruleForm.detail_list.splice(index, 1);
      }
      console.log(this.ruleForm.detail_list);
      this.timer = new Date().getTime();
    },

    // 添加标签
    addhtml(type) {
  

  
      let index = this.active_right_index;
      console.log(index);
      if (type == 1) {
        this.$nextTick(() => {
          if (index == -1) {
            this.ruleForm.detail_list.push({ type: 1, title: "" });
          } else {
            this.ruleForm.detail_list.splice(index + 1, 0, {
              type: 1,
              title: "",
            });
          }
        });
      }
      if (type == 2) {
        this.$nextTick(() => {
          if (index == -1) {
            this.ruleForm.detail_list.push({ type: 2, paragraph: "" });
          } else {
            this.ruleForm.detail_list.splice(index + 1, 0, {
              type: 2,
              paragraph: "",
            });
          }
        });
      }
      if (type == 3) {
        this.$nextTick(() => {
          if (index == -1) {
            this.ruleForm.detail_list.push({
              type: 3,
              big_image: "",
              local_image: "",
            });
          } else {
            this.ruleForm.detail_list.splice(index + 1, 0, {
              type: 3,
              big_image: "",
              local_image: "",
            });
          }
        });
      }
      if (type == 4) {
        this.$nextTick(() => {
          if (index == -1) {
            this.ruleForm.detail_list.push({
              type: 4,
              small_image: "",
              local_image: "",
            });
          } else {
            this.ruleForm.detail_list.splice(index + 1, 0, {
              type: 4,
              small_image: "",
              local_image: "",
            });
          }
        });
      }


      console.log(this.ruleForm.detail_list);
    },
    // 删除当前模块
    add_detail_dialog_del() {
      // this.add_detail_dialog=false;

      let id = this.id;

      this.$confirm("确认删除当前详细介绍模块吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          newintroduce("companyintroduce_delete", { id }).then((ele) => {
            console.log(ele);
            if (ele.code === 2000) {
              this.add_detail_dialog = false;
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.companyintroduce_list(); //获取介绍公司详情列表
            } else {
            }
          });
        })
        .catch(() => {});
    },
    // 添加详细介绍弹窗取消
    add_detail_dialog_cancel() {
      this.add_detail_dialog = false;
    },
    // 添加详细介绍弹窗保存
    add_detail_dialog_save() {
      this.$refs.introduce_deail.validate((valid) => {
        if (valid) {
          console.log(this.up_new);
          if (this.up_new == "new") {
            let arg = {};

            arg.module = this.ruleForm.module;
            arg.content = this.ruleForm.detail_list;
            console.log(arg);
            for (let item of arg.content) {
              if (item.type == 3 || item.type == 4) {
                if (item.big_image == "" || item.small_image == "") {
                  this.$message.error({
                    message: "请上传配图",
                  });
                  return;
                }
              }
            }

            if (arg.content.length <= 0) {
              this.$message.error({
                message: "至少有一项介绍文案",
              });
              return;
            } else {
              newintroduce("companyintroduce_add", arg).then((ele) => {
                if (ele.code == 2000) {
                  this.$message.success({
                    showClose: true,
                    message: "保存成功",
                    duration: 1000,
                  });
                  this.companyintroduce_list(); //获取介绍公司详情列表
                  this.add_detail_dialog = false;
                } else {
                  this.$message.error({
                    message: "保存失败，请稍后重试",
                  });
                }
                console.log(ele);
              });
            }
          }
          if (this.up_new == "up") {
            let arg = {};
            arg.id = this.id;
            arg.module = this.ruleForm.module;
            arg.content = this.ruleForm.detail_list;

            if (arg.content.length <= 0) {
              this.$message.error({
                message: "至少有一项介绍文案",
              });
              return;
            } else {
              newintroduce("companyintroduce_update", arg).then((ele) => {
                if (ele.code == 2000) {
                  this.$message.success({
                    showClose: true,
                    message: "保存成功",
                    duration: 1000,
                  });
                  this.companyintroduce_list(); //获取介绍公司详情列表
                  this.add_detail_dialog = false;
                } else {
                  this.$message.error({
                    message: "保存失败，请稍后重试",
                  });
                }
                console.log(ele);
              });
            }
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //企业详细介绍模块列表
    companyintroduce_list() {
      newintroduce("companyintroduce_list").then((ele) => {
        if (ele.code == 2000) {
          if (ele.data) {
            this.id = ele.data[0].id;
            this.activeindex = 0;
            let arr = [...ele.data];
            this.all_introduce_list = ele.data;
            if (ele.data.length <= 5) {
              this.introduce_list = arr;
            } else {
              this.introduce_list = arr.splice(0, 5);
              this.over_introduce_list = arr;
            }
            this.companyintroduce_detail(); //企业详细介绍模块详情
          }
        } else {
        }
        // console.log(ele);
      });
    },
    //企业介绍模块详情
    companyintroduce_detail() {
      newintroduce("companyintroduce_detail", { id: this.id }).then((ele) => {
        if (ele.code == 2000) {
          this.content = ele.data.content;
        } else {
        }
        // console.log(ele);
      });
    },
    // 项目详情
    itemdetial(item) {
      console.log(item);
      this.detail_name = item.name;

      this.area_project_detail = this.jsonClone(item.province_list);

      this.area_project_detail.forEach((item0) => {
        // console.log(item0);
        let arr = [];

        item0.project_list.forEach((item1) => {
          item1.forEach((item2) => {
            if (item2.show_detail == 1) {
              arr.push(item2);
            }
          });
        });
        // console.log(arr);
        item0.project_list = arr;
        let bhc = this.arr_segmentation(item0.project_list, 3);
        item0.project_list = bhc;
      });

      // console.log(item.province_list);
      console.log(this.area_project_detail);

      this.project_detial_dialog = true;
    },
    //项目详情关闭
    project_dialog_save() {
      this.project_detial_dialog = false;
    },
    //跳转到项目详情
    gopage(item) {
      console.log(item);

      template1("templatedetail", {
        uni_code: item.uni_code,
      }).then((ele) => {
        console.log(ele);
        let path = "/project";
        if (ele.data.template == 1) {
          path = "/project1";
        }
        let routeData = this.$router.resolve({
          path,
          query: {
            uni_code: item.uni_code || "",
            name: item.name || "",
          },
        });
        window.open(routeData.href, "_blank");
      });
    },
    //显示项目分布弹窗
    project_distribution() {
      this.project_distribution_dialog = true;
    },
    //项目分布弹窗取消
    distribution_dialog_cancel() {
      this.project_distribution_dialog = false;
    },
    //企业项目分布详情
    companyplatform_detail() {
      newintroduce("companyplatform_detail").then((ele) => {
        if (ele.code == 2000) {
          this.project_alldata = ele.data || { platforms: [], region: [] };
          if (ele.data) {
            ele.data.platforms.forEach((item) => {
              if (!item.image) {
                item.image = {
                  name: "",
                  url: "",
                };
              }
            });
          }
          this.area_list = ele.data?.region || [];
          this.project_list = ele.data?.platforms || [];
          this.project_list_filter = ele.data?.platforms || [];
          this.project_list_filter.forEach((item, index) => {
            // console.log(item);
            if (!this.all_category.includes(item.category)) {
              this.all_category.push(item.category);
            }
          });
          // console.log(this.all_category);

          if (ele.data) {
            let pro = [...ele.data.platforms];
            let area = [...ele.data.region];

            pro.forEach((item, index) => {
              //把省份变成对应的字
              this.options.forEach((item2, index2) => {
                if (item.province == item2.value) {
                  this.$set(item, "province", item2.label);
                }
              });
            });
            area.forEach((item, index) => {
              item.provinces.forEach((item1, index1) => {
                //把省份变成对应的字
                this.options.forEach((item2, index2) => {
                  if (item1 == item2.value) {
                    this.$set(item.provinces, index1, item2.label);
                  }
                });
              });
            });

            // console.log(pro);
            area.forEach((item, index) => {
              // 各区域省份包含的项目
              item.province_list = [];
              //所有项目
              item.project = [];
              //营收总和
              item.allrevenue = 0;

              //计算各区域省份包含的项目
              item.provinces.forEach((item1, index1) => {
                this.$set(item.province_list, index1, {
                  province: item1,
                  allrevenue: 0,
                  project_num: 0,
                  project_list: [],
                });

                pro.forEach((item3, index3) => {
                  if (item3.province == item1) {
                    if (item3.show_detail == 1) {
                    }
                    item.province_list[index1].project_list.push(item3);
                    item.province_list[index1].project_num += 1;
                    item.province_list[index1].allrevenue += item3.revenue;
                  }
                });
                //各区域所有项目
                item.province_list[index1].project_list.forEach(
                  (item2, index2) => {
                    item.project.push(item2);
                  }
                );
                //分割数组
                item.province_list[index1].project_list = this.arr_segmentation(
                  item.province_list[index1].project_list,
                  3
                );
                // console.log(item.province_list[index1].project_list);
              });
              // console.log(item.project);
              // 计算总营业额
              item.project.forEach((item6, index6) => {
                item.allrevenue += item6.revenue;
              });
            });

            // console.log(area);
            //初始化图表数据
            this.showarea_list = this.arr_segmentation(area, 4);
            this.showarea_list.forEach((item, index) => {
              item.forEach((item1, index1) => {
                let category = [];
                let backup_category = [];
                let series = [];
                // console.log(item1.project);
                item1.project.forEach((item2, index2) => {
                  if (!category.includes(item2.category)) {
                    category.unshift(item2.category.substr(0, 2));
                    backup_category.unshift(item2.category);
                  }
                });
                // console.log(category);
                // console.log(item1.province_list);
                item1.province_list.forEach((item3, index3) => {
                  // console.log(item3);
                  series[index3] = {
                    name: item3.province,
                    type: "bar",
                    stack: "total",
                    emphasis: {
                      focus: "none",
                    },
                    // itemStyle: {
                    //   normal: { color: ['red',"blue"] },
                    // },

                    data: [],
                  };
                  // console.log(item3.project_list);
                  backup_category.forEach((item4, index4) => {
                    series[index3].data[index4] = 0;
                    item3.project_list.forEach((item5, index5) => {
                      item5.forEach((item6, index6) => {
                        if (item4 == item6.category) {
                          series[index3].data[index4] += item6.revenue;
                        } else {
                          series[index3].data[index4] += 0;
                        }
                      });
                    });
                  });
                });

                // console.log(series);

                this.projectdata(index, index1, category, series);
                this.timer = new Date().getTime();
              });
            });

            // area.forEach((item, index) => {
            //   item.provinces.forEach((item1, index1) => {
            //     //分割数组
            //     item.province_list[index1].project_list = this.arr_segmentation(
            //       item.province_list[index1].project_list,
            //       3
            //     );
            //   });
            // });
          } else {
          }
        }
        // console.log(ele);
      });
    },
    //项目分布省份删除
    delprovince(item, index) {
    
      item.splice(index, 1);
    },
    //深拷贝一个对象
    jsonClone(obj) {
      return JSON.parse(JSON.stringify(obj));
    },

    //项目分布弹窗保存
    distribution_dialog_save() {
      this.provinceerror = true;
      try {
        if (this.area_list.length <= 0) {
          this.$message.error({
            showClose: true,
            message: "区域列表不能为空",
          });
          throw "jumpout";
        }
        if (this.project_list.length <= 0) {
          this.$message.error({
            showClose: true,
            message: "项目数据不能为空",
          });
          throw "jumpout";
        }

        let arg = {};

        this.area_list.forEach((item, index) => {
          item.provinces.forEach((item1, index1) => {
            this.options.forEach((item2, index2) => {
              if (item1 == item2.label) {
                item1 = item2.value;
                this.$set(item.provinces, index1, item2.value);
              }
            });
          });
        });
        this.project_list.forEach((item) => {
          this.options.forEach((item2, index2) => {
            if (item.province == item2.label) {
              item.province = item2.value;
              // this.$set(item.provinces, index1, item2.value);
            }
          });
        });
        let array = [];
        for (let item of this.area_list) {
          for (let i = 0; i < item.provinces.length; i++) {
            // 首次遍历数组
            if (array.indexOf(item.provinces[i]) === -1) {
              // 判断索引有没有等于
              array.push(item.provinces[i]);
            } else {
              // item.province_i=i;
              this.$set(item, "province_i", i);

              console.log(item);
              this.$message.error({
                showClose: true,
                message: "区域列表省份不能重复",
              });
              throw "jumpout";
            }
          }

          console.log(item);
          if (item.name === "" || !item.name) {
            item.tag = true;
            return;
          } else {
            item.tag = false;
          }

          if (item.provinces.length <= 0) {
            this.$message.error({
              showClose: true,
              message: "请添加包含省份",
            });
            return;
          } else {
            for (let item1 of item.provinces) {
              // console.log(item1);
              if (item1 === "" || !item1) {
                this.provinceerror = true;
                this.$message.error({
                  showClose: true,
                  message: "请选择省份",
                });
                return;
              }
            }

            if (this.isRepeat(item.provinces)) {
              this.$message.error({
                showClose: true,
                message: "省份不能重复",
              });
              return;
            }
          }
        }

        this.project_list.forEach((item, index) => {
          if (item.name === "" || !item.name) {
            this.error_index = index;
            this.$message.error({
              showClose: true,
              message: "项目名称不能为空",
            });

            throw "jumpout";
          }

          if (item.province === "" || !item.province) {
            this.error_index = index;
            this.$message.error({
              showClose: true,
              message: "请选择所在省份",
            });

            throw "jumpout";
          }

          if (item.category === "" || !item.category) {
            this.error_index = index;
            this.$message.error({
              showClose: true,
              message: "项目类型不能为空",
            });

            throw "jumpout";
          }
          // console.log(item.revenue);
          // this.project_list.forEach((item, index) => {
          //   console.log(item.revenue);
          //   if (!item.revenue) {
          //     this.error_index = index;
          //     this.$message.error({
          //       showClose: true,
          //       message: "营收不能为空",
          //     });

          //     throw "jumpout";
          //   }
          //   item.revenue = Number(item.revenue);
          // });
          if (item.revenue==='') {
            this.error_index = index;
            this.$message.error({
              showClose: true,
              message: "营收不能为空",
            });

            throw "jumpout";
          }
          if (item.revenue < 0) {
            this.error_index = index;
            this.$message.error({
              showClose: true,
              message: "营收不能为负",
            });

            throw "jumpout";
          }
          item.revenue = Number(item.revenue);
        });

        arg.region = this.jsonClone(this.area_list);
        arg.platforms = this.jsonClone(this.project_list);

        arg.platforms.forEach((item, index) => {
          if (item.image.url === "") {
            item.image = null;
          }
        });

        newintroduce("companyplatform_save", arg).then((ele) => {
          if (ele.code == 2000) {
            this.$message.success({
              showClose: true,
              message: "保存成功",
              duration: 1000,
            });

            this.project_distribution_dialog = false;
            this.companyplatform_detail();
          } else {
          }
          console.log(ele);
        });
      } catch (e) {}
    },
    //是否有重复元素
    isRepeat(ary) {
      for (var i = 0; i < ary.length; i++) {
        if (ary.indexOf(ary[i]) != i) {
          // console.log(ary[i]);
          return true;
        }
      }
    },
    // 回车事件
    searchevent() {
      this.filter();
    },
    //筛选事件
    filter() {
      console.log(this.keyword);
      console.log(this.pro_type);
      console.log(this.pro_province);
      console.log(this.checkList);
      console.log(this.project_list_filter);
      this.$refs.pagechild.changepagesize();

      this.project_list = this.project_list_filter.filter((item, index) => {
        // console.log(item);
        if (this.keyword) {
          if (item.name.includes(this.keyword)) {
            // this.flag = true;
          } else {
            return false;
          }
        }
        if (this.pro_type) {
          if (item.category == this.pro_type) {
            // this.flag = true;
          } else {
            return false;
          }
        }
        if (this.pro_province) {
          if (item.province == this.pro_province) {
            // this.flag = true;
          } else {
            return false;
          }
        }

        if (this.checkList.length == 1) {
          if (item.show_detail == this.checkList[0]) {
          } else {
            return false;
          }
        }
        return true;
      });
      this.show_filter = false;

      console.log(this.project_list);
    },
    //恢复默认
    restore_default() {
      console.log(this.keyword);
      console.log(this.pro_type);
      console.log(this.pro_province);
      console.log(this.checkList);
      this.pro_type = "";
      this.pro_province = "";
      this.checkList = [];
      this.show_filter = false;
      this.filter();
    },
    //选择省份对应的code
    selectcode(scope_row, i) {
      this.$set(scope_row, "province_i", -1);
    },
    //区域列表表格添加
    area_list_table_add() {
      this.area_list.push({ name: "", provinces: [] });
      this.$nextTick(() => {
        let lidv = document.querySelector(
          ".area_list_table .el-table__body-wrapper"
        ); // 要滚动的元素
        lidv.scrollTop = lidv.scrollHeight;
      });
    },
    addprovince(scoperow) {
      this.provinceerror = false;
      console.log(scoperow);
      scoperow.push("");
    },
    //区域列表删除
    area_list_table_del(index) {
      this.area_list.splice(index, 1);
    },
    //批量上传数据
    accept(list) {
      let provinces = {};
      this.options.forEach((item, index1) => {
        provinces[item.label] = item.value;
      });
      list.forEach((item) => {
        let p = item.province;
        let code = provinces[p];
        item.province = code;
      });
      this.project_list = [...this.project_list, ...list];
      console.log(this.project_list);
      setTimeout(() => {
        this.$refs.pagechild.lastpage();
      }, 50);
    },
    download_template() {
      let tmpa = document.createElement("a");
      tmpa.download = "模板.xlsx";
      tmpa.target = "_blank";
      tmpa.href = "static/data_model.xlsx"; //绑定a标签
      tmpa.click(); //模拟点击实现下载
    },
    // 清空数据
    cleardata() {
      this.project_list = [];
    },
    //项目数据表格添加
    project_list_add() {
      if (
        this.keyword ||
        this.pro_type ||
        this.pro_province ||
        this.checkList.length > 0
      ) {
        this.keyword = "";
        this.pro_type = "";
        this.pro_province = "";
        this.checkList = [];
        this.filter();

        setTimeout(() => {
          this.project_list.push({
            name: "",
            province: "",
            category: "",
            revenue: "",
            show_detail: 2,
            image: { name: "", url: "" },
          });

          console.log(this.project_list);
          this.$refs.pagechild.lastpage();

          this.$nextTick(() => {
            let lidv = document.querySelector(
              ".project_list_table .el-table__body-wrapper"
            ); // 要滚动的元素
            lidv.scrollTop = lidv.scrollHeight;
          });
        }, 10);
      } else {
        this.project_list.push({
          name: "",
          province: "",
          category: "",
          revenue: "",
          show_detail: 2,
          image: { name: "", url: "" },
        });

        console.log(this.project_list);
        this.$refs.pagechild.lastpage();

        this.$nextTick(() => {
          let lidv = document.querySelector(
            ".project_list_table .el-table__body-wrapper"
          ); // 要滚动的元素
          lidv.scrollTop = lidv.scrollHeight;
        });
      }
    },
    project_del(size, page) {
      let index = size * (page - 1) + this.project_list_index;
      this.project_list.splice(index, 1);
      this.$refs.pagechild.curpentpage_del();
      // console.log(this.project_list.length);

      // setTimeout(()=>{
      //   this.$refs.pagechild.curpentpage_del();
      // },50)
    },
    //项目数据删除
    project_list_del(index) {
      // console.log(index);
      this.project_list_index = index;
      this.$refs.pagechild.del_list();
    },
    listChange(data) {
      // console.log(data);
      this.project_list_table = data;
    },
    //显示发展历程弹窗
    show_development_dialog() {
      this.development_dialog = true;
    },
    //发展历程详情
    companydevelop_detail() {
      newintroduce("companydevelop_detail").then((ele) => {
        if (ele.code == 2000) {
          this.development_list = ele.data?.history || [];
          this.transform_develop_list = this.arr_segmentation(
            ele.data?.history || [],
            9
          );
          // console.log(this.transform_develop_list);
        }
        // console.log(ele);
      });
    },
    //判断一个对象是否有重复
    is_repeat(arr, attr, tag, text) {
      let result = [];
      let obj = {};
      for (var i = 0; i < arr.length; i++) {
        if (!obj[arr[i][attr]]) {
          obj[arr[i][attr]] = true;
        } else {
          result.push(i);
        }
      }
      if (result.length > 0) {
        arr[result[0]][tag] = true;
        this.$message({
          message: text + "不能重复",
          type: "error",
        });
        return false;
      } else {
        return true;
      }
    },
    //发展弹窗保存
    development_dialog_save() {
      try {
        console.log(this.development_list);
        if (this.development_list.length <= 0) {
          this.$message({
            message: "不能为空",
            type: "error",
          });
          return;
        }

        for (let item of this.development_list) {
          if (item.date == "" || !item.date) {
            this.$set(item, "tag", true);
            // item.tag = true;
            console.log(item);
            this.$message({
              message: "时间不能为空",
              type: "error",
            });
            return;
          }
          if (item.desc == "" || !item.desc) {
            this.$set(item, "tag1", true);
            item.tag1 = true;
            console.log(item);
            this.$message({
              message: "描不能为空",
              type: "error",
            });
            return;
          }
        }

        let tag = false;
        this.development_list.forEach((item, index) => {
          let nextindex = index + 1;
          if (nextindex == this.development_list.length) {
            nextindex = index;
          }

          let a = this.development_list[index].date;
          let b = this.development_list[nextindex].date;
          let timea = new Date(a).getTime();
          let timeb = new Date(b).getTime();
          if (timea > timeb) {
            // this.development_list[index].tag = true;
            this.$set(this.development_list[index], "tag", true);
            this.$message({
              message: "时间顺序不正确",
              type: "error",
            });
            tag = true;
            throw "jumpout";
          }
        });

        if (tag) {
          return;
        } else {
          let isrepead = this.is_repeat(
            this.development_list,
            "date",
            "tag",
            "时间"
          );
          if (!isrepead) {
            return;
          }
        }

        newintroduce("companydevelop_save", {
          history: this.development_list,
        }).then((ele) => {
          if (ele.code == 2000) {
            this.$message.success({
              showClose: true,
              message: "保存成功",
              duration: 1000,
            });
            this.companydevelop_detail();
            this.development_dialog = false;
          } else {
            this.$message.error({
              message: "保存失败请稍后重试",
            });
          }
          console.log(ele);
        });
        this.development_dialog = false;
      } catch (e) {}
    },
    // 发展弹窗取消
    development_dialog_cancel() {
      this.development_dialog = false;
    },
    // 发展历程添加
    development_table_add() {
      this.development_list.push({
        date: "",
        desc: "",
        image: { name: "", url: "" },
      });
      this.$nextTick(() => {
        let lidv = document.querySelector(
          ".edit_development .el-table__body-wrapper"
        ); // 要滚动的元素
        lidv.scrollTop = lidv.scrollHeight;
      });
    },
    // 发展历程删除
    development_table_del(index) {
      this.development_list.splice(index, 1);
    },
    //输入框获取焦点
    inputfocus(scoperow, type) {
      if (type == 0) {
        this.$set(scoperow, "tag", false);
      }
      if (type == 1) {
        this.$set(scoperow, "tag1", false);
      }
    },
    //数组分割
    arr_segmentation(arr, n) {
      // 将data数组分割成[[1,2,3],[4,5,6],[7,8,9]]
      // var data = [1, 2, 3, 4, 5, 6, 7, 8, 9];
      let newArr = [];
      for (let i = 0; i < arr.length; i += n) {
        newArr.push(arr.slice(i, i + n));
      }
      // console.log(newArr); //[[1,2,3],[4,5,6],[7,8,9]]
      return newArr;
    },
    //找到省份对应的code
    findKey(obj, value) {
      return Object.keys(obj).find((k) => {
        return obj[k] == value;
      });
    },

    projectdata(index, index1, category, series) {
      this.$nextTick(function () {
        let a = "" + index + index1;
        // 基于准备好的dom，初始化echarts实例
        var myChart = echarts.init(document.getElementById(a));

        let option = {
          backgroundColor: "#f6f8fc",
          color: [
            "#a9ccef",
            "#db6f6a",
            "#f1ca6a",
            "#5b70c0",
            "#efa9a9",
            "#efdba9",
            "#e5efa9",
            "#a9efd",
          ],
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
            // formatter: function (data) {
            //   console.log(data);
            //   return  1

            // },

            // confine: true,
            // position: ["10%", "10%"],

            // position: function (pos, params, dom, rect, size) {
            //   // console.log(pos, params, dom, rect, size);
            //   return pos;
            // },
          },

          grid: {
            left: "0%",
            right: "0%",
            bottom: "3%",
            width: "100%",
            height: "100%",
            containLabel: true,
          },
          xAxis: {
            type: "value",

            axisLine: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
          },
          yAxis: {
            type: "category",
            data: category,

            axisLine: {
              show: false,
            },

            axisTick: {
              show: false,
            },
          },
          series: series,
          // series: [
          //   {
          //     name: "Affiliate Ad",
          //     type: "bar",
          //     stack: "total",

          //     emphasis: {
          //       focus: "series",
          //     },
          //     data: [220, 182, 191, 234, 290, 330, 310],
          //   },
          //   {
          //     name: "Video Ad",
          //     type: "bar",
          //     stack: "total",

          //     emphasis: {
          //       focus: "series",
          //     },
          //     data: [150, 212, 201, 154, 190, 330, 410],
          //   },
          //   {
          //     name: "Search Engine",
          //     type: "bar",
          //     stack: "total",

          //     emphasis: {
          //       focus: "series",
          //     },
          //     data: [820, 832, 901, 934, 1290, 1330, 1320],
          //   },
          // ],
        };
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);
        // window.addEventListener("resize", () => {
        //   myChart.resize();
        // });
      });
    },
    init_map() {
      $.get("map/json/china.json", function (chinaJson) {
        echarts.registerMap("china", chinaJson);
        var chart = echarts.init(document.getElementById("map"));
        chart.setOption({
          series: [
            {
              type: "map",
              map: "china",
            },
          ],
        });
      });
      let mapChart = this.$echarts.init(document.getElementById("map"));
    },

    //   var geoCoordMap = {
    //     上海: [121.4648, 31.2891],
    //     东莞: [113.8953, 22.901],
    //     东营: [118.7073, 37.5513],
    //     中山: [113.4229, 22.478],
    //     临汾: [111.4783, 36.1615],
    //     临沂: [118.3118, 35.2936],
    //     丹东: [124.541, 40.4242],
    //     丽水: [119.5642, 28.1854],
    //     乌鲁木齐: [87.9236, 43.5883],
    //     佛山: [112.8955, 23.1097],
    //     保定: [115.0488, 39.0948],
    //     兰州: [103.5901, 36.3043],
    //     包头: [110.3467, 41.4899],
    //     北京: [116.4551, 40.2539],
    //     北海: [109.314, 21.6211],
    //     南京: [118.8062, 31.9208],
    //     南宁: [108.479, 23.1152],
    //     南昌: [116.0046, 28.6633],
    //     南通: [121.1023, 32.1625],
    //     厦门: [118.1689, 24.6478],
    //     台州: [121.1353, 28.6688],
    //     合肥: [117.29, 32.0581],
    //     呼和浩特: [111.4124, 40.4901],
    //     咸阳: [108.4131, 34.8706],
    //     哈尔滨: [127.9688, 45.368],
    //     唐山: [118.4766, 39.6826],
    //     嘉兴: [120.9155, 30.6354],
    //     大同: [113.7854, 39.8035],
    //     大连: [122.2229, 39.4409],
    //     天津: [117.4219, 39.4189],
    //     太原: [112.3352, 37.9413],
    //     威海: [121.9482, 37.1393],
    //     宁波: [121.5967, 29.6466],
    //     宝鸡: [107.1826, 34.3433],
    //     宿迁: [118.5535, 33.7775],
    //     常州: [119.4543, 31.5582],
    //     广州: [113.5107, 23.2196],
    //     廊坊: [116.521, 39.0509],
    //     延安: [109.1052, 36.4252],
    //     张家口: [115.1477, 40.8527],
    //     徐州: [117.5208, 34.3268],
    //     德州: [116.6858, 37.2107],
    //     惠州: [114.6204, 23.1647],
    //     成都: [103.9526, 30.7617],
    //     扬州: [119.4653, 32.8162],
    //     承德: [117.5757, 41.4075],
    //     拉萨: [91.1865, 30.1465],
    //     无锡: [120.3442, 31.5527],
    //     日照: [119.2786, 35.5023],
    //     昆明: [102.9199, 25.4663],
    //     杭州: [119.5313, 29.8773],
    //     枣庄: [117.323, 34.8926],
    //     柳州: [109.3799, 24.9774],
    //     株洲: [113.5327, 27.0319],
    //     武汉: [114.3896, 30.6628],
    //     汕头: [117.1692, 23.3405],
    //     江门: [112.6318, 22.1484],
    //     沈阳: [123.1238, 42.1216],
    //     沧州: [116.8286, 38.2104],
    //     河源: [114.917, 23.9722],
    //     泉州: [118.3228, 25.1147],
    //     泰安: [117.0264, 36.0516],
    //     泰州: [120.0586, 32.5525],
    //     济南: [117.1582, 36.8701],
    //     济宁: [116.8286, 35.3375],
    //     海口: [110.3893, 19.8516],
    //     淄博: [118.0371, 36.6064],
    //     淮安: [118.927, 33.4039],
    //     深圳: [114.5435, 22.5439],
    //     清远: [112.9175, 24.3292],
    //     温州: [120.498, 27.8119],
    //     渭南: [109.7864, 35.0299],
    //     湖州: [119.8608, 30.7782],
    //     湘潭: [112.5439, 27.7075],
    //     滨州: [117.8174, 37.4963],
    //     潍坊: [119.0918, 36.524],
    //     烟台: [120.7397, 37.5128],
    //     玉溪: [101.9312, 23.8898],
    //     珠海: [113.7305, 22.1155],
    //     盐城: [120.2234, 33.5577],
    //     盘锦: [121.9482, 41.0449],
    //     石家庄: [114.4995, 38.1006],
    //     福州: [119.4543, 25.9222],
    //     秦皇岛: [119.2126, 40.0232],
    //     绍兴: [120.564, 29.7565],
    //     聊城: [115.9167, 36.4032],
    //     肇庆: [112.1265, 23.5822],
    //     舟山: [122.2559, 30.2234],
    //     苏州: [120.6519, 31.3989],
    //     莱芜: [117.6526, 36.2714],
    //     菏泽: [115.6201, 35.2057],
    //     营口: [122.4316, 40.4297],
    //     葫芦岛: [120.1575, 40.578],
    //     衡水: [115.8838, 37.7161],
    //     衢州: [118.6853, 28.8666],
    //     西宁: [101.4038, 36.8207],
    //     西安: [109.1162, 34.2004],
    //     贵阳: [106.6992, 26.7682],
    //     连云港: [119.1248, 34.552],
    //     邢台: [114.8071, 37.2821],
    //     邯郸: [114.4775, 36.535],
    //     郑州: [113.4668, 34.6234],
    //     鄂尔多斯: [108.9734, 39.2487],
    //     重庆: [107.7539, 30.1904],
    //     金华: [120.0037, 29.1028],
    //     铜川: [109.0393, 35.1947],
    //     银川: [106.3586, 38.1775],
    //     镇江: [119.4763, 31.9702],
    //     长春: [125.8154, 44.2584],
    //     长沙: [113.0823, 28.2568],
    //     长治: [112.8625, 36.4746],
    //     阳泉: [113.4778, 38.0951],
    //     青岛: [120.4651, 36.3373],
    //     韶关: [113.7964, 24.7028],
    //   };
    //   var dataT = [
    //     {
    //       name: "上海",
    //       value: 15,
    //     },
    //     {
    //       name: "广州",
    //       value: 15,
    //     },
    //     {
    //       name: "大连",
    //       value: 15,
    //     },
    //   ];

    //   geoCoordMap["aa2"] = [116.854911, 33.901464];

    //   var GZData = [];
    //   var tempData = ["aa2", GZData];

    //   dataT.map((item, index) => {
    //     var arr = [];
    //     arr.push(item);
    //     arr.push({
    //       name: "aa2",
    //     });
    //     GZData.push(arr);
    //   });

    //   var convertData = function (data) {
    //     var res = [];
    //     for (var i = 0; i < data.length; i++) {
    //       var dataItem = data[i];
    //       var fromCoord = geoCoordMap[dataItem[0].name];
    //       var toCoord = geoCoordMap[dataItem[1].name];
    //       if (fromCoord && toCoord) {
    //         res.push({
    //           fromName: dataItem[0].name,
    //           toName: dataItem[1].name,
    //           coords: [fromCoord, toCoord],
    //           value: dataItem[0].value,
    //         });
    //       }
    //     }
    //     return res;
    //   };

    //   var color = ["blue"];
    //   var series = [
    //     {
    //       type: "map",
    //       map: "china",
    //       //    width: '100%',
    //       geoIndex: 1,
    //       zlevel: 1,
    //       //    aspectScale: 0.75, //长宽比
    //       showLegendSymbol: false, // 存在legend时显示
    //       label: {
    //         normal: {
    //           show: false,
    //         },
    //         emphasis: {
    //           show: false,
    //           textStyle: {
    //             color: "#f00",
    //           },
    //         },
    //       },
    //       roam: false,
    //       itemStyle: {
    //         normal: {
    //           areaColor: "#a5a5a5",
    //           borderColor: "#a5a5a5", //省市边界线00fcff 516a89
    //         },
    //         emphasis: {
    //           areaColor: "green",
    //         },
    //       },
    //       data: [
    //         {
    //           name: "南海诸岛",
    //           value: 0,
    //           itemStyle: {
    //             normal: {
    //               opacity: 0,
    //               label: {
    //                 show: false,
    //               },
    //             },
    //             color: "blue",
    //           },
    //         },
    //       ],
    //     },
    //   ];

    //   series.push(
    //     {
    //       name: tempData[0],
    //       type: "lines",
    //       zlevel: 3,
    //       symbol: ["none", "circle"],
    //       effect: {
    //         show: false,
    //         period: 3, //箭头指向速度，值越小速度越快
    //         trailLength: 0.01, //特效尾迹长度[0,1]值越大，尾迹越长重
    //         symbolSize: 2, //图标大小
    //       },
    //       lineStyle: {
    //         normal: {
    //           opacity: 1,
    //           curveness: 0.2, //曲线的弯曲程度
    //           color: "red",
    //         },
    //       },
    //       data: convertData(tempData[1]),
    //     },
    //     {
    //       name: tempData[0],
    //       type: "scatter",
    //       coordinateSystem: "geo",
    //       zlevel: 2,
    //       rippleEffect: {
    //         brushType: "stroke",
    //       },
    //       label: {
    //         normal: {
    //           show: true,
    //           position: "right",
    //           formatter: "{b}",
    //         },
    //       },
    //       symbolSize: function (val) {
    //         console.log(val);
    //         return val[2] / 2;
    //       },
    //       itemStyle: {
    //         normal: {
    //           //                fontSize: 80,
    //         },
    //       },
    //       data: tempData[1].map(function (dataItem) {
    //         return {
    //           name: dataItem[0].name,
    //           value: geoCoordMap[dataItem[0].name].concat([dataItem[0].value]),
    //         };
    //       }),
    //     },
    //     {
    //       type: "effectScatter",
    //       coordinateSystem: "geo",
    //       zlevel: 4,
    //       symbolSize: 30,
    //       label: {
    //         normal: {
    //           show: true,
    //           position: "right",
    //           formatter: "{b}",

    //           color: "white",
    //         },
    //       },
    //       itemStyle: {
    //         normal: {
    //           color: "white",
    //         },
    //       },
    //       rippleEffect: {
    //         scale: 0,
    //         brushType: "stroke",
    //       },
    //       data: [
    //         {
    //           name: tempData[0],
    //           value: geoCoordMap[tempData[0]],
    //           visualMap: false,
    //         },
    //       ],
    //     },
    //     {
    //       type: "scatter",
    //       coordinateSystem: "geo",
    //       zlevel: 4,
    //       symbolSize: 20,
    //       symbol: "pin",
    //       itemStyle: {
    //         normal: {
    //           color: "white",
    //         },
    //       },
    //       rippleEffect: {
    //         //        brushType: 'stroke'
    //       },
    //       data: [
    //         {
    //           name: tempData[0],
    //           value: geoCoordMap[tempData[0]],
    //           visualMap: false,
    //         },
    //       ],
    //     }
    //   );

    //   option = {
    //     tooltip: {
    //       trigger: "item",
    //       formatter: function (params) {
    //         if (params.seriesType == "scatter" && params.name != tempData[0]) {
    //           return (
    //             "<br>" +
    //             params.seriesName +
    //             " ---> " +
    //             params.data.name +
    //             "<br />数量：" +
    //             params.data.value[2]
    //           );
    //         } else if (params.seriesType == "lines") {
    //           return (
    //             "<br>" +
    //             params.data.fromName +
    //             " ---> " +
    //             params.data.toName +
    //             "<br />数量：" +
    //             params.data.value
    //           );
    //         } else {
    //           return params.name;
    //         }
    //       },
    //     },
    //     visualMap: {
    //       color: ["#096dd0"],
    //       //            textStyle: {
    //       //                color: '#fff',
    //       //            },
    //       show: false,
    //     },
    //     geo: {
    //       map: "china",
    //       zoom: 0.98,
    //       zlevel: 2,
    //       label: {
    //         emphasis: {
    //           show: false,
    //         },
    //       },
    //       roam: false, //是否允许缩放
    //       itemStyle: {
    //         normal: {
    //           areaColor: "#d9d9d9",
    //           borderColor: "#fff", //省市边界线00fcff 516a89
    //         },
    //         emphasis: {
    //           color: "#f00", //悬浮背景
    //         },
    //       },
    //     },
    //     series: series,
    //   };
    // },
  },
};
</script>

<style lang="less">
.newhome_page {
  width: 1920px;
  height: 1080px;
  padding: 0 24px 24px 24px;
  box-sizing: border-box;
  position: relative;

  background: url("../assets/newbg.png") no-repeat;
  background-size: 100% 100%;
  background-color: #f5f5f5;
  div {
    box-sizing: border-box;
  }
  .newhome_title {
    //   width: 80%;
    //   margin: 0 auto;
    // background-color: #fff;
    background-image: url("../assets/new_tit_bg@2x.png");
    background-size: 100% 150px;
    background-repeat: no-repeat;
    height: 88px;
    padding-top: 11px;

    font-size: 46px;
    font-family: YouSheBiaoTiHei, YouSheBiaoTiHei-Regular;
    font-weight: 400;
    text-align: CENTER;
    color: rgba(0, 0, 0, 0.85);
    letter-spacing: 0px;
    // line-height: 88px;
    .title {
      height: 66px;
    }
  }
  .newhome_bd {
    height: calc(100% - 88px);
    display: flex;
    .edit_newhome {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 100;
    }
    .newhome_bd_l {
      position: relative;
      width: 472px;
      height: 100%;
      //   background-color: #5f5f5f;
      .company_avatar {
        position: relative;
        width: 456px;
        height: 223px;
        padding: 24px 24px 16px 24px;
        background-color: #fff;
        background-image: url("../assets/jianjie_bg.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        .brief {
          display: flex;
          .brief_left {
            margin-right: 12px;
            .company_info {
              width: 240px;
              height: 60px;

              .company_img {
                width: 100%;
                height: 100%;
                // object-fit: scale-down;
                object-fit: contain;
              }
              .company_name {
                // font-size: 9px;
              }
            }
            .slogan {
              width: 275px;
              height: 44px;
              overflow: scroll;
              &::-webkit-scrollbar {
                display: none;
              }

              font-size: 16px;
              font-family: PingFang SC, PingFang SC-Semibold;
              font-weight: 600;
              text-align: LEFT;
              color: #0a6ed1;
              letter-spacing: 0px;
              margin-top: 10px;

              pre {
                margin: 0;
                white-space: pre-wrap;
                line-height: 22px;
              }
            }
          }
          .brief_right {
            width: 120px;
            height: 120px;
            // object-fit: scale-down;
            border-radius: 50%;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              // object-fit: scale-down;
              object-fit: contain;
            }
          }
        }
        .achievement {
          display: flex;
          margin-top: 20px;
          .achievement_text {
            width: 372px;
            display: flex;
            flex-wrap: wrap;
            // background-color: #f00;
            overflow: scroll;
            &::-webkit-scrollbar {
              display: none;
            }
            max-height: 35px;
            .list {
              padding: 0 4px;
              line-height: 24px;
              background: #129bff;
              border-radius: 2px;

              font-size: 12px;
              font-family: PingFang SC, PingFang SC-Semibold;
              font-weight: 600;
              text-align: LEFT;
              color: #ffffff;
              letter-spacing: 0px;
              margin: 0 8px 8px 0;
              cursor: pointer;
              .list_img {
                display: none;
              }
            }
          }
          .achievement_img1 {
            width: 21px;
            height: 21px;
            margin-right: 8px;
          }
          .achievement_img2 {
            width: 25px;
            height: 25px;
          }
        }
      }
      .introduce {
        position: relative;
        width: 456px;
        height: 728px;
        margin-top: 16px;
        // background-color: red;
        .navgation {
          display: flex;
          align-items: flex-end;
          .nav_items {
            position: relative;
            width: 82px;
            height: 25px;
            background: #eeeeee;
            border-radius: 2px;
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            text-align: right;
            color: rgba(0, 0, 0, 0.85);
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 4px;
            cursor: pointer;
            img {
              width: 24px;
              height: 24px;
              cursor: pointer;
              position: absolute;
              top: 0;
              right: 0;
            }
            span {
              display: inline-block;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 100%;
              text-align: center;
            }
            .span {
              padding-left: 4px;
              padding-right: 28%;
            }
          }
          .activeclass {
            width: 82px;
            height: 36px;
            background: #ffffff;
            border-radius: 4px 4px 0px 0px;
            img {
              width: 24px;
              height: 24px;
              cursor: pointer;
              position: absolute;
              top: 6px;
              right: 0;
            }
          }
          .select {
            position: relative;

            .more {
              width: 24px;
              height: 24px;
              vertical-align: top;
              cursor: pointer;
            }
          }
          .navadd {
            width: 24px;
            height: 24px;
            background-color: #eeeeee;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            &:hover {
              background-color: #dfdfdf;
            }
            &:active {
              background-color: #959595;
            }
            img {
              width: 11px;
              height: 11px;
            }
          }
        }
        .nav_detail {
          width: 100%;
          height: 692px;
          overflow: scroll;
          //   display:table;
          &::-webkit-scrollbar {
            display: none;
          }
          padding: 24px;
          background-color: #fff;
          .title {
            height: 38px;
            font-size: 16px;
            font-family: PingFang SC, PingFang SC-Semibold;
            font-weight: 600;
            text-align: LEFT;
            color: rgba(0, 0, 0, 0.85);
            letter-spacing: 0px;
            display: flex;
            align-items: center;
            margin:10px 0;

            span {
              display: inline-block;
              width: 3px;
              height: 16px;
              background: #0a6ed1;
              margin-right: 4px;
            }
          }
          .text {
            line-height: 36px;
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            text-align: LEFT;
            color: rgba(0, 0, 0, 0.65);
            pre {
              white-space: pre-wrap;
            }
          }
          .picture {
            width: 100%;
            height: 232px;
            padding: 8px 0;

           .pic_el-img {
              width: 100%;
              height: 100%;
            }
          }
          .littlepic {
            width: 204px;
            height: 118px;
            display: inline-block;
            padding: 8px;
           .pic_el-img{
              width: 100%;
              height: 100%;
            }
          }
          .detail_box {
            display: inline-block;
          }
        }
      }
    }
    .newhome_bd_r {
      width: 1400px;
      height: 100%;

      .bd_r_top {
        width: 100%;
        height: 664px;

        display: flex;
        .map {
          width: 926px;
          height: 664px;
          background: rgba(255, 255, 255, 0);
          // background-color: #fff;
          //  background: url("../assets/mode1.png") no-repeat;
          //  background-size:100% 100%;
          margin-right: 16px;
          position: relative;
        }
        .area {
          width: 458px;
          height: 664px;

          .shuffling {
            width: 100%;
            height: 100%;
            .el-carousel__container {
              width: 100%;
              // height: 632px;
              height: 100%;
              .project {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                padding: 16px 0;
                .common {
                  width: 221px;
                  height: 308px;
                  background: #f6f8fc;
                  &:active {
                    box-shadow: -3px -3px 10px 0px #ffffff inset,
                      3px 3px 10px 0px #d1d3d6 inset;
                  }
                  &:hover {
                    box-shadow: 3px 3px 20px 0px #d1d3d6,
                      2px 2px 2px 0px #ffffff inset;
                  }
                  border-radius: 4px;
                  box-shadow: 3px 3px 20px 0px #d1d3d6,
                    -10px -10px 20px 0px #ffffff, 2px 2px 2px 0px #ffffff inset;
                  padding: 20px;
                  cursor: pointer;
                  .child_columns1 {
                    font-size: 16px;
                    font-family: PingFang SC, PingFang SC-Semibold;
                    font-weight: 600;
                    text-align: LEFT;
                    color: rgba(0, 0, 0, 0.85);
                    margin-bottom: 15px;
                  }
                  .child_columns2 {
                    display: flex;
                    justify-content: space-between;
                    div {
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      justify-content: space-between;
                      .top {
                        height: 21px;
                        font-size: 12px;
                        font-family: PingFang SC, PingFang SC-Regular;
                        font-weight: 400;
                        text-align: LEFT;
                        color: rgba(0, 0, 0, 0.65);
                        line-height: 21px;
                        margin-bottom: 8px;
                      }
                      .bottom {
                        height: 26px;
                        font-size: 16px;
                        font-family: PingFang SC, PingFang SC-Regular;
                        font-weight: 400;
                        text-align: LEFT;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 26px;
                      }
                    }
                  }
                  .child_columns3 {
                    display: flex;
                    // justify-content: space-between;
                    margin-bottom: 16px;
                    margin-top: 15px;
                    .provinces {
                      width: 42px;
                      height: 21px;
                      line-height: 21px;
                      background: #a9ccef;
                      border-radius: 2px;
                      font-size: 12px;
                      font-family: PingFang SC, PingFang SC-Regular;
                      font-weight: 400;
                      text-align: CENTER;
                      color: #ffffff;
                    }
                    .provinces2 {
                      background: #db6f6a;
                      margin-left: 6px;
                    }
                    .provinces3 {
                      background: #f1ca6a;
                      margin-left: 6px;
                    }
                    .provinces4 {
                      background: #5b70c0;
                      margin-left: 6px;
                    }
                  }
                  #pro {
                    width: 181px;
                    height: 132px;
                    background-color: #fff;
                  }
                }
              }
            }
            .el-carousel__indicators {
              width: 100%;
              height: 32px;
              .el-carousel__button {
                width: 12px;
                height: 4px;
                background: rgba(10, 110, 209, 0.35);
                border-radius: 4px;
              }
            }
          }
        }
      }
      .bd_r_bottom {
        position: relative;
        width: 100%;
        height: 304px;
        background-color: #fff;
        background-image: url("../assets/licheng.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        padding: 28px 24px 0;
        .course {
          width: 100%;
          height: 100%;
          .el-carousel__container {
            width: 100%;
            height: 100%;

            .course_item {
              width: 100%;
              height: 100%;
              //   background-color: pink;

              //   display: flex;
              .aaaa {
                height: 100%;
                width: 100%;

                position: relative;
                .son {
                  width: 206px;
                  height: 107px;
                  background: rgba(255, 255, 255, 0);
                  position: relative;
                  padding-left: 16px;
                  display: flex;
                  flex-direction: column-reverse;
                  img {
                    position: absolute;
                    top: 0;
                    left: 0;
                  }
                  .img0 {
                    width: 25px;
                    height: 107px;
                  }
                  .imgx {
                    width: 8px;
                    height: 107px;
                  }
                  .imgs {
                    width: 8px;
                    height: 107px;
                  }
                  .img {
                    width: 94px;
                    height: 53px;
                    position: relative;
                    .pic_el-img {
                      width: 94px;
                      height: 53px;
                    }
                  }
                  .time {
                    font-size: 16px;
                    font-family: DIN Alternate, DIN Alternate-Bold;
                    font-weight: 700;

                    color: #0a6ed1;
                  }
                  .introduction {
                    width: 100%;
                    margin-top: 4px;
                    margin-bottom: 5px;
                    font-size: 14px;
                    font-family: PingFang SC, PingFang SC-Regular;
                    font-weight: 400;
                    text-align: LEFT;
                    color: rgba(0, 0, 0, 0.65);
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                  }
                }
                .son0 {
                  padding-left: 31px;
                  position: absolute;
                  top: 0;
                  left: 0;
                }
                .son1 {
                  position: absolute;
                  bottom: 24px;
                  left: 122px;
                }
                .son2 {
                  position: absolute;
                  top: 0;
                  left: 272px;
                }
                .son3 {
                  position: absolute;
                  bottom: 24px;
                  left: 425px;
                }
                .son4 {
                  position: absolute;
                  top: 0;
                  left: 576px;
                }
                .son5 {
                  position: absolute;
                  bottom: 24px;
                  left: 730px;
                }
                .son6 {
                  position: absolute;
                  top: 0;
                  left: 879px;
                }
                .son7 {
                  position: absolute;
                  bottom: 24px;
                  left: 1032px;
                }
                .son8 {
                  position: absolute;
                  top: 0;
                  right: -35px;
                }
              }
            }
          }
        }
      }
    }
  }
  .editc-btn {
    height: 80px;
    width: 80px;
    position: absolute;
    right: 0;
    cursor: pointer;
    color: #fff;
    line-height: 80px;
    z-index: 1;
  }
}
</style>
<style lang="less">
//弹窗基本样式
.public_dialog {
  .el-input__inner {
    height: 28px;
  }
  .el-dialog {
    .el-dialog__header {
      font-size: 20px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 600;
      text-align: LEFT;
      color: rgba(0, 0, 0, 0.85);
    }
    .el-dialog__footer {
      padding: 0 20px 10px;
      text-align: center;
      .el-button {
        border-radius: 2px;
      }
      .cancel {
        color: rgba(0, 0, 0, 0.85);
        margin-right: 8px;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 2px;
        border: none;
        &:hover {
          background-color: #c3c3c3;
          opacity: 0.85;
        }
        &:active {
          background-color: #41474f;
          opacity: 0.85;
        }
      }
      .submit-btn {
        color: #fff;
        border: none;
        background-color: #0a6ed1;
        &:hover {
          background-color: #2f8cde;
        }
        &:active {
          background-color: #0050ab;
        }
      }
    }
    .el-dialog__body {
      max-height: 80vh;
      overflow: scroll;
      padding: 0 20px;

      .dialog-content {
      }
    }
  }
}
//企业画像弹窗
.edit_company_img {
  .dialog-content {
    .up_img {
      display: flex;
      justify-content: space-between;
      .logo {
        width: 287px;
        .tit {
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
        }
        .limit {
          width: 100%;
          height: 56px;
          background-color: #fffbe6;
          padding: 8px 13px;
          box-sizing: border-box;
          display: flex;
          margin-bottom: 10px;
          .limit_text {
            font-size: 12px;
            margin-left: 10px;
          }
        }
        .img {
          width: 287px;
          height: 90px;
        }
      }
      .badge {
        .img {
          width: 90px;
          height: 90px;
          border-radius: 50%;
          border: 1px dashed rgba(255, 255, 255, 0.65);
          //   overflow: hidden;
        }
      }
    }
    .slogan {
      margin-top: 24px;
      .slogan_name {
        height: 28px;
        cursor: pointer;

        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: rgba(0, 0, 0, 0.85);
        line-height: 28px;
      }
      .slogan_text {
      }
    }
    .company_chengjiu {
      margin-top: 24px;
      .achievement_name {
        width: 76px;
        height: 28px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: rgba(0, 0, 0, 0.85);
        line-height: 28px;
      }
      .achievement_table {
        ::-webkit-scrollbar {
          //   border: none;
          width: 0px;
        }

        .el-table__header-wrapper {
          .el-table__header {
            tr {
              th {
                padding: 3px 0;
              }
            }
          }
        }
        .el-table__body {
          &::-webkit-scrollbar {
            width: 0;
          }

          tr {
            td {
              background-color: #f3f3f3;
              padding: 0px;
              .cell {
                padding: 0;
                .el-input__inner {
                  // border: none;
                  text-align: center;
                  background-color: #f3f3f3;
                  border: none;
                }
              }
            }
            :hover td {
              background-color: #f3f3f3;
            }
          }
          .operation {
            .cell {
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                cursor: pointer;
                width: 24px;
                height: 24px;
                // display: block;
              }
            }
          }
        }
        .el-table__empty-block {
          width: 100%;
          height: 145px !important;
          background-color: #f3f3f3;
          .achievement_table_empty {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
              width: 64px;
              height: 40px;
            }
            .empty_text {
              width: 178px;
              height: 30px;
              font-size: 14px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              text-align: CENTER;
              color: rgba(0, 0, 0, 0.25);
              line-height: 30px;
              span {
                color: #0a6ed1;
                cursor: pointer;
              }
            }
          }
        }
      }
      .add {
        width: 100px;
        height: 32px;
        margin-top: 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        color: #0a6ed1;
        .span {
          cursor: pointer;
        }
      }
    }
  }
}
//添加详情介绍
.edit_detail {
  .dialog-content {
    div {
      box-sizing: border-box;
    }
    .module_name {
      height: 36px;

      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;

      color: rgba(0, 0, 0, 0.85);
      line-height: 36px;
    }
    .typography {
      width: 782px;

      display: flex;
      background: rgba(255, 255, 255, 0);
      .left {
        width: 258px;
        height: 100%;
        height: 350px;
        // background-color: pink;
        padding-right: 25px;

        .box {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          flex-wrap: wrap;
        }
        .left_son {
          width: 112px;
          height: 120px;
          background-color: #fff;
          padding: 12px 0;
          margin-bottom: 8px;
          cursor: pointer;
          display: flex;
          flex-flow: column;
          align-items: center;
          img {
            width: 40px;
            height: 40px;
            margin: 12px 0;
          }
          .neirong {
            width: 112px;
            height: 32px;
            line-height: 32px;
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            text-align: CENTER;
            color: rgba(0, 0, 0, 0.85);
          }

          &:hover {
            background-color: #e6e6e6;
          }
          &:active {
            background-color: #999;
          }
        }

        .big:hover ~ .pic_tip {
          display: block;
          .limit_text1 {
            display: block;
          }
        }
        .little:hover ~ .pic_tip {
          display: block;
          .limit_text2 {
            display: block;
          }
        }
        .pic_tip {
          width: 100%;
          background-color: #fffbe6;
          display: none;
          height: 76px;
          background-color: #fffbe6;
          padding: 8px 32px 8px 16px;
          box-sizing: border-box;

          margin-bottom: 10px;
          .box1 {
            display: flex;
            .limit_text1 {
              display: none;
              font-size: 12px;
              margin-left: 10px;
            }
            .limit_text2 {
              display: none;
              font-size: 12px;
              margin-left: 10px;
            }
          }
        }
      }
      .right {
        width: 524px;
        height: 500px;
        overflow: auto;
        &::-webkit-scrollbar {
          display: none;
        }

        padding: 16px;
        background-color: #fff;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
        .box_right {
          display: inline-block;
        }
        .active_right {
          border: 1px solid #0a6ed1;
        }
        .right_empty {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          width: 100%;
          height: 432px;
          background: #ffffff;
          border-radius: 4px;
          // box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
          font-size: 14px;
          font-family: Roboto, Roboto-Regular;
          font-weight: 400;

          color: rgba(0, 0, 0, 0.65);
          img {
            width: 160px;
            height: 98px;
          }
        }

        .type1 {
          width: 100%;
          // height: 52px;
          padding: 10px;
          display: flex;
          align-items: center;
          cursor: pointer;
          &:hover {
            background-color: #e7f1fa;
          }
          span {
            width: 3px;
            height: 22px;
            background: #0a6ed1;
            margin-right: 4px;
          }
          .el-form-item {
            width: 100%;
            margin-bottom: 0;
            .el-form-item__error {
              position: absolute;
              top: 82%;
            }
          }
        }
        .type2 {
          width: 100%;
          // height: 110px;
          padding: 15px 10px;
          &:hover {
            background-color: #e7f1fa;
          }
          textarea {
            // height: 90px;
          }
          .el-form-item {
            width: 100%;
            margin-bottom: 0;
            .el-form-item__error {
              position: absolute;
              top: 97%;
            }
          }
        }
        .type3 {
          width: 100%;
          height: 268px;
        }
        .type4 {
          width: 245px;
          height: 147px;
          display: inline-block;
          vertical-align: top;
          box-sizing: border-box;
        }
      }
    }
  }
}
//项目详情弹窗样式
.project_detial {
  .dialog-content {
    .provincename {
      .tit {
        height: 34px;

        font-size: 20px;
        font-family: PingFang SC, PingFang SC-Semibold;
        font-weight: 600;
        text-align: LEFT;
        color: #1d2129;
        line-height: 34px;
        margin-bottom: 8px;
      }
      .earnings {
        height: 22px;

        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: rgba(0, 0, 0, 0.45);
        line-height: 22px;
        margin-bottom: 11px;
        span {
          color: #f53f3f;
        }
      }
      .project_type {
        width: 100%;
        height: 260px;
      

        

        .el-carousel__indicators {
          width: 100%;
          height: 20px;
          .el-carousel__button {
            width: 12px;
            height: 4px;
            background: rgba(10, 110, 209, 0.35);
            border-radius: 4px;
          }
        }
        .el-carousel__container {
          width: 100%;
          height: 230px;
          .project_box {
            display: flex;
            // justify-content: space-between;
            .margin {
              margin: 0 21px;
            }
            .project_type_list {
              width: 247px;
              height: 100%;
              cursor: pointer;

              .list1 {
                img {
                  width: 247px;
                  height: 139px;
                }
                margin-bottom: 16px;
              }
            }
            .list2 {
              height: 24px;
              font-size: 14px;
              font-family: PingFang SC, PingFang SC-Semibold;
              font-weight: 600;
              text-align: LEFT;
              color: rgba(0, 0, 0, 0.85);
              line-height: 24px;
            }
            .list3 {
              height: 29px;
              font-size: 14px;
              font-family: Nunito Sans, Nunito Sans-Regular;
              font-weight: 400;
              text-align: LEFT;
              color: rgba(0, 0, 0, 0.45);
              line-height: 29px;
              span {
                color: rgba(0, 0, 0, 0.85);
              }
            }
            .list4 {
              height: 21px;
              font-size: 14px;
              font-family: Nunito Sans, Nunito Sans-Regular;
              font-weight: 400;
              text-align: LEFT;
              color: rgba(0, 0, 0, 0.45);
              line-height: 21px;
              span {
                color: rgba(0, 0, 0, 0.85);
              }
            }
          }
        }
        
      }
      .empty {
        width: 100%;
        height: 136px;
        // background: #000;
        display: flex;
        align-items: center;
        flex-direction: column;

        font-size: 14px;
        font-family: Roboto, Roboto-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: rgba(0, 0, 0, 0.65);
        img {
          width: 160px;
          height: 98px;
        }
      }
    }
  }
}
//编辑项目分布
.edit_distribution {
  .dialog-content {
    // input::-webkit-input-placeholder {
    //   color: red;
    // }
    // input::-moz-input-placeholder {
    //   color: red;
    // }
    // input::-ms-input-placeholder {
    //   color: red;
    // }
    .tit {
      height: 52px;

      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 600;
      text-align: LEFT;
      color: rgba(0, 0, 0, 0.85);
      line-height: 52px;
      display: flex;
      align-items: center;
      span {
        display: inline-block;
        width: 4px;
        height: 16px;
        background: #0a6ed1;
        margin-right: 10px;
      }
    }
    .area_list_table {
      ::-webkit-scrollbar {
        // border: none;
        width: 0px;
      }

      .el-table__header-wrapper {
        .el-table__header {
          tr {
            th {
              padding: 3px 0;
            }
          }
        }
      }
      .el-table__body {
        &::-webkit-scrollbar {
          width: 0;
        }

        tr {
          td {
            background-color: #f3f3f3;
            padding: 0px;
            .cell {
              padding: 0;
              .el-input__inner {
                // border: none;
                text-align: center;
                background-color: #f3f3f3;
                border: none;
              }
            }
          }
          :hover td {
            background-color: #f3f3f3;
          }
        }
        .province {
          padding: 10px 5px 6px 10px;
          .shengfen {
            display: flex;
            flex-wrap: wrap;
            .shengfen_box {
              width: 80px;
              height: 28px;
              background: #e6e8eb;
              border-radius: 3px;
              display: flex;
              align-items: center;
              margin: 0 10px 4px 0;

              .shengfen_select {
                .el-input__suffix {
                  display: none;
                }
                .el-input__inner {
                  padding: 0 5px;
                  font-size: 14px;
                  font-family: PingFang HK, PingFang HK-Regular;
                  font-weight: 400;
                  background: #e6e8eb;
                  color: rgba(0, 0, 0, 0.85);
                }
              }
              .icon_i {
                height: 28px;

                cursor: pointer;
                i {
                  line-height: 28px;
                  color: #bbbdbf;
                }
              }
            }
            .addprovince {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 64px;
              height: 28px;
              background: rgba(255, 255, 255, 0.4);
              border: 1px solid #0a6ed1;
              border-radius: 2px;
              color: #0a6ed1;
              font-size: 14px;
              font-family: PingFang HK, PingFang HK-Regular;
              font-weight: 400;
              cursor: pointer;
            }
          }
        }
        .operation {
          .cell {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              cursor: pointer;
              width: 24px;
              height: 24px;
              // display: block;
            }
          }
        }
      }
      .el-table__empty-block {
        width: 100%;
        height: 145px !important;
        background-color: #f3f3f3;
        .achievement_table_empty {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;

          img {
            width: 64px;
            height: 40px;
          }
          .empty_text {
            width: 178px;
            height: 30px;
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            text-align: CENTER;
            color: rgba(0, 0, 0, 0.25);
            line-height: 30px;
            span {
              color: #0a6ed1;
              cursor: pointer;
            }
          }
        }
      }
    }
    .add {
      width: 100px;
      height: 32px;
      margin-top: 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      color: #0a6ed1;
      .span {
        cursor: pointer;
      }
    }
    .deal_with {
      display: flex;
      margin-bottom: 16px;
      .dealwith_l {
        width: 50%;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        // margin-left: 24px;
        color: rgba(0, 0, 0, 0.85);
        .el-icon-search {
          line-height: 28px;
        }
        .el-input {
          width: 245px;
        }
        .filter {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          width: 32px;
          height: 32px;

          margin-left: 8px;
          margin-right: 2px;

          cursor: pointer;
          > div {
            position: absolute;
            bottom: -27px;
            left: 0;
            z-index: 100;
            width: 40px;
            height: 24px;
            line-height: 24px;
            background: rgba(0, 0, 0, 0.75);
            border-radius: 2px;
            font-size: 12px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            text-align: center;
            color: #ffffff;
            display: none;
          }
          &:hover {
            background: rgba(0, 0, 0, 0.1);
            div {
              display: block;
            }
          }
          &:active {
            background: rgba(0, 0, 0, 0.4);
          }
        }
        .searchicon {
          position: relative;

          display: inline-block;
          width: 32px;
          height: 32px;
          cursor: pointer;
          i {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%); /* 50%为自身尺寸的一半 */
          }
          &:hover {
            background: rgba(0, 0, 0, 0.1);
            div {
              display: block;
            }
          }
          &:active {
            background: rgba(0, 0, 0, 0.4);
          }
        }
      }
      .dealwith_r {
        width: 50%;
        display: flex;
        flex-direction: row-reverse;
        .son {
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;

          color: #0a6ed1;
          cursor: pointer;
        }
        .r1 {
          line-height: 32px;
          margin-right: 8px;
        }
        .r2 {
          width: 72px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          background: rgba(0, 0, 0, 0.2);
          border-radius: 2px;
          margin-right: 8px;
          color: rgba(0, 0, 0, 0.85);
        }
        .r3 {
          width: 72px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          background: #0a6ed1;
          border-radius: 2px;
          color: #fff;
        }
      }
    }
    .project_list_table {
      /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
      //   ::-webkit-scrollbar {
      //     width: 10px;
      //     height: 10px;
      //     background-color: #f5f5f5;
      //   }

      //   /*定义滚动条轨道 内阴影+圆角*/
      //   ::-webkit-scrollbar-track {
      //     -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      //     border-radius: 10px;
      //     background-color: #f5f5f5;
      //   }

      //   /*定义滑块 内阴影+圆角*/
      //   ::-webkit-scrollbar-thumb {
      //     border-radius: 10px;
      //     -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      //     background-color: #555;
      //   }
      .el-table__fixed-right {
        .el-table__header {
          tr {
            th {
              padding: 3px 0;
            }
          }
        }
      }

      .el-table__header-wrapper {
        .el-table__header {
          tr {
            th {
              padding: 3px 0;
            }
          }
        }
      }
      .el-table__body {
        &::-webkit-scrollbar {
          width: 0;
        }

        tr {
          td {
            background-color: #f3f3f3;
            padding: 0px;
            .cell {
              padding: 0;
              .el-input__inner {
                // border: none;
                text-align: center;
                background-color: #f3f3f3;
                border: none;
                padding: 0 4px;
              }
            }
          }
          :hover td {
            background-color: #f3f3f3;
          }
        }
        .province {
          .shengfen_select {
            .el-input__suffix {
              i {
                line-height: 28px;
              }
            }
            .el-input__inner {
              padding: 0 5px;
              font-size: 14px;
              font-family: PingFang HK, PingFang HK-Regular;
              font-weight: 400;

              color: rgba(0, 0, 0, 0.85);
            }
          }
        }
        .operation {
          .cell {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              cursor: pointer;
              width: 24px;
              height: 24px;
              // display: block;
            }
          }
        }
      }
      .el-table__empty-block {
        width: 100%;
        height: 145px !important;
        background-color: #f3f3f3;
        .achievement_table_empty {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;

          img {
            width: 64px;
            height: 40px;
          }
          .empty_text {
            width: 178px;
            height: 30px;
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            text-align: CENTER;
            color: rgba(0, 0, 0, 0.25);
            line-height: 30px;
            span {
              color: #0a6ed1;
              cursor: pointer;
            }
          }
        }
      }
    }
    .page {
      width: 100%;
      margin-top: 24px;
      background: #ffffff;
      .el-pagination .el-select .el-input .el-input__inner {
        margin-left: 8px;
      }
      .el-pager li {
        height: 24px;
        width: 24px;
        min-width: auto;
        line-height: 23px;
        padding: 0;
        border: 1px solid transparent;
      }
      .el-pager li.active {
        border: 1px solid #1890ff;
      }
    }
  }
}
// 发展历程弹窗样式
.edit_development {
  .dialog-content {
    .development_table {
      ::-webkit-scrollbar {
        //   border: none;
        width: 0px;
      }

      .el-table__header-wrapper {
        .el-table__header {
          tr {
            th {
              padding: 3px 0;
            }
          }
        }
      }
      .el-table__body {
        &::-webkit-scrollbar {
          width: 0;
        }

        tr {
          td {
            background-color: #f3f3f3;
            padding: 0px;
            .cell {
              padding: 0;
              .el-input__inner {
                // border: none;
                text-align: center;
                background-color: #f3f3f3;
                border: none;
              }
            }
          }
          :hover td {
            background-color: #f3f3f3;
          }
        }
        .data {
          .data_picker {
            width: 100%;
            .el-input__inner {
              z-index: 100;
            }
            .el-input__prefix {
              i {
                line-height: 28px;
              }
            }
            .el-input__suffix {
              i {
                line-height: 28px;
              }
            }
          }
        }
        .operation {
          .cell {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              cursor: pointer;
              width: 24px;
              height: 24px;
              // display: block;
            }
          }
        }
      }
      .el-table__empty-block {
        width: 100%;
        height: 145px !important;
        background-color: #f3f3f3;
        .achievement_table_empty {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;

          img {
            width: 64px;
            height: 40px;
          }
          .empty_text {
            width: 178px;
            height: 30px;
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            text-align: CENTER;
            color: rgba(0, 0, 0, 0.25);
            line-height: 30px;
            span {
              color: #0a6ed1;
              cursor: pointer;
            }
          }
        }
      }
    }
    .add {
      width: 100px;
      height: 32px;
      margin-top: 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      color: #0a6ed1;
      .span {
        cursor: pointer;
      }
    }
  }
}
</style>
<style lang="less">
.moreselect {
  //   display: block !important;
  border: none;
  background-color: #fff;
  // position: absolute;
  // top: 210px !important ;
  // left: 184px !important;
  .el-dropdown-menu__item {
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;

    color: rgba(0, 0, 0, 0.85);
    display: flex;
    align-items: center;
    &:hover {
      background-color: #f5f5f5;
      color: rgba(0, 0, 0, 0.85);
    }
    img {
      width: 24px;
      height: 24px;
    }
  }
  .moreadd {
    color: #0a6ed1;
    text-align: center;
    &:hover {
      background-color: #f5f5f5;
      color: #0a6ed1;
    }
  }
  .popper__arrow {
    display: none;
  }
}
//筛选样式
.filter_l_pop {
  .el-popover__title {
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Semibold;
    font-weight: 900;
    text-align: left;
    color: rgba(0, 0, 0, 0.85);
  }
  .content {
    margin-bottom: 20px;
    .name {
      height: 28px;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 400;

      color: rgba(0, 0, 0, 0.85);
      line-height: 28px;
    }
    .select2 {
      width: 100%;
      margin-bottom: 20px;
      .el-input__inner {
        height: 28px;
      }
      .el-input__suffix {
        i {
          line-height: 28px;
        }
      }
    }
    .checkbox {
    }
  }
  .btn {
    text-align: center;
    margin: 0;
    > span {
      display: inline-block;
      box-sizing: border-box;
      width: 72px;
      height: 32px;

      padding: 6px 8px;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 2px;
      cursor: pointer;
    }
    .btn1 {
      margin-right: 8px;
    }
    .btn2 {
      background: #0a6ed1;
      color: #fff;
    }
    .el-button--primary {
      padding: 12px 20px;
      background: #0a6ed1;
      border: none;
    }
    .el-button--text {
      padding: 12px 20px;
      background: rgba(0, 0, 0, 0.2);
      border: none;
    }
  }
  .popper__arrow {
    display: none;
  }
}
//发展历程 时间选择器样式
.development_picker[x-placement^="bottom"] .popper__arrow::after {
  border-bottom-color: #fff;
}
.development_picker[x-placement^="bottom"] .popper__arrow {
  border-bottom-color: #fff;
}
.errorclass {
  .el-input__inner {
    border: 1px solid red !important;
  }
  // :focus {
  //   border-color: #409eff;
  // }
}
.errorclass1 {
  height: 30px !important;

  border: 1px solid red !important;
}
.errorclass2 {
  height: 30px !important;

  border: 1px solid red !important;
}
.del_tooltop.is-dark {
  padding: 5px 10px;
  cursor: pointer;
  background: #fff;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  img {
    width: 24px;
    height: 24px;
  }
  .popper__arrow {
    border-color: transparent;
  }
  &.del_tooltop.is-dark[x-placement^="top"] .popper__arrow::after {
    bottom: 1px;
    margin-left: -5px;
    border-top-color: #fff;
    border-bottom-width: 0;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.el-message-box {
  .el-message-box__btns {
    .el-button {
      background-color: #cccccc;
      border: none;
      &:hover {
        color: #606266;
      }
    }
    .el-button--primary {
      background-color: #0a6ed1;
      &:hover {
        color: #fff;
      }
    }
  }
}
.el-carousel__arrow--left{
  background-color: #959596;
  &:hover{
     background-color: #aaa;
  }
}
.el-carousel__arrow--right{
  background-color: #959596;
   &:hover{
     background-color: #aaa;
  }
}
</style>
