<template>
  <div class="edit-homem2">
      <div>
        <div style="font-weight: 500;line-height: 24px;height:24px;font-size:14px">文本信息</div>
        <div class="cut-line"></div>
        <div class="flex-bte" style="margin-bottom:24px;position: relative;">
            <div class="flex-bte"><span style="line-height:32px">区块标题：</span>
                <div>
                    <el-input v-model="dialog_info.title" @change="endEmpty(dialog_info,'title')" :class="{errorbd:check_type&&!dialog_info.title}" maxlength="20" placeholder="请输入，20字内"></el-input>
                    <div class="error-txt" style="left:80px" v-if="check_type&&!dialog_info.title">区块标题不得为空</div>
                </div>
            </div>
            <div class="flex-bte"><span style="line-height:32px">数值单位：</span>
                <el-input v-model="dialog_info.value_unit" @change="endEmpty(dialog_info,'value_unit')" maxlength="10" placeholder="请输入，10字内"></el-input>
            </div>
        </div>
        <div class="img-set" style="display:flex;">
            <span style="width:140px">图例设置:（1～8项）</span>
            <div class="set-list" style="width:588px;display: flex;flex-wrap: wrap;">
                <span v-for="(item,index) in legend" :key="index" style="position:relative">
                    <el-input :id="'legend_'+index" style="margin-bottom:8px" :class="{errorbd:check_type&&!legend[index]}" maxlength="10" v-model="legend[index]" @change="endEmpty(legend,index)">
                        <i slot="suffix" class="el-icon-error"  v-if="legend.length>1" @click="deleItem(index)"></i>
                    </el-input>
                    <div class="error-txt" style="left:5px;top:26px" v-if="check_type&&!legend[index]">图例名称不得为空</div>
                </span>
                <span class="add-btn" @click="addLegend" v-if="legend.length<8">+添加</span>
            </div>
        </div>

        <div style="font-weight: 500;line-height: 24px;height:24px;font-size:14px;margin-top:24px">图表数据</div>
        <div class="cut-line"></div>
        <div class="flex-bte">
            <div class="con-l" style="padding-bottom:0;padding-top:0">
                <div :class="{'box-shadow':box_shadow}"></div>
                <div v-if="dialog_info.data&&dialog_info.data.length > 0" >
                    <div class="con-l-b" id="lidvs" style="height:auto;max-height:325px;overflow-y:auto" @scroll="orderScroll">
                        <div v-for="(item,index) in dialog_info.data" :class="{active:active_index===index,itemlist:true,lidiv:true}" :key="index" style="cursor:pointer" @click="changeActive(index)">{{item.date}}</div>
                    </div>
                    <div class="add-btn no-select" @click="addDate">+ 添加</div>
                </div>
                <div v-else class="no-data-l" style="margin-top: 60px;">
                    <div class="no-data-l-img"></div>
                    <div>还没有添加数据</div>
                    <div class="add-btn" @click="addDate" style="width:80px">+ 添加</div>
                </div>
            </div>
            <div class="con-r" style="overflow:hidden;position:relative" v-if="JSON.stringify(now_data)!='{}'">
                <i class="del-icon" @click="delDate" style="position:absolute;right:21px;top:14px"></i>
                <div>
                    <div style="margin-bottom:6px">时间点</div>
                    <!-- <el-input class="width188" v-model="dialog_info.title" placeholder="2021-12"></el-input> -->
                    <el-date-picker
                    v-model="now_data.date"
                    type="month"
                    format="yyyy-MM"
                    :clearable="false"
                    value-format="yyyy-MM"
                    style="width:188px"
                    :picker-options="pickerOptions"
                    placeholder="选择日期时间">
                    </el-date-picker>
                </div>
                <div class="flex-bte" style="flex-wrap: wrap;margin-top:20px">
                    <div v-for="(item,index) in legend" :key="index">
                        <div>
                            <div style="height:19px">{{item}}</div>
                            <el-input class="width188" maxlength="9" :disabled="!active_index&&active_index!=0" v-model="now_data.values[index]" @change="formatN(now_data.values,index)" @input="formatVal(now_data.values,index)" placeholder=""></el-input>
                        </div>
                    </div>
                </div>
            </div>
            <div class="con-r" v-else>
                <div class="no-data-r" style="margin-top: 68px;">
                    <div class="no-data-r-img"></div>
                    <div>暂无数据</div>
                </div>
            </div>
        </div>
    </div>
    <div></div>
  </div>
</template>

<script>
import * as moment from 'moment'
import {rivermapinter} from "../api/getdata"
export default {
    props:{
        map_info:{legend:[],data:[]}
    },
    data(){
        return{
            edit_type:false,
            edit_dialog_visible:false,
            dialog_info:this.map_info,
            now_data:{},
            active_index:'',
            // box_shadow:false,
            pickerOptions: {
            disabledDate:(time) =>{
                let date_l = this.dialog_info.data.map(ele=>{
                    return ele.date
                })
                time = moment(time).format('yyyy-MM')
                return date_l.includes(time)
            },},
            check_type:false,
            date_list:[]
        }
    },
    computed:{
        legend(){
            return this.dialog_info.legend ||[]
        },
    },
    methods:{
        addLegend(){
        if(!this.dialog_info.legend){
            this.$set(this.dialog_info,'legend',[])  
        }
        if(!this.dialog_info.data){
            this.$set(this.dialog_info,'data',[])
            this.dialog_info.data.map(ele=>{
                ele.values = []
            })
        }
        let index = this.dialog_info.legend.length
        let arr = [...this.dialog_info.legend,'']
        this.$set(this.dialog_info,'legend',arr)
        this.$nextTick(()=>{
            document.querySelector('#legend_'+index).focus()
        })
        this.dialog_info.data.map(ele=>{
            if(ele.values){
                ele.values = [...ele.values,'']
            }else{
                ele.values = ['']
            }
            
        })
        // this.now_data.values =  [...this.now_data.values,'']
        },
        addDate(){
            let next_month = ''
            if(!this.dialog_info.data){
                this.$set(this.dialog_info,'data',[])
                next_month = moment().format('yyyy-MM')
            }else{
                next_month = this.dialog_info.data[0]?.date||moment().format('yyyy-MM')
            }
            let values = this.legend.map(ele=>{
                return ''
            })
            if(!next_month){next_month=moment().format('yyyy-MM')}
            this.dialog_info.data.forEach(ele=>{
                if(moment(ele.date).isAfter(next_month)){
                    next_month = ele.date
                }
            })
            next_month=moment(next_month).add(1, 'M').format('yyyy-MM')
            this.$set(this.dialog_info,'data',[...this.dialog_info.data,{date:next_month,values}])
            this.$nextTick(()=>{
                let lidv = document.getElementById('lidvs')
                lidv.scrollTop = lidv.scrollHeight;
                this.changeActive(this.dialog_info.data?.length-1||0)
            })
            

        },
        delDate(){
            if(!this.dialog_info.data || this.dialog_info.data.length==0){
                return
            }
            this.dialog_info.data.splice(this.active_index,1)
            if(this.active_index===0){
                this.changeActive(0)
            }else{
                this.changeActive(this.active_index-1)
            }
            
        },
        deleItem(index){
            this.dialog_info.legend.splice(index,1)
            this.dialog_info.data.forEach(ele => {
                ele.values.splice(index,1)
            });
        },
        changeActive(index){
            if(!this.dialog_info.data||this.dialog_info.data.length==0){
                    this.active_index = null
                    this.now_data={}
            }else{
                index = Number(index)
                this.active_index = index;
                this.now_data = this.dialog_info.data[index]
            }
            
        },
        saveEdit(){
            this.check_type = true
            if(!this.dialog_info.title){
                this.$message.error({
                    showClose: true,
                    message: "失败：标题不得为空"
                });
                return
            }
            if(!this.dialog_info.legend||this.dialog_info.legend.length<1){
                this.$message.error({
                    showClose: true,
                    message: "失败：*图例设置*至少需添加一项"
                });
                return
            }
            for(let i in this.dialog_info.legend){
                if(!this.dialog_info.legend[i]){
                    this.$message.error({
                    showClose: true,
                    duration:0,
                    message: "失败：图例名称不得为空"
                });
                return
                }
            }
            for(let ele in this.dialog_info.data){
                if(!this.dialog_info.data[ele].date){
                    this.$message.error({
                        showClose: true,
                        message: "失败：图表数据时间点不得为空"
                    });
                    this.changeActive(ele)
                    return
                }
                this.dialog_info.data[ele].values = (this.dialog_info.data[ele].values||[]).map(ele1=>{
                    if(ele1===""||ele1==null||isNaN(ele1)){
                        return null
                    }else{
                        return Number(ele1)
                    }
                })
            }
            // this.dialog_info.data.forEach(ele=>{
            //     ele.values = (ele.values||[]).map(eles=>{
            //         return eles?eles:0
            //     })
            // })
            (this.dialog_info.data).sort(function(a,b){
                return a.date>b.date?1:-1;
            })
            rivermapinter('update',this.dialog_info).then(ele=>{
                this.$message.success({
                    showClose: true,
                    message: "成功：操作成功"
                });
                setTimeout(() => {
                    this.$emit('updateDate')
                }, 800);
                this.$emit('update:visible',false)
                
            })
        },
        formatVal(now_data,index){
            now_data[index] = now_data[index].replace(/[^-\d.]/g, '').replace(".", "$#$").replace(/\./g, "").replace("$#$", ".") ||''
            if(['.','-','0'].includes(now_data[index].slice(-1))){
                return
            }
            if(!now_data[index]||isNaN(now_data[index])){
                now_data[index] = null
                return
            }
            if(now_data[index] == null){
                return
            }
            now_data[index] = parseFloat(now_data[index])
        },
        formatN(now_data,index){
            if(now_data[index]==null){
                return
            }
            now_data[index] = Number(now_data[index])
        },
    },
    mounted(){
        if(this.dialog_info.data?.length>0){
            this.changeActive(0)
        }
    }
}
</script>

<style lang="less">
.edit-homem2{
    .flex-bte .con-l div.con-l-b{
        height:auto;max-height:290px;overflow-y:auto;
        padding: 0;
        &::-webkit-scrollbar {
            display: none;
        }
        div{
            cursor: pointer;
            padding: 0;
            &:hover{
                opacity: 0.85;
            }
        }
        .itemlist{
            padding: 0 16px;
        }
    }
    .el-input__icon{
        line-height: 1;
    }
    .el-date-editor input.el-input__inner{
        width: 193px;
    }
    i.del-icon{
        height: 24px;
        width: 24px;
        display: inline-block;
        cursor: pointer;
        &:hover{
            opacity: 0.85;
        }
        background-image: url('../assets/delicon.png');
    }
}
</style>