<template>
  <div>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="page_size"
      layout="total,slot,prev,pager,next,sizes "
      :total="page_total"
      class="pagination"
    >
      <span
        v-if="showinfo"
        style="font-size: 13px; color: #606266; font-weight: 400"
        >可用库存共计 {{ num }} {{ unit }}</span
      >
    </el-pagination>
  </div>
</template>
<script>
export default {
  props: {
    rawdata: [],
    showinfo: false,
    unit: "",
    num: 0,
    errorindex: "",
  },
  watch: {
    rawdata() {
      // console.log(this.rawdata);
      //  console.log(this.currentPage);
      this.handleCurrentChange(this.currentPage);
    },
      errorindex() {
      // console.log(this.errorindex);
      let page = (this.errorindex + 1) / this.page_size;
      let current_page = Math.ceil(page);
      this.handleCurrentChange(current_page);
    },
  },
  data() {
    return {
      currentPage: 1,
      page_size: 5,
    };
  },
  computed: {
    showinfo() {
      return this.showinfo;
    },
  },
  mounted() {
    this.handleCurrentChange(1);
  },
 

  methods: {
    handleSizeChange(pagesize) {
      let data = [];
      this.currentPage = 1;
      this.page_size = pagesize;
      let start = this.page_size * (this.currentPage - 1);
      let end = this.currentPage * this.page_size;
      data = (this.rawdata || []).slice(start, end);
      this.$emit("listChange", data);
    },
    handleCurrentChange(page) {
      // console.log(page);
      let data = [];
      this.currentPage = page;
      let start = this.page_size * (this.currentPage - 1);
      let end = this.currentPage * this.page_size;
      data = (this.rawdata || []).slice(start, end);
      // console.log(start, end);
      // console.log(data);
      this.$emit("listChange", data);
    },
    lastpage() {
      const totalPage = Math.ceil(this.page_total / this.page_size);
      this.$nextTick(() => {
        this.handleCurrentChange(totalPage);
      });
    },
    //当前页删除
    curpentpage_del() {
      // console.log(this.currentPage);
      
      let data = [];
      // this.currentPage = page;
      let start = this.page_size * (this.currentPage - 1);
      let end = this.currentPage * this.page_size;
      data = (this.rawdata || []).slice(start, end);
      // console.log(start, end);
      // console.log(data);
      if (data.length <= 0) {
        let a = this.currentPage - 1;
        this.$nextTick(() => {
          this.handleCurrentChange(a);
        });
      } else {
        this.$nextTick(() => {
          this.handleCurrentChange(this.currentPage);
        });
      }
    },

    del_list() {
      // console.log(this.page_size, "一页多少条");
      // console.log(this.currentPage, "当前页");
      this.$emit("project_del", this.page_size, this.currentPage);
    },
    changepagesize(){
      this.currentPage=1;
      // console.log(this.currentPage);

    }

 
  },
  computed: {
    page_total() {
      return this.rawdata?.length || 0;
    },
  },
};
</script>

