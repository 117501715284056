<template>
  <div class="nolist">
    <div class="center">
      <img src="../assets/empty.png" alt="" />
      <div>暂无数据</div>
    </div>
  </div>
</template>
<script>
export default {};
</script>

<style lang="less">
.nolist {
  width: 289px;
  height: 349px;

  position: relative;
  .center {
    width: 107px;
    height: 90px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 100%;
      height: 60px;
    }
    div {
      font-size: 14px;
      font-family: Roboto, Roboto-Regular;
      font-weight: 400;
      text-align: LEFT;
      color: #ffffff;
      margin-top: 8px;
    }
  }
}
</style>