<template>
  <div id="hr2" ref="hr2" style="height:257px;width:472px"></div>
</template>

<script>
export default {
    props:{
        map_data:{title:'',value_unit:'',x:[],y:[],data:[]}
    },
    watch:{
        map_data(){
            let x_data = new Array();
            (this.map_data?.x||[]).forEach((element,ind) => {
                    if(!x_data[ind]){
                        x_data[ind] = []
                    }
                    this.map_data.data.forEach((ele,index)=>{
                        x_data[ind][index] = ele[ind]
                    })
            });
            this.series = (this.map_data?.x||[]).map((ele,index)=>{
                return {
                        name: ele,
                        type: 'bar',
                        stack: 'total',
                        label: {
                            show: false
                        },
                        barWidth : 18,
                        itemStyle: {
                            normal: {
                                borderColor:'#000',
                                borderWidth: 2,
                            }
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: x_data[index]
                }
            })
            this.initChart();
        }
    },
    data(){
        return {
            hlInstance:'',
            series:[]
        }
    },
    methods:{
        cutdata(params) {
            if(!params){
                return
            }
                let newParamsName = "";// 最终拼接成的字符串
                let paramsNameNumber = params?.length || 0;// 实际标签的个数
                let provideNumber = 5;// 每行能显示的字的个数
                let rowNumber = Math.ceil(paramsNameNumber / provideNumber);// 换行的话，需要显示几行，向上取整
                
                // 条件等同于rowNumber>1
                if (paramsNameNumber > provideNumber) {
                    for (let p = 0; p < rowNumber; p++) {
                        let tempStr = "";// 表示每一次截取的字符串
                        let start = p * provideNumber;// 开始截取的位置
                        let end = start + provideNumber;// 结束截取的位置
                        // 此处特殊处理最后一行的索引值
                        if (p == rowNumber - 1) {
                            // 最后一次不换行
                            tempStr = params.substring(start, paramsNameNumber);
                        } else {
                            // 每一次拼接字符串并换行
                            tempStr = params.substring(start, end) + "\n";
                        }
                        newParamsName += tempStr;// 最终拼成的字符串
                    }
                } else {
                    // 将旧标签的值赋给新标签
                    newParamsName = params;
                }
                //将最终的字符串返回
                return newParamsName
            },
        initChart(){
            let unit_name = this.cutdata(this.map_data.value_unit)||''
            let option = {
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                borderColor: 'transparent',
                borderWidth: 0,
                padding:5,
                backgroundColor:"rgba(5,125,133,0.85)",
                formatter: (params)=>{
                    // <div style="font-size:12px;min-width:99px">'+params[0].name+'</div>
                    let tipHtml = ''
                    params.forEach(ele=>{
                        if(ele.data===null){
                            ele.data=0
                        }
                        tipHtml+='<div style="display:block;font-size:12px;display:flex;justify-content:space-between;padding:0 5px"><span><i style="height:10px;margin-right:8px;width:10px;border-radius:50%;display:inline-block;background:'+ele.color +'"></i>'+ele.seriesName +' </span><span style="font-size:12px;margin-left:10px;color:#FFCF5F;"> '+ele.data +' '+ this.map_data.value_unit+'</span></div>'
                    })
                    return tipHtml
                }
              },
              textStyle:{
                  color:'#FFF'
              },
              legend: {
                orient: 'vertical',
                x:'right',
                y:'20px',
                left: 380,
                itemWidth:10,
                itemHeight:10,
                icon: "circle",
                borderRadius:20,
                formatter: function (params) {
                    if(!params){
                        return
                    }
                let newParamsName = "";// 最终拼接成的字符串
                let paramsNameNumber = params.length||0;// 实际标签的个数
                let provideNumber = 6;// 每行能显示的字的个数
                let rowNumber = Math.ceil(paramsNameNumber / provideNumber);// 换行的话，需要显示几行，向上取整
                
                // 条件等同于rowNumber>1
                if (paramsNameNumber > provideNumber) {
                    for (let p = 0; p < rowNumber; p++) {
                        let tempStr = "";// 表示每一次截取的字符串
                        let start = p * provideNumber;// 开始截取的位置
                        let end = start + provideNumber;// 结束截取的位置
                        // 此处特殊处理最后一行的索引值
                        if (p == rowNumber - 1) {
                            // 最后一次不换行
                            tempStr = params.substring(start, paramsNameNumber);
                        } else {
                            // 每一次拼接字符串并换行
                            tempStr = params.substring(start, end) + "\n";
                        }
                        newParamsName += tempStr;// 最终拼成的字符串
                    }
                } else {
                    // 将旧标签的值赋给新标签
                    newParamsName = params;
                }
                //将最终的字符串返回
                return newParamsName
                },
                textStyle:{
                    color:'#FFF',
                    fontSize:12,
                    width: '30px',
                    height:30,
                    overflow: "break"
                },
                data:this.map_data.x
              },
              color:['#129BFF','#31FBFB','#4120E6','#F85F64','#FFCF5F'],
              grid: {
                  left: '16px',
                  right: '95px',
                  top:'24px',
                  bottom: '3%',
                  containLabel: true
              },
              xAxis: {
                  type: 'value',
                  label: {
                        show: false
                    },
                    splitLine:{
                　　　　show:false
                　　},
                    axisLine:{
                        show:false,
                    },
                    splitLine:{
                　　　　show:false
                　　},
                name: unit_name,
                nameLocation:'end',
                nameTextStyle:{
                    verticalAlign:'top',
                    lineHeight:11,
                    padding:[7, 5, 0, 5],
                    fontWeight:'400',
                    align:'left',
                    fontSize:10,
                    height:90,
                    lineOverflow:'none'
                }
              },
              yAxis: {
                type: 'category',
                label: {
                    show: false
                },
                splitLine:{
            　　　　show:false
            　　},
                axisLine:{
                    show:false
                },
                splitLine:{
            　　　　show:false
            　　},
                data:this.map_data?.y ||[]
              },
              series:this.series
          };
            this.hlInstance = this.$echarts.init(this.$refs.hr2)
            this.hlInstance.setOption(option);
        }
    },
    mounted(){
        
    }
}
</script>

<style>

</style>