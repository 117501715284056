<template>
  <div id="map" style="width: 926px; height: 664px"></div>
</template>

<script>
// import axios from "axios";
import ret from "../../public/static/map/china.json";
export default {
  props: ["prodata"],
  data() {
    return {
      maxdata: [],
      middledata: [],
      mindata: [],
      middlelist: [],

      options: [
        {
          value: "110000",
          label: "北京",
        },
        {
          value: "120000",
          label: "天津",
        },
        {
          value: "130000",
          label: "河北",
        },
        {
          value: "140000",
          label: "山西",
        },
        {
          value: "150000",
          label: "内蒙古",
        },
        {
          value: "210000",
          label: "辽宁",
        },
        {
          value: "220000",
          label: "吉林",
        },
        {
          value: "230000",
          label: "黑龙江",
        },
        {
          value: "310000",
          label: "上海",
        },
        {
          value: "320000",
          label: "江苏",
        },
        {
          value: "330000",
          label: "浙江",
        },
        {
          value: "340000",
          label: "安徽",
        },
        {
          value: "350000",
          label: "福建",
        },
        {
          value: "360000",
          label: "江西",
        },
        {
          value: "370000",
          label: "山东",
        },
        {
          value: "410000",
          label: "河南",
        },
        {
          value: "420000",
          label: "湖北",
        },
        {
          value: "430000",
          label: "湖南",
        },
        {
          value: "440000",
          label: "广东",
        },
        {
          value: "450000",
          label: "广西",
        },
        {
          value: "460000",
          label: "海南",
        },
        {
          value: "500000",
          label: "重庆",
        },
        {
          value: "510000",
          label: "四川",
        },
        {
          value: "520000",
          label: "贵州",
        },
        {
          value: "530000",
          label: "云南",
        },
        {
          value: "540000",
          label: "西藏",
        },
        {
          value: "610000",
          label: "陕西",
        },
        {
          value: "620000",
          label: "甘肃",
        },
        {
          value: "630000",
          label: "青海",
        },
        {
          value: "640000",
          label: "宁夏",
        },
        {
          value: "650000",
          label: "新疆",
        },
        {
          value: "710000",
          label: "台湾",
        },
        {
          value: "810000",
          label: "香港",
        },
        {
          value: "820000",
          label: "澳门",
        },
      ],
      geoCoordMap: {
        北京: [116.4551, 40.2539],
        天津: [117.4219, 39.4189],
        河北: [114.4995, 38.1006],
        山西: [112.3352, 37.9413],
        内蒙古: [111.4124, 40.4901],
        辽宁: [123.1238, 42.1216],
        吉林: [125.8154, 44.2584],
        黑龙江: [127.9688, 45.368],
        上海: [121.4648, 31.2891],
        江苏: [120.2234,33.5577],
        浙江: [119.5313, 29.8773],
        安徽: [117.29, 32.0581],
        福建: [119.4543, 25.9222],
        江西: [116.0046, 28.6633],
        山东: [117.1582, 36.8701],
        河南: [113.4668, 34.6234],
        湖北: [114.3896, 30.6628],
        湖南: [113.0823, 28.2568],
        广东: [113.5107, 23.2196],
        广西: [108.479, 23.1152],
        海南: [110.3893, 19.8516],
        重庆: [107.7539, 30.1904],
        四川: [103.9526, 30.7617],
        贵州: [106.6992, 26.7682],
        云南: [102.9199, 25.4663],
        西藏: [91.1865, 30.1465],
        陕西: [109.1162, 34.2004],
        甘肃: [103.5901, 36.3043],
        青海: [101.4038, 36.8207],
        宁夏: [106.3586, 38.1775],
        新疆: [87.9236, 43.5883],
        台湾: [121.01, 23.54],
        澳门: [113.54, 22.19],
        香港: [114.17, 22.32],
      },
    };
  },
  watch: {
    prodata() {
      this.data();
      this.intmap();
    },
  },
  mounted() {
    this.data();
    this.intmap();

    // console.log(this.prodata);
  },
  methods: {
    intmap() {
      echarts.registerMap("china", ret);
      var chart = echarts.init(document.getElementById("map"));

      // 目标节点（流向节点）
      //   var keyNode = [
      //     {
      //       name: "华东供电公司",
      //       gps: [114.854911, 33.901464],
      //       project: 100,
      //       prevence: 3002,
      //     },
      //     {
      //       name: "华南电公司",
      //       gps: [115.854911, 26.901464],
      //       project: 40,
      //       prevence: 3002,
      //     },
      //   ];
      // 原始节点
      //   var dataMap = [
      //     {
      //       name: "上海",
      //       value: 95,
      //       gps: [121.4648, 31.2891],
      //       project: 20,
      //       prevence: 3002,
      //     },
      //   ];
      let dataMap = this.maxdata; //起始节点
      let keyNode = this.middledata; //目标节点
      let otherdata = this.mindata; // 无连线的节点

      //无连线的节点
      //   var otherdata = [
      //     {
      //       name: "深圳",
      //       value: [114.07, 22.62, 200],
      //       project: 20,
      //       prevence: 3002,
      //     },
      //     {
      //       name: "广州",
      //       value: [113.23, 23.16, 150],
      //       project: 20,
      //       prevence: 3002,
      //     },
      //   ];

      // 原始节点
      var dataMapDatas = keyNode.map(({ name: dName }, i) => ({
        name: dName,
        type: "scatter",
        coordinateSystem: "geo",
        zlevel: 2,
        symbolSize: 15,
        rippleEffect: {
          brushType: "stroke",
        },
        label: {
          normal: {
            show: true,
            position: "bottom",
            formatter: "{b}",
            color: "black",
            fontSize: "16px",
            fontWeight: "600",
          },
        },
        tooltip: {
          alwaysShwContent: true,
          formatter: function (params) {
            // console.log(params);
            let text = "";
            text += `
              <div style=" display: flex;align-items: center;">
                <div style="  padding: 0 6px;
                  height: 32px;
                  background: #129bff;
                  border-radius: 8px;
                  line-height: 32px;
                  text-align: center;
                  margin-right: 8px;
                  font-weight: 600;
                  color: #ffffff;">${params.data.name}</div>
                 <div style="  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  font-weight: 400;
                  color: rgba(0, 0, 0, 0.65);">
             <div>项目数：${params.data.project}+</div>
             <div> 营业额： ${params.data.prevence}万+</div>
            </div>
             </div>
            `;
            return text;
          },
          position: "top",
        },
        itemStyle: {
          normal: {
            color: "#096dd0",
          },
        },
        data: dataMap.map(({ name, gps, value, project, prevence }, i) => ({
          name,
          value: [...gps, value],
          project,
          prevence,
        })),
      }));
      // 目标节点
      var dataKeyNode = keyNode.map(
        ({ name, gps: value, project, prevence }, i) => ({
          name,
          value,
          visualMap: false,
          project,
          prevence,
        })
      );
      

      // 流向线条
      var dataMapLines = keyNode.map(({ name: dName, gps: dGps }, i) => ({
        name: dName,
        type: "lines",
        zlevel: 3,
        symbol: "none",
        
        color:"red",
        effect: {
          show: true,
          period: 2.5,
          trailLength: 0.2,
          symbolSize: 10,
          // color:red,
        },
        lineStyle: {
          normal: {
            opacity: 3,
            curveness: 0.2,
            color: "#247cd4",
     
            width: 3,
          },
        },

        data: dataMap.map(({ name, gps, value, project, prevence }, i) => ({
          fromName: name,
          toName: dName,
          coords: [gps, dGps],
          value: value,
          project,
          prevence,
          name,
          value: [...gps, value],
        })),
      }));

      let option = {
        tooltip: {
          trigger: "item",
          formatter: function (params) {
            // console.log(params);
            let text = "";
            text += `
              <div style=" display: flex;align-items: center;">
                <div style="  padding: 0 6px;
                  height: 32px;
                  background: #129bff;
                  border-radius: 8px;
                  line-height: 32px;
                  text-align: center;
                  margin-right: 8px;
                  font-weight: 600;
                  color: #ffffff;">${params.data.name}</div>
                 <div style="  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  font-weight: 400;
                  color: rgba(0, 0, 0, 0.65);">
             <div>项目数：${params.data.project}+</div>
             <div> 营业额： ${params.data.prevence}万+</div>
            </div>
             </div>
            `;
            return text;
          },
        },

        geo: {
          map: "china",
          zoom: 1.25,
          zlevel: 2,
          label: {
            emphasis: {
              show: false,
            },
          },
          roam: false,
          itemStyle: {
            normal: {
              areaColor: "#f4f4f4",
              borderColor: "#666",
            },
            emphasis: {
              color: "#f4f4f4",
            },
           
     
          },
        },
        series: [
          // 原始节点
          ...dataMapDatas,
          // 目标节点
          {
            type: "scatter",
            coordinateSystem: "geo",
            zlevel: 4,
            symbolSize: 15,
            label: {
              normal: {
                show: true,
                position: "bottom",
                formatter: "{b}",
                color: "black",
                fontSize: "16px",
                fontWeight: "600",
              },
            },
            tooltip: {
              position: "top",
            },
            itemStyle: {
              normal: {
                // color: "transpant",
                color: "#096dd0",
              },
            },
            rippleEffect: {
              scale: 4,
              brushType: "stroke",
            },
            data: dataKeyNode,
          },
          // 线条流向
          ...dataMapLines,
          //不用连线的点
          {
            type: "scatter",
            coordinateSystem: "geo",
            zlevel: 4,
            symbolSize: 15,
            label: {
              normal: {
                show: false,
                position: "top",
                formatter: "{b}",
                color: "black",
              },
            },
            tooltip: {
              position: "top",
            },
            itemStyle: {
              normal: {
                color: "#6fa6dc",
              },
            },
            rippleEffect: {
              scale: 4, //涟漪放大倍数
              brushType: "stroke",
            },
            data: otherdata,
          },
        ],
      };

      chart.setOption(option);
    },
    data() {
      let { region = [], platforms = [] } = this.prodata;
      // console.log(platforms, region);
      let max = 0;
      let maxname = "";
      region.forEach((item, index) => {
        item.province_list.forEach((item1, index1) => {
          if (item1.project_num > max) {
            max = item1.project_num;
            maxname = item1.province;
            this.$set(this.maxdata, 0, {
              name: item1.province,
              gps: this.geoCoordMap[item1.province],
              project: item1.project_num,
              prevence: item1.allrevenue,
            });
          }
        });
      });
   

      let middlename = [];
      for (let item1 of region) {
        let a = Math.max.apply(
          Math,
          item1.province_list.map((item) => {
            return item.project_num;
          })
        );
        for (let item of item1.province_list) {
          if (item.project_num == a) {
            middlename.push(item.province);
            this.middledata.push({
              name: item.province,
              gps: this.geoCoordMap[item.province],
              project: item.project_num,
              prevence: item.allrevenue,
            });
            break;
          }
        }
      }
      //   console.log(middlename);
      this.middledata.forEach((item, index) => {
        //   console.log(item.name,maxname);
        if (item.name == maxname) {
          this.middledata.splice(index, 1);
        }
      });

      region.forEach((item, index) => {
        item.province_list.forEach((item1, index1) => {
          if (item1.province != middlename[index]) {
            this.mindata.push({
              name: item1.province,
              value: this.geoCoordMap[item1.province],
              project: item1.project_num,
              prevence: item1.allrevenue,
            });
          }
        });
      });

      // console.log(this.maxdata, this.middledata, this.mindata);
    },
  },
};
</script>

<style lang="less" scoped>
#map {
  //   background-color: #fff;
  //   background-image: "../assets/mode1.png";
}
.a {
  display: flex;
  align-items: center;
  padding: 0 6px;
  height: 32px;
  background: #f9494e;
 
  border-radius: 8px;
  line-height: 32px;
  text-align: center;
  margin-right: 8px;
  font-weight: 600;
  color: #ffffff;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
}
</style>

